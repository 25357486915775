import { Avatar, Badge, notification, Spin } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { CiMenuKebab } from "react-icons/ci";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../Helpers/axios";
import Apis from "../../Helpers/Apis";
import { usePagination } from "../../Helpers/custom-hooks/usePagination";
import { debounce } from "lodash";
import { useDispatch } from "react-redux";
import LeftSideTitlePart from "../../Components/HeaderParts/LeftSideTitlePart";
import { HiOutlineUserGroup } from "react-icons/hi2";
import RightSIdeTitlePart from "../../Components/HeaderParts/RightSIdeTitlePart";
import { setActiveTab } from "../../Redux/slices/activeTab.reducer";
import NoData from "../../Components/NoData";
import { TableStriped } from "../../Common/CommonUiStyles";
import { useNavigate } from "react-router-dom";

// TODO:- Add sorting and selection
// const GebruikerSort = styled(Flex)`
//   border-radius: 8px;
//   background: var(--gebruikers-table-sortBg);
//   box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.04);
//   backdrop-filter: blur(2px);
//   position: sticky;
//   top: 10px;
//   z-index: 1;
//   padding: 13px 24px;
//   align-items: center;
//   justify-content: space-between;
//
//   .ant-checkbox {
//     .ant-checkbox-inner {
//       border-radius: 100vh;
//       border: 1px solid #88909a;
//     }
//
//     + span {
//       color: #88909a;
//       font-size: 16px;
//       font-style: normal;
//       font-weight: 300;
//     }
//   }
//
//   .email-sort-button {
//     &:hover,
//     &:active,
//     &:focus-visible {
//       background-color: transparent !important;
//     }
//   }
// `;

const TableStatus = styled(Badge)`
  --active-badge: 84, 161, 133;
  --suspended-badge: 224, 168, 61;
  --draft-badge: 136, 144, 154;
  --gearchiveerd-badge: 224, 54, 78;
  --uitgenodigd-badge: 105, 202, 167;

  padding: 7px 12px;
  border-radius: 100vh;

  .ant-badge-status-dot {
    width: 8px !important;
    height: 8px !important;
  }

  &.active-badge {
    background-color: rgba(var(--active-badge), 0.16);
    border: 1px solid transparent;

    .ant-badge-status-text {
      color: rgb(var(--active-badge));
    }

    .ant-badge-status-dot {
      background-color: rgb(var(--active-badge));
    }
  }

  &.suspended-badge {
    background-color: rgba(var(--suspended-badge), 0.16);
    border: 1px solid transparent;

    .ant-badge-status-text {
      color: rgb(var(--suspended-badge));
    }

    .ant-badge-status-dot {
      background-color: rgb(var(--suspended-badge));
    }
  }

  &.draft-badge {
    background-color: rgba(var(--draft-badge), 0.16);
    border: 1px solid rgba(var(--draft-badge), 0.16);

    .ant-badge-status-text {
      color: rgb(var(--draft-badge));
    }

    .ant-badge-status-dot {
      background-color: rgb(var(--draft-badge));
    }
  }

  &.gearchiveerd-badge {
    background-color: rgba(var(--gearchiveerd-badge), 0.16);
    border: 1px solid transparent;

    .ant-badge-status-text {
      color: rgb(var(--gearchiveerd-badge));
    }

    .ant-badge-status-dot {
      background-color: rgb(var(--gearchiveerd-badge));
    }
  }

  &.uitgenodigd-badge {
    background-color: rgba(var(--uitgenodigd-badge), 0);
    border: 1px solid rgba(var(--uitgenodigd-badge), 0.32);

    .ant-badge-status-text {
      color: rgb(var(--uitgenodigd-badge));
    }

    .ant-badge-status-dot {
      background-color: rgb(var(--uitgenodigd-badge), 0.48);
    }
  }
`;

const AlleGebruikersTable = () => {
  const { t } = useTranslation();
  // TODO:- Add sorting and selection
  // const [checked, setChecked] = useState(false);
  // const [labelText, setLabelText] = useState(t("select") + " " + t("all"));
  const [tenantUsersLoading, setTenantUsersLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [tenantUsers, setTenantUsers] = useState([]);
  const path = location.pathname.split("/");
  const dispatch = useDispatch();
  const { pagination, updatePagination } = usePagination(
    1,
    10,
    "created_at",
    true,
  );
  const navigate = useNavigate();

  // TODO:- Add sorting and selection
  // const handleCheckboxChange = () => {
  //   setChecked(!checked);
  // };
  // useEffect(() => {
  //   setLabelText(
  //     checked ? t("select") + " " + t("all") : t("users") + " " + t("selected"),
  //   );
  // }, [checked, t]);

  const columns = [
    {
      title: "",
      dataIndex: "",
      className: "whitespace-nowrap",
      render: (data) => (
        <>
          <div className={"flex items-center"}>
            <Avatar className="!flex shrink-0 items-center justify-center">
              {data?.first_name?.length &&
                (data?.first_name[0] || "")?.toUpperCase() +
                  ((data?.last_name?.length &&
                    (data?.last_name[0] || "")?.toUpperCase()) ||
                    "")}
            </Avatar>
            <span className="ms-4 font-medium">{`${data?.first_name || ""} ${data?.last_name || ""}`}</span>
          </div>
        </>
      ),
    },
    {
      title: "",
      dataIndex: "email",
    },
    {
      title: "",
      dataIndex: "auth0Id",
      render: (data) => <span className="text-project-base/60">{data}</span>,
    },
    {
      title: "",
      dataIndex: "createdAt",
      className: "whitespace-nowrap",
      render: (data) => <span className="text-project-base/60">{data}</span>,
    },
    {
      title: "",
      dataIndex: "is_deleted",
      render: (data) => (
        <>
          <TableStatus
            status={data ? "default" : "success"}
            text={t(data ? "deleted" : "active")}
            className={data ? "gearchiveerd-badge" : "active-badge"}
          />
        </>
      ),
    },
    {
      title: "",
      dataIndex: "moreMenu",
      render: () => <CiMenuKebab />,
    },
  ];

  const getTenantUsers = useCallback(
    async (searchTerm, page, limit = pagination.pageSize) => {
      try {
        const resp = await axiosInstance.get(
          Apis.GET_ALL_TENANT_USERS({ page, limit, filter: searchTerm }),
        );
        setTenantUsers(resp?.data?.payload?.data || []);
        updatePagination(
          "totalItems",
          resp.data?.payload?.meta?.total_found || 0,
        );
        updatePagination("currentPage", page);
        updatePagination("pageSize", limit);
      } catch (error) {
        notification.error({ message: t("something_went_wrong") });
      } finally {
        setTenantUsersLoading(false);
      }
    },
    [updatePagination, t],
  );

  const debouncedGetTenantUsers = useCallback(
    debounce((searchTerm, page) => {
      setTenantUsersLoading(true);
      getTenantUsers(searchTerm, page);
    }, 500),
    [],
  );

  useEffect(() => {
    setTenantUsersLoading(true);
    getTenantUsers(searchTerm, pagination.currentPage);
  }, []);

  return (
    <>
      {/* TODO:- Add sorting and selection */}
      {/* <GebruikerSort className="mb-8">
        <Checkbox checked={checked} onChange={handleCheckboxChange}>
          {labelText}
        </Checkbox>
        <Button className="email-sort-button | flex items-center gap-4 text-left border-0 email-sort bg-transparent">
          <span>
            <span className="block text-sm text-slate-400">
              {t("sort") + "  " + t("on")}:
            </span>
            <p className="block text-base text-slate-600">E-mail</p>
          </span>
          <BiSortAlt2 size={20} />
        </Button>
      </GebruikerSort> */}
      <div className="px-4 xl:px-16 w-full">
        <div className="primary-header pt-9 pb-5">
          <LeftSideTitlePart
            pageTitle={t("users")}
            pageIcon={true}
            pageInnerIcon={<HiOutlineUserGroup size={20} />}
            backButton={false}
          />
          <RightSIdeTitlePart
            isFilterShow={true}
            searchTermPlaceholder={t("search")}
            searchTerm={searchTerm}
            searchDisabled={
              !tenantUsers?.length && (!searchTerm || searchTerm === "")
            }
            setSearchTerm={setSearchTerm}
            userAdd={true}
            debouncedInit={debouncedGetTenantUsers}
            handleUserAddClick={() => {
              dispatch(
                setActiveTab({
                  page: path[1],
                  tab: t("all_users"),
                  redirectionLink: "/users/user/add",
                }),
              );
            }}
          />
        </div>

        <section className="mt-4">
          <TableStriped
            pagination={{
              locale: { items_per_page: "" },
              pageSizeOptions: [10, 25, 50],
              showSizeChanger: true,
              hideOnSinglePage: true,
              pageSize: pagination.pageSize,
              defaultCurrent: 1,
              defaultPageSize: 10,
              onChange: (page, pageSize) => {
                setTenantUsersLoading(true);
                getTenantUsers(searchTerm, page, pageSize);
              },
              total: pagination.totalItems,
              current: pagination.currentPage,
            }}
            // TODO:- Add row selection
            // rowSelection={{
            //   type: "checkbox",
            // }}
            showHeader={false}
            rowKey={(record) => record?._id}
            onRow={(record) => {
              return {
                onClick: () => {
                  navigate(`/users/user/${record?._id}`);
                  dispatch(
                    setActiveTab({
                      page: path[1],
                      tab: "Users",
                      redirectionLink: `/users/user/${record?._id}`,
                    }),
                  );
                },
              };
            }}
            columns={columns}
            dataSource={tenantUsers}
            scroll={{
              x: 500,
            }}
            locale={{
              emptyText: <NoData title={t("no_data_found")} />,
            }}
            loading={{
              spinning: tenantUsersLoading,
              indicator: <Spin indicator={<div className="custom-loader" />} />,
            }}
          />
        </section>
      </div>
    </>
  );
};

export default AlleGebruikersTable;
