import React from "react";
import { useTranslation } from "react-i18next";
import { TableStriped } from "../../Common/CommonUiStyles";
import { Spin } from "antd";
import NoData from "../../Components/NoData";

const TenantListTable = ({
  tenants,
  setActiveTenant,
  setIsDrawerOpen,
  pagination,
  init,
  searchTerm,
  loader,
  setLoader,
}) => {
  const { t } = useTranslation();

  const columns = [
    {
      title: t("name"),
      dataIndex: "name",
      render: (record, rowData) => {
        return (
          <div
            onClick={() => {
              setActiveTenant(rowData), setIsDrawerOpen(true);
            }}
            style={{ cursor: "pointer" }}
          >
            {rowData?.name}
          </div>
        );
      },
    },
    {
      title: t("email"),
      dataIndex: "email",
      render: (record, rowData) => {
        return <div>{rowData?.userId?.email}</div>;
      },
    },
    // {
    // title: "Primary database connection string",
    // dataIndex: "primaryDatabase",
    // render: (record, rowData) => {
    //   return <div>{rowData?.primary_database_connection_string}</div>;
    // },
    // },
    {
      title: t("primary_domain"),
      dataIndex: "primaryDomain",
      render: (record, rowData) => {
        return <div>{rowData?.primary_domain}</div>;
      },
    },
    {
      title: t("cors_urls"),
      dataIndex: "corsUrls",
      render: (record, rowData) => {
        return <div>{rowData?.cors_urls}</div>;
      },
    },
  ];

  return (
    <TableStriped
      className="max-w-full"
      pagination={{
        pageSizeOptions: [10, 25, 50],
        current: pagination.currentPage,
        pageSize: pagination.pageSize,
        total: pagination.totalItems,
        hideOnSinglePage: true,
        showSizeChanger: true,
        locale: { items_per_page: "" },
        onChange: async (page, size) => {
          page !== pagination.currentPage &&
            (setLoader(true), await init(searchTerm, page, size));
        },
        onShowSizeChange: async (page, size) => {
          setLoader(true);
          await init(searchTerm, page, size);
        },
      }}
      rowKey={(record) => record?._id}
      columns={columns}
      dataSource={tenants?.filter((t) => !!t.name)}
      loading={{
        spinning: loader,
        indicator: <Spin indicator={<div className="custom-loader" />} />,
      }}
      locale={{
        emptyText: <NoData title={t("no_data_found")} />,
      }}
      /* scroll={{
        x: 700,
      }} */
    />
  );
};

export default TenantListTable;
