import { Radio } from "antd";
import React, { useEffect } from "react";

const CustomRadio = ({
  item,
  updateFormData,
  formData,
  isValidationError,
}) => {
  useEffect(() => {
    if (formData?.[item.path] === undefined) {
      updateFormData(item.schema_definition?.default || "");
    }
  }, [formData?.[item.path]]);
  return (
    <Radio.Group
      onChange={(e) => updateFormData(e.target.value || "")}
      options={item?.meta?.options?.choices?.sort((a, b) => a?.sort - b?.sort)}
      value={formData?.[item?.path]}
      className={`font-normal flex flex-col gap-y-2 w-full ${isValidationError ? "border-[red]" : ""}`}
      disabled={!!item?.meta?.readonly}
    ></Radio.Group>
  );
};

export default CustomRadio;
