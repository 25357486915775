import React, { useCallback, useEffect, useState } from "react";
import { Flex, Spin } from "antd";
import IconsAll from "../../../Components/IconsAll";
import LeftSideTitlePart from "../../../Components/HeaderParts/LeftSideTitlePart";
import RightSIdeTitlePart from "../../../Components/HeaderParts/RightSIdeTitlePart";
import { useTranslation } from "react-i18next";
import NoData from "../../../Components/NoData";
import Apis from "../../../Helpers/Apis";
import axiosInstance from "../../../Helpers/axios";
import withRouter from "../../../Common/withRouter";
import { debounce } from "lodash";
import { usePagination } from "../../../Helpers/custom-hooks/usePagination";
import AppInfo from "../../../Components/AppInfo";
import { TableStriped } from "../../../Common/CommonUiStyles";

const List = ({ router }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [configurations, setConfigurations] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const { pagination, updatePagination } = usePagination(
    1,
    10,
    "createdAt",
    true,
  );

  const columns = [
    {
      title: t("type"),
      dataIndex: "type",
      key: "type",
    },
  ];

  const getConfiguration = async (
    search = "",
    page = pagination.currentPage,
    limit = pagination.pageSize,
  ) => {
    try {
      const response = await axiosInstance.get(
        Apis.GET_CONFIGURATION({
          filter: search,
          pagination: true,
          page,
          limit,
        }),
      );
      if (response.status === 200) {
        setConfigurations(response?.data?.payload?.data);
        updatePagination(
          "totalItems",
          response.data?.payload?.data?.meta?.total_found || 0,
        );
        updatePagination("currentPage", page);
        updatePagination("pageSize", limit);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const debouncedInit = useCallback(
    debounce((search, page) => {
      setLoading(true);
      getConfiguration(search, page);
    }, 1000),
    [],
  );

  useEffect(() => {
    getConfiguration();
  }, []);

  return (
    <Flex className="w-full">
      <div className="px-4 xl:px-16 w-full">
        <div className="primary-header pt-9 pb-5">
          <LeftSideTitlePart
            pageTitle={t("configuration")}
            pageSubTitle={t("setting")}
            pageIcon={true}
            pageInnerIcon={<IconsAll.RequestFeatureIcon />}
            backButton={false}
          />
          <RightSIdeTitlePart
            isFilterShow={true}
            searchTerm={searchTerm}
            searchDisabled={
              !configurations?.length && (!searchTerm || searchTerm === "")
            }
            setSearchTerm={setSearchTerm}
            searchTermPlaceholder={t("search_in_configuration")}
            addButton={true}
            addDisabled={loading}
            handleAddClick={() =>
              router.navigate(`/settings/configuration/add`)
            }
            debouncedInit={debouncedInit}
          />
        </div>

        <section className="mt-4">
          <TableStriped
            onRow={(i) => ({
              onClick: () =>
                router.navigate(`/settings/configuration/${i?.type}`),
            })}
            columns={columns}
            dataSource={configurations}
            pagination={{
              pageSizeOptions: [10, 25, 50],
              current: pagination.currentPage,
              pageSize: pagination.pageSize,
              total: pagination.totalItems,
              hideOnSinglePage: true,
              showSizeChanger: true,
              locale: { items_per_page: "" },
              onChange: async (page, size) => {
                page !== pagination.currentPage &&
                  (await getConfiguration(searchTerm, page, size));
              },
              onShowSizeChange: async (page, size) => {
                await getConfiguration(searchTerm, page, size);
              },
            }}
            rowKey={(record) => record?._id}
            scroll={{
              x: 300,
            }}
            locale={{
              emptyText: <NoData title={t("no_data_found")} />,
            }}
            loading={{
              spinning: loading,
              indicator: <Spin indicator={<div className="custom-loader" />} />,
            }}
          />
        </section>
      </div>
      <AppInfo
        visibleList={["information"]}
        config={{
          msg: t("information_translations"),
        }}
      />
    </Flex>
  );
};

export default withRouter(List);
