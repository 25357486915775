import React, { useCallback, useEffect, useState } from "react";
import { Dropdown, Flex, Modal, Spin, notification } from "antd";
import IconsAll from "../../../Components/IconsAll";
import LeftSideTitlePart from "../../../Components/HeaderParts/LeftSideTitlePart";
import RightSIdeTitlePart from "../../../Components/HeaderParts/RightSIdeTitlePart";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../../Helpers/axios";
import Apis from "../../../Helpers/Apis";
import { TableStriped } from "../../../Common/CommonUiStyles";
import { Link } from "react-router-dom";
import { usePagination } from "../../../Helpers/custom-hooks/usePagination";
import { debounce } from "lodash";
import withRouter from "../../../Common/withRouter";
import { LuCopyPlus, LuPencil, LuTrash2 } from "react-icons/lu";

const EmailTemplates = ({ router }) => {
  const { t } = useTranslation();
  const { pagination, updatePagination } = usePagination(
    1,
    10,
    "createdAt",
    true,
  );

  const [emailTemplates, setEmailTemplates] = useState([]);
  const [searchFlow, setSearchFlow] = useState("");
  const [loader, setLoader] = useState(true);
  const [duplicationLoader, setDuplicationLoader] = useState(null);

  const getEmailTemplates = async (page, size, search) => {
    try {
      setLoader(true);
      const response = await axiosInstance.get(
        Apis.GET_EMAIL_TEMPLATES({
          limit: size,
          page: page,
          filter: search,
        }),
      );
      setEmailTemplates(response.data?.payload?.data || []);
      updatePagination(
        "totalItems",
        response.data?.payload?.meta?.total_found || 0,
      );
      updatePagination("currentPage", page || 1);
      updatePagination("pageSize", size);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoader(false);
    }
  };

  const duplicateEmailTemplate = async (templateId) => {
    setDuplicationLoader(templateId);
    try {
      await axiosInstance.get(Apis.DUPLICATE_EMAIL_TEMPLATE({ templateId }));
      notification.success({
        message: t("email_template_duplicated"),
      });
      await getEmailTemplates(
        pagination.currentPage,
        pagination.pageSize,
        searchFlow,
      );
    } catch (error) {
      notification.error({
        message: t("email_template_duplicate_failed"),
      });
    } finally {
      setDuplicationLoader(null);
    }
  };

  const columns = [
    {
      title: t("status"),
      dataIndex: "is_active",
      key: "status",
      render: (record) => (
        <span
          className={`${record === true ? "bg-project-base border-project-base text-project-base-text" : "bg-[var(--input-disabled-field-bg)] border-[var(--input-disabled-field-bg)] text-[var(--input-disabled-color)]"} 'block rounded-full px-3 py-1 leading-7 border h-auto text-sm`}
        >
          {t(record ? "active" : "inactive")}
        </span>
      ),
    },
    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
      render: (record, rowData) => (
        <Link to={`/settings/email-templates/${rowData?._id}`}>{record}</Link>
      ),
    },
    {
      title: t("description"),
      dataIndex: "description",
      key: "description",
      render: (record) => (
        <span className="whitespace-nowrap text-ellipsis overflow-hidden block">
          {record}
        </span>
      ),
    },
    {
      title: "",
      dataIndex: "_id",
      key: "actions",
      render: (record, rowData) => (
        <Dropdown
          menu={{
            items: [
              {
                key: "edit",
                label: t("edit"),
                icon: <LuPencil size={16} />,
                onClick: () => {
                  router.navigate(`/settings/email-templates/${record}`);
                },
              },
              {
                key: "duplicate",
                label: t("duplicate"),
                disabled:
                  duplicationLoader === record ||
                  rowData?.is_deletable === false,
                icon:
                  duplicationLoader === record ? (
                    <Spin size="small" />
                  ) : (
                    <LuCopyPlus size={16} />
                  ),
                onClick: () => {
                  Modal.confirm({
                    title: t("duplicate_template"),
                    content: t("duplicate_template_confirmation", {
                      selectedTemplate: rowData?.name,
                    }),
                    icon: (
                      <div className="modal-icon">
                        <LuCopyPlus size={22} />
                      </div>
                    ),
                    okText: t("duplicate"),
                    okType: "primary",
                    cancelText: t("cancel"),
                    maskClosable: true,
                    className: "modal-confirm",
                    onOk: async () => {
                      !(duplicationLoader === record) &&
                        (await duplicateEmailTemplate(record));
                    },
                  });
                },
              },
              {
                key: "delete",
                label: t("delete"),
                danger: true,
                icon: <LuTrash2 size={16} />,
                onClick: () => {
                  Modal.confirm({
                    title: t("delete_template"),
                    content: t("delete_template_confirmation", {
                      selectedTemplate: rowData?.name,
                    }),
                    icon: (
                      <div className="modal-icon">
                        <IconsAll.TrashIcon />
                      </div>
                    ),
                    okText: t("delete"),
                    okType: "primary",
                    cancelText: t("cancel"),
                    maskClosable: true,
                    className: "modal-delete",
                    onOk: async () => {
                      try {
                        await axiosInstance.delete(
                          Apis.DELETE_EMAIL_TEMPLATE({ templateId: record }),
                        );
                        await getEmailTemplates(
                          pagination.currentPage,
                          pagination.pageSize,
                          searchFlow,
                        );
                        notification.success({
                          message: t("email_template_deleted"),
                        });
                      } catch (error) {
                        notification.error({
                          message: t("email_template_delete_failed"),
                        });
                      }
                    },
                  });
                },
                disabled: rowData?.is_deletable === false,
              },
            ],
          }}
          trigger={["click"]}
        >
          <div>
            <IconsAll.MoreIcon />
          </div>
        </Dropdown>
      ),
    },
  ];

  useEffect(() => {
    getEmailTemplates(pagination.currentPage, pagination.pageSize, searchFlow);
  }, []);

  const getSearchedFlow = useCallback(
    debounce((search, page) => {
      getEmailTemplates(page, pagination.pageSize, search);
    }, 700),
    [pagination.pageSize],
  );

  return (
    <Flex className="w-full">
      <div className="px-4 xl:px-16 w-full">
        <div className="primary-header pt-9 pb-5">
          <LeftSideTitlePart
            pageTitle={t("email_templates")}
            pageSubTitle={t("setting")}
            pageIcon={true}
            pageInnerIcon={<IconsAll.EmailTemplatesIcon />}
            backButton={false}
          />
          <RightSIdeTitlePart
            isFilterShow={true}
            searchTerm={searchFlow}
            setSearchTerm={setSearchFlow}
            debouncedInit={getSearchedFlow}
            addButton={true}
            handleAddClick={() => {
              router.navigate("/settings/email-templates/add");
            }}
          />
        </div>
        <section className="mt-4 w-full h-4/5">
          <TableStriped
            columns={columns}
            dataSource={emailTemplates}
            rowKey={(record) => record?._id}
            pagination={{
              pageSizeOptions: [10, 25, 50],
              current: pagination.currentPage,
              pageSize: pagination.pageSize,
              total: pagination.totalItems,
              hideOnSinglePage: true,
              showSizeChanger: true,
              locale: { items_per_page: "" },
              onChange: async (page, size) => {
                await getEmailTemplates(page, size);
              },
            }}
            loading={{
              spinning: loader,
              indicator: <Spin indicator={<div className="custom-loader" />} />,
            }}
          />
        </section>
      </div>
    </Flex>
  );
};

export default withRouter(EmailTemplates);
