import { Button, Col, Flex, Form, Input, notification, Row } from "antd";
import React, { useEffect, useState } from "react";
import LeftSideTitlePart from "../../../Components/HeaderParts/LeftSideTitlePart";
import RightSIdeTitlePart from "../../../Components/HeaderParts/RightSIdeTitlePart";
import { useTranslation } from "react-i18next";
import withRouter from "../../../Common/withRouter";
import { AiOutlinePlus } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import axiosInstance from "../../../Helpers/axios";
import Apis from "../../../Helpers/Apis";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../../Redux/slices/loader.reducer";
import { useSelector } from "react-redux";

const EditConfiguration = ({ router }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const loader = useSelector((state) => state.loader.isLoading);
  const dispatch = useDispatch();

  const getConfiguration = async (type) => {
    dispatch(showLoader());
    try {
      let response = await axiosInstance.get(Apis.GET_CONFIGURATION({ type }));
      response = response?.data?.payload?.data?.[0];
      delete response?._id;

      const arrayOfObjects = Object.entries(response).map(([key, value]) => ({
        key,
        value,
      }));

      form.setFieldsValue({ items: arrayOfObjects });
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(hideLoader());
    }
  };

  const handleSubmit = async () => {
    try {
      const formData = await form.validateFields();
      setLoading(true);
      const object = formData?.items?.reduce((acc, item) => {
        acc[item.key] = item.value;
        return acc;
      }, {});

      delete object?.type;

      const response = await axiosInstance.put(
        Apis.UPDATE_CONFIGURATION({ type: params?.field }),
        object,
      );
      router.navigate("/settings/configuration");
      notification.success({ message: response.data.message });
    } catch (error) {
      notification.success({ message: error.response.data.message });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getConfiguration(params?.field);
  }, [params]);

  return (
    <Flex className="w-full main-content-wrap">
      <div className="px-4 xl:px-16 w-full left-part">
        <div className="primary-header pt-9 pb-5">
          <div
            onClick={() => {
              router.navigate(`/settings/configuration`);
            }}
            className="grow"
          >
            <LeftSideTitlePart
              pageTitle={`${t("updating_item_in")} ${t("configuration")}`}
              pageSubTitle={t("configuration")}
              backButton={true}
            />
          </div>
          <RightSIdeTitlePart
            showButton={true}
            handleShowClick={handleSubmit}
            showDisabled={loading}
            buttonLoading={loading}
          />
        </div>

        <section className="container max-w-4xl">
          {!loader && (
            <Form form={form}>
              <Form.List name="items" initialValue={[]}>
                {(fields, { add, remove }) => {
                  return (
                    <>
                      {fields.map((field) => {
                        const { items } = form.getFieldsValue();
                        const isTypeKey = items?.[field.key]?.key === "type";
                        return (
                          <Row
                            key={field.key}
                            gutter={16}
                            className="justify-start mb-4"
                          >
                            <Col span={11}>
                              <Form.Item
                                {...field}
                                name={[field.name, "key"]}
                                key={`${field.key}_key`}
                                className="mb-0"
                                rules={[
                                  {
                                    required: true,
                                    message: t("please_enter_the_key"),
                                  },
                                ]}
                              >
                                <Input
                                  placeholder={t("key")}
                                  className={`project-custom-input`}
                                  disabled={isTypeKey}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={11}>
                              <Form.Item
                                {...field}
                                name={[field.name, "value"]}
                                key={`${field.key}_value`}
                                rules={[
                                  {
                                    required: true,
                                    message: t("please_enter_the_value"),
                                  },
                                ]}
                                className="mb-0"
                              >
                                <Input
                                  placeholder={t("value")}
                                  className={`project-custom-input`}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={2}>
                              {!isTypeKey && (
                                <RiDeleteBin6Line
                                  onClick={() => remove(field.name)}
                                  size={18}
                                  className="btn text-delete-base cursor-pointer min-h-14"
                                />
                              )}
                            </Col>
                          </Row>
                        );
                      })}
                      <Row>
                        <Col span={22}>
                          <Form.Item>
                            <Button
                              className="project-custom-btn w-full transition-all duration-200"
                              onClick={() => add()}
                              block
                              icon={<AiOutlinePlus />}
                            >
                              {t("add_item")}
                            </Button>
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  );
                }}
              </Form.List>
            </Form>
          )}
        </section>
      </div>
    </Flex>
  );
};

export default withRouter(EditConfiguration);
