import React, { useEffect, useState } from "react";
import { Button, Col, Flex, Row, Typography } from "antd";
import SubMenu from "../../Components/SubMenu";
import { capitalizeAndFormat } from "../../Helpers/utils";
import withRouter from "../../Common/withRouter";
import ContentTable from "./ContentTable";
import AddContent from "./AddContent";
import { useDispatch } from "react-redux";
import { setActiveTab } from "../../Redux/slices/activeTab.reducer";
import LeftSideTitlePart from "../../Components/HeaderParts/LeftSideTitlePart";
import IconsAll from "../../Components/IconsAll";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { buildTree } from "../Instellingen/utilities";

const NoContent = () => {
  let navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <>
      <Row className="main-content-wrap">
        <Col className="px-8 xl:px-16 left-part" span={24}>
          <LeftSideTitlePart
            pageSubTitle={t("content")}
            pageIcon={true}
            pageInnerIcon={<IconsAll.ContentIcon />}
            backButton={false}
          />

          <Row className="flex items-center justify-center no-content-custom-height h-full w-full">
            <Col className="flex flex-col items-center justify-center text-center space-y-4">
              <div className="no-content-title-icon flex items-center justify-center size-40">
                <IconsAll.ContentIcon width={50} height={50} />
              </div>
              <Typography.Title level={4} className="text-gray-base">
                {t("no_collections")}
              </Typography.Title>
              <Typography.Paragraph className="text-gray-base">
                {t("no_collections_msg_1")} <br />
                {t("no_collections_msg_2")}
              </Typography.Paragraph>
              <Button
                className="project-custom-btn rounded-full bg-white border-grey-base/90 text-black-base w-full"
                onClick={() => navigate(`/settings/data-model`)}
              >
                {t("create_new_collection")}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

const Content = () => {
  const path = location.pathname.split("/");
  const [selectedRows, setSelectedRows] = useState([]);
  const [currentCollection, setCurrentCollection] = useState(null);
  const [loading, setLoading] = useState(true);
  const schemas = useSelector((state) => state?.schemas?.schemas);
  const dispatch = useDispatch();

  const init = async (schema) => {
    !path[2] 
    path[2] &&
      dispatch(
        setActiveTab({
          page: "content",
          tab: capitalizeAndFormat(schema?.collection_id),
          redirectionLink: `/content/${path[2]}`,
        }),
      );
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  useEffect(() => {
    const schema = schemas?.find((c) => c?._id === path[2]);
    setCurrentCollection(schema);
    init();
  }, [schemas]);


  const generateItems = () => {
    const processItems = (items) => {
      return items?.map((item) => {
        const children =
          item?.children?.length > 0 ? processItems(item.children) : null;
        return {
          ...item,
          link: !item?.meta?.is_folder && "/content/" + item._id,
          name: capitalizeAndFormat(item.collection_name),
          children,
        };
      });
    };
    return processItems(buildTree(schemas?.filter((item) => item?.visible)));
  };

  return (
    <Flex className="w-full">
      <SubMenu
        isSubMenuLogo
        isSubMenuVersion
        items={generateItems()}
        currentPage={path[1]}
        setCurrentCollection={setCurrentCollection}
        disabled={loading}
      />
      {schemas?.length > 0 && !loading ? (
        path.includes("add") ||
        path.includes("edit") ||
        currentCollection?.meta?.singleton ? (
          <AddContent currentCollection={currentCollection} />
        ) : (
          path[2] && (
            <ContentTable
              tableType="content"
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
            />
          )
        )
      ) : (
        !loading && <NoContent />
      )}
    </Flex>
  );
};

export default withRouter(Content);
