import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "antd"; // Add this import
import withRouter from "../Common/withRouter";
import IconsAll from "../Components/IconsAll";
import BrandLogo from "../Assets/images/logo-sm.svg";
import BrandSmLogo from "../Assets/images/logo-sm.svg";
// import profilePic from "../Assets/images/users/avatar-1.jpg";
import { Avatar, Menu } from "antd";
import {
  StyleSimpleBar,
  StyleBrandLogo,
  StyleSider,
  SidebarDivider,
  SideBarUserProfile,
  SidebarNotification,
} from "../Common/SidebarStyle";
import { Link } from "react-router-dom";
import { GrNotification } from "react-icons/gr";
import { useTranslation } from "react-i18next";
import { themes } from "../config.js";
import { useSelector } from "react-redux";
import { CiLogout } from "react-icons/ci";
import axiosInstance from "../Helpers/axios.js";
import Apis from "../Helpers/Apis.js";
import { getDisplayImage, retrieveLoginPrompt } from "../Helpers/utils.js";
import { hideLoader, showLoader } from "../Redux/slices/loader.reducer.js";
import { useDispatch } from "react-redux";
import { persistor } from "../Redux/store";
import { VscSignOut } from "react-icons/vsc";
import * as LucideIcons from "lucide-react";
import { setSettings } from "../Redux/slices/sidebar.reducer.js";
const { ...filteredIcons } = LucideIcons;

const SidebarLayout = ({ router }) => {
  const { t } = useTranslation();
  const userProfile = useSelector((state) => state.user.userProfile);
  const { logo_company } = useSelector((state) => state?.appearance || {});
  const [logo, setLogo] = useState({});
  const path = location.pathname.split("/");
  const dispatch = useDispatch();
  const sidebar = useSelector((state) => state.sidebar);
  const sidebarItems = sidebar?.items;
  const activeTabState = useSelector((state) => state.activeTab);

  function getItem(label, key, icon, sort) {
    return {
      key,
      icon,
      label,
      sort,
    };
  }

  const modifyItem = (item) => {
    const IconComponent = item?.icon?.toLowerCase().includes("iconsall")
      ? IconsAll[item?.icon.split(".")[1]]
      : filteredIcons[item?.icon];

    const title =
      item.id === "media-library"
        ? `${t("media")} ${t("library")}`
        : t(`${item.id}`);

    const itemPath = activeTabState[item.id]?.redirectionLink ?? item?.path;

    return getItem(
      <Link
        to={item?.type === "link" ? itemPath : itemPath}
        className="ml-6"
        title={item.label || title}
        target={item?.type === "link" ? "_blank" : "_self"}
      >
        {item.label || title}
      </Link>,
      item.id,
      <IconComponent
        className="ant-menu-item-icon"
        size={22}
        color="var(--project-base)"
        strokeColor="var(--project-base)"
      />,
      item.sort,
    );
  };

  useEffect(() => {
    getAndSetAppearance();
  }, [logo_company]);

  const getAndSetAppearance = async () => {
    setLogo(
      !userProfile?.nox_admin && logo_company
        ? await getDisplayImage(logo_company)
        : BrandLogo,
    );
  };

  const menuItems = useMemo(() => {
    return sidebarItems
      ?.filter((item) => {
        if (item.type === "link") return true;
        const visible = item.type === "checkbox" ? item.visible : true;

        const adminKeys = ["dashboard", "users", "adminSettings"];
        const managerKeys = [
          "dashboard",
          "content",
          "media-library",
          "settings",
          "users",
        ];
        const userKeys = ["content", "users"];
        return userProfile?.nox_admin
          ? adminKeys.includes(item.id) && visible
          : userProfile?.role?.admin_access
            ? managerKeys.includes(item.id) && visible
            : userKeys.includes(item.id) && visible;
      })
      .map((item) => modifyItem(item))
      .sort((a, b) => a.sort - b.sort);
  }, [sidebarItems, activeTabState, t, userProfile]);

  // const items2 = [
  //   getItem(
  //     <Link to={`/dashboard`} className="ml-6" title={t("dashboard")}>
  //       {t("dashboard")}
  //     </Link>,
  //     "dashboard",
  //     <IconsAll.DashboardIcon strokeColor="" />,
  //     1,
  //   ),
  //   getItem(
  //     <Link
  //       to={
  //         useSelector((state) => state.activeTab["content"])?.redirectionLink ??
  //         `/content`
  //       }
  //       className="ml-6"
  //       title={t("content")}
  //     >
  //       {t("content")}
  //     </Link>,
  //     "content",
  //     <IconsAll.ContentIcon />,
  //     2,
  //   ),
  //   getItem(
  //     <Link
  //       to={
  //         useSelector((state) => state.activeTab["users"])?.redirectionLink ??
  //         `/users`
  //       }
  //       className="ml-6"
  //       title={t("users")}
  //     >
  //       {t("users")}
  //     </Link>,
  //     "users",
  //     <IconsAll.GebruikersIcon strokeColor="" />,
  //     3,
  //   ),
  //   getItem(
  //     <Link
  //       to={
  //         useSelector((state) => state.activeTab["media-library"])
  //           ?.redirectionLink ?? `/media-library`
  //       }
  //       className="ml-6"
  //       title={`${t("media")} ${t("library")}`}
  //     >
  //       {t("media")} {t("library")}
  //     </Link>,
  //     "media-library",
  //     <IconsAll.MediaLibraryIcon strokeColor="" />,
  //     4,
  //   ),
  //   getItem(
  //     <Link
  //       to={
  //         useSelector((state) => state.activeTab["settings"])
  //           ?.redirectionLink ?? `/settings/data-model`
  //       }
  //       className="ml-6"
  //       title={t("setting")}
  //     >
  //       {t("setting")}
  //     </Link>,
  //     "settings",
  //     <IconsAll.ConfigIcon strokeColor="" />,
  //     5,
  //   ),
  //   getItem(
  //     <Link
  //       to={
  //         useSelector((state) => state.activeTab["adminSettings"])
  //           ?.redirectionLink ?? `/admin/settings/system-translations`
  //       }
  //       className="ml-6"
  //       title={t("settings")}
  //     >
  //       {t("settings")}
  //     </Link>,
  //     "adminSettings",
  //     <IconsAll.ExtentiesIcon strokeColor="var(--project-base-text)" />,
  //     6,
  //   ),
  // ];

  const logoutUser = async () => {
    dispatch(showLoader());
    try {
      persistor.pause();
      await persistor.purge();
      const resp = await axiosInstance.post(Apis.LOGOUT(), {});
      if (resp.status === 200) {
        await localStorage.clear();
        retrieveLoginPrompt().then((response) => {
          window.open(response?.data, "_self");
        });
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      dispatch(hideLoader());
    }
  };

  const fetchSettings = async () => {
    dispatch(showLoader());
    try {
      const resp = await axiosInstance.get(Apis.GET_SETTING());
      if (resp.status === 200) {
        dispatch(setSettings(resp.data?.payload?.data));
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      dispatch(hideLoader());
    }
  };

  useEffect(() => {
    if (sidebar?.status === "idle") {
      fetchSettings();
    }
  }, [sidebar]);

  const showLogoutConfirm = () => {
    Modal.confirm({
      okType: "primary",
      maskClosable: true,
      icon: (
        <div className="modal-icon">
          <VscSignOut size={24} />
        </div>
      ),
      className: "modal-delete",
      title: t("Are you sure you want to logout?"),
      content: t("You will be redirected to the login page."),
      onOk: logoutUser,
      okText: t("logout"),
      cancelText: t("cancel"),
    });
  };

  return userProfile ? (
    <StyleSider
      id="sidebar-layout"
      width={() =>
        themes[useSelector((state) => state.theme.name)].components.Menu
          .verticalSidebarWidth
      }
      collapsedWidth="64"
      breakpoint="xl"
    >
      <Link to={`/dashboard`}>
        <StyleBrandLogo className="company-logo ant-mx-auto">
          <img
            alt="Brand logo"
            src={logo}
            height={30}
            style={{ lineHeight: "24px", maxWidth: "40px" }}
            className="brand-dark-logo ant-mx-auto"
          />
          <img
            alt="Brand sm logo"
            src={BrandSmLogo}
            height={24}
            style={{ lineHeight: "24px", maxWidth: "40px" }}
            className="brand-sm-logo ant-mx-auto"
          />
        </StyleBrandLogo>
      </Link>
      <StyleSimpleBar>
        <Menu
          selectedKeys={[
            path[1] === "admin" && path[2] === "settings"
              ? "adminSettings"
              : path[1],
          ]}
          mode="inline"
          theme="light"
          items={menuItems}
          collapsedWidth="100"
        ></Menu>
      </StyleSimpleBar>

      <div className="flex flex-col justify-center">
        <SidebarNotification
          type="link"
          className="active mx-auto"
          aria-label="Notifiation"
          icon={
            <GrNotification size={18} className="text-(--project-base-text)!" />
          }
        />
        <SidebarDivider className="w-full!" />
        <div
          className="mt-3 mb-5 cursor-pointer text-center"
          title={t("logout")}
        >
          <CiLogout
            color="var(--project-base-text)"
            size={24}
            onClick={showLogoutConfirm} // Update this line
            className="mx-auto"
          />
        </div>
        <SideBarUserProfile
          type="link"
          className="mx-auto! mb-3!"
          onClick={() => router.navigate("/user-profile")}
        >
          <Avatar className="flex! items-center justify-center mx-auto">
            {userProfile?.first_name?.length &&
              (userProfile?.first_name[0] || "")?.toUpperCase() +
                ((userProfile?.last_name?.length &&
                  (userProfile?.last_name[0] || "")?.toUpperCase()) ||
                  "")}
          </Avatar>
        </SideBarUserProfile>
      </div>
    </StyleSider>
  ) : (
    <></>
  );
};

export default withRouter(SidebarLayout);
