import {
  Col,
  Flex,
  Form,
  Input,
  Row,
  Button,
  notification,
  Spin,
  Radio,
  Dropdown,
  Tooltip,
  Skeleton,
  Table,
  Tabs,
} from "antd";
import Title from "antd/lib/typography/Title";
import React, { useEffect, useState } from "react";
import * as LucideIcons from "lucide-react";
import styled from "styled-components";
import Apis from "../../../Helpers/Apis";
import axiosInstance from "../../../Helpers/axios";
import {
  CollapsWithLine,
  DrawerStyle,
  InvertedRadioBox,
} from "../../../Common/CommonUiStyles";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useTranslation } from "react-i18next";
import { IoMdCheckmark } from "react-icons/io";
import { MdOutlineDoNotDisturbAlt } from "react-icons/md";
import TextArea from "antd/es/input/TextArea";
import { useWatch } from "antd/es/form/Form";
import { useParams } from "react-router-dom";
import LeftSideTitlePart from "../../../Components/HeaderParts/LeftSideTitlePart";
import NoData from "../../../Components/NoData";
import withRouter from "../../../Common/withRouter";
import RightSIdeTitlePart from "../../../Components/HeaderParts/RightSIdeTitlePart";
import { hideLoader } from "../../../Redux/slices/loader.reducer";
import { generateTree } from "../../../Helpers/utils";
import { LuChevronDown, LuChevronUp, LuDatabase } from "react-icons/lu";
import { TbListCheck } from "react-icons/tb";
import IconsAll from "../../../Components/IconsAll";
import FieldPermissions from "./FieldPermissions";
import ItemPermissions from "./ItemPermissions";

const createItems = [
  {
    label: "All Access",
    key: "all_access",
    icon: <IoMdCheckmark size={18} />,
  },
  {
    label: "No Access",
    key: "no_access",
    icon: <MdOutlineDoNotDisturbAlt size={18} />,
  },
  //  TODO: Custom access is not implemented yet
  {
    type: "divider",
  },
  {
    label: "Use Custom",
    key: "custom_access",
    icon: <TbListCheck size={18} />,
  },
];

const TableStripe = styled(Table)`
  .ant-table {
    tbody {
      font-family: "Sometype Mono", monospace;
      font-weight: 600;

      .ant-table-row {
        &:hover {
          .opacity-0 {
            opacity: 1;
          }
        }
      }
    }

    .ant-table-cell {
      > div {
        .ant-btn-primary {
          background: hsla(var(--add-access-controll-btn), 1);
          color: white;
          border-radius: 6px;

          &:hover {
            background: hsla(var(--add-access-controll-btn), 0.8);
            border-color: hsla(var(--add-access-controll-btn), 1);
            color: white;
          }
        }

        .ant-btn-half-active {
          background: hsla(var(--add-access-controll-btn), 0.2);
          color: hsla(var(--add-access-controll-btn), 1);
          border-radius: 6px;

          &:hover {
            border-color: hsla(var(--add-access-controll-btn), 1);
          }
        }

        .ant-btn-remove {
          background: hsla(var(--add-access-controll-btn), 0);
          color: hsla(var(--add-access-controll-btn), 1);
          border-radius: 6px;

          &:hover {
            background: hsla(var(--add-access-controll-btn), 0.1);
            color: hsla(var(--add-access-controll-btn), 1);
          }
        }

        .ant-btn-select-all {
          background: hsla(var(--add-access-controll-btn), 0);
          color: hsla(var(--add-access-controll-btn), 0.6);
          border-radius: 6px;

          &:hover {
            background: hsla(var(--add-access-controll-btn), 0);
            color: #69caa7;
          }

          &.remove-all {
            background: hsla(var(--add-access-controll-btn), 0);
            color: hsla(var(--add-access-controll-btn), 0.6);

            &:hover {
              color: red !important;
            }
          }
        }
      }
    }
  }
`;

const ExpandIcon = ({ expanded, onExpand, record }) => {
  // eslint-disable-next-line no-unused-vars
  const { icons, createLucideIcon, ...filteredIcons } = LucideIcons;

  if (!record?.children) return null;
  return (
    <div
      onClick={(e) => {
        onExpand(record, e);
        e.stopPropagation();
      }}
      className={`flex items-center gap-3 cursor-pointer`}
    >
      {record?.meta?.icon ? (
        <span className="flex items-center justify-center">
          {filteredIcons[record.meta.icon] &&
            React.createElement(filteredIcons[record.meta.icon], {
              size: 22,
              color: record.meta?.color || "var(--project-base)",
            })}
        </span>
      ) : (
        <LuDatabase size={22} color="var(--project-base)" />
      )}
      {record?.collection_name}
      {expanded ? <LuChevronUp /> : <LuChevronDown />}
    </div>
  );
};

const AddEditRole = ({ router }) => {
  const { t } = useTranslation();
  const { field } = useParams();

  const [form] = Form.useForm();
  const accessValue = useWatch("access", form);

  const [loader, setLoader] = useState(true);
  const [permissions, setPermissions] = useState([]);
  const [roleData, setRoleData] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedSchema, setSelectedSchema] = useState({});

  const dispatch = useDispatch();
  const { schemas, systemCollections } = useSelector(
    ({ schemas }) => ({
      schemas: schemas.schemas,
      systemCollections: schemas.system_collections,
    }),
    shallowEqual,
  );

  // eslint-disable-next-line no-unused-vars
  const { icons, createLucideIcon, ...filteredIcons } = LucideIcons;

  const initialFormValues = {
    name: "",
    description: "",
    type: true,
    access: "app_access",
  };

  const nestedSchemas = generateTree(
    schemas,
    "_id",
    "parent",
    false,
    (a, b) => {
      const aVal = a.meta?.sort ?? Infinity;
      const bVal = b.meta?.sort ?? Infinity;
      return aVal - bVal;
    },
  );
  const nestedSystemCollections = generateTree(systemCollections);

  const isPermissionExists = (action, collectionId, data = null) => {
    return (data || permissions)?.find(
      (item) => item.collection_id === collectionId && item.action === action,
    );
  };

  const getDefaultPermissions = async (data = []) => {
    try {
      setLoader(true);
      const resp = await axiosInstance.get(Apis.GET_DEFAULT_PERMISSIONS());
      if (resp.status === 200) {
        const nonExistingPermissions = resp.data.payload.data.filter(
          (item) => !isPermissionExists(item.action, item.collection_id, data),
        );
        setPermissions((prev) => [...prev, ...data, ...nonExistingPermissions]);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
  };

  const getRoleData = async (roleId) => {
    try {
      setLoader(true);
      const resp = await axiosInstance.get(Apis.GET_A_ROLE(roleId));
      if (resp.status === 200) {
        setRoleData(resp.data.payload.data);
        form.setFieldValue(
          "description",
          resp.data.payload.data.role.description,
        );
        form.setFieldValue("name", resp.data.payload.data.role.name);
        form.setFieldValue("type", !resp.data.payload.data.role?.tenant);
        form.setFieldValue(
          "access",
          !resp.data.payload.data.role?.admin_access
            ? "app_access"
            : "admin_access",
        );
        await getDefaultPermissions(resp.data.payload.data.permissions);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoader(false);
    }
  };

  const handleSubmit = async () => {
    setLoader(true);

    try {
      const values = await form.validateFields();
      if (roleData?.role?._id) {
        const resp = await axiosInstance.put(
          Apis.UPDATE_A_ROLE(roleData.role._id),
          {
            permissions,
            description: values.description,
            access: values.access,
          },
        );
        if (resp.status === 200) {
          notification.success({ message: t("role_update_success") });
          router.navigate("/settings/toegangscontrole");
        }
      } else {
        const formattedValues = {
          name: values.name,
          description: values.description,
          is_api_user: values.type,
          access: values.access,
          permissions,
        };

        const resp = await axiosInstance.post(
          Apis.ADD_A_ROLE(),
          formattedValues,
        );
        if (resp.status === 200) {
          notification.success({ message: t("role_add_success") });
          await form.resetFields();
          setPermissions([]);
          router.navigate("/settings/toegangscontrole");
        }
      }
    } catch (error) {
      console.log("Error", error);
      error?.data?.message &&
        notification.error({
          message:
            error?.data?.message || roleData?.role?._id
              ? t("update_role_error")
              : t("add_role_error"),
        });
    } finally {
      setLoader(false);
    }
  };

  const handlePermissionToggle = (action, rowData, key) => {
    switch (key) {
      case "all_access":
        setPermissions((prev) => [
          ...prev,
          {
            action: action,
            collections: rowData?.collection_name,
            collection_id: rowData?._id,
            full_access: key === "all_access",
            fields: [],
          },
        ]);
        break;
      case "no_access":
        setPermissions((prev) => {
          const existingPermissionIndex = prev.findIndex(
            (item) =>
              item.collection_id === rowData?._id &&
              item.action === action &&
              !item.is_default,
          );
          const updatedPermissions = [...prev];
          updatedPermissions.splice(existingPermissionIndex, 1);
          return updatedPermissions;
        });
        break;
      case "custom_access":
        setSelectedSchema({ rowData, action });
        setIsDrawerOpen(true);
        break;
      case "all":
        setPermissions((prev) => {
          const prevData = [...prev];
          // TODO: Share action is not implemented yet
          const actions = ["create", "read", "update", "delete"];
          actions.forEach((action) => {
            const existingPermission = prevData.find(
              (item) =>
                item.collection_id === rowData?._id && item.action === action,
            );

            if (existingPermission) {
              existingPermission.full_access = true;
            } else {
              prevData.push({
                action: action,
                collections: rowData?.collection_name,
                collection_id: rowData?._id,
                full_access: true,
                fields: [],
              });
            }
          });
          return prevData;
        });
        break;
      case "none":
        setPermissions((prev) => {
          // TODO: Share action is not implemented yet
          const actions = ["create", "read", "update", "delete"];
          return prev.filter(
            (item) =>
              item.collection_id !== rowData?._id ||
              !actions.includes(item.action) ||
              item?.is_default,
          );
        });
        break;
    }
  };

  const ActionButton = ({ action, rowData, label }) => {
    if (rowData?.meta?.is_folder) return null;
    const permissionToggle = isPermissionExists(action, rowData?._id);
    return (
      <div>
        <Dropdown
          menu={{
            items: createItems?.map((item) => {
              let disabled = false;
              switch (item.key) {
                case "all_access":
                  disabled = !!permissionToggle;
                  break;
                case "no_access":
                  disabled = !permissionToggle;
                  break;
                default:
                  disabled = false;
              }
              return {
                ...item,
                disabled,
              };
            }),
            onClick: ({ key }) => handlePermissionToggle(action, rowData, key),
          }}
          trigger={["click"]}
          disabled={permissionToggle?.is_default}
        >
          <Tooltip
            title={
              permissionToggle?.is_default ? t("required_for_app_access") : ""
            }
            key={action}
          >
            <Button
              className={{
                "btn-primary-permission": permissionToggle?.full_access,
                "btn-permission": !permissionToggle,
                "btn-primary-partial-permission":
                  permissionToggle && !permissionToggle?.full_access,
              }}
              size="small"
              disabled={permissionToggle?.is_default}
            >
              {label}
            </Button>
          </Tooltip>
        </Dropdown>
      </div>
    );
  };

  const columns = [
    {
      title: t("collection"),
      dataIndex: "collection_name",
      key: "collection_name",
      render: (record, rowData) => {
        if (rowData?.children) return null;
        return (
          <div className={"flex gap-3 items-center"}>
            {rowData?.meta?.icon ? (
              <span className="flex items-center justify-center">
                {filteredIcons[rowData.meta.icon] &&
                  React.createElement(filteredIcons[rowData.meta.icon], {
                    size: 22,
                    color: rowData.meta?.color || "var(--project-base)",
                  })}
              </span>
            ) : (
              <LuDatabase size={22} color="var(--project-base)" />
            )}
            {rowData?.collection_name}
          </div>
        );
      },
    },
    {
      title: "",
      dataIndex: "all_or_none",
      key: "all_or_none",
      width: 50,
      render: (record, rowData) => {
        if (rowData?.meta?.is_folder) return null;
        return (
          <div className="flex text-project-base/50 opacity-0">
            <Button
              onClick={() => handlePermissionToggle("all", rowData, "all")}
              type="select-all"
              size="small"
            >
              {t("all")}
            </Button>
            /
            <Button
              onClick={() => handlePermissionToggle("all", rowData, "none")}
              type="select-all remove-all"
              size="small"
            >
              {t("none")}
            </Button>
          </div>
        );
      },
    },
    {
      title: t("actions"),
      dataIndex: "can_add",
      key: "can_add",
      width: 50,
      render: (record, rowData) => {
        return (
          <ActionButton
            action={"create"}
            rowData={rowData}
            label={t("create")}
          />
        );
      },
    },
    {
      title: "",
      dataIndex: "can_read",
      key: "can_read",
      width: 50,
      render: (record, rowData) => {
        return (
          <ActionButton action={"read"} rowData={rowData} label={t("read")} />
        );
      },
    },
    {
      title: "",
      dataIndex: "can_update",
      key: "can_update",
      width: 50,
      render: (record, rowData) => {
        return (
          <ActionButton
            action={"update"}
            rowData={rowData}
            label={t("update")}
          />
        );
      },
    },
    {
      title: "",
      dataIndex: "can_delete",
      key: "can_delete",
      width: 50,
      render: (record, rowData) => {
        return (
          <ActionButton
            action={"delete"}
            rowData={rowData}
            label={t("delete")}
          />
        );
      },
    },
    // TODO: Share action is not implemented yet
    // {
    //   title: "",
    //   dataIndex: "can_select",
    //   key: "can_select",
    //   width: 50,
    //   render: (record, rowData) => {
    //     return (
    //       <ActionButton action={"share"} rowData={rowData} label={t("share")} />
    //     );
    //   },
    // },
    // TODO: Remove action is not implemented yet
    // {
    //   title: "",
    //   dataIndex: "remove",
    //   key: "remove",
    //   width: 50,
    //   render: () => {
    //     return (
    //       <div>
    //         <Button type="remove" size="small">
    //           <MdClose size={20} />
    //         </Button>
    //       </div>
    //     );
    //   },
    // },
  ];

  const DrawerTitle = () => {
    const { rowData, action } = selectedSchema;
    const title = `${roleData?.role?.name} -> ${rowData?.collection_name} -> ${action}`;
    return (
      <Tooltip
        className="!font-[inter] font-bold"
        title={title}
        placement="bottomLeft"
      >
        {title}
      </Tooltip>
    );
  };

  const drawerItems = [
    {
      key: "1",
      label: t("field_permissions"),
      children: (
        <FieldPermissions
          selectedSchema={selectedSchema}
          setPermissions={setPermissions}
          permissions={permissions}
          setIsDrawerOpen={setIsDrawerOpen}
          DrawerTitle={DrawerTitle}
        />
      ),
      closable: true,
      closeIcon: <IconsAll.BlogIcon />,
    },
  ];

  if (selectedSchema.action !== "create") {
    drawerItems.push({
      key: "2",
      label: t("item_permissions"),
      children: (
        <ItemPermissions
          DrawerTitle={DrawerTitle}
          selectedSchema={selectedSchema}
          setPermissions={setPermissions}
          setIsDrawerOpen={setIsDrawerOpen}
          permissions={permissions}
        />
      ),
    });
  }

  const init = async () => {
    try {
      if (field && field !== "add-role") {
        await getRoleData(field);
      } else {
        await getDefaultPermissions();
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      dispatch(hideLoader());
    }
  };

  useEffect(() => {
    if (schemas.length > 0) {
      init();
    }
  }, [schemas]);

  return (
    <div className="p-10 relative w-full">
      <Flex wrap="wrap" align="center" justify="space-between">
        <LeftSideTitlePart
          pageTitle={
            loader ? (
              <Skeleton.Button size="small" active />
            ) : roleData?.role?._id ? (
              roleData?.role?.name
            ) : (
              t("add_role")
            )
          }
          pageSubTitle={t("access_control")}
          backButton={true}
          backLink="/settings/toegangscontrole"
        />
        <RightSIdeTitlePart
          showButton={true}
          handleShowClick={handleSubmit}
          buttonLoading={loader}
        />
      </Flex>

      <Form
        layout="vertical"
        form={form}
        initialValues={initialFormValues}
        disabled={loader}
      >
        <Row gutter={28} className="mx-auto max-w-[991px] mt-9">
          <Col className="gutter-row" xs={24}>
            <Form.Item
              label={t("role")}
              name="name"
              className="font-bold"
              rules={[{ required: true }]}
            >
              <Input
                disabled={roleData?.role?._id}
                type="text"
                placeholder={t("enter_the_role")}
                className="project-custom-input"
              />
            </Form.Item>
          </Col>

          <Col className="gutter-row" xs={24}>
            <Form.Item
              label={t("description")}
              name="description"
              className="font-bold"
            >
              <TextArea
                rows={4}
                placeholder={t("enter_the_description")}
                className="project-custom-input pt-4"
              />
            </Form.Item>
          </Col>

          <>
            <Col className="gutter-row" xs={24}>
              <Form.Item
                label={t("type")}
                name="type"
                className="font-bold"
                rules={[{ required: true }]}
              >
                <Radio.Group
                  className="flex md:flex-row flex-col gap-7"
                  disabled={roleData?.role?._id}
                >
                  <InvertedRadioBox value={true}>{t("api")}</InvertedRadioBox>
                  <InvertedRadioBox value={false}>
                    {t("management")}
                  </InvertedRadioBox>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24}>
              <Form.Item
                label={t("access")}
                name="access"
                className="font-bold"
                rules={[{ required: true }]}
              >
                <Radio.Group className="flex md:flex-row flex-col gap-7">
                  <InvertedRadioBox value={"app_access"}>
                    {t("app_access")}
                  </InvertedRadioBox>
                  <InvertedRadioBox value={"admin_access"}>
                    {t("admin_access")}
                  </InvertedRadioBox>
                </Radio.Group>
              </Form.Item>
            </Col>
          </>
          {accessValue === "app_access" && (
            <Col xs={24} className="gutter-row my-9">
              <Title level={2} className="font-bold text-2xl!">
                {t("permissions")}
              </Title>
              <TableStripe
                columns={columns}
                expandable={{
                  childrenColumnName: "children",
                  expandIcon: ExpandIcon,
                }}
                dataSource={nestedSchemas}
                rowKey={(record) => record?._id}
                pagination={false}
                scroll={{ x: 300 }}
                locale={{
                  emptyText: <NoData title={t("no_data_found")} />,
                }}
                loading={{
                  spinning: loader,
                  indicator: (
                    <Spin indicator={<div className="custom-loader" />} />
                  ),
                }}
              />
              <CollapsWithLine
                ghost
                items={[
                  {
                    key: "1",
                    label: (
                      <span className="collapse-title">
                        {t("system_collections")}
                      </span>
                    ),
                    children: (
                      <TableStripe
                        pagination={false}
                        showHeader={false}
                        columns={columns}
                        rowKey={(record) => record?._id}
                        expandable={{
                          childrenColumnName: "children",
                          expandIcon: ExpandIcon,
                          indentSize: 40,
                        }}
                        dataSource={nestedSystemCollections}
                        locale={{
                          emptyText: <NoData title={t("no_data_found")} />,
                        }}
                        loading={{
                          spinning: loader,
                          indicator: (
                            <Spin
                              indicator={<div className="custom-loader" />}
                            />
                          ),
                        }}
                      />
                    ),
                  },
                ]}
              />
            </Col>
          )}
        </Row>
      </Form>

      <DrawerStyle
        width={870}
        open={isDrawerOpen}
        onClose={() => {
          setIsDrawerOpen(false);
          setSelectedSchema({});
        }}
        closeIcon={<IconsAll.CloseIconCustom />}
        destroyOnClose
      >
        <Tabs defaultActiveKey="1" items={drawerItems} tabPosition={"left"} />
      </DrawerStyle>
    </div>
  );
};

export default withRouter(AddEditRole);
