import { Checkbox } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const CustomToggle = ({
  item,
  updateFormData,
  formData,
  isValidationError,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (formData?.[item.path] === undefined) {
      updateFormData(!!item.schema_definition?.default);
    }
  }, [formData?.[item.path]]);

  return (
    <Checkbox
      onChange={(e) => updateFormData(e.target.checked)}
      checked={!!formData?.[item.path]}
      defaultChecked={!!item?.schema_definition?.default}
      className={`font-normal ${isValidationError ? "border-[red]" : ""}`}
      disabled={!!item?.meta?.readonly}
    >
      {t("enabled")}
    </Checkbox>
  );
};

export default CustomToggle;
