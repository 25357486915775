import {
  Col,
  Flex,
  Form,
  Input,
  Row,
  Button,
  notification,
  InputNumber,
  Tooltip,
} from "antd";
import Title from "antd/lib/typography/Title";
import React, { useEffect } from "react";
import IconsAll from "../../Components/IconsAll";
import { BsCheckLg } from "react-icons/bs";
import styled from "styled-components";
import Apis from "../../Helpers/Apis";
import axiosInstance from "../../Helpers/axios";
// import Joi from "joi";
import { unflatten, flatten } from "flat";
import { InvertedCheckBox, TItleIconStyle } from "../../Common/CommonUiStyles";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../Redux/slices/loader.reducer";
import { useSelector } from "react-redux";

const HeaderButton = styled(Button)`
  min-width: 56px !important;
  width: 56px !important;
  height: 56px;
  border: transparent;
  display: flex;
  align-items: center;
  justify-content: center;

  &.add-button {
    background: ${({ theme }) =>
      theme.components.HeaderButtonsStyle.addButtonBg};
    color: ${({ theme }) => theme.components.HeaderButtonsStyle.addButtonClr};

    &:hover {
      background: ${({ theme }) =>
        theme.components.HeaderButtonsStyle.addButtonHoverBg} !important;
      color: ${({ theme }) =>
        theme.components.HeaderButtonsStyle.addButtonHoverClr} !important;
    }
  }
`;

const Tenant = ({
  setIsDrawerOpen,
  init,
  activeTenant,
  form,
  setActiveTenant,
}) => {
  // const [errors, setErrors] = useState({});
  const loader = useSelector((state) => state.loader.isLoading);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const formData = [
    {
      name: "name",
      label: t("name"),
      interface: "input",
      type: "String",
      required: true,
      placeholder: t("enter_name"),
      disabled: false,
      width: "50%",
    },
    {
      name: "email",
      label: t("email"),
      interface: "input",
      // path: "userId.email",
      type: "String",
      required: true,
      placeholder: t("enter_email"),
      disabled: false,
      field: "1gg63fik4xon",
      info: "",
      _fc_drag_tag: "input",
      display: true,
      width: "50%",
      immutable: !!activeTenant,
    },
    // {
    //   name: "primary_database_connection_string",
    //   label: "Primary database connection string",
    //   interface: "input",
    //   type: "String",
    //   required: true,
    //   placeholder: "Primary database connection string",
    //   disabled: false,
    //   field: "1gg63fik4xon",
    //   info: "",
    //   _fc_drag_tag: "input",
    //   display: true,
    //   width: "50%",
    // },
    {
      name: "primary_domain",
      label: t("primary_domain"),
      interface: "input",
      type: "String",
      required: true,
      placeholder: t("primary_domain"),
      disabled: false,
      field: "1gg63fik4xon",
      info: "",
      _fc_drag_tag: "input",
      display: true,
      width: "50%",
    },
    {
      name: "cors_urls",
      label: t("cors_urls"),
      interface: "input",
      type: "String",
      required: true,
      placeholder: t("cors_urls"),
      disabled: false,
      field: "1gg63fik4xon",
      info: "",
      _fc_drag_tag: "input",
      display: true,
      width: "50%",
    },
    {
      name: "environments.0.type",
      label: t("type"),
      interface: "input",
      type: "String",
      required: true,
      disabled: true,
      value: "Development",
      width: "50%",
    },
    {
      name: "environments.0.connection_string",
      label: t("connection_string"),
      interface: "input",
      type: "String",
      required: true,
      placeholder: t("generated_automatically"),
      disabled: true,
      width: "50%",
    },
    {
      name: "environments.0.domain",
      label: t("domain"),
      interface: "input",
      type: "String",
      required: true,
      placeholder: t("enter_domain"),
      disabled: false,
      width: "50%",
    },
    // {
    //   name: "environments.0.connection_string_for_collections",
    //   label: "Connection string for collection",
    //   interface: "input",
    //   type: "String",
    //   required: true,
    //   placeholder: "Enter Connection string",
    //   disabled: false,
    //   width: "50%",
    // },
    {
      name: "environments.0.is_active",
      label: t("is_active"),
      interface: "checkbox",
      type: "Boolean",
      required: false,
      disabled: false,
      width: "50%",
    },
    // {
    //   name: "environments.0.is_primary",
    //   label: "Is Primary",
    //   interface: "checkbox",
    //   type: "Boolean",
    //   required: false,
    //   disabled: false,
    //   width: "50%",
    // },
    {
      name: "environments.1.type",
      label: t("type"),
      interface: "input",
      type: "String",
      required: true,
      disabled: true,
      value: "Acceptance",
      width: "50%",
    },
    {
      name: "environments.1.connection_string",
      label: t("connection_string"),
      interface: "input",
      type: "String",
      required: true,
      placeholder: t("generated_automatically"),
      disabled: true,
      width: "50%",
    },
    {
      name: "environments.1.domain",
      label: t("domain"),
      interface: "input",
      type: "String",
      required: true,
      placeholder: t("enter_domain"),
      disabled: false,
      width: "50%",
    },
    // {
    //   name: "environments.1.connection_string_for_collections",
    //   label: "Connection string for collection",
    //   interface: "input",
    //   type: "String",
    //   required: true,
    //   placeholder: "Enter Connection string",
    //   disabled: false,
    //   width: "50%",
    // },
    {
      name: "environments.1.is_active",
      label: t("is_active"),
      interface: "checkbox",
      type: "Boolean",
      required: false,
      disabled: false,
      width: "50%",
    },
    // {
    //   name: "environments.1.is_primary",
    //   label: "Is Primary",
    //   interface: "checkbox",
    //   type: "Boolean",
    //   required: false,
    //   disabled: false,
    //   width: "50%",
    // },
    {
      name: "environments.2.type",
      label: t("type"),
      interface: "input",
      type: "String",
      required: true,
      disabled: true,
      value: "Production",
      width: "50%",
    },
    {
      name: "environments.2.connection_string",
      label: t("connection_string"),
      interface: "input",
      type: "String",
      required: true,
      placeholder: t("generated_automatically"),
      disabled: true,
      width: "50%",
    },
    {
      name: "environments.2.domain",
      label: t("domain"),
      interface: "input",
      type: "String",
      required: true,
      placeholder: t("enter_domain"),
      disabled: false,
      width: "50%",
    },
    // {
    //   name: "environments.2.connection_string_for_collections",
    //   label: "Connection string for collection",
    //   interface: "input",
    //   type: "String",
    //   required: true,
    //   placeholder: "Enter Connection string",
    //   disabled: false,
    //   width: "50%",
    // },
    {
      name: "environments.2.is_active",
      label: t("is_active"),
      interface: "checkbox",
      type: "Boolean",
      required: false,
      disabled: false,
      width: "50%",
    },
    // {
    //   name: "environments.2.is_primary",
    //   label: "Is Primary",
    //   interface: "checkbox",
    //   type: "Boolean",
    //   required: false,
    //   disabled: false,
    //   width: "50%",
    // },
    // {
    //   interface: "button",
    //   type: "Submit",
    //   label: "Add Tenant",
    //   children: ["Button"],
    //   _fc_drag_tag: "el-button",
    //   hidden: false,
    //   display: true,
    //   width: "50%",
    // },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    setFormData();
  }, [activeTenant]);

  const setFormData = async () => {
    if (activeTenant) {
      form.setFieldsValue(
        flatten({ ...activeTenant, email: activeTenant?.userId?.email }, {}),
      );
    }
  };

  const handleSubmit = async () => {
    try {
      // Validate fields and get the form data
      const flatData = await form.validateFields();
      let unflattenedData = unflatten(flatData);
      dispatch(showLoader());

      if (activeTenant) {
        delete unflattenedData?.email;
        try {
          const resp = await axiosInstance.put(
            Apis.UPDATE_A_TENANT(activeTenant?._id),
            unflattenedData,
          );
          if (resp.status === 200) {
            notification.success({ message: t("tenant_update_success") });
          }
          await form.resetFields();
          setActiveTenant(null);
          setIsDrawerOpen(false);
          await init();
        } catch (error) {
          notification.error({
            message: t("something_went_wrong"),
          });
        } finally {
          dispatch(hideLoader());
        }
      } else {
        try {
          const resp = await axiosInstance.post(
            Apis.ADD_A_TENANT(),
            unflattenedData,
          );
          if (resp.status === 200) {
            notification.success({ message: t("tenant_add_success") });
          }
          await form.resetFields();
          setIsDrawerOpen(false);
          await init();
        } catch (error) {
          if (error?.response?.data?.type === "VALIDATION") {
            let errors = error?.response?.data?.payload?.error;
            // Uncomment and modify this section to handle validation errors
            // const validationErrors = {};
            // Object.entries(errors).forEach(([, detail]) => {
            //   const detailPath = detail.path.slice(1);
            //   const path = detailPath.join(".");
            //   let message = `"${detailPath[detailPath.length - 1]}" is required`;
            //   message = message.replaceAll("_", " ");
            //   validationErrors[path] =
            //     '"' + message.charAt(1).toUpperCase() + message.slice(2);
            // });
            // setErrors(validationErrors);
            Object.entries(errors).forEach(([, val]) => {
              notification.error({ message: val });
            });
          }
        } finally {
          dispatch(hideLoader());
        }
      }
    } catch (validationError) {
      console.log("Validation Error:", validationError);
    } finally {
      dispatch(hideLoader());
    }
  };

  const generateFormInput = (
    inputType,
    inputInterface,
    disabled,
    value,
    inputPlaceHolder,
    inputClassName,
  ) => {
    if (inputType === "String" && inputInterface === "input")
      return (
        <Input
          placeholder={inputPlaceHolder}
          className={inputClassName}
          type="text"
          disabled={disabled}
          value={"value"}
        />
      );
    if (inputType === "Number" && inputInterface === "input")
      return (
        <InputNumber
          placeholder={inputPlaceHolder}
          className={inputClassName}
          disabled={disabled}
          value={value}
        />
      );
    if (inputType === "Boolean" && inputInterface === "checkbox") {
      return (
        <InvertedCheckBox disabled={disabled}>{t("Enabled")}</InvertedCheckBox>
      );
    }
    if (inputType === "File") {
      return (
        <input
          type="file"
          // onChange={(e) => {
          //     const file = e.target.files && e.target.files[0];
          //     form.setFields([{ name: fieldName, value: file }]);
          // }}
        />
      );
    }
    return null;
  };

  return (
    <div className="relative py-4">
      <Flex
        wrap="wrap"
        align="center"
        justify="space-between"
        className="mb-3 grow"
      >
        <Title className="project-header-left-side max-w-[90%]" level={3}>
          <TItleIconStyle>
            <IconsAll.LinkIcon />
          </TItleIconStyle>
          <Tooltip
            title={activeTenant ? t("edit_tenant") : t("add_tenant")}
            placement="bottomLeft"
            className="truncate"
          >
            {activeTenant ? t("edit_tenant") : t("add_tenant")}
          </Tooltip>
        </Title>
      </Flex>
      {!loader && (
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <HeaderButton
            shape="circle"
            icon={<BsCheckLg size={24} />}
            className="btn add-button absolute top-4 right-0"
            onClick={handleSubmit}
            disabled={loader}
          />

          <Row gutter={28}>
            {formData.map((s, ind) => {
              return (
                <Col
                  key={ind}
                  className="gutter-row"
                  xs={24}
                  md={s.width === "50%" ? 12 : 24}
                >
                  <Form.Item
                    name={s.name}
                    label={s.type !== "Submit" ? s.label : ""}
                    valuePropName={
                      s.type === "Boolean" && s.interface === "checkbox"
                        ? "checked"
                        : "value"
                    }
                    initialValue={s?.value && s?.value}
                    // getValueFromEvent={
                    //   s.type === "File" ? (e) => e.target.files[0] : undefined
                    // }
                    rules={[
                      {
                        required: s.disabled ? !s.disabled : s.required,
                        message: t("please_input_label", { label: s.label }),
                      },
                    ]}
                    className="font-bold"
                  >
                    {generateFormInput(
                      s.type,
                      s.interface,
                      !!s.immutable || !!s.disabled,
                      s?.value,
                      s?.placeholder,
                      "project-custom-input",
                    )}
                  </Form.Item>
                </Col>
              );
            })}
          </Row>
        </Form>
      )}
    </div>
  );
};

export default Tenant;
