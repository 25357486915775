import {
  Checkbox,
  Drawer,
  Form,
  Flex,
  Typography,
  Table,
  Collapse,
  Radio,
} from "antd";
import styled from "styled-components";
import { themes } from "../config";
import { useSelector } from "react-redux";

const DrawerStyle = styled(Drawer)`
  .ant-drawer-header {
    padding: 0;
    border: unset !important;
    .ant-drawer-header-title {
      .ant-drawer-close {
        width: 56px;
        height: 56px;
        position: absolute;
        left: -65px;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 30px;
        background: var(--color-white);
        border-radius: 100vh;
        box-shadow: 0px 4px 12px 0px rgba(8, 24, 44, 0.16);
        @media screen and (max-width: 991px) {
          left: calc(50% - 28px);
          bottom: 1rem;
          top: auto;
        }
      }
    }
  }
  .ant-drawer-body {
    &:has(.ant-tabs-nav) {
      padding: 0;
    }
    .ant-tabs-nav {
      padding-top: 40px;
      width: min(100%, 200px);
      min-height: 100vh;
      background-color: #f5f6f7;
      .ant-tabs-nav-wrap {
        &:before,
        &:after,
        .ant-tabs-ink-bar {
          display: none;
        }
        .ant-tabs-nav-list {
          padding-inline: 10px;
          .ant-tabs-tab {
            border-radius: 8px;
            margin-top: 5px;
            > div {
              color: #000 !important;
              font-size: 16px;
              font-style: normal;
            }
            &.ant-tabs-tab-active {
              background-color: var(--sidebar-active-menu-bg) !important;
            }
          }
        }
      }
    }

    .ant-tabs-content-holder {
      padding-top: 40px;
      .ant-tabs-tabpane {
        padding-right: 24px;
      }
    }
  }
`;

const FormStyling = styled(Form)`
  border-top: 1px solid var(--input-field-border);
  border-bottom: 1px solid var(--input-field-border);
  padding-block: 34px !important;
`;

const InvertedCheckBox = styled(Checkbox)`
  border-radius: var(--br-12);
  border: 1px solid var(--input-field-border);
  background: var(--input-field-bg);
  color: var(--heading-text);
  padding: 1rem;
  width: 100%;
  font-weight: 400;
  transition: 0.3s ease-in-out;
  align-items: center;
  &:hover {
    border-color: #4096ff;
  }
  &:has(.ant-checkbox-checked) {
    border-color: var(--project-base);
  }
  &.ant-checkbox-wrapper-disabled {
    background-color: var(--input-disabled-field-bg);
    border-color: var(--input-disabled-field-border);
    color: var(--input-disabled-color);
    border-radius: var(--br-12);
    font-weight: normal;
    &::placeholder {
      color: var(--input-disabled-color);
    }
  }
`;

const InvertedRadioBox = styled(Radio)`
  border-radius: var(--br-12);
  border: 1px solid var(--input-field-border);
  background: var(--input-field-bg);
  color: var(--heading-text);
  padding: 1rem;
  width: 100%;
  font-weight: 400;
  margin-inline-end: 0 !important;
  transition: 0.3s ease-in-out;
  align-items: center;
  &:hover {
    border-color: #4096ff;
  }
  &:has(.ant-radio-checked) {
    border-color: var(--project-base);
  }
  &.ant-radio-wrapper-disabled {
    background-color: var(--input-disabled-field-bg);
    border-color: var(--input-disabled-field-border);
    color: var(--input-disabled-color);
    border-radius: var(--br-12);
    font-weight: normal;
    &::placeholder {
      color: var(--input-disabled-color);
    }
  }
`;

const NotHasField = styled(Flex)`
  background-color: var(--input-disabled-field-bg);
  border-left: 6px solid var(--input-disabled-color);
  padding-inline: 15px;
  border-radius: 6px;
  min-height: 56px;
`;

// Showing on the header part of left side
const TItleIconStyle = styled(Typography)`
  min-width: 56px !important;
  width: 56px !important;
  height: 56px;
  border-radius: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(from var(--project-base) r g b / 10%);
  color: ${({ theme }) => theme.components.HeaderButtonsStyle.backButtonClr};
`;

// Table styles
const TableStriped = styled(Table)`
.ant-table {
background-color: ${() =>
  themes[useSelector((state) => state.theme.name)].components.Table.tableBg};
&.table{
  background-color:transparent;
}
.gray-clr {
  color: ${() =>
    themes[useSelector((state) => state.theme.name)].token.grayClr};
}
thead .ant-table-cell {
    background: var(--body-bg);
    border-bottom: 2px solid var(--input-disabled-color) !important;
}
.ant-table-cell.ant-table-cell-fix-left{
  background-color: ${() =>
    themes[useSelector((state) => state.theme.name)].components.Table
      .sortedBg} !important;
    }
}
.ant-table-cell {
  border-bottom: 1px solid
      ${() =>
        themes[useSelector((state) => state.theme.name)].components.Table
          .borderColor} !important;
  &.ant-table-column-has-sorters {
    &:hover {
      background-color: ${() =>
        themes[useSelector((state) => state.theme.name)].components.Table
          .sortedBg} !important;
    }
  }
  &.ant-table-column-sort {
    background-color: ${() =>
      themes[useSelector((state) => state.theme.name)].components.Table
        .sortedBg};
  }
}
.imgLocationClr {
  color: ${() =>
    themes[useSelector((state) => state.theme.name)].components.Table
      .imgLocationClr};
  margin-left: 10px;
}
}
  thead>tr:first-child >*:last-child {
    padding-right: 60px;
  }

  tbody {
      tr {
          .ant-table-cell {
              background-color: var(--table-row-strips);
              > a {
                  color: var(--project-base);
                  &:hover {
                    color: var(--project-base);
                  }
              }
              &.ant-table-cell-row-hover {
                  background-color: rgb(from var(--table-row-strips) r g b / 0.4) !important;
              }
          }
          &:nth-child(odd) {
              .ant-table-cell {
                  background-color: rgb(from var(--table-row-strips) r g b / 0.08);
              }
          }
      }
  }`;

const CollapsWithLine = styled(Collapse)`
  margin-block: 20px;
  .ant-collapse-item {
    .ant-collapse-header {
      padding: 0;
      .ant-collapse-header-text {
        .collapse-title {
          background-color: var(--body-bg);
          display: block;
          z-index: 1;
          position: relative;
          width: fit-content;
          padding-right: 12px;
        }
        &:after {
          content: "";
          width: 100%;
          height: 1px;
          background-color: var(--input-field-border);
          display: block;
          position: relative;
          top: -10px;
        }
      }
    }
    .ant-collapse-content {
      .ant-collapse-content-box {
        padding-inline: 0;
      }
    }
  }
`;

export {
  DrawerStyle,
  FormStyling,
  InvertedCheckBox,
  InvertedRadioBox,
  NotHasField,
  TItleIconStyle,
  TableStriped,
  CollapsWithLine,
};
