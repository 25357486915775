import React, { useEffect, useState } from "react";
import { Button, Menu, Space } from "antd";
import { FiPlus } from "react-icons/fi";
import styled from "styled-components";
import { convertToTreeData } from "../Helpers/utils";
import { useTranslation } from "react-i18next";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";

const ColumnsListBtn = styled(Space)`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  gap: 0;
  .columnsList-btn {
    width: 56px;
    height: 56px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    border-radius: 0 10px 0 0;
    background-color: var(--body-bg);
  }
`;

const SelectTableColumns = ({
  currentColumns,
  columnOptions,
  handleColumnsAdd,
  selectOpen,
  setSelectOpen,
  loading,
}) => {
  const { t } = useTranslation();
  const [selectedColumns, setSelectedColumns] = useState(currentColumns);

  const handleClickOutside = (event) => {
    if (
      selectOpen &&
      !event.target.closest(".add-field-type-dropdown-wrapper") &&
      !event.target.closest(".columnsList-btn")
    ) {
      setSelectOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectOpen]);

  const items = convertToTreeData(
    columnOptions?.filter((c) => !c?.meta?.hidden),
    "tree-columns",
  );

  const findSelectedNode = (key, items) => {
    for (let item of items) {
      if (item.key === key) {
        return item;
      }
      if (item.children) {
        const found = findSelectedNode(key, item.children);
        if (found) return found;
      }
    }
    return null;
  };

  const updateSelectedColumns = (key, isSelected) => {
    if (!isSelected && selectedColumns?.length < 2) return;
    const selectedNode = findSelectedNode(key, items);
    setSelectedColumns((prev) =>
      isSelected
        ? [
            ...prev,
            {
              display_label: selectedNode?.field_name.replaceAll(".", "-"),
              field_name: selectedNode?.field_name,
              field_details: selectedNode?.field_details,
            },
          ]
        : prev.filter((item) => item?.field_name !== selectedNode?.field_name),
    );
  };

  useEffect(() => {
    setSelectedColumns(currentColumns);
  }, [currentColumns]);

  return (
    <ColumnsListBtn>
      <div
        className="columnsList-btn"
        onClick={() => setSelectOpen((prev) => !prev)}
      >
        <FiPlus />
      </div>
      {selectOpen && (
        <div className="add-field-type-dropdown-wrapper">
          <div className="add-field-type-dropdown custom-scrollbar">
            <Menu
              items={items}
              selectedKeys={selectedColumns?.map((col) => col?.field_name)}
              multiple
              mode="inline"
              onSelect={(a1) => updateSelectedColumns(a1.key, true)}
              onDeselect={(a1) => updateSelectedColumns(a1.key, false)}
              expandIcon={({ isOpen }) =>
                isOpen ? <IoIosArrowDown /> : <IoIosArrowForward />
              }
            />
          </div>
          <Button
            className="add-column-btn flex justify-center items-center"
            onClick={() => handleColumnsAdd(selectedColumns)}
            disabled={loading}
          >
            {t("update_columns")}
          </Button>
        </div>
      )}
    </ColumnsListBtn>
  );
};

export default SelectTableColumns;
