import {
  Button,
  Col,
  Divider,
  Flex,
  Form,
  Input,
  notification,
  Row,
  Select,
  Switch,
  Tag,
} from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Edit3Icon,
  EyeIcon,
  Link2Icon,
  LucideMail,
  PlusIcon,
  Trash2Icon,
  LucideCopyCheck,
  LucidePlugZap2,
  LucideUnplug,
  LucideFileCode2,
  LucideKeyRound,
} from "lucide-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import TextArea from "antd/es/input/TextArea";
import { handleFlowActions } from "../../../../Redux/slices/flows.reducer";
import Title from "antd/es/typography/Title";
import { TItleIconStyle } from "../../../../Common/CommonUiStyles";
import IconsAll from "../../../../Components/IconsAll";
import { GrLinkNext } from "react-icons/gr";
import styled from "styled-components";
import { themes } from "../../../../config";
import { addEdge } from "@xyflow/react";
import { RiDeleteBin6Line } from "react-icons/ri";
import { AiOutlinePlus } from "react-icons/ai";
import { useWatch } from "antd/es/form/Form";
import CodeEditorInput from "./CodeEditorInput";
import axiosInstance from "../../../../Helpers/axios";
import Apis from "../../../../Helpers/Apis";
import ReactQuill from "react-quill";

const HeaderButton = styled(Button)`
  min-width: 56px !important;
  width: 56px !important;
  height: 56px;
  border: transparent;
  display: flex;
  align-items: center;
  justify-content: center;

  &.add-button {
    background: ${() =>
      themes[useSelector((state) => state.theme.name)].components
        .HeaderButtonsStyle.addButtonBg};
    color: ${() =>
      themes[useSelector((state) => state.theme.name)].components
        .HeaderButtonsStyle.addButtonClr};

    &:hover {
      background: ${() =>
        themes[useSelector((state) => state.theme.name)].components
          .HeaderButtonsStyle.addButtonHoverBg} !important;
      color: ${() =>
        themes[useSelector((state) => state.theme.name)].components
          .HeaderButtonsStyle.addButtonHoverClr} !important;
    }
    &[disabled] {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }
`;

const OperationOption = ({ setSelectedOperation, operation, isSelected }) => {
  return (
    <div
      key={operation.id}
      className={`flex justify-between items-center bg-project-base/5 border border-project-base/15 p-4 rounded-lg cursor-pointer ${isSelected && "!broder-[var(--project-color)] cursor-auto"}`}
      onClick={() => setSelectedOperation(operation)}
    >
      <div className="flex items-center gap-5">
        <div
          className={
            "bg-white h-10 w-10 flex items-center justify-center rounded-full"
          }
        >
          {operation.icon}
        </div>
        <div className={"flex flex-col"}>
          <h3>{operation.title}</h3>
          <p>{operation.description}</p>
        </div>
      </div>
      {isSelected && (
        <div
          className={
            "border border-project-base mr-2 rounded-full p-1 cursor-pointer hover:bg-project-base group transition duration-300"
          }
          onClick={(event) => {
            setSelectedOperation({});
            event.stopPropagation();
          }}
        >
          <PlusIcon
            className={"rotate-45 group-hover:text-project-base-text!"}
            size={16}
          />
        </div>
      )}
    </div>
  );
};

const CustomCases = ({
  i,
  field,
  initialValues,
  form,
  t,
  JsonValidator,
  flowsWithOperationTriggerOptions,
}) => {
  const is_same_payload = useWatch("is_same_payload", form);
  return (
    <div key={i} className="grow">
      <Col className="gutter-row" xs={24}>
        <Form.Item
          label={t("is_same_payload")}
          name={`is_same_payload`}
          valuePropName="checked"
          className="font-bold"
        >
          <Switch />
        </Form.Item>
      </Col>
      <Col className="gutter-row" xs={24}>
        <label className={"font-bold pb-2 block"}>{field.label}</label>
        <Form.List
          name={field.key}
          {...(!initialValues[field.key] && {
            initialValue: field?.initialValue || [{ key: "", value: "" }],
          })}
        >
          {(fields, { add, remove }) => (
            <>
              {fields.map((fieldData) => {
                return (
                  <Row
                    key={fieldData.key}
                    gutter={16}
                    className="justify-start mb-4"
                  >
                    <Col span={11}>
                      <Form.Item
                        {...fieldData}
                        name={[fieldData.name, "key"]}
                        key={`${fieldData.key}_key`}
                        rules={[
                          {
                            required: true,
                            message: t("please_enter_the_key"),
                          },
                        ]}
                      >
                        <Input
                          placeholder={field?.key_placeholder ?? ""}
                          className={`project-custom-input`}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={11}>
                      <Form.Item
                        {...fieldData}
                        name={[fieldData.name, "value"]}
                        key={`${fieldData.key}_value`}
                        rules={[
                          {
                            required: true,
                            message: t("please_enter_the_value"),
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          className="remove-input-pl flex-1 h-14 w-full"
                          options={flowsWithOperationTriggerOptions}
                          placeholder={fieldData?.value_placeholder ?? ""}
                          optionFilterProp={"label"}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={2}>
                      {fields.length > 1 && (
                        <RiDeleteBin6Line
                          onClick={() => remove(fieldData.name)}
                          size={18}
                          className={`btn delete-button cursor-pointer min-h-14`}
                        />
                      )}
                    </Col>
                    {!is_same_payload && (
                      <>
                        <Col span={22}>
                          <Form.Item
                            {...fieldData}
                            name={[fieldData.name, "payload"]}
                            key={`${fieldData.key}_payload`}
                            rules={[
                              {
                                validator: JsonValidator,
                              },
                            ]}
                          >
                            <CodeEditorInput defaultLanguage={"json"} />
                          </Form.Item>
                        </Col>
                      </>
                    )}
                  </Row>
                );
              })}
              <Row>
                <Col span={22}>
                  <Form.Item>
                    <Button
                      className="project-custom-btn w-full transition-all duration-200"
                      onClick={() => add()}
                      block
                      icon={<AiOutlinePlus />}
                    >
                      {t("add_item")}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </Form.List>
      </Col>
      {is_same_payload && (
        <>
          <Col className="gutter-row" xs={24}>
            <Form.Item
              name={"payload"}
              label={t("payload")}
              className={"font-bold"}
              rules={[
                {
                  validator: JsonValidator,
                },
              ]}
            >
              <CodeEditorInput defaultLanguage={"json"} />
            </Form.Item>
          </Col>
        </>
      )}
    </div>
  );
};

const SelectWithDynamicOptions = ({ field }) => {
  const { t } = useTranslation();

  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let mounted = true;

    const getOptions = async () => {
      if (!field?.getOptions) return;

      setLoading(true);
      try {
        const response = await field.getOptions();
        if (mounted) {
          setOptions(
            response.data.payload.data?.map((item) => ({
              label: item[field.optionLabel],
              value: item[field.optionKey],
              ...(field?.extraKeysInOption || []).reduce((acc, key) => {
                acc[key] = item[key];
                return acc;
              }, {}),
            })) || [],
          );
        }
      } catch (e) {
        console.log(e);
        if (mounted) {
          setOptions([]);
        }
      } finally {
        if (mounted) {
          setLoading(false);
        }
      }
    };

    getOptions();

    return () => {
      mounted = false;
    };
  }, [
    field?.key,
    field?.getOptions,
    field?.optionLabel,
    field?.optionKey,
    field?.extraKeysInOption,
  ]);

  return (
    <Col className="gutter-row" xs={24} md={field?.width === "half" ? 12 : 24}>
      <Form.Item
        label={field.label}
        className="font-bold"
        name={field?.key}
        rules={field?.rules || []}
      >
        <Select
          showSearch
          className="remove-input-pl flex-1 h-14 w-full"
          options={options}
          mode={field?.mode}
          placeholder={
            loading ? t("loading_options") : field?.placeholder ?? ""
          }
          labelInValue={field?.labelInValue}
          fieldNames={field?.fieldNames}
          optionFilterProp={field?.optionFilterProp}
          loading={loading}
          disabled={loading}
          onSelect={field?.onOptionSelect || (() => {})}
        />
      </Form.Item>
    </Col>
  );
};

const CustomSelectWithTags = ({ field, form }) => {
  const selectedTags = useWatch(field.key, form);

  const handleChange = (value) => {
    form.setFieldValue(field.key, value);
  };

  return (
    <>
      <Form.Item
        label={field.label}
        className="font-bold"
        name={field?.key}
        rules={field?.rules || []}
      >
        <Select
          showSearch
          className="remove-input-pl flex-1 h-14 w-full"
          options={field?.options ?? []}
          mode={field?.mode}
          placeholder={field?.placeholder ?? ""}
          labelInValue={field?.labelInValue}
          fieldNames={field?.fieldNames}
          optionFilterProp={field?.optionFilterProp}
          tagRender={() => null}
          open={false}
        />
      </Form.Item>
      <div style={{ marginTop: "10px" }}>
        {selectedTags?.map((tag) => (
          <Tag
            key={tag}
            closable
            onClose={() => handleChange(selectedTags.filter((t) => t !== tag))}
            style={{
              backgroundColor: "rgb(from var(--project-base) r g b / 10%)",
              borderRadius: "6px",
              height: "auto",
              lineHeight: "1",
              padding: "8px 12px",
              color: "var(--color-project-base)",
            }}
          >
            {tag}
          </Tag>
        ))}
      </div>
    </>
  );
};

const EditOperation = ({
  operationData,
  setNodes,
  setEdges,
  operationNodeId,
  flowsWithOperationTrigger,
}) => {
  const [form] = Form.useForm();
  const selectedJwtOperation = Form.useWatch("operation", form);
  const selectedEmailProvider = Form.useWatch("provider", form);
  const { t } = useTranslation();
  const schemas = useSelector((state) => state.schemas.schemas);
  const source = useSelector((state) => state.flows.source);
  const dispatch = useDispatch();
  const [bodyFieldsLoading, setBodyFieldsLoading] = useState(false);

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge({ ...params, type: "button" }, eds)),
    [setEdges],
  );

  const JsonValidator = (_, value) => {
    if (!value) {
      return Promise.resolve();
    }
    try {
      JSON.parse(value);
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(new Error(t("invalid_json")));
    }
  };

  const objectIdValidator = (_, value) => {
    if (!value) {
      return Promise.resolve();
    }
    const objectIdPattern = /^[0-9a-fA-F]{24}$/;
    const variablePattern = /^\{\{[\w.$]+\}\}$/;
    const invalidIds = value
      .map((id) => id.trim())
      .filter((id) => !objectIdPattern.test(id) && !variablePattern.test(id));
    return invalidIds.length > 0
      ? Promise.reject(new Error(t("invalid_object_ids")))
      : Promise.resolve();
  };

  const multipleEmailValidator = (_, value) => {
    if (
      !value ||
      value.every(
        (email) =>
          /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) ||
          /^\{\{.*\}\}$/.test(email),
      )
    ) {
      return Promise.resolve();
    }
    return Promise.reject(new Error(t("invalid_email")));
  };

  const schemaOptions = schemas?.map((c) => {
    return {
      label: c?.collection_id,
      value: c?._id,
    };
  });

  const flowsWithOperationTriggerOptions = flowsWithOperationTrigger.map(
    (item) => ({
      label: item.name,
      value: item._id,
    }),
  );

  const operationFormConfig = [
    {
      id: "conditionally_trigger_another_flow",
      icon: <LucidePlugZap2 size={26} />,
      title: t("conditionally_trigger_another_flow_operation"),
      description: t("conditionally_trigger_another_flow_description"),
      fields: [
        {
          key: "expression",
          type: "input",
          label: t("expression"),
          placeholder: t("enter_expression"),
        },
        {
          key: "cases",
          type: "cases",
          label: t("case"),
          key_placeholder: t("enter_case_value"),
          value_placeholder: t("enter_flow_id"),
          initialValue: [{ key: "", value: "" }],
          payload_placeholder: t("enter_payload"),
        },
      ],
    },
    {
      id: "trigger",
      icon: <LucideUnplug size={22} />,
      title: t("trigger_another_flow_operation"),
      description: t("trigger_another_flow_description"),
      fields: [
        {
          key: "flow",
          type: "select",
          label: t("flow"),
          rules: [{ required: true, message: t("select_flow") }],
          options: flowsWithOperationTriggerOptions,
          placeholder: t("select_flow"),
          optionFilterProp: "label",
        },
        {
          key: "payload",
          type: "json",
          label: t("payload"),
          placeholder: t("enter_payload"),
          rules: [
            {
              validator: JsonValidator,
            },
          ],
        },
      ],
    },
    {
      id: "json-web-token",
      icon: <LucideKeyRound size={22} />,
      title: t("json_web_token_operation"),
      description: t("json_web_token_description"),
      fields: [
        {
          key: "operation",
          type: "select",
          label: t("operation"),
          rules: [{ required: true, message: t("select_operation") }],
          options: [
            {
              label: t("sign_token"),
              value: "sign",
            },
            {
              label: t("verify_token"),
              value: "verify",
            },
            {
              label: t("decode_token"),
              value: "decode",
            },
          ],
          placeholder: t("select_operation"),
          optionFilterProp: "label",
        },
        {
          key: "payload",
          type: "json",
          label: t("payload"),
          placeholder: t("enter_payload"),
          rules: [{ validator: JsonValidator }],
          dependency: ["sign"],
        },
        {
          key: "secret",
          type: "input",
          label: t("token_secret"),
          placeholder: t("enter_token_secret"),
          rules: [{ required: true, message: t("token_secret_required") }],
          dependency: ["sign", "verify"],
        },
        {
          key: "token",
          type: "input",
          label: t("token"),
          placeholder: t("enter_token"),
          rules: [{ required: true, message: t("token_required") }],
          dependency: ["verify", "decode"],
        },
        {
          key: "options",
          type: "json",
          label: t("options"),
          placeholder: t("enter_options"),
          rules: [{ validator: JsonValidator }],
          dependency: ["sign", "verify", "decode"],
        },
      ],
    },
    {
      id: "predefined_script",
      icon: <LucideFileCode2 size={22} />,
      title: t("predefined_script_operation"),
      description: t("predefined_script_operation_description"),
      fields: [
        {
          key: "scriptKey",
          type: "select",
          label: t("scriptKey"),
          rules: [{ required: true, message: t("select_scriptKey") }],
          options: [
            {
              label: t("createUserinAuth0"),
              value: "createUserinAuth0",
            },
          ],
          placeholder: t("select_scriptKey"),
          optionFilterProp: "label",
        },
        {
          key: "payload",
          type: "json",
          label: t("payload"),
          placeholder: t("enter_payload"),
          rules: [
            {
              validator: JsonValidator,
            },
          ],
        },
      ],
    },
    {
      id: "condition",
      icon: <LucideCopyCheck size={22} />,
      title: t("condition_operation"),
      description: t("condition_operation_description"),
      fields: [
        {
          key: "condition",
          type: "json",
          label: t("condition"),
          placeholder: t("enter_condition"),
          rules: [
            {
              validator: JsonValidator,
            },
          ],
        },
      ],
    },
    {
      id: "transform",
      icon: <LucideCopyCheck size={22} />,
      title: t("transform_operation"),
      description: t("transform_operation_description"),
      fields: [
        {
          key: "payload",
          type: "json",
          label: t("payload"),
          placeholder: t("enter_payload"),
          rules: [
            {
              validator: JsonValidator,
            },
          ],
        },
      ],
    },
    {
      id: "item-create",
      icon: <PlusIcon size={22} />,
      title: t("create_item_operation"),
      description: t("create_item_operation_description"),
      fields: [
        {
          key: "collection",
          type: "select",
          label: t("collection"),
          rules: [{ required: true, message: t("select_collection") }],
          placeholder: t("select_collection"),
          options: schemaOptions,
          labelInValue: true,
          optionFilterProp: "label",
        },
        {
          key: "payload",
          type: "json",
          label: t("payload"),
          placeholder: t("enter_payload"),
          rules: [
            {
              validator: JsonValidator,
            },
          ],
        },
      ],
    },
    {
      id: "item-update",
      icon: <Edit3Icon size={20} />,
      title: t("update_item_operation"),
      description: t("update_item_operation_description"),
      fields: [
        {
          key: "collection",
          type: "select",
          label: t("collection"),
          rules: [{ required: true, message: t("select_collection") }],
          placeholder: t("select_collection"),
          options: schemaOptions,
          labelInValue: true,
          optionFilterProp: "label",
        },
        {
          key: "ids",
          type: "select",
          mode: "tags",
          label: t("ids"),
          placeholder: t("enter_ids"),
          rules: [
            {
              validator: objectIdValidator,
            },
          ],
        },
        {
          key: "payload",
          type: "json",
          label: t("payload"),
          placeholder: t("enter_payload"),
          rules: [
            {
              validator: JsonValidator,
            },
          ],
        },
        {
          key: "query",
          type: "json",
          label: t("query"),
          placeholder: t("enter_query"),
          rules: [
            {
              validator: JsonValidator,
            },
          ],
        },
      ],
    },
    {
      id: "item-delete",
      icon: <Trash2Icon size={20} />,
      title: t("delete_item_operation"),
      description: t("delete_item_operation_description"),
      fields: [
        {
          key: "collection",
          type: "select",
          label: t("collection"),
          rules: [{ required: true, message: t("select_collection") }],
          placeholder: t("select_collection"),
          options: schemaOptions,
          labelInValue: true,
          optionFilterProp: "label",
        },
        {
          key: "ids",
          type: "select",
          mode: "tags",
          label: t("ids"),
          placeholder: t("enter_ids"),
          rules: [
            {
              validator: objectIdValidator,
            },
          ],
        },
        {
          key: "query",
          type: "json",
          label: t("query"),
          placeholder: t("enter_query"),
          rules: [
            {
              validator: JsonValidator,
            },
          ],
        },
      ],
    },
    {
      id: "item-read",
      icon: <EyeIcon size={22} />,
      title: t("read_item_operation"),
      description: t("read_item_operation_description"),
      fields: [
        {
          key: "collection",
          type: "select",
          label: t("collection"),
          rules: [{ required: true, message: t("select_collection") }],
          placeholder: t("select_collection"),
          options: schemaOptions,
          labelInValue: true,
          optionFilterProp: "label",
        },
        {
          key: "ids",
          type: "select",
          mode: "tags",
          label: t("ids"),
          placeholder: t("enter_ids"),
          rules: [
            {
              validator: objectIdValidator,
            },
          ],
        },
        {
          key: "query",
          type: "json",
          label: t("query"),
          placeholder: t("enter_query"),
          rules: [
            {
              validator: JsonValidator,
            },
          ],
        },
      ],
    },
    {
      id: "webhook",
      icon: <Link2Icon size={22} />,
      title: t("webhook_operation"),
      description: t("webhook_operation_description"),
      fields: [
        {
          key: "method",
          type: "select",
          label: t("method"),
          rules: [{ required: true, message: t("select_method") }],
          options: [
            {
              label: "GET",
              value: "GET",
            },
            {
              label: "POST",
              value: "POST",
            },
            {
              label: "PUT",
              value: "PUT",
            },
            {
              label: "DELETE",
              value: "DELETE",
            },
            {
              label: "PATCH",
              value: "PATCH",
            },
          ],
          placeholder: t("select_method"),
        },
        {
          key: "url",
          type: "input",
          label: t("url"),
          rules: [{ required: true, message: t("enter_url") }],
        },
        {
          key: "headers",
          type: "json",
          label: t("request_headers"),
          placeholder: t("enter_headers"),
          rules: [
            {
              validator: JsonValidator,
            },
          ],
        },
        {
          key: "body",
          type: "json",
          label: t("request_body"),
          placeholder: t("enter_body"),
          rules: [
            {
              validator: JsonValidator,
            },
          ],
        },
      ],
    },
    {
      id: "email",
      icon: <LucideMail size={22} />,
      title: t("email_operation"),
      description: t("email_operation_description"),
      fields: [
        {
          key: "to",
          type: "select_with_tags",
          label: t("to"),
          mode: "tags",
          placeholder: t("enter_to_email_placeholder"),
          rules: [{ validator: multipleEmailValidator }],
        },
        {
          key: "subject",
          type: "input",
          label: t("subject"),
          placeholder: t("enter_subject_placeholder"),
        },
        {
          key: "provider",
          type: "select",
          label: t("provider"),
          rules: [{ required: true, message: t("select_email_provider") }],
          options: [
            {
              label: "Flowmailer",
              value: "flowmailer",
            },
            {
              label: "SMTP",
              value: "smtp",
            },
          ],
          placeholder: t("select_provider"),
        },
        {
          key: "flow",
          type: "select_with_dynamic_options",
          label: t("flow"),
          rules: [{ required: true, message: t("select_email_flow") }],
          getOptions: () => axiosInstance.get(Apis.GET_FLOWMAILER_FLOWS()),
          optionLabel: "description",
          optionKey: "id",
          extraKeysInOption: ["templateId"],
          placeholder: t("select_flow"),
          dependency: ["flowmailer"],
          optionFilterProp: "label",
          onOptionSelect: (selectedOption) => {
            setBodyFieldsLoading(true);
            axiosInstance
              .get(
                Apis.GET_FLOWMAILER_FLOW_BODY_JSON({
                  flow_id: selectedOption,
                }),
              )
              .then((response) => {
                form.setFieldValue(
                  "flow_body",
                  JSON.stringify(response.data.payload.data.bodyJson, null, 2),
                );
              })
              .catch(() => {
                notification.error({
                  message: t("failed_to_load_email_body_structure"),
                });
              })
              .finally(() => {
                setBodyFieldsLoading(false);
              });
          },
        },
        {
          key: "template",
          type: "select_with_dynamic_options",
          label: t("template"),
          rules: [{ required: true, message: t("must_select_email_template") }],
          getOptions: () => axiosInstance.get(Apis.GET_EMAIL_TEMPLATES({})),
          optionLabel: "name",
          optionKey: "_id",
          placeholder: t("select_email_template"),
          dependency: ["smtp"],
          optionFilterProp: "label",
          onOptionSelect: (selectedOption) => {
            setBodyFieldsLoading(true);
            axiosInstance
              .get(
                Apis.GET_EMAIL_TEMPLATE_BODY_JSON({
                  templateId: selectedOption,
                }),
              )
              .then((response) => {
                form.setFieldValue(
                  "email_body",
                  JSON.stringify(response.data.payload.data.bodyJson, null, 2),
                );
              })
              .catch(() => {
                notification.error({
                  message: t("failed_to_load_email_body_structure"),
                });
              })
              .finally(() => {
                setBodyFieldsLoading(false);
              });
          },
        },
        {
          key: "flow_body",
          type: "json",
          label: t("flow_body"),
          placeholder: t("enter_flow_body_placeholer"),
          dependency: ["flowmailer"],
        },
        {
          key: "email_body",
          type: "json",
          label: t("email_body"),
          placeholder: t("enter_email_body_placeholer"),
          dependency: ["smtp"],
        },
      ],
    },
  ];

  const [selectedOperation, setSelectedOperation] = useState(
    operationFormConfig.find((i) => i?.id === operationData?.type) ?? {},
  );

  const initialValues = {
    name: operationData?.name,
    key: operationData?.key,
    collection: operationData?.options?.schema_id,
    ...(operationData?.options || {}),
  };

  const getOperationFormFields = (fields = []) => {
    return fields.map((field, i) => {
      switch (field.type) {
        case "select":
          return (
            <Col
              key={i}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={field.label}
                className="font-bold"
                name={field?.key}
                rules={field?.rules || []}
              >
                <Select
                  showSearch
                  className="remove-input-pl flex-1 h-14 w-full"
                  options={field?.options ?? []}
                  mode={field?.mode}
                  placeholder={field?.placeholder ?? ""}
                  labelInValue={field?.labelInValue}
                  fieldNames={field?.fieldNames}
                  optionFilterProp={field?.optionFilterProp}
                />
              </Form.Item>
            </Col>
          );
        case "select_with_tags":
          return (
            <Col
              key={i}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <CustomSelectWithTags field={field} form={form} />
            </Col>
          );
        case "select_with_dynamic_options":
          return <SelectWithDynamicOptions field={field} key={i} />;
        case "textarea":
          return (
            <Col key={i} className="gutter-row" xs={24}>
              <Form.Item
                label={field.label}
                name={field.key}
                rules={[...(field?.rules || [])]}
                className="font-bold"
              >
                <TextArea
                  placeholder={field?.placeholder ?? ""}
                  className="project-custom-input"
                  rows={field?.rows ?? 6}
                />
              </Form.Item>
            </Col>
          );
        case "json":
          return (
            <Col key={i} className="gutter-row" xs={24}>
              <Form.Item
                label={field.label}
                name={field.key}
                rules={[...(field?.rules || [])]}
                className="font-bold"
              >
                <CodeEditorInput
                  loading={
                    (field.key === "flow_body" || field.key === "email_body") &&
                    bodyFieldsLoading
                  }
                  defaultLanguage={"json"}
                />
              </Form.Item>
            </Col>
          );
        case "input":
          return (
            <Col key={i} className="gutter-row" xs={24}>
              <Form.Item
                label={field.label}
                name={field.key}
                className="font-bold"
                rules={[...(field?.rules || [])]}
              >
                <Input
                  placeholder={field?.placeholder ?? ""}
                  className="project-custom-input"
                />
              </Form.Item>
            </Col>
          );
        case "cases":
          return (
            <CustomCases
              key={i}
              field={field}
              initialValues={initialValues}
              i={i}
              form={form}
              t={t}
              JsonValidator={JsonValidator}
              flowsWithOperationTriggerOptions={
                flowsWithOperationTriggerOptions
              }
            />
          );
        case "wysiwyg": {
          return (
            <Col key={i} className="gutter-row" xs={24}>
              <Form.Item
                label={field.label}
                name={field.key}
                rules={[...(field?.rules || [])]}
                className="font-bold"
              >
                <ReactQuill
                  theme="snow"
                  placeholder={field?.placeholder ?? ""}
                />
              </Form.Item>
            </Col>
          );
        }
        case "toggle":
          return (
            <Col key={i} className="gutter-row" xs={24}>
              <Form.Item
                label={field.label}
                name={field.key}
                valuePropName="checked"
                className="font-bold"
              >
                <Switch />
              </Form.Item>
            </Col>
          );
        default:
          return null;
      }
    });
  };

  const handleSaveEditedOperation = async () => {
    try {
      const values = await form.validateFields();

      if (Object.prototype.hasOwnProperty.call(values, "is_same_payload")) {
        values.is_same_payload = !!values.is_same_payload;
      }

      if (operationNodeId) {
        //  find and update node
        const updatedNode = {
          ...operationData,
          name: values.name,
          key: values.key,
          type: selectedOperation.id,
        };

        delete values.name;
        delete values.key;
        updatedNode.options = values;

        setNodes((nodes) => {
          //   find and update node
          return nodes.map((node) => {
            if (node.id === operationNodeId) {
              return { ...node, data: updatedNode, isEdited: true };
            }
            return node;
          });
        });
      } else {
        //   create node
        const newNode = {
          id: `node-${Date.now()}`,
          type: "slide",
          position: {
            x: 0,
            y: 0,
          },
          data: {
            name: values.name,
            key: values.key,
            type: selectedOperation.id,
          },
          isCreated: true,
        };

        const guessedPosition = {
          x: source?.position.x + 360,
          y: source?.position.y + (source?.handleId === "reject" ? 310 : 0),
        };
        newNode.position = guessedPosition;

        // create connection
        const connection = {
          source: source?.id,
          target: newNode.id,
          sourceHandle: source?.handleId,
          targetHandle: "target",
        };

        delete values.name;
        delete values.key;
        newNode.data.options = values;
        setNodes((nodes) => [...nodes, newNode]);

        onConnect(connection);
      }
      dispatch(
        handleFlowActions({ flag: null, data: {}, nodeId: "", source: {} }),
      );
    } catch (error) {
      console.log(error);
    }
  };

  const filteredFields = useMemo(() => {
    return selectedOperation?.fields?.filter((field) => {
      if (!field?.dependency) return true;
      return (
        field.dependency.includes(selectedJwtOperation) ||
        field.dependency.includes(selectedEmailProvider)
      );
    });
  }, [selectedJwtOperation, selectedEmailProvider, selectedOperation?.fields]);

  return (
    <div className="py-4 flow-action-drawer">
      <Flex wrap="wrap" align="center" justify="space-between">
        <Title className="project-header-left-side" level={3}>
          <TItleIconStyle>
            <IconsAll.FlowsIcon />
          </TItleIconStyle>
          {operationData?._id ? t("edit_operation") : t("create_new_operation")}
        </Title>
        <HeaderButton
          shape="circle"
          icon={<GrLinkNext size={24} />}
          className="btn add-button"
          disabled={!selectedOperation?.id}
          onClick={handleSaveEditedOperation}
        ></HeaderButton>
      </Flex>
      <Form
        layout="vertical"
        className="mt-6"
        form={form}
        initialValues={initialValues}
      >
        <Row gutter={28}>
          <Col className="gutter-row" xs={24} md={12}>
            <Form.Item
              label={t("name")}
              className="font-bold"
              name={`name`}
              rules={[{ required: true, message: t("name_required") }]}
            >
              <Input
                type={"text"}
                placeholder={t("enter_flow_name")}
                className="project-custom-input"
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" xs={24} md={12}>
            <Form.Item
              label={t("key")}
              className="font-bold"
              name={`key`}
              rules={[{ required: true, message: t("key_required") }]}
            >
              <Input
                type={"text"}
                placeholder={t("enter_flow_key")}
                className="project-custom-input"
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" xs={24}>
            <Divider className="my-6" />
          </Col>
          <Col className="gutter-row" xs={24}>
            <div className={"flex flex-col gap-2"}>
              {selectedOperation?.id ? (
                <>
                  <OperationOption
                    setSelectedOperation={setSelectedOperation}
                    operation={selectedOperation}
                    isSelected
                  />
                  <Row gutter={28} className={"mt-6"}>
                    {getOperationFormFields(filteredFields)}
                  </Row>
                </>
              ) : (
                operationFormConfig.map((operation, i) => (
                  <OperationOption
                    setSelectedOperation={setSelectedOperation}
                    operation={operation}
                    key={i}
                  />
                ))
              )}
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default EditOperation;
