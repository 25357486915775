import { Tooltip } from "antd";
import React from "react";
import { IoIosInformationCircleOutline } from "react-icons/io";

const ValidationError = ({ error }) => {
  return (
    <div className="flex items-center gap-1 ant-form-item-explain-error">
      <span>{error?.custom_msg || error?.error_msg || ""}</span>
      <span className="">
        {error?.context_error_msg && (
          <Tooltip
            className=""
            title={
              Array.isArray(error?.context_error_msg)
                ? error?.context_error_msg?.map((msg, index) => (
                    <span key={index}>
                      {msg}
                      <br />
                    </span>
                  ))
                : error?.context_error_msg
            }
          >
            <IoIosInformationCircleOutline />
          </Tooltip>
        )}
      </span>
    </div>
  );
};

export default ValidationError;
