import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  setting: null,
  items: [
    {
      id: "dashboard",
      label: "Dashboard",
      path: "/dashboard",
      icon: "IconsAll.DashboardIcon",
      type: "checkbox",
      visible: true,
      sort: 1,
    },
    {
      id: "content",
      label: "Content",
      path: "/content",
      icon: "IconsAll.ContentIcon",
      type: "checkbox",
      visible: true,
      sort: 2,
    },
    {
      id: "users",
      label: "Users",
      path: "/users",
      icon: "IconsAll.GebruikersIcon",
      type: "checkbox",
      visible: true,
      sort: 3,
    },
    {
      id: "media-library",
      label: "Media Library",
      path: "/media-library",
      icon: "IconsAll.MediaLibraryIcon",
      type: "locked",
      visible: true,
      sort: 4,
    },
    {
      id: "settings",
      label: "Settings",
      path: "/settings/data-model",
      icon: "IconsAll.ConfigIcon",
      visible: true,
      type: "locked",
      sort: 5,
    },
    {
      id: "adminSettings",
      label: "Admin Settings",
      path: "/admin/settings/system-translations",
      icon: "IconsAll.ExtentiesIcon",
      visible: true,
      type: "locked",
      sort: 6,
    },
  ],
  status: "idle", // Possible values: "idle", "loading", "succeeded", "failed"
  error: null,
};

const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    setSettings: (state, action) => {
      state.status = "loading";
      let data = action.payload;
      const { modules } = action.payload;
      delete data.modules;
      state.setting = action.payload;
      state.items = modules;
      state.status = "succeeded";
    },

    setSidebarItems: (state, action) => {
      state.status = "loading";
      state.items = action.payload;
      state.status = "succeeded";
    },

    addSidebarItem: (state, action) => {
      state.status = "loading";
      const items = state.items;
      const newItem = {
        ...action.payload,
      };
      items.push(newItem);
      state.items = items;
      state.status = "succeeded";
    },

    updateSidebarItem: (state, action) => {
      state.status = "loading";
      const { id, updatedData } = action.payload;
      const index = state.items.findIndex((item) => item.id === id);
      if (index !== -1) {
        state.items[index] = {
          ...state.items[index],
          ...updatedData,
        };
      }
      state.status = "succeeded";
    },
    deleteSidebarItem: (state, action) => {
      state.status = "loading";
      const items = state.items.filter((item) => item.id !== action.payload);
      state.items = items;
      state.status = "succeeded";
    },
  },
});

export const {
  setSidebarItems,
  addSidebarItem,
  updateSidebarItem,
  deleteSidebarItem,
  setSettings,
} = sidebarSlice.actions;
export default sidebarSlice.reducer;
