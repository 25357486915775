import React from "react";
import { Flex } from "antd";
import AlleGebruikersTable from "./AlleGebruikersTable";
import AppInfo from "../../Components/AppInfo";
import { useTranslation } from "react-i18next";

const AlleGebruikers = () => {
  const { t } = useTranslation();

  return (
    <Flex className="w-full">
      <AlleGebruikersTable />
      <AppInfo
        visibleList={["information"]}
        config={{
          msg: t("information_all_users"),
        }}
      />
    </Flex>
  );
};

export default AlleGebruikers;
