import React, { useEffect, useState } from "react";
import "@xyflow/react/dist/style.css";
import "./i18n";
import RoutesComponents from "./Routes/index";
import { useSelector } from "react-redux";
import i18n from "./i18n";
import { useDispatch } from "react-redux";
import Apis from "./Helpers/Apis";
import axiosInstance from "./Helpers/axios";
import { setUserProfile } from "./Redux/slices/userDetails.reducer";
import { useLocation } from "react-router-dom";
import {
  useGroupedTranslations,
  useSystemTranslations,
} from "./Helpers/custom-hooks/useGroupedTranslations";
import { configureTheme } from "./Helpers/utils";
import GlobalLoader from "./Common/GlobalLoader";
import {
  getAllFields,
  getAllFieldsRelations,
  getAllSchemas,
} from "./Helpers/apiCalls";
import { setAppearance } from "./Redux/slices/appearance.reducer";

const App = () => {
  const bodyTag = document.querySelector("body");
  const htmlTag = document.querySelector("html");
  const activeTheme = useSelector((state) => state.theme.name);
  bodyTag.setAttribute("data-theme", activeTheme);
  const lng = useSelector((state) => state?.user?.userProfile?.language);
  let location = useLocation();
  const dispatch = useDispatch();
  const translations = useGroupedTranslations(lng);
  const systemTranslations = useSystemTranslations();
  const [user, setUser] = useState();
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    Object.keys(translations).forEach((code) => {
      if (translations[code]) {
        i18n.addResourceBundle(
          code,
          "tenant",
          translations[code] || {},
          true,
          true,
        );
      }
    });
    i18n.changeLanguage(lng || "en");
  }, [lng, translations]);

  useEffect(() => {
    Object.keys(systemTranslations).forEach((code) => {
      if (systemTranslations[code]) {
        i18n.addResourceBundle(
          code,
          "system",
          systemTranslations[code] || {},
          true,
          true,
        );
      }
    });
    i18n.changeLanguage(lng || "en");
  }, [systemTranslations]);

  useEffect(() => {
    htmlTag.className = activeTheme?.includes("dark") ? "dark" : "";
  }, [activeTheme, location]);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    setLoader(true);
    try {
      await configureTheme().then(async (appearance) => {
        dispatch(setAppearance(appearance));
        const resp = await axiosInstance.get(Apis.GET_USER_PROFILE());
        if (resp.status === 200) {
          let isAdmin = resp.data.payload.data?.nox_admin;
          document.title = isAdmin
            ? "Super Admin | NOX®"
            : resp.data.payload.data?.tenant?.name + " | NOX®";
          setUser(resp.data.payload.data);
          if (!isAdmin) {
            getAllFields({ dispatch });
            getAllFieldsRelations({ dispatch });
            getAllSchemas({ dispatch });
          }
          dispatch(setUserProfile(resp.data.payload.data));
        }
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      <GlobalLoader />
      {!loader && <RoutesComponents user={user} />}
    </>
  );
};

export default App;
