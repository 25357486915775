import React, { useEffect } from "react";
import { Select } from "antd";
import { getTranslatedLabel } from "../../../Helpers/utils";

const CustomDropdown = ({
  item,
  updateFormData,
  formData,
  isValidationError,
}) => {
  useEffect(() => {
    if (formData?.[item.path] === undefined) {
      updateFormData(item.schema_definition?.default || "");
    }
  }, [formData?.[item.path]]);
  return (
    <Select
      showSearch
      placeholder={getTranslatedLabel(item?.meta?.options?.placeholder)}
      filterOption={(input, option) =>
        (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
      }
      onSelect={(value) => updateFormData(value || "")}
      value={formData?.[item?.path]}
      options={item?.meta?.options?.choices?.sort((a, b) => a?.sort - b?.sort)}
      disabled={!!item?.meta?.readonly}
      className={`${isValidationError ? "border-[red]" : ""}`}
    />
  );
};

export default CustomDropdown;
