import React, { useEffect, useState } from "react";
import { Form, Row, Flex, Spin, notification } from "antd";
import GenerateUpdateFieldsForm from "../../Update-Schema-Fields/GenerateUpdateFieldsForm";
import RightSIdeTitlePart from "../../HeaderParts/RightSIdeTitlePart";
import IconsAll from "../../IconsAll";
import LeftSideTitlePart from "../../HeaderParts/LeftSideTitlePart";
import { flatten, unflatten } from "flat";
import EditConfig from "./editFieldsJson.json";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { FormStyling } from "../../../Common/CommonUiStyles";
import { capitalizeAndFormat, formatDate } from "../../../Helpers/utils";
import { useTranslation } from "react-i18next";
dayjs.extend(utc);

const UpdateSchema = ({ activeItem, dataSource, updateField, loader }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const [tags, setTags] = useState(
    activeItem?.schema_definition?.default || [],
  );
  const [Json, setJson] = useState(() => {
    let parsedJson = null;
    let isValidJson = false;

    if (activeItem?.schema_definition?.default) {
      try {
        parsedJson = JSON.parse(activeItem.schema_definition.default);
        isValidJson = true;
      } catch (error) {
        parsedJson = null;
      }
    }
    return {
      jsonData: parsedJson,
      isValidJson,
    };
  });

  useEffect(() => {
    form.resetFields();
  }, [activeItem?._id]);

  const handleSubmit = async () => {
    if (activeItem?.meta?.interface === "json" && !Json.isValidJson) {
      notification.error({ message: t("please_enter_valid_json") });
      return;
    }
    const finalValues = unflatten({
      ...flatten(activeItem),
      ...(await form.getFieldsValue()),
    });
    const { _id, ...restOfSelectedField } = finalValues;
    delete restOfSelectedField.__v;
    let updatedDate = await form.getFieldValue("schema_definition.default");
    let final = {
      ...restOfSelectedField,
      meta: {
        ...restOfSelectedField?.meta,
        required: !restOfSelectedField?.meta?.nullable,
      },
      schema_definition:
        restOfSelectedField?.meta?.interface === "datetime"
          ? {
              ...restOfSelectedField?.schema_definition,
              default: updatedDate
                ? formatDate(updatedDate, activeItem?.type)
                : null,
            }
          : restOfSelectedField?.meta?.interface === "tags"
            ? {
                ...restOfSelectedField?.schema_definition,
                default: tags,
              }
            : restOfSelectedField?.meta?.interface === "json"
              ? {
                  ...restOfSelectedField?.schema_definition,
                  default: JSON.stringify(Json.jsonData),
                }
              : restOfSelectedField?.schema_definition,
    };

    updateField(_id, final);
  };

  const getConfig = () => {
    switch (activeItem?.meta?.interface) {
      case "object":
        return EditConfig["object"];
      case "items":
        return EditConfig["object"];
      case "input":
        return EditConfig["input"][activeItem?.type];
      case "autocomplete":
        return EditConfig["autocomplete"];
      case "textarea":
        return EditConfig["textarea"];
      case "wysiwyg":
        return EditConfig["wysiwyg"];
      case "tags":
        return EditConfig["tags"];
      case "json":
        return EditConfig["json"];
      case "toggle":
        return EditConfig["toggle"];
      case "datetime":
        return EditConfig["datetime"];
      case "color":
        return EditConfig["color"];
      case "dropdown":
        return EditConfig["dropdown"];
      case "checkboxes":
        return EditConfig["checkboxes"];
      case "dropdown_multiple":
        return EditConfig["dropdown_multiple"];
      case "radio":
        return EditConfig["radio"];
      case "list-m2m":
        return EditConfig["list-m2m"];
      case "list-m2a":
        return EditConfig["list-m2a"];
      case "list-o2m":
        return EditConfig["list-o2m"];
      case "list-m2o":
        return EditConfig["list-m2o"];
      case "file":
        return EditConfig["file"];
      case "file-image":
        return EditConfig["file-image"];
      case "files":
        return EditConfig["files"];
      case "translations":
        return EditConfig["translations"];
      default:
        return {};
    }
  };

  return (
    <>
      <Flex wrap="wrap" align="center" justify="space-between">
        <LeftSideTitlePart
          pageTitle={
            capitalizeAndFormat(activeItem?.meta?.interface) +
            " " +
            capitalizeAndFormat(activeItem?.type) +
            " (" +
            capitalizeAndFormat(dataSource?.collection_name) +
            ")"
          }
          pageIcon={true}
          pageInnerIcon={<IconsAll.ContentIcon />}
        />
        <RightSIdeTitlePart
          showButton={true}
          handleShowClick={handleSubmit}
          showDisabled={loader}
        />
      </Flex>

      {loader ? (
        <Spin
          spinning={loader}
          fullscreen
          indicator={<div className="custom-loader" />}
        />
      ) : (
        <FormStyling form={form} className="mt-4" layout="vertical">
          <Row gutter={28}>
            {Object.entries(getConfig()).map(([key]) => (
              <GenerateUpdateFieldsForm
                key={key}
                activeItem={activeItem}
                category={key}
                form={form}
                tags={tags}
                setTags={setTags}
                setJson={setJson}
                Json={Json}
              />
            ))}
          </Row>
        </FormStyling>
      )}
    </>
  );
};

export default UpdateSchema;
