export default {
  GET_ALL_COLLECTION_NAMES: () => `/noxp/collection`,
  GET_COLLECTION_RECORD_BY_CODE: ({ code }) => `/noxp/collection/${code}`,
  GET_PROJECTION_SCHEMA: ({
    page = 1,
    limit = 10,
    sortBy = "createdAt",
    descending = true,
    schemas = "",
    pagination = true,
  }) =>
    `/noxp/projection_schema?page=${page}&limit=${limit}&sortBy=${sortBy}&descending=${descending}&pagination=${pagination}&schemas=${schemas}`,
  GET_PROJECTION_SCHEMA_BY_CODE: ({ schema = "", isOnlySchema = false }) =>
    `/noxp/projection_schema/${schema}?isOnlySchema=${isOnlySchema}`,
  UPDATE_LISTING_PROJECTION_SCHEMA: ({ code }) =>
    `/noxp/projection_schema/${code}`,
  GET_ROLES: ({
    page = 1,
    limit = 10,
    filter = "",
    sortBy = "createdAt",
    descending = true,
  }) =>
    `/api/v1/role?page=${page}&limit=${limit}&filter=${filter}&sortBy=${sortBy}&descending=${descending}`,
  GET_ROLE_DETAIL: (id) => `/role/${id}`,
  UPDATE_ROLE: (role_id) => `/role/${role_id}`,
  //Tenant Api calls
  ADD_A_TENANT: () => `/api/v1/tenant`,
  UPDATE_A_TENANT: (tenantID) => `/api/v1/tenant/${tenantID}`,
  GET_ALL_TENANTS: ({
    page = 1,
    limit = 10,
    descending = "true",
    sortBy = "createdAt",
    filter = "",
  }) =>
    `/api/v1/tenant?filter=${filter}&limit=${limit}&page=${page}&descending=${descending}&sortBy=${sortBy}`,
  GET_ALL_TENANT_USERS: ({
    page = 1,
    limit = 10,
    descending = "true",
    sortBy = "created_at",
    filter = "",
  }) =>
    `/api/v1/user/tenant-user?filter=${filter}&limit=${limit}&page=${page}&descending=${descending}&sortBy=${sortBy}`,
  GET_USER: (id) => `/api/v1/user/${id}`,
  RESET_PASSWORD: () => `/api/v1/auth/reset-password`,
  LOGIN: () => `api/v1/auth/retrieve/login-prompt`,
  LOGOUT: () => `api/v1/auth/logout`,
  GET_DEFAULT_PERMISSIONS: () => `api/v1/role/default-permissions`,
  ADD_A_SCHEMA: () => `/api/v1/schema`,
  GET_ALL_SCHEMAS: ({
    page = 1,
    limit = 30,
    filter = "",
    sortBy = "createdAt",
    descending = true,
    system = false,
  }) =>
    `/api/v1/schema?page=${page}&limit=${limit}&filter=${filter}&sortBy=${sortBy}&descending=${descending}&system=${system}`,
  GET_A_SCHEMA: (collection_id, populated) =>
    `/api/v1/schema/${collection_id}?populated=${populated}`,
  UPDATE_SCHEMA_VISIBILITY: (collection_id) =>
    `/api/v1/schema/${collection_id}`,
  UPDATE_SCHEMA: (collection_id) => `/api/v1/schema/${collection_id}`,
  UPDATE_SCHEMA_SORT: () => `/api/v1/schema/schema-sort`,
  DELETE_A_SCHEMA: (collection_id) => `/api/v1/schema/${collection_id}`,
  ADD_A_ROLE: () => `/api/v1/role`,
  GET_ALL_ROLES: (all = "") => `/api/v1/role?all=${all}`,
  GET_A_ROLE: (role_id) => `/api/v1/role/${role_id}`,
  UPDATE_A_ROLE: (role_id) => `/api/v1/role/${role_id}`,
  GET_USER_PERMISSIONS: () => `/api/v1/module/accessible`,
  GET_USER_PROFILE: () => `/api/v1/user/my-profile`,
  UPDATE_USER_PROFILE: (user_id) => `/api/v1/user/my-profile/${user_id}`,
  ADD_A_FIELD: () => `/api/v1/fields`,
  UPDATE_A_FIELD: (schema_id) => `/api/v1/fields/${schema_id}`,
  DELETE_A_FIELD: (id) => `/api/v1/fields/${id}`,
  UPDATE_FIELD_SORT: (schema_id) => `/api/v1/fields/${schema_id}/sort`,
  GET_ALL_FIELDS_SCHEMA_ID: (schema_id) => `/api/v1/fields/${schema_id}`,
  GET_ALL_FIELDS: ({
    page = 1,
    limit = 10,
    filter = "",
    sortBy = "",
    descending = true,
    pagination = true,
  }) =>
    `/api/v1/fields/all?page=${page}&limit=${limit}&filter=${filter}&sortBy=${sortBy}&descending=${descending}&pagination=${pagination}`,
  GET_ALL_FIELDS_RELATIONS: () => `/api/v1/fields/all-relations`,
  UPDATE_FIELDS_VISIBILITY: (id) => `/api/v1/fields/${id}`,
  ADD_CONTENT: (schema_id) => `/api/v1/items/${schema_id}`,
  UPDATE_CONTENT: (schema_id, content_id) =>
    `/api/v1/items/${schema_id}/${content_id}`,
  GET_CONTENT: (schema_id, searchTerm, { page = 1, limit = 10 }) =>
    `/api/v1/items/${schema_id}/data?filter=${searchTerm}&page=${page}&limit=${limit}`,
  GET_SINGLE_CONTENT: (schema_id, content_id) =>
    `/api/v1/items/${schema_id}/${content_id}`,
  DELETE_CONTENT: (content_id) => `/api/v1/items/${content_id}`,
  GET_PRESET: (collection_id) => `/api/v1/presets/${collection_id}`,
  ADD_PRESET: (collection_id) => `/api/v1/presets/${collection_id}`,
  UPDATE_PRESET: (collection_id) => `/api/v1/presets/${collection_id}`,
  GET_LANGUAGE_OPTIONS: (limit = 1000) =>
    `/api/v1/translation-language?limit=${limit}`,
  UPSERT_TRANSLATIONS: (id) => `/api/v1/translations/${id}`,
  GET_TRANSLATIONS: (searchTerm, unique, page = 1, limit = 10) =>
    `/api/v1/translations?filter=${searchTerm}&unique=${unique}&page=${page}&limit=${limit}`,
  GET_SINGLE_TRANSLATION: (id) => `/api/v1/translations/${id}`,
  DELETE_TRANSLATIONS: () => `/api/v1/translations`,
  GET_TRANSLATIONS_BY_GROUP: (code) =>
    `/api/v1/translations/locale?code=${code}`,
  GET_COMMON_SYSTEM_TRANSLATIONS: () => `/api/v1/common/system-translations`,
  GET_ALL_FOLDERS: ({ pagination = "false" }) =>
    `/api/v1/folders?pagination=${pagination}`,
  CREATE_FOLDER: () => `/api/v1/folders`,
  UPDATE_FOLDER: (id) => `/api/v1/folders/${id}`,
  MOVE_FOLDER: () => `/api/v1/folders/move`,
  DELETE_FOLDER: (id) => `/api/v1/folders/${id}`,
  UPLOAD_FILE: () => `api/v1/files`,
  DELETE_FILE: () => `api/v1/files/delete`,
  GET_DISPLAY_IMAGE: (img_id, height = "", width = "") =>
    `api/v1/image/${img_id}?height=${height}&width=${width}&fit=contain&background=${encodeURIComponent("#f5f6f7")}`,
  GET_FILES: ({
    folder = "",
    page = 1,
    limit = 10,
    descending = "true",
    sortBy = "createdAt",
  }) =>
    `api/v1/files?folder=${folder}&page=${page}&limit=${limit}&descending=${descending}&sortBy=${sortBy}`,
  GET_FILE: (file_id) => `api/v1/files/${file_id}`,
  UPDATE_FILE: (file_id) => `api/v1/files/${file_id}`,
  ADD_TENANT_USER: () => `api/v1/user/tenant-user`,
  UPDATE_USER: (id) => `api/v1/user/${id}`,
  UPSERT_SYSTEM_TRANSLATIONS: (id) => `/api/v1/system-translations/${id}`,
  GET_SYSTEM_TRANSLATIONS: (searchTerm, unique, page = 1, limit = 10) =>
    `/api/v1/system-translations?filter=${searchTerm}&unique=${unique}&page=${page}&limit=${limit}`,
  GET_SINGLE_SYSTEM_TRANSLATION: (id) => `/api/v1/system-translations/${id}`,
  DELETE_SYSTEM_TRANSLATIONS: () => `/api/v1/system-translations`,
  GET_REVISION: (schema_id, item_id, { page = 1, limit = 45 }) =>
    `/api/v1/revision/${schema_id}/${item_id}?page=${page}&limit=${limit}`,
  GET_CONFIGURATION: ({
    type = "",
    page = 1,
    limit = 10,
    descending = "true",
    sortBy = "createdAt",
    pagination = false,
    filter = "",
  }) =>
    `/api/v1/configuration?pagination=${pagination}&page=${page}&limit=${limit}&descending=${descending}&sortBy=${sortBy}&type=${type}&filter=${filter}`,
  ADD_CONFIGURATION: () => `/api/v1/configuration`,
  UPDATE_CONFIGURATION: ({ type }) => `/api/v1/configuration/${type}`,
  DELETE_CONFIGURATION: ({ type }) => `/api/v1/configuration/${type}`,
  DEFAULT_GET_CONTENT: (schema_id, searchTerm, { page = 1, limit = 10 }) =>
    `/api/v1/content/${schema_id}/list?filter=${searchTerm}&page=${page}&limit=${limit}`,
  DEFAULT_GET_SINGLE_CONTENT: (schema_id, content_id) =>
    `/api/v1/content/${schema_id}/${content_id}`,
  DEFAULT_ADD_CONTENT: (schema_id) => `/api/v1/content/${schema_id}`,
  DEFAULT_UPDATE_CONTENT: (schema_id, content_id) =>
    `/api/v1/content/${schema_id}/${content_id}`,
  DEFAULT_DELETE_CONTENT: (content_id) =>
    `/api/v1/content/${content_id}/delete`,
  GET_EMAIL_TEMPLATES: ({
    page = 1,
    limit = 10,
    descending = "true",
    sortBy = "created_at",
    pagination = false,
    filter = "",
    trigger = "",
  }) =>
    `/api/v1/email-templates?pagination=${pagination}&page=${page}&limit=${limit}&descending=${descending}&sortBy=${sortBy}&filter=${filter}&trigger=${trigger}`,
  GET_EMAIL_TEMPLATE_DATA: ({ templateId }) =>
    `/api/v1/email-templates/${templateId}`,
  GET_EMAIL_TEMPLATE_BODY_JSON: ({ templateId }) =>
    `/api/v1/email-templates/${templateId}/body`,
  DUPLICATE_EMAIL_TEMPLATE: ({ templateId }) =>
    `/api/v1/email-templates/${templateId}/duplicate`,
  CREATE_EMAIL_TEMPLATE: () => `/api/v1/email-templates`,
  UPDATE_EMAIL_TEMPLATE: ({ templateId }) =>
    `/api/v1/email-templates/${templateId}`,
  DELETE_EMAIL_TEMPLATE: ({ templateId }) =>
    `/api/v1/email-templates/${templateId}`,
  GET_FLOWS: ({
    page = 1,
    limit = 10,
    descending = "true",
    sortBy = "created_at",
    pagination = false,
    filter = "",
    trigger = "",
  }) =>
    `/api/v1/flows?pagination=${pagination}&page=${page}&limit=${limit}&descending=${descending}&sortBy=${sortBy}&filter=${filter}&trigger=${trigger}`,
  GET_FLOW_DATA: ({ flow_id }) => `/api/v1/flows/${flow_id}`,
  GET_FLOW_ACTIVITY: ({
    flow_id,
    page = 1,
    limit = 10,
    descending = "true",
    sortBy = "created_at",
    pagination = false,
  }) =>
    `/api/v1/flows/activity/${flow_id}?pagination=${pagination}&page=${page}&limit=${limit}&descending=${descending}&sortBy=${sortBy}`,
  UPDATE_FLOW: ({ flow_id }) => `/api/v1/flows/${flow_id}`,
  DELETE_FLOW: ({ flow_id }) => `/api/v1/flows/${flow_id}`,
  CREATE_FLOW: () => `/api/v1/flows`,
  GET_FLOWMAILER_FLOWS: () => `/api/v1/flowmailer/flows`,
  GET_FLOWMAILER_FLOW_BODY_JSON: ({ flow_id }) =>
    `/api/v1/flowmailer/flows/${flow_id}/body`,
  GET_SWAGGER: () => `/api/v1/common/generate-swagger`,
  GET_SETTING: () => `/api/v1/setting/`,
  UPDATE_SETTING: () => `/api/v1/setting/update`,
};
