import React, { useEffect } from "react";
import { ColorPicker } from "antd";

const CustomColorInput = ({
  item,
  updateFormData,
  formData,
  isValidationError,
}) => {
  useEffect(() => {
    if (formData?.[item.path] === undefined) {
      updateFormData(item.schema_definition?.default || "");
    }
  }, [formData?.[item.path]]);
  return (
    <ColorPicker
      defaultValue={item?.schema_definition?.default || ""}
      value={formData?.[item.path] || ""}
      disabledAlpha
      allowClear
      showText
      className={`project-custom-color-picker ${isValidationError ? "border-[red]" : ""}`}
      onChange={(e) => updateFormData(e.toRgbString() || "")}
      disabled={item?.meta?.readonly}
    />
  );
};

export default CustomColorInput;
