import { combineReducers } from "@reduxjs/toolkit";
import themeReducer from "./slices/theme.reducer";
import activeTabReducer from "./slices/activeTab.reducer";
import languageOptionsReducer from "./slices/language.reducer";
import userDetailsReducer from "./slices/userDetails.reducer";
import mediaReducer from "./slices/media.reducer";
import appearanceReducer from "./slices/appearance.reducer";
import loaderReducer from "./slices/loader.reducer";
import fieldsReducer from "./slices/fields.reducer";
import schemasReducer from "./slices/schema.reducer";
import mediaFoldersReducer from "./slices/mediaFolders.reducers";
import flowsReducer from "./slices/flows.reducer";
import sidebarReducer from "./slices/sidebar.reducer";

const rootReducer = combineReducers({
  sidebar: sidebarReducer,
  theme: themeReducer,
  activeTab: activeTabReducer,
  languageOptions: languageOptionsReducer,
  user: userDetailsReducer,
  media: mediaReducer,
  appearance: appearanceReducer,
  loader: loaderReducer,
  fields: fieldsReducer,
  schemas: schemasReducer,
  mediaFolders: mediaFoldersReducer,
  flows: flowsReducer,
});

export default rootReducer;
