import React, { forwardRef } from "react";
import * as LucideIcons from "lucide-react";
import IconsAll from "../../Components/IconsAll";
import { Dropdown, Flex, Modal } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { capitalizeAndFormat } from "../../Helpers/utils";
import { AiOutlineMore } from "react-icons/ai";
import { LuChevronsDownUp, LuChevronsUpDown, LuDatabase } from "react-icons/lu";
import { FaGripLines } from "react-icons/fa6";
import { RiDeleteBin6Line } from "react-icons/ri";
import { setActiveTab } from "../../Redux/slices/activeTab.reducer";

export function Action({ active, cursor, style, ...props }) {
  return (
    <button
      {...props}
      className={``}
      tabIndex={0}
      style={{
        ...style,
        cursor,
        "--fill": active?.fill,
        "--background": active?.background,
      }}
    />
  );
}

export function Handle(props) {
  return (
    <Action cursor="grab" data-cypress="draggable-handle" {...props}>
      <svg viewBox="0 0 20 20" width="12">
        <path d="M7 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 2zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 14zm6-8a2 2 0 1 0-.001-4.001A2 2 0 0 0 13 6zm0 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 14z"></path>
      </svg>
    </Action>
  );
}

export const TreeItem = forwardRef(
  (
    {
      item,
      childCount,
      clone,
      depth,
      handleProps,
      indentationWidth,
      onCollapse,
      wrapperRef,
      openUpdateFolderModal,
      handleMenuClick,
      ...props
    },
    ref,
  ) => {
    // eslint-disable-next-line no-unused-vars
    const { icons, createLucideIcon, ...filteredIcons } = LucideIcons;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    return (
      <>
        <div ref={wrapperRef} {...props}>
          <div
            ref={ref}
            style={{
              fontFamily: "Sometype Mono, monospace",
              marginLeft: `${indentationWidth * depth}px`,
            }}
            className="group flex items-center p-[0.9rem] mb-[0.5rem] border border-(--input-field-border) bg-(--input-field-bg) text-base text-(--heading-text) rounded-(--br-12) cursor-pointer"
          >
            <FaGripLines
              {...handleProps}
              focusable={false}
              color={"rgba(from var(--heading-text) r g b / 50%)"}
              className="mr-4"
              size={24}
            />
            <div
              className="flex justify-between w-full"
              onClick={() => {
                item?.meta?.is_folder
                  ? openUpdateFolderModal(item?._id)
                  : navigate(`/settings/data-model/${item?._id}`);
              }}
            >
              <div className="flex cursor-pointer">
                {item?.visible ? (
                  item?.meta?.icon ? (
                    <span className="flex items-center justify-center">
                      {filteredIcons[item?.meta?.icon] &&
                        React.createElement(filteredIcons[item?.meta?.icon], {
                          size: 22,
                          color: item?.meta?.color || "var(--project-base)",
                        })}
                    </span>
                  ) : (
                    <LuDatabase size={22} color="var(--project-base)" />
                  )
                ) : (
                  <IconsAll.EyeCloseIcon className="text-xs" />
                )}
                <span className={`ml-4 ${item?.visible ? "" : "opacity-25"}`}>
                  {capitalizeAndFormat(item?.collection_name)}
                </span>
                <span className="ml-4 item-note hidden group-hover:block text-heading-text opacity-50">
                  {item?.meta?.note}
                </span>
              </div>
            </div>

            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              {item?.children?.length > 0 ? (
                item?.meta?.collapse === "open" ? (
                  <LuChevronsUpDown
                    size={22}
                    color="rgba(from var(--heading-text) r g b / 20%)"
                    onClick={onCollapse}
                    title={t("expand")}
                  />
                ) : (
                  <LuChevronsDownUp
                    size={22}
                    color="rgba(from var(--heading-text) r g b / 20%)"
                    onClick={onCollapse}
                    title={t("collapse")}
                  />
                )
              ) : (
                <></>
              )}
              <Dropdown
                menu={{
                  items: [
                    item?.visible &&
                      !item?.meta?.is_folder && {
                        key: "view",
                        label: (
                          <Flex gap={"8px"}>
                            <IconsAll.ContentIcon />
                            {t("view_content")}
                          </Flex>
                        ),
                      },
                    !item?.meta?.is_folder && {
                      key: "visible",
                      label: (
                        <Flex gap={"8px"}>
                          {item?.visible ? (
                            <IconsAll.EyeCloseIcon
                              className="text-xs"
                              strokeColor="var(--project-base)"
                            />
                          ) : (
                            <IconsAll.OpenEyeIcon
                              strokeColor="var(--project-base)"
                              className="text-xs"
                            />
                          )}
                          {t("make_collection") + " "}
                          {item?.visible ? t("hidden") : t("visible")}
                        </Flex>
                      ),
                    },
                    {
                      key: "delete",
                      label: (
                        <Flex gap={"8px"}>
                          <IconsAll.TrashIcon
                            className="text-xs"
                            strokeColor={`var(--color-delete-base)`}
                          />
                          {t(
                            item?.meta?.is_folder
                              ? "delete_folder"
                              : "delete_collection",
                          )}
                        </Flex>
                      ),
                    },
                  ],
                  onClick: (e) => {
                    if (e.key === "delete") {
                      const isFolder = item?.meta?.is_folder;
                      Modal.confirm({
                        title: isFolder
                          ? t("delete_folder")
                          : t("delete_collection"),
                        content: isFolder
                          ? t("delete_folder_confirm", {
                              selectedFolder: capitalizeAndFormat(
                                item?.collection_name,
                              ),
                            })
                          : t("delete_collection_confirm"),
                        icon: (
                          <div className="modal-icon">
                            <RiDeleteBin6Line size={24} />
                          </div>
                        ),
                        okText: isFolder
                          ? t("delete_folder")
                          : t("delete_collection"),
                        okType: "primary",
                        cancelText: t("cancel"),
                        maskClosable: true,
                        className: "modal-delete",
                        onOk() {
                          handleMenuClick(item?._id, e.key);
                        },
                      });
                    } else if (e.key === "view") {
                      navigate(`/content/${item?._id}`);
                      dispatch(
                        setActiveTab({
                          page: "content",
                          tab: item?._id,
                          redirectionLink: `/content/${item?._id}`,
                        }),
                      );
                    } else if (e.key === "visible") {
                      handleMenuClick(item?._id, e.key, item?.visible);
                    }
                  },
                }}
                trigger={["click"]}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <a>
                  <AiOutlineMore
                    size={24}
                    color="rgba(from var(--heading-text) r g b / 20%)"
                  />
                </a>
              </Dropdown>
            </div>
            {clone && childCount && childCount > 1 ? (
              <span>{childCount}</span>
            ) : null}
          </div>
        </div>
      </>
    );
  },
);

TreeItem.displayName = "TreeItem";
