import {
  Button,
  Checkbox,
  Col,
  ColorPicker,
  DatePicker,
  Dropdown,
  Form,
  Input,
  InputNumber,
  notification,
  Radio,
  Row,
  Select,
  Spin,
  Tag,
  Tooltip,
  TreeSelect,
} from "antd";
import React, { useCallback, useEffect, useId, useState } from "react";
import ReactDOMServer from "react-dom/server";
import {
  capitalizeAndFormat,
  convertToTreeData,
  CustomInfoText,
  formatDate,
  removeNullAndUndefined,
} from "../../Helpers/utils";
import {
  DrawerStyle,
  FormStyling,
  InvertedCheckBox,
} from "../../Common/CommonUiStyles";
import { useParams } from "react-router-dom";
import axiosInstance from "../../Helpers/axios";
import Apis from "../../Helpers/Apis";
import TextArea from "antd/es/input/TextArea";
import ReactQuill from "react-quill";
import { BsInfoCircle, BsTags } from "react-icons/bs";
import { IoLanguage } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  rectIntersection,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import CreateChoices from "./CreateChoices";
import InputWithTranslations from "./InputWithTranslations";
import IconsAll from "../IconsAll";
import AddVertalingen from "../../Pages/Instellingen/AddVertalingen";
import FolderActionsModal from "../../Pages/MediaLibrary/FolderActionsModal";
import {
  setFolderActionData,
  setSelectedFolderData,
} from "../../Redux/slices/media.reducer";
import { useDispatch } from "react-redux";
import { FiFolderPlus } from "react-icons/fi";
import { MdDeleteOutline } from "react-icons/md";
import { useSelector } from "react-redux";
import {
  addField,
  addFieldsRelations,
} from "../../Redux/slices/fields.reducer";
import { addSchema } from "../../Redux/slices/schema.reducer";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaGripLines } from "react-icons/fa6";
import { CSS } from "@dnd-kit/utilities";
import { GoPlus } from "react-icons/go";
import JsonEditor from "./EditFields/JsonEditor";

const ItemSort = ({
  data,
  highlightedItem,
  setChoices,
  setActiveChoice,
  setOpen,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: data?.value,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: isDragging ? "none" : transition || "transform 250ms ease",
    opacity: isDragging ? 0.9 : 1,
    backgroundColor: isDragging
      ? "rgb(from var(--project-base) r g b / 5%)"
      : "bg-(--input-field-bg)",
    boxShadow: isDragging
      ? "0px 8px 16px rgba(0, 0, 0, 0.1)"
      : "0px 2px 8px rgba(0, 0, 0, 0.05)",
    borderRadius: "8px",
  };

  const handleChoiceClick = () => {
    setActiveChoice(data);
    setOpen(true);
  };

  return (
    <div
      className={`flex justify-between items-center border border-(--input-field-border) bg-(--input-field-bg) p-3  m-2 rounded-xl highlighting-drag 
         ${highlightedItem === data?.value ? "bg-project-base/5  shadow-md" : ""}`}
      {...attributes}
      {...listeners}
      style={style}
      ref={setNodeRef}
    >
      <ul
        className="flex gap-4 flex-1 items-center w-[93%] text-ellipsis overflow-clip whitespace-nowrap"
        onClick={handleChoiceClick}
      >
        <li className="cursor-pointer list-none">
          <FaGripLines color="gray" size={24} />
        </li>
        <li className="font-normal text-ellipsis overflow-clip w-[93%]">
          {data?.label}
        </li>
      </ul>
      <button
        type="button"
        title="Delete"
        className="text-delete-base cursor-pointer shrink-0"
        onClick={() =>
          setChoices((prev) =>
            prev?.filter((rel) => rel?.value !== data?.value),
          )
        }
      >
        <Tooltip title="Delete Item">
          <RiDeleteBin6Line size={24} />
        </Tooltip>
      </button>
    </div>
  );
};

const convertToTreeFormat = (data) => {
  const treeMap = new Map();
  const tree = [];
  data?.forEach((item) => {
    const node = {
      title: item?.name,
      value: item?._id,
      isRoot: item?.parent === null,
      children: [],
    };
    treeMap.set(item?._id, node);
  });
  data?.forEach((item) => {
    if (item?.parent) {
      if (treeMap.has(item?.parent)) {
        treeMap.get(item?.parent).children.push(treeMap.get(item?._id));
      }
    } else {
      tree.push(treeMap.get(item?._id));
    }
  });
  return tree;
};

const options = [
  "Undo",
  "Redo",
  "Bold",
  "Italic",
  "Underline",
  "Strikethrough",
  "Subscript",
  "Superscript",
  "Select Font",
  "Select Font Size",
  "Heading 1",
  "Heading 2",
  "Heading 3",
  "Heading 4",
  "Heading 5",
  "Heading 6",
  "Align Left",
  "Align Center",
  "Align Right",
  "Align Justify",
  "Align None",
  "Indent",
  "Outdent",
  "Numbered List",
  "Bullet List",
  "Foreground Color",
  "Background Color",
  "Remove Format",
  "Cut",
  "Copy",
  "Paste",
  "Remove",
  "Select All",
  "Blockquote",
  "Add/Edit Link",
  "Remove Link",
  "Add/Edit Image",
  "Add/Edit Media",
  "Table",
  "Horizontal Rule",
  "Edit Source Code",
  "Full Screen",
  "View invisible elements",
  "Directionality",
].map((s) => {
  return { value: s, label: s };
});

const FolderOptionWithActions = ({ node, setFolderModal, t }) => {
  const dispatch = useDispatch();

  const folderActions = [
    {
      key: "create",
      label: "Create",
      icon: <FiFolderPlus size={16} />,
    },
    {
      key: "delete",
      label: "Delete",
      icon: <MdDeleteOutline size={18} />,
      style: node?.isRoot ? {} : { color: "var(--color-delete-base)" },
      disabled: node?.isRoot,
    },
  ];

  const handleMenuClick = (e) => {
    e.domEvent.stopPropagation();
    if (e?.key === "delete" && node?.isRoot) return;
    setFolderModal({
      type: e?.key === "delete" ? t("delete") : t("create"),
      isOpen: true,
      loading: false,
    });
    const folderData = {
      _id: node?.value,
      name: node?.title,
      isRoot: node?.isRoot,
    };
    dispatch(
      e?.key === "delete"
        ? setFolderActionData(folderData)
        : setSelectedFolderData(folderData),
    );
  };

  return (
    <Dropdown
      menu={{ items: folderActions, onClick: handleMenuClick }}
      trigger={"contextMenu"}
      className={"w-full"}
    >
      <div className={"flex justify-between w-full"}>{node.title}</div>
    </Dropdown>
  );
};

const FieldsConfigComponent = ({
  activeTab,
  fieldsList,
  dataSource,
  initialValues,
  nativeFieldType,
  setActiveTab,
  setIsDrawerOpen,
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const id = useId();
  const params = useParams();
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [collectionsList, setCollectionList] = useState([]);
  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [selectedField, setSelectedField] = useState("String");
  const [value, setValue] = useState([]);
  const [choices, setChoices] = useState([]);
  const [activeChoice, setActiveChoice] = useState(null);
  const [displayTemplate, setDisplayTemplate] = useState("");
  const [highlightedItem, setHighlightedItem] = useState({});
  const [activeSortItem, setActiveSortItem] = useState(null);
  const [selectedFields, setSelectedFields] = useState([]);
  const [open, setOpen] = useState(false);
  const [folders, setFolders] = useState(null);
  const [folderModal, setFolderModal] = useState({
    type: t("create"),
    isOpen: false,
    loading: false,
  });
  const [selectedTranslation, setSelectedTranslation] = useState(null);
  const [isTranslationDrawerOpen, setIsTranslationDrawerOpen] = useState(false);
  const [translationsList, setTranslationsList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [Json, setJson] = useState({ jsonData: {}, isValidJson: true });
  const schemas = useSelector((state) => state?.schemas?.schemas);
  const systemCollections = useSelector(
    (state) => state?.schemas?.system_collections,
  );
  const fields = useSelector((state) => state?.fields?.fields);
  const dispatch = useDispatch();
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 20,
    },
  });

  const keyboardSensor = useSensor(KeyboardSensor);
  const sensors = useSensors(mouseSensor, keyboardSensor);

  useEffect(() => {
    getTranslations();
    ["wysiwyg", "file", "file-image", "files"].includes(activeTab) &&
      getFoldersList();
    ["list-m2m", "list-o2m", "list-m2o", "list-m2a", "translations"].includes(
      activeTab,
    ) &&
      setCollectionList(
        [...schemas, ...systemCollections]?.filter((c) => !c?.meta?.is_folder),
      );
  }, [schemas]);

  const handleDisplayTemplateChange = (value, fromSelect = false) => {
    setDisplayTemplate((prev) => (fromSelect ? `${prev}{{${value}}}` : value));
  };

  const onContentBlur = useCallback((event) => {
    const contentEditable = event.currentTarget;
    let updatedTemplate = "";
    contentEditable.childNodes.forEach((node) => {
      if (node.nodeName === "BUTTON") {
        updatedTemplate += `{{${node.textContent}}}`;
      } else if (node.nodeType === Node.TEXT_NODE) {
        updatedTemplate += node.textContent.trim();
      } else if (node.nodeName === "SPAN") {
        updatedTemplate += node.textContent.trim();
      } else if (node.nodeName === "#text") {
        updatedTemplate += <span>{node.textContent.trim()}</span>;
      } else {
        updatedTemplate += node.textContent.trim();
      }
    });

    handleDisplayTemplateChange(updatedTemplate);
  }, []);

  const handleButtonClick = (placeholder) => {
    const updatedTemplate = displayTemplate.replace(`{{${placeholder}}}`, "");
    setDisplayTemplate(updatedTemplate);
  };

  const renderDisplayTemplate = (template) => {
    const regex = /{{(.*?)}}/g;
    const parts = template.split(regex);
    return parts.map((part, index) => {
      if (part !== "" && selectedFields?.some((op) => op?.path === part)) {
        return (
          <button
            key={index}
            contentEditable={false}
            onClick={() => handleButtonClick(part)}
            className="text-project-base bg-project-base/10 text-sm font-normal mx-1 rounded-md px-2 py-1 leading-5"
          >
            {part}
          </button>
        );
      }
      return (
        <span
          key={index}
          className="text-sm font-normal text-[var(--input-field-text-clr)] h-[56px] block leading-14"
        >
          {part}
        </span>
      );
    });
  };

  const getTranslations = async () => {
    try {
      const resp = await axiosInstance.get(Apis.GET_TRANSLATIONS("", true));
      if (resp.status === 200) setTranslationsList(resp.data.payload.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const getFoldersList = async () => {
    try {
      const resp = await axiosInstance.get(
        Apis.GET_ALL_FOLDERS({ pagination: "true" }),
      );
      if (resp.status === 200) {
        setFolders(resp.data?.payload?.data);
      }
    } catch (error) {
      notification.error({ message: t("something_went_wrong") });
    }
  };

  const onDragEnd = (event) => {
    const { active, over } = event;
    if (active && over) {
      if (active.id !== over?.id) {
        setChoices((items) => {
          const oldIndex = items.findIndex((item) => item.value === active.id);
          const newIndex = items.findIndex((item) => item.value === over.id);
          return arrayMove(items, oldIndex, newIndex).map((item, i) => {
            return { ...item, sort: i + 1 };
          });
        });
      }
      setHighlightedItem({});
    }
  };

  const handleInputConfirm = () => {
    if (inputValue && !tags.includes(inputValue)) {
      setTags([...tags, inputValue]);
    }
    setInputValue("");
  };

  const getDefaultField = () => {
    switch (activeTab) {
      case "translations":
      case "input":
        return selectedField === "Number" ? (
          <InputNumber id={`${id}-default`} className="project-custom-input" />
        ) : (
          <Input
            placeholder={t("null")}
            id={`${id}-default`}
            className="project-custom-input"
            disabled={selectedField === "ObjectId"}
          />
        );
      case "autocomplete":
        return (
          <Input
            placeholder={t("null")}
            id={`${id}-default`}
            className="project-custom-input"
          />
        );
      case "textarea":
        return (
          <TextArea id={`${id}-default`} className="font-normal" rows={4} />
        );
      case "wysiwyg":
        return <ReactQuill theme="snow" />;
      case "tags":
        return (
          <div>
            <Input
              type="text"
              placeholder={t("add_a_tag_and_press_enter")}
              id={`${id}-presets`}
              className="project-custom-input"
              suffix={<BsTags size={20} color="gray" />}
              value={inputValue}
              onChange={(e) => {
                setInputValue(e.target.value);
              }}
              onPressEnter={handleInputConfirm}
            />
            <div style={{ marginTop: "16px" }}>
              {tags?.map((tag, index) => (
                <Tag
                  color="blue"
                  key={index}
                  onClick={() =>
                    setTags((prev) => prev?.filter((t) => t !== tag))
                  }
                  style={{
                    display: "inline-block",
                    padding: "5px",
                    background: "#f3f3f3",
                    margin: "5px",
                    borderRadius: "5px",
                  }}
                >
                  {tag}
                </Tag>
              ))}
            </div>
          </div>
        );
      case "json":
        return <JsonEditor setJson={setJson} />;
      case "toggle":
        return (
          <InvertedCheckBox id={`${id}-default`}>
            {t("enabled")}
          </InvertedCheckBox>
        );
      case "datetime":
        return (
          <DatePicker
            showTime={!(form.getFieldValue("type") === "date")}
            size={"large"}
            picker={form.getFieldValue("type") === "time" ? "time" : "date"}
            id={`${id}-default`}
            className="project-custom-input"
          />
        );
      case "dropdown":
        return (
          <Radio.Group
            options={choices}
            className="flex flex-col gap-y-2 w-full"
          />
        );
      case "checkboxes":
        return (
          <Checkbox.Group
            options={choices}
            className="flex flex-col gap-y-2 w-full"
          />
        );
      case "dropdown_multiple":
        return (
          <Checkbox.Group
            options={choices}
            className="flex flex-col gap-y-2 w-full"
          />
        );
      case "radio":
        return (
          <Radio.Group
            options={choices}
            className="flex flex-col gap-y-2 w-full"
          />
        );
    }
  };

  const getFormConfig = () => {
    return fieldsList?.map((field) => {
      switch (field?.name) {
        case "name":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                name={field?.name}
                className="font-bold"
                label={t("key")}
                rules={[{ required: true }]}
                htmlFor={`${id}-input`}
              >
                <Input
                  placeholder={t(field?.placeholder)}
                  id={`${id}-input`}
                  className="project-custom-input"
                  onChange={(e) => {
                    form.setFieldValue(
                      "name",
                      e.target.value
                        .replace(/[^a-zA-Z0-9_ ]/g, "")
                        .replace(/ /g, "_")
                        ?.toLowerCase(),
                    );
                  }}
                />
              </Form.Item>
            </Col>
          );
        case "group":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                name={field?.name}
                label={t("group")}
                className="font-bold"
                htmlFor={`${id}-group`}
              >
                <TreeSelect
                  id={`${id}-group`}
                  showSearch
                  dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                  placeholder={t(field?.placeholder)}
                  allowClear
                  onClear={() => setSelectedGroup(null)}
                  treeDefaultExpandAll
                  treeTitleRender={(node) => (
                    <span>
                      {node.value}
                      <span className="text-xs text-project-base ml-2">
                        ({node.type === "Array" ? "items" : "group"})
                      </span>
                    </span>
                  )}
                  onSelect={(_, e) => setSelectedGroup(e)}
                  treeData={convertToTreeData(
                    dataSource?.fields?.filter((f) => {
                      const options = ["object"];
                      if (!field?.blackList?.includes("items")) {
                        options.push("items");
                      }
                      return options?.includes(f?.meta?.interface);
                    }),
                    "tree-select",
                  )}
                  popupClassName="custom-tree-select"
                  virtual={false}
                />
              </Form.Item>
            </Col>
          );
        case "type":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                name={field?.name}
                label={t("type")}
                htmlFor={`${id}-type`}
                className="font-bold"
                rules={[{ required: true }]}
              >
                <Select
                  size="large"
                  placeholder={t(field?.placeholder)}
                  disabled={field?.fieldTypes?.disabled}
                  style={{ flex: 1, height: "56px" }}
                  id={`${id}-type`}
                  onChange={(e) => setSelectedField(e)}
                  options={field?.fieldTypes?.allowedTypes}
                />
              </Form.Item>
            </Col>
          );
        case "default":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("default_value")}
                htmlFor={`${id}-default`}
                className="font-bold"
                name={field?.name}
                valuePropName={activeTab === "toggle" ? "checked" : "value"}
              >
                {getDefaultField()}
              </Form.Item>
            </Col>
          );
        case "required":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("required")}
                htmlFor={`${id}-requireValue`}
                className="font-bold"
                name={field?.name}
                valuePropName="checked"
              >
                <InvertedCheckBox id={`${id}-requireValue`}>
                  {t("require_msg")}
                </InvertedCheckBox>
              </Form.Item>
            </Col>
          );
        case "url":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("url")}
                htmlFor={`${id}-url`}
                className="font-bold"
                name={field?.name}
              >
                <Input
                  placeholder={t(field?.placeholder)}
                  id={`${id}-url`}
                  className="project-custom-input"
                />
              </Form.Item>
            </Col>
          );
        case "results_path":
          return (
            <Col key={field.name} className="gutter-row" xs={24}>
              <Form.Item
                label={t("results_path")}
                htmlFor={`${id}-resultsPath`}
                className="font-bold"
                name={field?.name}
              >
                <Input
                  placeholder={t(field?.placeholder)}
                  id={`${id}-resultsPath`}
                  className="project-custom-input"
                />
              </Form.Item>
            </Col>
          );
        case "text_path":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("text_path")}
                htmlFor={`${id}-textPath`}
                className="font-bold"
                name={field?.name}
              >
                <Input
                  placeholder={t(field?.placeholder)}
                  id={`${id}-textPath`}
                  className="project-custom-input"
                />
              </Form.Item>
            </Col>
          );
        case "value_path":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("value_path")}
                htmlFor={`${id}-ValuePath`}
                className="font-bold"
                name={field?.name}
              >
                <Input
                  placeholder={t(field?.placeholder)}
                  id={`${id}-ValuePath`}
                  className="project-custom-input"
                />
              </Form.Item>
            </Col>
          );
        case "trigger":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("trigger")}
                htmlFor={`${id}-trigger`}
                className="font-bold"
                name={field?.name}
              >
                <Select
                  placeholder={t(field?.placeholder)}
                  size="large"
                  style={{ flex: 1, height: "56px" }}
                  id={`${id}-trigger`}
                  options={[
                    {
                      value: "Throttle",
                      label: "Throttle",
                    },
                    {
                      value: "Debounce",
                      label: "Debounce",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          );
        case "rate":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("rate")}
                htmlFor={`${id}-rate`}
                className="font-bold"
                name={`rate`}
              >
                <InputNumber
                  placeholder={t(field?.placeholder)}
                  id={`${id}-rate`}
                  min={1}
                  max={1000}
                  className="project-custom-input"
                />
              </Form.Item>
            </Col>
          );
        case "placeholder":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("placeholder")}
                htmlFor={`${id}-placeholder`}
                className="font-bold"
                name={field?.name}
              >
                <InputWithTranslations
                  form={form}
                  selectedTranslation={selectedTranslation}
                  setIsTranslationDrawerOpen={setIsTranslationDrawerOpen}
                  setSelectedTranslation={setSelectedTranslation}
                  translationsList={translationsList}
                />
              </Form.Item>
            </Col>
          );
        case "toolbar":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("toolbar")}
                htmlFor={`${id}-toolbar`}
                className="font-bold"
                name={field?.name}
              >
                <Select
                  mode={`multiple`}
                  style={{
                    width: "100%",
                  }}
                  options={options}
                  placeholder={t(field?.placeholder)}
                  maxTagCount={`responsive`}
                  id={`${id}-toolbar`}
                  value={value}
                  onChange={setValue}
                />
              </Form.Item>
            </Col>
          );
        case "font":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("font")}
                htmlFor={`${id}-font`}
                className="font-bold"
                name={field?.name}
              >
                <Select
                  size="large"
                  placeholder={t("font")}
                  style={{ flex: 1, height: "56px" }}
                  id={`${id}-font`}
                  options={[
                    {
                      value: "Serif",
                      label: "Serif",
                    },
                    {
                      value: "Sans Serif",
                      label: "Sans Serif",
                    },
                    {
                      value: "Monospace",
                      label: "Monospace",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          );
        case "folder": {
          const treeData = convertToTreeFormat(folders);
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("root_folder")}
                className="font-bold"
                htmlFor={`${id}-folder`}
                name={`folder`}
                help={
                  <p className="font-normal mt-2 text-project-base/50">
                    {t("folder_msg")}
                  </p>
                }
              >
                <TreeSelect
                  id={`${id}-folder`}
                  showSearch
                  dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                  placeholder={t("select_folder")}
                  allowClear
                  treeDefaultExpandedKeys={[treeData?.[0]?.value]}
                  onSelect={(e) => form.setFieldValue("folder", e)}
                  treeData={treeData}
                  treeTitleRender={(node) => {
                    if (!node.title) {
                      node.title = folders?.find(
                        (f) => f?._id === node.value,
                      )?.name;
                    }
                    return (
                      <FolderOptionWithActions
                        node={node}
                        setFolderModal={setFolderModal}
                        t={t}
                      />
                    );
                  }}
                />
              </Form.Item>
              <FolderActionsModal
                folderModal={folderModal}
                setFolderModal={setFolderModal}
                afterHandleFoldarModalOk={() => getFoldersList()}
              />
            </Col>
          );
        }
        case "static_access_token":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("static_access_token")}
                htmlFor={`${id}-static_access_token`}
                className="font-bold"
                name={field?.name}
              >
                <div>
                  <Input
                    placeholder={t("static_access_token")}
                    id={`${id}-static_access_token`}
                    className="project-custom-input"
                  />
                  <span className="text-sm text-black-base font-light italic">
                    {"Static access token is appended to the assets' URL"}
                  </span>
                </div>
              </Form.Item>
            </Col>
          );

        case "alphabetize":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("alphabetize")}
                htmlFor={`${id}-Alphabetize`}
                className="font-bold"
                name={field?.name}
                valuePropName="checked"
              >
                <InvertedCheckBox id={`${id}-Alphabetize`}>
                  {t("force_alphabetical_order")}
                </InvertedCheckBox>
              </Form.Item>
            </Col>
          );

        case "allow_other":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("allow_other")}
                htmlFor={`${id}-allowOther`}
                className="font-bold"
                name={field?.name}
                valuePropName="checked"
              >
                <InvertedCheckBox id={`${id}-allowOther`}>
                  {t("allow_other_values")}
                </InvertedCheckBox>
              </Form.Item>
            </Col>
          );

        case "whitespace":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("whitespace")}
                htmlFor={`${id}-whitespace`}
                className="font-bold"
                name={field?.name}
              >
                <Select
                  size="large"
                  style={{ flex: 1, height: "56px" }}
                  id={`${id}-whitespace`}
                  options={[
                    {
                      value: "Replace with hyphen",
                      label: "Replace with hyphen",
                    },
                    {
                      value: "Replace with underscore",
                      label: "Replace with underscore",
                    },
                    {
                      value: "Remove whitespace",
                      label: "Remove whitespace",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          );

        case "capitalization":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("capitalization")}
                htmlFor={`${id}-capitalization`}
                className="font-bold"
                name={field?.name}
              >
                <Select
                  size="large"
                  style={{ flex: 1, height: "56px" }}
                  id={`${id}-capitalization`}
                  options={[
                    {
                      value: "Convert Lowercase",
                      label: "Convert Lowercase",
                    },
                    {
                      value: "Use Title Auto-Formatter",
                      label: "Use Title Auto-Formatter",
                    },
                    {
                      value: "Convert Uppercase",
                      label: "Convert Uppercase",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          );

        case "icon_left":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("icon_left")}
                htmlFor={`${id}-iconLeft`}
                className="font-bold"
                name={field?.name}
              >
                <Select
                  size="large"
                  style={{ flex: 1, height: "56px" }}
                  id={`${id}-iconLeft`}
                  options={[
                    {
                      value: "Icon 1",
                      label: "Icon 1",
                    },
                    {
                      value: "Icon 2",
                      label: "Icon 2",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          );
        case "icon_right":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("icon_right")}
                htmlFor={`${id}-iconright`}
                className="font-bold"
                name={field?.name}
              >
                <Select
                  size="large"
                  style={{ flex: 1, height: "56px" }}
                  id={`${id}-iconright`}
                  options={[
                    {
                      value: "Icon 1",
                      label: "Icon 1",
                    },
                    {
                      value: "Icon 2",
                      label: "Icon 2",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          );

        case "icon_on":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("Icon On")}
                htmlFor={`${id}-iconOn`}
                className="font-bold"
                name={field?.name}
              >
                <Select
                  size="large"
                  placeholder={t("search_for_color")}
                  style={{ flex: 1, height: "56px" }}
                  id={`${id}-iconOn`}
                  options={[
                    {
                      value: "Icon 01",
                      label: "Icon 01",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          );
        case "icon_off":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("icon_off")}
                htmlFor={`${id}-iconOff`}
                className="font-bold"
                name={field?.name}
              >
                <Select
                  size="large"
                  placeholder={t("search_for_icon")}
                  style={{ flex: 1, height: "56px" }}
                  id={`${id}-iconOff`}
                  options={[
                    {
                      value: "Icon 01",
                      label: "Icon 01",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          );
        case "color_on":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("color_on")}
                htmlFor={`${id}-colorOn`}
                className="font-bold"
                name={field?.name}
              >
                <Col
                  key={field.name}
                  orPicker
                  id={`${id}-colorOn`}
                  showText
                  className="w-full justify-start px-3 py-[14.08px] font-normal rounded-xl"
                  format="hex"
                  onChange={(_, hex) => form.setFieldsValue({ color_on: hex })}
                />
              </Form.Item>
            </Col>
          );
        case "color_off":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("color_off")}
                htmlFor={`${id}-colorOff`}
                className="font-bold"
                name={field?.name}
              >
                <Col
                  key={field.name}
                  orPicker
                  id={`${id}-colorOff`}
                  showText
                  className="w-full justify-start px-3 py-[14.08px] font-normal rounded-xl"
                  format="hex"
                  onChange={(_, hex) => form.setFieldsValue({ color_off: hex })}
                />
              </Form.Item>
            </Col>
          );
        case "label":
          return (
            <Col key={field.name} className="gutter-row" xs={24}>
              <Form.Item
                label={t("label")}
                htmlFor={`${id}-label`}
                className="font-bold"
                name={field?.name}
              >
                <Input
                  placeholder={t("Enter a Label...")}
                  id={`${id}-label`}
                  className="project-custom-input"
                  suffix={<IoLanguage size={20} color="gray" />}
                />
              </Form.Item>
            </Col>
          );
        case "include_seconds":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("include_seconds")}
                htmlFor={`${id}-includeSeconds`}
                className="font-bold"
                name={field?.name}
                valuePropName="checked"
              >
                <InvertedCheckBox id={`${id}-includeSeconds`}>
                  {t("enabled")}
                </InvertedCheckBox>
              </Form.Item>
            </Col>
          );
        case "use_24_hrs_format":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("use_24_hrs_format")}
                htmlFor={`${id}-UseFormat`}
                className="font-bold"
                name={field?.name}
                valuePropName="checked"
              >
                <InvertedCheckBox id={`${id}-UseFormat`} defaultChecked={true}>
                  {t("enabled")}
                </InvertedCheckBox>
              </Form.Item>
            </Col>
          );
        case "choices":
          return (
            <Col key={field.name} className="gutter-row" xs={24}>
              <Form.Item
                label={t("choices")}
                htmlFor={`${id}-new-field`}
                className="font-bold"
              >
                {!choices?.length &&
                  CustomInfoText(
                    <BsInfoCircle size={20} />,
                    <p className="font-normal">{t("add_a_new_choice")}</p>,
                  )}
                <Button
                  className="project-custom-btn border-solid border-grey-full bg-grey-full text-black-base mt-4"
                  onClick={() => {
                    setActiveChoice(null);
                    setOpen(true);
                  }}
                >
                  {t("create_new")}
                </Button>
              </Form.Item>
              {choices?.length > 0 && (
                <DndContext
                  sensors={sensors}
                  collisionDetection={rectIntersection}
                  onDragStart={({ active }) =>
                    setActiveSortItem(
                      choices?.find((rl) => rl?.value === active.id),
                    )
                  }
                  onDragEnd={onDragEnd}
                  onDragOver={({ over }) => {
                    setHighlightedItem(over?.id);
                  }}
                >
                  <SortableContext
                    items={choices.map((item) => item.value)}
                    strategy={verticalListSortingStrategy}
                  >
                    {choices?.map((rl, i) => (
                      <ItemSort
                        key={rl?.value}
                        data={rl}
                        index={i}
                        highlightedItem={highlightedItem}
                        setChoices={setChoices}
                        setActiveChoice={setActiveChoice}
                        setOpen={setOpen}
                      />
                    ))}
                  </SortableContext>
                  <DragOverlay>
                    {activeSortItem ? (
                      <div
                        className={`flex justify-between border border-blue-300 p-3 my-2 rounded-lg items-center`}
                      >
                        <ul className="flex gap-4 flex-1 items-center">
                          <li className="cursor-pointer list-none">
                            <FaGripLines color="gray" size={24} />
                          </li>
                          <li className="font-normal">
                            {activeSortItem?.label}
                          </li>
                        </ul>
                      </div>
                    ) : null}
                  </DragOverlay>
                </DndContext>
              )}
            </Col>
          );
        case "allow_none":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("allow_none")}
                htmlFor={`${id}-no-selection`}
                className="font-bold"
                name={field?.name}
                valuePropName="checked"
              >
                <InvertedCheckBox
                  id={`${id}-no-selection`}
                  defaultChecked={false}
                >
                  {t("allow_no_selection")}
                </InvertedCheckBox>
              </Form.Item>
            </Col>
          );
        case "icon":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("icon")}
                htmlFor={`${id}-icon`}
                className="font-bold"
                name={field?.name}
              >
                <Select
                  size="large"
                  style={{ flex: 1, height: "56px" }}
                  id={`${id}-icon`}
                  options={[
                    {
                      value: "Manual",
                      label: "Manual",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          );
        case "color":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("color")}
                htmlFor={`${id}-colorOn`}
                className="font-bold"
                name={field?.name}
              >
                <ColorPicker
                  id={`${id}-colorOn`}
                  showText
                  className="w-full justify-start px-3 py-[14.08px] font-normal rounded-xl"
                  format="hex"
                  onChangeComplete={(hex) =>
                    form.setFieldsValue({ color: hex.toHexString() })
                  }
                />
              </Form.Item>
            </Col>
          );
        case "per_page":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("per_page")}
                htmlFor={`${id}-per_page`}
                className="font-bold"
                name={field?.name}
              >
                <InputNumber
                  id={`${id}-per_page`}
                  min={1}
                  max={10}
                  className="project-custom-input"
                />
              </Form.Item>
            </Col>
          );
        case "crop":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("crop_to_fit")}
                htmlFor={`${id}-requireValue`}
                className="font-bold"
                name={field?.name}
                valuePropName="checked"
              >
                <InvertedCheckBox id={`${id}-requireValue`} checked>
                  {t("crop_image_as_needed")}
                </InvertedCheckBox>
              </Form.Item>
            </Col>
          );
        case "layout":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("layout")}
                htmlFor={`${id}-layout`}
                className="font-bold"
                name={field?.name}
              >
                <Select
                  showSearch
                  size="large"
                  style={{ flex: 1, height: "56px" }}
                  id={`${id}-layout`}
                  options={[
                    {
                      value: "List",
                      label: "List",
                    },
                    {
                      value: "Table",
                      label: "Table",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          );
        case "foreign_key_table":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("related_collection")}
                className="font-bold"
                htmlFor={`${id}-related-collection`}
                rules={[
                  {
                    required: true,
                    message: t("related_collection_error"),
                  },
                ]}
                name={field?.name}
              >
                <Select
                  showSearch
                  size="large"
                  style={{ flex: 1, height: "56px" }}
                  id={`${id}-related-collection`}
                  mode={field?.multiple ? "multiple" : undefined}
                  options={collectionsList
                    ?.filter((c) =>
                      ["list-m2o", "list-o2m"].includes(activeTab)
                        ? true
                        : c?._id !== params.field,
                    )
                    ?.map((c) => ({
                      label: capitalizeAndFormat(c?.collection_id),
                      value: c?._id,
                    }))
                    ?.sort((a, b) => a.label.localeCompare(b.label))}
                  onChange={(col_id) => {
                    form.setFieldsValue({ foreign_key_column: null });
                    setSelectedFields(
                      fields?.filter((f) => f?.schema_id === col_id),
                    );
                  }}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      ?.toLowerCase()
                      ?.includes(input?.toLowerCase())
                  }
                />
              </Form.Item>
            </Col>
          );
        case "language_collection":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("language_collection")}
                className="font-bold"
                htmlFor={`${id}-language_collection`}
                rules={[
                  {
                    required: true,
                    message: t("related_collection_error"),
                  },
                ]}
                name={field?.name}
              >
                <Select
                  showSearch
                  size="large"
                  style={{ flex: 1, height: "56px" }}
                  id={`${id}-language_collection`}
                  options={collectionsList
                    ?.filter((c) => c?._id !== params.field)
                    ?.map((c) => {
                      return {
                        label: capitalizeAndFormat(c?.collection_id),
                        value: c?._id,
                      };
                    })
                    ?.sort((a, b) => a.label.localeCompare(b.label))}
                  onChange={(col_id) => {
                    form.setFieldsValue({ foreign_key_column: null });
                    setSelectedFields(
                      fields?.filter((f) => f?.schema_id === col_id),
                    );
                  }}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      ?.toLowerCase()
                      ?.includes(input?.toLowerCase())
                  }
                />
              </Form.Item>
            </Col>
          );
        case "creating_items":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("creating_items")}
                htmlFor={`${id}-create-item`}
                className="font-bold"
                name={field?.name}
                valuePropName="checked"
              >
                <InvertedCheckBox id={`${id}-create-item`} checked>
                  {t("enable_create_button")}
                </InvertedCheckBox>
              </Form.Item>
            </Col>
          );
        case "selecting_items":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("selecting_items")}
                htmlFor={`${id}-select-value`}
                className="font-bold"
                name={field?.name}
                valuePropName="checked"
              >
                <InvertedCheckBox id={`${id}-select-value`} checked>
                  {t("enable_select_button")}
                </InvertedCheckBox>
              </Form.Item>
            </Col>
          );
        case "junction_fields_location":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("junction_field_location")}
                htmlFor={`${id}-field-location`}
                className="font-bold"
                name={field?.name}
              >
                <Select
                  showSearch
                  size="large"
                  style={{ flex: 1, height: "56px" }}
                  id={`${id}-field-location`}
                  options={[
                    {
                      value: "Bottom",
                      label: "Bottom",
                    },
                    {
                      value: "Top",
                      label: "Top",
                    },
                    {
                      value: "Right",
                      label: "Right",
                    },
                    {
                      value: "Left",
                      label: "Left",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          );
        case "allow_duplicates":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("allow_duplicates")}
                htmlFor={`${id}-allow-duplicates`}
                className="font-bold"
                name={field?.name}
                valuePropName="checked"
              >
                <InvertedCheckBox id={`${id}-allow-duplicates`}>
                  {t("enabled")}
                </InvertedCheckBox>
              </Form.Item>
            </Col>
          );
        case "item_link":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("item_link")}
                htmlFor={`${id}-item-link`}
                className="font-bold"
                name={field?.name}
                valuePropName="checked"
              >
                <InvertedCheckBox id={`${id}-item-link`}>
                  {t("item_link_msg")}
                </InvertedCheckBox>
              </Form.Item>
            </Col>
          );
        case "foreign_key_column":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("foreign_key")}
                className="font-bold"
                htmlFor={`${id}-foreign-key`}
                rules={[
                  {
                    required: true,
                    message: t("foreign_key_error"),
                  },
                ]}
                name={field?.name}
              >
                <Select
                  showSearch
                  size="large"
                  style={{ flex: 1, height: "56px" }}
                  id={`${id}-foreign-key`}
                  options={selectedFields?.map((c) => {
                    return {
                      label: c?.field,
                      value: c?.field,
                      disabled: c?.field === "_id",
                    };
                  })}
                />
              </Form.Item>
            </Col>
          );
        case "language_indicator_field":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("language_indicator_field")}
                className="font-bold"
                htmlFor={`${id}-language_indicator_field`}
                rules={[
                  {
                    required: true,
                    message: t("language_indicator_field_error"),
                  },
                ]}
                name={field?.name}
              >
                <Select
                  showSearch
                  size="large"
                  style={{ flex: 1, height: "56px" }}
                  id={`${id}-language_indicator_field`}
                  options={selectedFields?.map((c) => {
                    return {
                      label: c?.field,
                      value: c?.field,
                      disabled: c?.field === "_id",
                    };
                  })}
                />
              </Form.Item>
            </Col>
          );
        case "language_direction_field":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("language_direction_field")}
                className="font-bold"
                htmlFor={`${id}-language_direction_field`}
                rules={[
                  {
                    required: true,
                    message: t("language_direction_field_error"),
                  },
                ]}
                name={field?.name}
              >
                <Select
                  showSearch
                  size="large"
                  style={{ flex: 1, height: "56px" }}
                  id={`${id}-language_direction_field`}
                  options={selectedFields?.map((c) => {
                    return {
                      label: c?.field,
                      value: c?.field,
                      disabled: c?.field === "_id",
                    };
                  })}
                />
              </Form.Item>
            </Col>
          );
        case "display_template":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("display_template")}
                className="font-bold relative"
                htmlFor={`${id}-display-template`}
                name={field?.name}
              >
                {form.getFieldValue("foreign_key_table") ? (
                  <>
                    <div className="display-template-input | ant-input ant-input-outlined">
                      <div
                        contentEditable
                        onBlur={onContentBlur}
                        dangerouslySetInnerHTML={{
                          __html: renderDisplayTemplate(displayTemplate).reduce(
                            (html, element) => {
                              return (
                                html +
                                (typeof element === "string"
                                  ? element
                                  : ReactDOMServer.renderToStaticMarkup(
                                      element,
                                    ))
                              );
                            },
                            "",
                          ),
                        }}
                        className="focus-visible:border-project-base! focus-visible:outline-0! whitespace-nowrap overflow-auto custom-scrollbar flex items-center content-editable"
                      ></div>
                    </div>
                    <TreeSelect
                      popupClassName="display-template-tree-select w-740 custom-tree-select"
                      suffixIcon={
                        <GoPlus
                          size={24}
                          color={"var(--input-field-text-clr)"}
                        />
                      }
                      showSearch
                      treeData={convertToTreeData(
                        selectedFields?.filter(
                          (f) =>
                            ![
                              "list-m2a",
                              "list-m2m",
                              "list-m2o",
                              "list-o2m",
                              "file",
                              "file-image",
                              "files",
                              "items",
                            ].includes(f?.meta?.interface),
                        ),
                        "tree-select",
                      )}
                      value={null}
                      onChange={(value) =>
                        handleDisplayTemplateChange(value, true)
                      }
                    />
                  </>
                ) : (
                  CustomInfoText(
                    <BsInfoCircle size={20} />,
                    <p className="font-normal">{t("select_collection")}</p>,
                  )
                )}
              </Form.Item>
            </Col>
          );
        case "default_language":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                name={field?.name}
                className="font-bold"
                label={t("default_language")}
                htmlFor={`${id}-default_language`}
              >
                <Input
                  placeholder={t(field?.placeholder)}
                  id={`${id}-default_language`}
                  className="project-custom-input"
                />
              </Form.Item>
            </Col>
          );
        case "use_current_user_language":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("use_current_user_language")}
                htmlFor={`${id}-use_current_user_language`}
                className="font-bold"
                name={field?.name}
                valuePropName="checked"
              >
                <InvertedCheckBox id={`${id}-use_current_user_language`}>
                  {t("enabled")}
                </InvertedCheckBox>
              </Form.Item>
            </Col>
          );
        case "default_split_view_state":
          return (
            <Col
              key={field.name}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={t("default_split_view_state")}
                htmlFor={`${id}-default_split_view_state`}
                className="font-bold"
                name={field?.name}
                valuePropName="checked"
              >
                <InvertedCheckBox id={`${id}-default_split_view_state`}>
                  {t("start_open")}
                </InvertedCheckBox>
              </Form.Item>
            </Col>
          );
      }
    });
  };

  const generateSchemaType = (type) => {
    if (["datetime", "timestamp"].includes(type)) {
      return "Date";
    }
    if (["date", "time"].includes(type)) {
      return "String";
    }
    if (["tags", "checkboxes", "dropdown_multiple"].includes(activeTab)) {
      return "String";
    }

    return type;
  };

  const handleSubmit = async () => {
    let isError;
    try {
      if (activeTab === "json" && !Json.isValidJson) {
        notification.error({ message: t("please_enter_valid_json") });
        return;
      }
      const values = await form.validateFields();
      let newField = selectedGroup
        ? selectedGroup?.value + "." + values?.name
        : values?.name;
      setLoader(true);
      isError = dataSource?.fields?.some((f) => f?.path === newField);
      if (isError) {
        notification.error({ message: t("unique_key_error") });
        return;
      }
      let relationsTable = null;
      if (activeTab === "list-m2a") {
        relationsTable = values?.foreign_key_table?.map(
          (val) => collectionsList?.find((c) => c?._id === val)?.collection_id,
        );
      } else if (activeTab === "translations") {
        relationsTable = collectionsList?.find(
          (c) => c?._id === values?.language_collection,
        )?.collection_id;
      } else {
        relationsTable = collectionsList?.find(
          (c) => c?._id === values?.foreign_key_table,
        )?.collection_id;
      }

      let payload = {
        schema_id: params.field,
        field: values.name,
        type:
          selectedGroup && nativeFieldType === "Array" ? "Array" : values.type,
        path: selectedGroup?.value
          ? selectedGroup?.value + "." + values?.name
          : values?.name,
        field_type: selectedGroup ? "Object" : nativeFieldType,
        // ref: values?.foreign_key_table,
        meta: {
          field: values.name,
          interface: activeTab,
          width: "full",
          sort:
            Math.max(
              0,
              ...(dataSource?.fields?.map((field) => field.meta?.sort) || []),
            ) + 1,
          nullable: true,
          display_options: {
            template: displayTemplate,
          },
          options: {
            language_field: values.language_indicator_field,
            language_collection: values.language_collection,
            language_direction_field: values.language_direction_field,
            default_language: values.default_language,
            use_current_user_language: values.use_current_user_language,
            default_split_view_state: values.default_split_view_state,
            related_collections: values.related_collections,
            per_page: values.per_page,
            allow_duplicates: values.allow_duplicates,
            item_link: values.item_link,
            layout: values.layout,
            creating_items: values.creating_items,
            selecting_items: values.selecting_items,
            junction_fields_location: values.junction_fields_location,
            icon_left: values.icon_left,
            icon_right: values.icon_right,
            placeholder: selectedTranslation
              ? "$t:" + values.placeholder
              : values.placeholder,
            url: values.url,
            results_path: values.results_path,
            text_path: values.text_path,
            value_path: values.value_path,
            trigger: values.trigger,
            rate: values.rate,
            font: values.font,
            static_access_token: values.static_access_token,
            toolbar: values.toolbar,
            alphabetize: values.alphabetize,
            allow_other: values.allow_other,
            whitespace: values.whitespace,
            capitalization: values.capitalization,
            icon_on: values.icon_on,
            icon_off: values.icon_off,
            color_on: values.color_on,
            color_off: values.color_off,
            label: values.label,
            include_seconds: values.include_seconds,
            use_24_hrs_format: values.use_24_hrs_format,
            choices: choices,
            allow_none: values.allow_none,
            icon: values.icon,
            color: values.color,
            crop: values.crop,
          },
          folder: values.folder,
          hidden: false,
          required: !!values.required,
        },
        schema_definition: {
          name: values.name,
          type: generateSchemaType(values?.type),
          default:
            activeTab === "tags"
              ? tags
              : activeTab === "json"
                ? JSON.stringify(Json.jsonData)
                : activeTab === "datetime"
                  ? formatDate(values.default, form.getFieldValue("type"))
                  : values.default,
          // foreign_key_table: values.foreign_key_table,
          // foreign_key_column: values.foreign_key_column,
          // foreign_key_table_id: collectionsList?.find(
          //   (c) => c?.collection_id === values.foreign_key_table,
          // )?._id,
        },
        relations: {
          foreign_key_table: relationsTable,
          foreign_key_column: values.foreign_key_column,
          foreign_key_table_id:
            activeTab === "translations"
              ? values.language_collection
              : values.foreign_key_table,
        },
      };

      const resp = await axiosInstance.post(Apis.ADD_A_FIELD(), {
        ...removeNullAndUndefined(payload),
        totalFields: dataSource?.fields?.length,
      });
      if (resp.status === 200) {
        notification.success({ message: t("field_add_success") });
        dispatch(
          addField({ singleObject: true, data: resp.data?.payload?.data }),
        );

        const { meta, schema, relations } = resp.data?.payload?.data || {};
        if (
          [
            "list-m2m",
            "list-m2o",
            "list-o2m",
            "list-m2a",
            "files",
            "file",
            "file-image",
            "translations",
          ].includes(meta?.interface)
        ) {
          if (Array.isArray(relations) && relations.length > 0) {
            dispatch(addFieldsRelations(relations));
          }

          if (Array.isArray(schema?.fields) && schema.fields.length > 0) {
            dispatch(addField({ singleObject: false, data: schema.fields }));
          }

          if (schema) {
            // eslint-disable-next-line no-unused-vars
            const { fields, ...restSchema } = schema;
            dispatch(addSchema({ singleObject: true, data: restSchema }));
          }
        }
      }
      setActiveTab(null);
    } catch (error) {
      console.log("error", error);
      error?.data?.message &&
        notification.error({
          message: error?.data?.message,
        });
      isError = true;
    } finally {
      !isError &&
        (activeTab !== "json" || Json.jsonData) &&
        setIsDrawerOpen(false);

      setLoader(false);
    }
  };

  return (
    <>
      {loader ? (
        <Spin
          spinning={loader}
          fullscreen
          indicator={<div className="custom-loader" />}
        />
      ) : (
        <FormStyling
          form={form}
          layout="vertical"
          className="mt-12"
          initialValues={initialValues}
        >
          <Row gutter={28}>
            {getFormConfig()}
            <Col className="gutter-row" xs={24}>
              <Button
                onClick={handleSubmit}
                className="project-custom-btn mt-4 w-full"
                disabled={loader}
              >
                {t("save")}
              </Button>
              {/* <Button type="link" className="mt-4 font-medium h-auto w-full">
          Continue in Advanced Field Creation Mode
        </Button> */}
            </Col>
          </Row>
        </FormStyling>
      )}

      <DrawerStyle
        width={870}
        destroyOnClose
        open={open}
        onClose={() => {
          setOpen(false);
          setActiveChoice(null);
        }}
      >
        <CreateChoices
          setChoices={setChoices}
          setOpen={setOpen}
          choiceType={activeTab}
          activeChoice={activeChoice}
        />
      </DrawerStyle>
      <DrawerStyle
        width={870}
        open={isTranslationDrawerOpen}
        onClose={() => setIsTranslationDrawerOpen(false)}
        closeIcon={<IconsAll.CloseIconCustom />}
        destroyOnClose
      >
        <AddVertalingen
          setIsTranslationDrawerOpen={setIsTranslationDrawerOpen}
          type={"drawer"}
          getTranslations={getTranslations}
        />
      </DrawerStyle>
    </>
  );
};

export default FieldsConfigComponent;
