import React from "react";
// import { DrawerSidebarTabs } from "../../Common/DrawerSidebarTabStyle";
import { useTranslation } from "react-i18next";
import {
  DatePicker,
  Flex,
  Input,
  InputNumber,
  Tabs,
  Tag,
  Typography,
} from "antd";
import { capitalizeAndFormat, CustomInfoText } from "../../Helpers/utils";
import LeftSideTitlePart from "../HeaderParts/LeftSideTitlePart";
import moment from "moment";
import { IoTriangleOutline } from "react-icons/io5";
import { FaCircleMinus, FaCirclePlus } from "react-icons/fa6";
import styled from "styled-components";
import { InvertedCheckBox } from "../../Common/CommonUiStyles";
import { BsInfoCircle } from "react-icons/bs";
import RightSIdeTitlePart from "../HeaderParts/RightSIdeTitlePart";
import dayjs from "dayjs";

const { Text } = Typography;

const DrawerSidebarTabs = styled(Tabs)`
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
  .ant-tabs-content-holder {
    padding-top: 1rem !important;
  }
  .ant-tabs-nav {
    min-width: 200px;
    padding-top: 1rem !important;
    @media screen and (max-width: 768px) {
      min-width: 100%;
    }
    .ant-tabs-ink-bar {
      display: none;
    }
    .ant-tabs-nav-wrap {
      .ant-tabs-nav-list {
        .ant-tabs-tab {
          border-radius: 8px;
          margin-top: 5px;
          > div {
            color: #000 !important;
            font-size: 16px;
            font-style: normal;
          }
          &.ant-tabs-tab-active {
            background-color: var(--sidebar-active-menu-bg) !important;
          }
        }
      }
    }
  }
`;

const RevisionDrawer = ({
  selectedData,
  isCreate,
  setFormData,
  setIsDrawerOpen,
}) => {
  const { t } = useTranslation();

  const renderValue = (value, field_interface, is_preview) => {
    if (Array.isArray(value) && value?.length > 0) {
      if (typeof value[0] === "string")
        return (
          <Input
            value={value.join(",")}
            disabled
            className={is_preview ? `project-custom-input` : `bg-red`}
          />
        );
    } else if (field_interface === "wysiwyg") {
      return (
        <div
          className={is_preview ? `project-custom-input` : `bg-red`}
          dangerouslySetInnerHTML={{ __html: value }}
        ></div>
      );
    } else if (field_interface === "datetime") {
      return (
        <DatePicker
          size={"large"}
          style={{ height: "56px" }}
          showTime
          value={dayjs(value)}
          className={`font-normal`}
          disabled
        />
      );
    }
    switch (typeof value) {
      case "string":
        return (
          <Input
            value={value}
            disabled
            className={is_preview ? `project-custom-input` : `bg-red`}
          />
        );
      case "number":
        return (
          <InputNumber
            value={value}
            disabled
            className={is_preview ? `project-custom-input` : `bg-red`}
          />
        );
      case "boolean":
        return (
          <InvertedCheckBox checked={!!value} disabled>
            {t("enabled")}
          </InvertedCheckBox>
        );
      default:
        return (
          <Input
            value={value}
            disabled
            className={is_preview ? `project-custom-input` : `bg-red`}
          />
        );
    }
  };

  const items = [
    !isCreate && {
      key: "updates_made",
      label: t("updates_made"),
      children: (
        <div>
          <LeftSideTitlePart
            pageTitle={`${t("item")} ${isCreate ? t("creation") : t("revision")}`}
            pageSubTitle={moment(selectedData?.updated_at).format(
              "MMM DD,YYYY (HH:mm:ss) a",
            )}
            pageIcon
            pageInnerIcon={<IoTriangleOutline size={24} />}
          />
          <div className="my-8">
            {CustomInfoText(
              <BsInfoCircle size={20} />,
              <span className="py-3">
                {t("revision_update_info_msg")} <br />
                {t("revision_common_info_msg")}
              </span>,
            )}
          </div>
          {selectedData?.changes?.map((rev, i) => {
            return (
              <div key={i}>
                <Text className="mt-6 mb-3 !text-(--heading-text) text-base font-medium block">
                  {capitalizeAndFormat(rev?.field_translation)}
                </Text>
                <Tag
                  color="error"
                  bordered={false}
                  icon={<FaCircleMinus size={18} className="shrink-0" />}
                  className="w-full px-4 py-2.5 mr-0 rounded-none flex items-center gap-[var(--br-12)] text-base whitespace-normal break-all"
                >
                  {renderValue(rev?.from, rev?.field_interface)}
                </Tag>
                <Tag
                  color="success"
                  bordered={false}
                  icon={<FaCirclePlus size={18} className="shrink-0" />}
                  className="w-full px-4 py-2.5 mr-0 rounded-none flex items-center gap-[var(--br-12)] text-base whitespace-normal break-all"
                >
                  {renderValue(rev?.to, rev?.field_interface)}
                </Tag>
              </div>
            );
          })}
        </div>
      ),
    },
    {
      key: isCreate ? "creation_preview" : "revision_preview",
      label: isCreate ? t("creation_preview") : t("revision_preview"),
      children: (
        <div>
          <Flex justify="space-between" className="w-full">
            <LeftSideTitlePart
              pageTitle={`${t("item")} ${isCreate ? t("creation") : t("revision")}`}
              pageSubTitle={moment(selectedData?.updated_at).format(
                "MMM DD,YYYY (HH:mm:ss) a",
              )}
              pageIcon
              pageInnerIcon={<IoTriangleOutline size={24} />}
            />
            <RightSIdeTitlePart
              revertButton={!isCreate}
              handleRevertClick={() => {
                setFormData((prev) => {
                  return {
                    ...prev,
                    ...selectedData?.changes?.reduce((acc, item) => {
                      acc[item?.field_key] =
                        item?.to === "false" ? false : item?.to;
                      return acc;
                    }, {}),
                  };
                });
                setIsDrawerOpen(false);
              }}
            />
          </Flex>
          <div className="my-8">
            {CustomInfoText(
              <BsInfoCircle size={20} />,
              <span className="py-3">
                {t(
                  isCreate
                    ? "revision_creation_info_msg"
                    : "revision_preview_info_msg",
                )}{" "}
                <br />
                {t("revision_common_info_msg")}
              </span>,
            )}
          </div>
          {selectedData?.changes?.map((rev, i) => {
            return (
              <div key={i}>
                <Text className="mt-6 mb-3 !text-(--heading-text) text-base font-medium block">
                  {capitalizeAndFormat(rev?.field_translation)}
                </Text>
                {renderValue(rev?.to, rev?.field_interface, true)}
              </div>
            );
          })}
        </div>
      ),
    },
  ];

  return (
    <>
      <DrawerSidebarTabs
        tabPosition={"left"}
        defaultActiveKey={isCreate ? "creation_preview" : "updates_made"}
        items={items}
      />
    </>
  );
};

export default RevisionDrawer;
