import React, { useState, useEffect } from "react";
import { Form, Row, Flex, notification, Skeleton, Spin } from "antd";
import LeftSideTitlePart from "../../Components/HeaderParts/LeftSideTitlePart";
import RightSIdeTitlePart from "../../Components/HeaderParts/RightSIdeTitlePart";
import {
  capitalizeAndFormat,
  flattenObject,
  getForeignCollectionDetails,
} from "../../Helpers/utils";
import withRouter from "../../Common/withRouter";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import DynamicForm from "../../Components/DynamicForm/DynamicForm";
import axiosInstance from "../../Helpers/axios";
import Apis from "../../Helpers/Apis";
import { setActiveTab } from "../../Redux/slices/activeTab.reducer";
import { useParams } from "react-router-dom";
import { JoiValidateData } from "../../Helpers/CreateJoiSchema";
import { unflatten } from "flat";
import { useTranslation } from "react-i18next";
import AppInfo from "../../Components/AppInfo";
import { useSelector } from "react-redux";
import { hideLoader, showLoader } from "../../Redux/slices/loader.reducer";
import { getFieldsBySchemaId } from "../../Helpers/functions";
import IconsAll from "../../Components/IconsAll";
import { isEmpty, isEqual } from "lodash";

const FormStyling = styled(Form)`
  background-color: transparent;
  border-top: 0;
  border-bottom: 0;
`;

const AddContent = ({ router, currentCollection = null }) => {
  const path = location.pathname.split("/");
  const params = useParams();
  const [collectionData, setCollectionData] = useState(null);
  const [formData, setFormData] = useState({});
  const [originalFormData, setOriginalFormData] = useState({});
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const loader = useSelector((state) => state.loader.isLoading);
  const internalLoading = useSelector((state) => state.loader.internalLoading);
  const fields = useSelector((state) => state?.fields?.fields);
  const schemas = useSelector((state) => state?.schemas?.schemas);
  const [validationErrors, setValidationErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [isInitCompleted, setIsInitCompleted] = useState(false);
  const { t } = useTranslation();

  const init = async () => {
    dispatch(
      showLoader({
        isLoading: !currentCollection?.meta?.singleton,
        internalLoading: currentCollection?.meta?.singleton,
      }),
    );
    try {
      const resp = getFieldsBySchemaId({
        schemas,
        fields,
        schemaId: params.table,
        populated: false,
      });
      setCollectionData(resp);
      if (path[3] === "edit" || currentCollection?.meta?.singleton) {
        await getAndSetFormData(resp?.collection_id, resp?.fields);
      } else {
        setIsInitCompleted(true);
        dispatch(hideLoader());
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const convertTranslationData = (data, lang_field) => {
    const result = {};

    data?.forEach((item) => {
      const langCode = item[lang_field];
      const fields = { ...item };
      delete fields[lang_field];

      result[langCode] = fields;
    });

    return result;
  };

  const getAndSetFormData = async (collection_id, collection_fields) => {
    try {
      const resp = await axiosInstance.get(
        Apis.DEFAULT_GET_SINGLE_CONTENT(
          collection_id,
          currentCollection?.meta?.singleton ? collection_id : params.id,
        ),
      );
      if (resp.status === 200) {
        let contentFormData = resp.data.payload.data;
        const translationFields = collection_fields?.filter(
          (f) => f?.meta?.interface === "translations",
        );
        if (translationFields?.length > 0) {
          for (const field of translationFields) {
            const myRelations = getForeignCollectionDetails({
              collection: field?.schema_id,
              field: field?.field,
              iFace: field?.meta?.interface,
              findJunction: true,
            });

            const transformedData = convertTranslationData(
              contentFormData?.[field?.path],
              myRelations?.junction_field,
            );
            contentFormData[field?.path] = transformedData;
          }
        }

        const keysToSkip = translationFields?.map((tf) => tf?.path);
        const dataToFlatten = Object.fromEntries(
          Object.entries(contentFormData).filter(
            ([key]) => !keysToSkip.includes(key),
          ),
        );

        const flattenedData = flattenObject(dataToFlatten);

        const nonFlattenedData = Object.fromEntries(
          Object.entries(contentFormData).filter(([key]) =>
            keysToSkip.includes(key),
          ),
        );

        const finalFormData = { ...flattenedData, ...nonFlattenedData };

        setFormData(finalFormData);
        setOriginalFormData(contentFormData);
        setIsInitCompleted(true);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      dispatch(hideLoader());
    }
  };

  const transformTranslationData = (
    isEdit,
    language_field,
    field_data = {},
    field_path,
  ) => {
    const transformedData = {
      create: [],
      update: [],
      delete: [],
    };
    if (!isEdit) {
      transformedData.create = Object.entries(field_data).map(
        ([key, value]) => ({
          [language_field]: key,
          ...value,
        }),
      );
    } else {
      const updateMap = {};
      const translationOriginalDoc = originalFormData?.[field_path] || {};
      const fieldDataKeys = Object.keys(field_data) || [];
      const originalDataKeys = Object.keys(translationOriginalDoc || {}) || [];

      [...new Set([...fieldDataKeys, ...originalDataKeys])]?.map((key) => {
        const fieldValue = field_data[key];
        const originalValue = translationOriginalDoc?.[key];
        if (fieldValue && !originalValue) {
          transformedData.create.push({
            [language_field]: key,
            ...fieldValue,
          });
        } else if (!fieldValue && originalValue) {
          transformedData.delete.push(originalValue?._id);
        } else if (fieldValue && originalValue) {
          updateMap[key] = fieldValue;
        }
      });

      if (Object.keys(updateMap).length > 0) {
        const formattedTranslationOriginalDoc = Object.fromEntries(
          Object.entries(
            flattenObject(
              Object.fromEntries(
                Object.entries(translationOriginalDoc || {}).filter(([key]) =>
                  Object.keys(updateMap).includes(key),
                ),
              ),
            ),
          ).map(([key, value]) => {
            let iFace = fields?.find(
              (f) => f?.path === key.split(".").slice(1).join("."),
            )?.meta?.interface;
            if (
              [
                "list-o2m",
                "list-m2o",
                "list-m2m",
                "list-m2a",
                "file",
                "file-image",
                "files",
              ].includes(iFace)
            ) {
              return [
                key,
                Array.isArray(value) && value?.length > 0
                  ? ["list-m2o", "file", "file-image"].includes(iFace)
                    ? value?.[0]?._id
                    : iFace === "list-m2a"
                      ? value?.map((val) => {
                          return {
                            collection: val?.collection,
                            item: val?.item,
                            sort: val?.sort,
                          };
                        })
                      : value?.map((i) => i?._id)
                  : [],
              ];
            }
            return [key, value];
          }),
        );
        const compareUpdatedMapDoc = flattenObject(updateMap);

        Object.entries(compareUpdatedMapDoc).forEach(([key, value]) => {
          const isValueEqual =
            (isEmpty(value) &&
              isEmpty(formattedTranslationOriginalDoc?.[key])) ||
            isEqual(value, formattedTranslationOriginalDoc?.[key]);
          if (isValueEqual) {
            delete compareUpdatedMapDoc?.[key];
          }
        });

        let finalUpdateMap = Object.entries(
          unflatten(compareUpdatedMapDoc || {}),
        )?.map(([key, value]) => ({
          _id: formattedTranslationOriginalDoc?.[`${key}._id`],
          [language_field]: key,
          ...value,
        }));

        transformedData.update = finalUpdateMap;
      }
    }
    return transformedData;
  };

  const handleSubmit = async () => {
    let updateFields = ["_id", "created_at", "updated_at", "__v"];
    let isError = false;
    const finalFormData = await unflatten(
      Object.fromEntries(
        Object.entries(formData).filter(
          ([key]) =>
            !updateFields?.includes(key) &&
            new Set(collectionData?.fields?.map((f) => f?.path)).has(key),
        ),
      ),
    );
    const { error, status } = await JoiValidateData({
      data: finalFormData,
      fields: collectionData?.fields,
    });
    if (!status && error) {
      console.log("error", error);
      isError = true;
      setValidationErrors((prev) => {
        const updatedErrors = Object.keys(prev).reduce((acc, key) => {
          if (
            !collectionData?.fields
              ?.map((f) => f?.path)
              .some((path) => key === path)
          ) {
            acc[key] = prev[key];
          }
          return acc;
        }, {});

        return { ...updatedErrors, ...error };
      });
    } else {
      setValidationErrors((prev) => {
        const updatedErrors = Object.keys(prev).reduce((acc, key) => {
          if (
            !collectionData?.fields
              ?.map((f) => f?.path)
              .some((path) => key === path)
          ) {
            acc[key] = prev[key];
          }
          return acc;
        }, {});

        return { ...updatedErrors };
      });
    }

    const translationFields = collectionData?.fields?.filter(
      (f) => f?.meta?.interface === "translations",
    );

    if (translationFields?.length > 0) {
      for (const field of translationFields) {
        const myRelations = getForeignCollectionDetails({
          collection: field?.schema_id,
          field: field?.field,
          iFace: field?.meta?.interface,
          findJunction: true,
          // getRelationaShipDetails: true
        });
        const translationCollectionFields = fields?.filter(
          (f) =>
            f?.schema_id === myRelations?.junction_collection_id &&
            !f?.meta?.hidden,
        );
        for (const [key, value] of Object.entries(
          finalFormData?.[field?.path] || {},
        )) {
          const { error, status } = await JoiValidateData({
            data: unflatten(
              Object.fromEntries(
                Object.entries(value).filter(
                  ([key]) =>
                    ![
                      ...updateFields,
                      myRelations?.junction_field_local,
                    ].includes(key),
                ),
              ),
            ),
            fields: translationCollectionFields,
            prefix: `${field?.path}.${key}.`,
          });

          if (!status && error) {
            console.log("error in translation form", error);
            isError = true;
            setValidationErrors((prev) => {
              const updatedErrors = { ...prev };
              Object.keys(updatedErrors).forEach((k) => {
                if (k.startsWith(`${field?.path}.${key}.`)) {
                  delete updatedErrors[k];
                }
              });

              return { ...updatedErrors, ...error };
            });
            continue;
          } else {
            setValidationErrors((prev) => {
              const updatedErrors = { ...prev };
              Object.keys(updatedErrors).forEach((k) => {
                if (k.startsWith(`${field?.path}.${key}.`)) {
                  delete updatedErrors[k];
                }
              });
              return updatedErrors;
            });
          }
        }

        if (!isError) {
          if (
            isEmpty(originalFormData?.[field?.path]) &&
            isEmpty(finalFormData?.[field?.path])
          ) {
            finalFormData[field?.path] = [];
          } else {
            const newData = transformTranslationData(
              path[3] === "edit",
              myRelations?.junction_field,
              finalFormData?.[field?.path],
              field?.path,
            );
            finalFormData[field?.path] = newData;
          }
          // return;
        }
      }
    }
    if (isError) return;
    setLoading(true);

    if (path[3] !== "edit" && !currentCollection?.meta?.singleton) {
      try {
        const resp = await axiosInstance.post(
          Apis.DEFAULT_ADD_CONTENT(collectionData?.collection_id),
          finalFormData,
        );
        if (resp.status === 200) {
          notification.success({ message: t("add_content_success") });

          router.navigate(`/content/${params.table}`),
            dispatch(
              setActiveTab({
                page: path[1],
                tab: collectionData?.collection_id,
                redirectionLink: `/content/${params.table}`,
              }),
            );
        }
      } catch (error) {
        if (error?.response?.data?.type === "VALIDATION") {
          setValidationErrors((prev) => {
            const updatedErrors = Object.keys(prev).reduce((acc, key) => {
              if (
                !collectionData?.fields
                  ?.map((f) => f?.path)
                  .some((path) => key === path)
              ) {
                acc[key] = prev[key];
              }
              return acc;
            }, {});

            return {
              ...updatedErrors,
              ...(error?.response?.data?.payload?.error || {}),
            };
          });
        } else {
          notification.error({
            message: error?.data?.message || t("something_went_wrong"),
          });
        }
      } finally {
        setLoading(false);
      }
    } else {
      try {
        const resp = await axiosInstance.put(
          Apis.DEFAULT_UPDATE_CONTENT(
            collectionData?.collection_id,
            formData._id,
          ),
          finalFormData,
        );
        if (resp.status === 200) {
          notification.success({ message: t("update_content_success") });
          if (!currentCollection?.meta?.singleton) {
            router.navigate(`/content/${params.table}`),
              dispatch(
                setActiveTab({
                  page: path[1],
                  tab: collectionData?.collection_id,
                  redirectionLink: `/content/${params.table}`,
                }),
              );
          }
        }
      } catch (error) {
        if (error?.response?.data?.type === "VALIDATION") {
          setValidationErrors((prev) => {
            const updatedErrors = Object.keys(prev).reduce((acc, key) => {
              if (
                !collectionData?.fields
                  ?.map((f) => f?.path)
                  .some((path) => key === path)
              ) {
                acc[key] = prev[key];
              }
              return acc;
            }, {});

            return {
              ...updatedErrors,
              ...(error?.response?.data?.payload?.error || {}),
            };
          });
        } else {
          notification.error({
            message: error?.data?.message || t("something_went_wrong"),
          });
        }
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (fields?.length > 0) {
      (async () => {
        await init();
      })();
    }
  }, [fields, currentCollection]);

  return (
    <Flex className="w-full main-content-wrap">
      <div className="px-4 xl:px-16 w-full left-part ">
        <div className="primary-header pt-9 pb-5">
          <div
            onClick={() => {
              if (!currentCollection?.meta?.singleton) {
                router.navigate(`/content/${params.table}`),
                  dispatch(
                    setActiveTab({
                      page: path[1],
                      tab: collectionData?.collection_id,
                      redirectionLink: `/content/${params.table}`,
                    }),
                  );
              }
            }}
          >
            <LeftSideTitlePart
              pageTitle={
                loader ? (
                  <Skeleton.Input size="small" active />
                ) : currentCollection?.meta?.singleton ? (
                  capitalizeAndFormat(collectionData?.collection_name) || ""
                ) : (
                  `${path[3] !== "edit" ? t("creating_item_in") : t("updating_item_in")} ${collectionData?.collection_name ? capitalizeAndFormat(collectionData?.collection_name) : ""}`
                )
              }
              pageSubTitle={
                loader ? (
                  <Skeleton.Button size="small" active />
                ) : currentCollection?.meta?.singleton ? (
                  t("content")
                ) : (
                  capitalizeAndFormat(collectionData?.collection_name)
                )
              }
              pageInnerIcon={
                collectionData?.icon ? (
                  filteredIcons[collectionData?.icon] &&
                  React.createElement(filteredIcons[collectionData?.icon], {
                    size: 16,
                    color: collectionData?.color || "var(--project-base)",
                  })
                ) : (
                  <IconsAll.DataModalIcon />
                )
              }
              pageIcon={currentCollection?.meta?.singleton}
              backButton={!currentCollection?.meta?.singleton}
            />
          </div>
          <RightSIdeTitlePart
            showButton={true}
            handleShowClick={handleSubmit}
            showDisabled={
              loading ||
              collectionData?.fields?.filter(
                (field) => field?.meta?.interface !== "none",
              )?.length < 1
            }
            buttonLoading={loading}
          />
        </div>

        {internalLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
              width: "100%",
            }}
          >
            <Spin indicator={<div className="custom-loader" />} />
          </div>
        ) : (
          <section className="container max-w-4xl">
            {!loader && !internalLoading && isInitCompleted && (
              <FormStyling layout="vertical" form={form}>
                <Row
                  gutter={16}
                  className="remove-sibling-padding !bg-transparent !px-0 !mx-0"
                >
                  <DynamicForm
                    form={collectionData?.fields
                      ?.filter((f) => !f?.meta?.hidden)
                      ?.sort((a, b) => a?.meta?.sort - b?.meta?.sort)}
                    setFormData={setFormData}
                    formData={formData}
                    validationErrors={validationErrors}
                  />
                </Row>
              </FormStyling>
            )}
          </section>
        )}
      </div>
      {/* <Col className="right-part"> */}
      {!loader && (
        <AppInfo
          visibleList={
            path[3] === "edit" ? ["information", "revision"] : ["information"]
          }
          config={{
            collection_id: path[2],
            item_id: path[4],
            currentData: Object.fromEntries(
              Object.entries(formData || {})?.filter(
                ([key]) =>
                  ![
                    "list-m2m",
                    "list-o2m",
                    "file",
                    "file-image",
                    "files",
                  ].includes(
                    collectionData?.fields?.find((f) => {
                      return f?.field === key;
                    })?.meta?.interface,
                  ) && !["_id", "__v", "created_at"].includes(key),
              ),
            ),
            collectionFields: collectionData?.fields,
            msg: t("information_add_content"),
            setFormData,
          }}
        />
      )}
      {/* </Col> */}
    </Flex>
  );
};

export default withRouter(AddContent);
