import { Divider, Flex, Tooltip, Typography } from "antd";
import Title from "antd/es/typography/Title";
import React, { useState } from "react";
import IconsAll from "../IconsAll";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import RightSIdeTitlePart from "../HeaderParts/RightSIdeTitlePart";
import { useSelector } from "react-redux";
import { themes } from "../../config";
import { newFieldsList } from "./NewFieldsList";
import FieldsConfigComponent from "./FieldsConfigComponent";
import { capitalizeAndFormat } from "../../Helpers/utils";

const TItleIconStyle = styled(Typography)`
  min-width: 56px !important;
  width: 56px !important;
  height: 56px;
  background-color: rgba(from var(--project-base) r g b / 10%);
  border-radius: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const TabHeaders = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  > div {
    color: ${() =>
      themes[useSelector((state) => state.theme.name)].token.grayClr};
    cursor: pointer;
    > .custom-tabs {
      background-color: var(--form-input-wrapper);
      border: 1px solid var(--input-field-border);
    }
  }
  .ant-form {
    grid-column: 1/5;
    grid-row: 2;
  }
  [data-tab-identification="1"] {
    grid-column: 1 / 2;
  }
  [data-tab-identification="2"] {
    grid-column: 2 / 3;
  }
  [data-tab-identification="3"] {
    grid-column: 3 / 4;
  }
  [data-tab-identification="4"] {
    grid-column: 4 / 5;
  }
  [data-tab-identification="5"] {
    grid-column: 1 / 2;
    + .ant-form {
      grid-row: 3;
    }
  }
  [data-tab-identification="6"] {
    grid-column: 2 / 3;
    + .ant-form {
      grid-row: 3;
    }
  }
  [data-tab-identification="7"] {
    grid-column: 3 / 4;
    + .ant-form {
      grid-row: 4;
    }
  }
  [data-tab-identification="8"] {
    grid-column: 4/5;
    + .ant-form {
      grid-row: 4;
    }
  }
`;

const TabHeader = styled.div`
  border-radius: 5px 5px 0 0;
`;

const categories = [
  "group_fields",
  "text_and_numbers",
  "selection",
  "media",
  "relational",
];

const CreateNewFields = ({ setIsDrawerOpen, dataSource, setDataSource }) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  return (
    <div className="py-4">
      <Flex wrap="wrap" align="center" justify="space-between">
        <Title className="project-header-left-side w-[60%]" level={3}>
          <TItleIconStyle>
            <IconsAll.ContentIcon />
          </TItleIconStyle>
          <Tooltip
            className="truncate"
            title={
              t("new_field") +
              " (" +
              capitalizeAndFormat(dataSource?.collection_id) +
              ")"
            }
            placement="bottomLeft"
          >
            {t("new_field") +
              " (" +
              capitalizeAndFormat(dataSource?.collection_id) +
              ")"}
          </Tooltip>
        </Title>
        <RightSIdeTitlePart
          isFilterShow={true}
          searchTermPlaceholder={t("search")}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
      </Flex>
      {categories.map((category, ind) => {
        return (
          <article className="my-12" key={ind}>
            <Typography
              className="flex items-center gap-3 text-base font-semibold"
              level={3}
            >
              {t(category)}
            </Typography>
            <Divider className="mt-2 mb-4" />
            <TabContainer>
              <TabHeaders>
                {newFieldsList[category]
                  .filter((tab) => {
                    return t(tab.id)
                      ?.toLowerCase()
                      .includes(searchTerm.toLowerCase());
                  })
                  .map((tab, i) => (
                    <React.Fragment key={i}>
                      <TabHeader
                        key={tab.id}
                        className={`${tab.id === activeTab ? "active-tab" : ""}`}
                        data-tab-identification={i + 1}
                        onClick={() => {
                          setActiveTab((prevTab) =>
                            prevTab === tab.id ? null : tab.id,
                          );
                        }}
                      >
                        <div className="custom-tabs | min-h-24 min-w-36 flex items-center justify-center mb-1 rounded-md">
                          {tab.icon}
                        </div>
                        <span className="text-heading-text">{t(tab.id)}</span>
                      </TabHeader>
                      {tab.id === activeTab && (
                        <FieldsConfigComponent
                          activeTab={activeTab}
                          dataSource={dataSource}
                          setActiveTab={setActiveTab}
                          setIsDrawerOpen={setIsDrawerOpen}
                          fieldsList={tab.fieldsList}
                          initialValues={tab.initialValues}
                          nativeFieldType={tab.nativeFieldType}
                          setDataSource={setDataSource}
                        />
                      )}
                    </React.Fragment>
                  ))}
              </TabHeaders>
            </TabContainer>
          </article>
        );
      })}
    </div>
  );
};

export default CreateNewFields;
