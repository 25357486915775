import React, { useEffect, useId, useState } from "react";
import {
  Flex,
  Input,
  Button,
  Select,
  Space,
  Spin,
  Switch,
  DatePicker,
  notification,
  Form,
  Row,
  Col,
} from "antd";
import LeftSideTitlePart from "../../HeaderParts/LeftSideTitlePart";
import IconsAll from "../../IconsAll";
import RightSIdeTitlePart from "../../HeaderParts/RightSIdeTitlePart";
import styled from "styled-components";
import { RiDeleteBin6Line } from "react-icons/ri";
import ValidationOptions from "./validationOptionsJson.json";
import { capitalizeAndFormat, formatDate } from "../../../Helpers/utils";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { DrawerStyle, FormStyling } from "../../../Common/CommonUiStyles";
import AddVertalingen from "../../../Pages/Instellingen/AddVertalingen";
import InputWithTranslations from "../InputWithTranslations";

const ValidationFieldsWrapper = styled(Space)`
  gap: 10px;
  margin-top: 0;
  margin-bottom: 10px;
  .condition-options-select {
    width: 150px;
    height: 34px;
    .ant-select-selector {
      border-radius: 100vh;
    }
    .ant-select-selection-item {
      line-height: 34px;
    }
    .ant-select-arrow {
      inset-inline-end: 14px;
    }
  }
  .condition-options-input {
    background: var(--input-field-bg) !important;
    border-radius: 8px;
    box-shadow: unset !important;
  }
  .delete-button {
    opacity: 1;
    &:hover {
      color: #ff0505ae !important;
    }
  }
`;

const ValidationFieldsColumn = styled(Space)`
  background-color: var(--validation-field-bg);
  border: 1px solid var(--input-field-border);
  gap: 0;
  width: 100%;
  border-radius: 10px;
  padding: 25px 20px 15px;
  margin-top: 20px;
  overflow: auto;
  height: auto;
  max-height: calc(100vh - 270px);
`;

const UpdateValidations = ({
  activeItem,
  dataSource,
  isDrawerOpen,
  updateField,
  loader,
  translationsList,
  getTranslations,
}) => {
  const { t } = useTranslation();
  const id = useId();
  const [form] = Form.useForm();
  const [validation, setValidation] = useState([]);
  const [selectedTranslation, setSelectedTranslation] = useState(null);
  const [isTranslationDrawerOpen, setIsTranslationDrawerOpen] = useState(false);
  const conditionsList = ValidationOptions[activeItem?.meta?.interface]
    ? Array.isArray(ValidationOptions[activeItem?.meta?.interface])
      ? ValidationOptions[activeItem?.meta?.interface]
      : ValidationOptions[activeItem?.meta?.interface][activeItem?.type]
    : [];
  const nonInputConditions = new Set([
    "isNull",
    "isNotNull",
    "isEmpty",
    "isNotEmpty",
  ]);

  useEffect(() => {
    if (isDrawerOpen) {
      setValidation(
        activeItem?.meta?.validations?.rules?.map((r) => {
          return r[r?.rule]?.insensitive
            ? {
                rule: r?.rule + "Insensitive",
                [r?.rule + "Insensitive"]: r[r?.rule],
              }
            : r;
        }) || [],
      );
      const validationMsg = activeItem?.meta?.validations?.validation_msg;
      const formattedMsg = validationMsg?.startsWith("$t:")
        ? validationMsg.replace("$t:", "")
        : validationMsg;
      if (validationMsg?.startsWith("$t:")) {
        setSelectedTranslation(formattedMsg);
      }
      form.setFieldValue("custom_validation_message", formattedMsg);
    } else {
      setValidation([]);
      form.setFieldValue("custom_validation_message", null);
    }
  }, [activeItem?._id]);

  const handleSelect = (e, ind) => {
    setValidation((prev) => {
      const updatedValidation = [...prev];
      updatedValidation[ind] = {
        rule: e,
        [e]: {
          ...(!nonInputConditions.has(e) && { value: null }),
          ...(e?.includes("Insensitive") && { insensitive: true }),
        },
      };
      return updatedValidation;
    });
  };

  const onOk = (value, ind) => {
    setValidation((prev) => {
      return prev?.map((item, i) =>
        i === ind
          ? {
              ...item,
              [item?.rule]: {
                ...item[item?.rule],
                value: value ? formatDate(value, activeItem?.type) : null,
              },
            }
          : item,
      );
    });
  };

  const ValidationFields = (item, ind) => {
    // return item?.type === "filter" ? (
    return (
      <ValidationFieldsWrapper key={ind}>
        <span>{capitalizeAndFormat(activeItem?.field)}</span>
        <Select
          value={item?.rule}
          options={conditionsList}
          onChange={(e) => handleSelect(e, ind)}
          className="condition-options-select"
        />
        {!nonInputConditions.has(item?.rule) &&
          (activeItem?.meta?.interface === "toggle" &&
          ["equals", "doesNotEqual"].includes(item?.rule) ? (
            <Switch
              checked={!!item[item?.rule]?.value}
              onChange={(checked) => {
                setValidation((prev) => {
                  return prev?.map((item, i) =>
                    i === ind
                      ? {
                          ...item,
                          [item?.rule]: {
                            ...item[item?.rule],
                            value: !!checked,
                          },
                        }
                      : item,
                  );
                });
              }}
              style={{
                backgroundColor: item[item?.rule]?.value
                  ? "var(--project-base)"
                  : undefined,
              }}
            />
          ) : activeItem?.meta?.interface === "datetime" ? (
            <DatePicker
              size={"large"}
              style={{ height: "56px" }}
              showTime={!(activeItem?.type === "date")}
              picker={activeItem?.type === "time" ? "time" : "date"}
              onOk={(val) => onOk(val, ind)}
              onChange={(val) => onOk(val, ind)}
              value={
                item[item?.rule]?.value
                  ? activeItem?.type === "time"
                    ? dayjs()
                        .set(
                          "hour",
                          parseInt(
                            item[item?.rule]?.value?.split(":")?.[0],
                            10,
                          ),
                        )
                        .set(
                          "minute",
                          parseInt(
                            item[item?.rule]?.value?.split(":")?.[1],
                            10,
                          ),
                        )
                        .set(
                          "second",
                          parseInt(
                            item[item?.rule]?.value?.split(":")?.[2],
                            10,
                          ),
                        )
                    : dayjs(item[item?.rule]?.value)
                  : null
              }
              className={`font-normal`}
            />
          ) : (
            <Input
              value={
                (item?.rule === "isOneOf" || item?.rule === "IsNotOneOf"
                  ? Array.isArray(item[item?.rule]?.value)
                    ? item[item?.rule]?.value.join(", ")
                    : item[item?.rule]?.value || ""
                  : item[item?.rule]?.value) || ""
              }
              onChange={(e) => {
                let newValue = e.target.value;

                if (activeItem?.type === "Number") {
                  if (isNaN(Number(newValue))) {
                    return;
                  } else {
                    newValue = Number(newValue);
                  }
                }
                setValidation((prev) => {
                  return prev?.map((item, i) =>
                    i === ind
                      ? {
                          ...item,
                          [item?.rule]: {
                            ...item[item?.rule],
                            value:
                              item?.rule === "isOneOf" ||
                              item?.rule === "IsNotOneOf"
                                ? newValue
                                    .toString()
                                    .split(",")
                                    .map((str) => str.trim())
                                : newValue,
                          },
                        }
                      : item,
                  );
                });
              }}
              className="condition-options-input"
            />
          ))}
        <Button
          type="link"
          className="p-0 h-auto !text-delete-base text-base opacity-0 | delete-button"
          onClick={() =>
            setValidation((prev) => prev?.filter((item, i) => i !== ind))
          }
        >
          <RiDeleteBin6Line />
        </Button>
      </ValidationFieldsWrapper>
    );
    // ) : (
    //   <div
    //     key={ind}
    //     onClick={(e) => {
    //       e.preventDefault();
    //       setValidation((prev) => {
    //         return prev?.map((item, i) =>
    //           i === ind
    //             ? {
    //                 ...item,
    //                 condition: item?.condition === "AND" ? "OR" : "AND",
    //               }
    //             : item,
    //         );
    //       });
    //     }}
    //   >
    //     {item?.condition} -- {item?.condition === "AND" ? "All" : "Any"} of the
    //     following
    //   </div>
    // );
  };

  const handleSubmit = async () => {
    const hasEmptyValues = validation?.some((v) => {
      const value = v[v?.rule]?.value;
      return (
        !nonInputConditions.has(v.rule) &&
        (value === null ||
          value === "" ||
          (Array.isArray(value) && value.length === 0))
      );
    });

    if (hasEmptyValues) {
      notification.error({
        message: t("validation_value_required_msg"),
      });
      return;
    }

    const formattedValidation = validation?.map((v) => {
      return v?.rule?.includes("Insensitive")
        ? {
            rule: v?.rule?.replace("Insensitive", ""),
            [v?.rule?.replace("Insensitive", "")]: v[v?.rule],
          }
        : v;
    });
    const finalValues = {
      ...activeItem,
      meta: {
        ...activeItem?.meta,
        validations: {
          rules: formattedValidation,
          validation_msg: selectedTranslation
            ? "$t:" + selectedTranslation
            : form.getFieldValue("custom_validation_message"),
        },
      },
    };
    const { _id, ...payload } = finalValues;
    delete payload?.__v;

    updateField(_id, payload);
  };

  const handleAdd = () => {
    // if (type === "filter") {
    let keyName = conditionsList?.length ? conditionsList[0]?.value : "";
    setValidation((prevState) => [
      ...prevState,
      { rule: keyName, [keyName]: { value: null, insensitive: false } },
    ]);
    // } else {
    //   setValidation((prev) => {
    //     return [...prev, { condition: "AND", type }];
    //   });
    // }
  };

  return (
    <>
      <Flex wrap="wrap" align="center" justify="space-between">
        <LeftSideTitlePart
          pageTitle={
            capitalizeAndFormat(activeItem?.meta?.interface) +
            " " +
            capitalizeAndFormat(activeItem?.type) +
            " (" +
            capitalizeAndFormat(dataSource?.collection_name) +
            ")"
          }
          pageIcon={true}
          pageInnerIcon={<IconsAll.ContentIcon />}
        />
        <RightSIdeTitlePart
          showButton={true}
          handleShowClick={handleSubmit}
          showDisabled={loader}
        />
      </Flex>
      {loader ? (
        <Spin
          spinning={loader}
          fullscreen
          indicator={<div className="custom-loader" />}
        />
      ) : (
        <Flex vertical>
          <div className="mb-2">
            {validation?.length > 0 ? (
              <ValidationFieldsColumn direction="vertical">
                {validation?.map((item, i) => ValidationFields(item, i))}
              </ValidationFieldsColumn>
            ) : (
              <Input
                placeholder={t("no_configured_validations")}
                className="mt-4 h-12"
              />
            )}
          </div>
          <div className="mb-2">
            <Button
              type="link"
              className="px-0 font-medium h-auto !text-project-base"
              onClick={() => handleAdd("filter")}
            >
              {t("add_filter")}
            </Button>
          </div>
          <div className="mb-2">
            <FormStyling
              form={form}
              layout="vertical"
              initialValues={{
                type: "String",
                icon_left: "Icon 1",
                icon_right: "Icon 1",
              }}
              className="mt-4"
            >
              <Row gutter={28}>
                <Col className="gutter-row" xs={24} md={24}>
                  <Form.Item
                    label={t("custom_validation_message")}
                    className="font-bold"
                    htmlFor={`${id}-template`}
                    name={`custom_validation_message`}
                  >
                    <InputWithTranslations
                      form={form}
                      selectedTranslation={selectedTranslation}
                      setIsTranslationDrawerOpen={setIsTranslationDrawerOpen}
                      setSelectedTranslation={setSelectedTranslation}
                      translationsList={translationsList}
                      inputFormName={`custom_validation_message`}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </FormStyling>
          </div>
        </Flex>
      )}
      <DrawerStyle
        width={870}
        open={isTranslationDrawerOpen}
        closeIcon={<IconsAll.CloseIconCustom />}
        onClose={() => setIsTranslationDrawerOpen(false)}
        destroyOnClose
      >
        <AddVertalingen
          setIsTranslationDrawerOpen={setIsTranslationDrawerOpen}
          type={"drawer"}
          getTranslations={getTranslations}
        />
      </DrawerStyle>
    </>
  );
};

export default UpdateValidations;
