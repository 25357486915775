import React from "react";
import JsonEditor from "../../Create-new-fields/EditFields/JsonEditor";
import { convertToJson } from "../../../Helpers/utils";
const CustomJson = ({
  item,
  updateFormData,
  formData,
  //   isValidationError,
  language,
}) => {
  return (
    <JsonEditor
      key={language?.selectedLanguage}
      setJson={(data) => {
        updateFormData(
          data.jsonData === null ? {} : JSON.stringify(data.jsonData),
        );
      }}
      initialValue={
        convertToJson(
          formData?.[item?.path] || item?.schema_definition?.default,
        ).jsonData || {}
      }
      disable={item?.meta?.readonly}
    />
  );
};
export default CustomJson;
