import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ConfigProvider, notification } from "antd";
import { BrowserRouter } from "react-router-dom";
import "./Helpers/axios.js";
import { Provider, useSelector } from "react-redux";
import { store, persistor } from "./Redux/store.js";
import { PersistGate } from "redux-persist/integration/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "./output.css";

export const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById("root"));
notification.config({
  placement: "top",
});

const RootComponent = () => {
  const currentTheme = useSelector((state) => state.theme);
  return (
    <ConfigProvider theme={currentTheme}>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <App />
      </BrowserRouter>
      {/* </Provider> */}
    </ConfigProvider>
  );
};

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <QueryClientProvider client={queryClient}>
        <RootComponent />
      </QueryClientProvider>
    </PersistGate>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
