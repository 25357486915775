import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  schemas: [],
  system_collections: [],
  presets: {},
};

const schemas = createSlice({
  name: "schemas",
  initialState,
  reducers: {
    setSchemas: (state, action) => {
      return { ...state, schemas: action.payload };
    },
    setSystemCollections: (state, action) => {
      return { ...state, system_collections: action.payload };
    },
    addSchema: (state, action) => {
      const { singleObject, data } = action.payload;
      if (singleObject) {
        state.schemas.push(data);
      } else {
        state.schemas = [...state.schemas, ...data];
      }
    },
    updateSchemaById: (state, action) => {
      const { id, updatedData } = action.payload;
      const index = state.schemas.findIndex((f) => f._id === id);

      if (index !== -1) {
        for (const [key, value] of Object.entries(updatedData)) {
          const keys = key.split(".");
          let target = state.schemas[index];

          for (let i = 0; i < keys.length - 1; i++) {
            target = target[keys[i]] ??= {};
          }

          target[keys[keys.length - 1]] = value;
        }
      }
    },
    updateSchemaSort: (state, action) => {
      const { draggedItemId, newParentId, position, targetItemId } =
        action.payload;

      const schemas = [...state.schemas];
      const draggedItem = schemas.find((item) => item._id === draggedItemId);
      const targetItem = schemas.find((item) => item._id === targetItemId);

      if (!draggedItem || !targetItem) {
        console.error("Dragged or target item not found");
        return state;
      }

      const targetSort = targetItem.meta.sort || 0;
      let newSortIndex;

      if (position === "before") {
        const aboveItem = schemas
          .filter(
            (item) =>
              item.parent === newParentId && item.meta.sort < targetSort,
          )
          .sort((a, b) => b.meta.sort - a.meta.sort)[0];
        newSortIndex = aboveItem
          ? ((aboveItem.meta.sort || 0) + targetSort) / 2
          : targetSort - 1;
      } else if (position === "after") {
        const belowItem = schemas
          .filter(
            (item) =>
              item.parent === newParentId && item.meta.sort > targetSort,
          )
          .sort((a, b) => a.meta.sort - b.meta.sort)[0];
        newSortIndex = belowItem
          ? (targetSort + (belowItem.meta.sort || 0)) / 2
          : targetSort + 1;
      }

      draggedItem.parent = newParentId;
      draggedItem.meta.sort = newSortIndex;

      const updatedItems = schemas.map((item) => {
        if (item?._id === draggedItemId) {
          return draggedItem;
        }
        if (item?._id === newParentId) {
          return {
            ...item,
            meta: {
              ...item.meta,
              collapse: false,
            },
          };
        }
        return item;
      });
      state.schemas = updatedItems;
    },
    updateSchemaMany: (state, action) => {
      const { filter, updatedData } = action.payload;

      state.schemas.forEach((field, index) => {
        const matches = Object.keys(filter).every(
          (key) => field[key] === filter[key],
        );

        if (matches) {
          for (const [key, value] of Object.entries(updatedData)) {
            const keys = key.split(".");
            let target = state.schemas[index];

            for (let i = 0; i < keys.length - 1; i++) {
              target = target[keys[i]] ??= {};
            }

            target[keys[keys.length - 1]] = value;
          }
        }
      });
    },
    deleteSchemaById: (state, action) => {
      state.schemas = state.schemas.filter((f) => f._id !== action.payload);
    },
    setPreset: (state, action) => {
      const { schemaId, preset } = action.payload;
      state.presets[schemaId] = preset;
    },
  },
});

export const {
  setSchemas,
  setSystemCollections,
  addSchema,
  updateSchemaById,
  updateSchemaMany,
  updateSchemaSort,
  deleteSchemaById,
  setPreset,
} = schemas.actions;
export default schemas.reducer;
