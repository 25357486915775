import {
  Button,
  Col,
  Divider,
  Flex,
  Form,
  Input,
  notification,
  Radio,
  Row,
  Select,
  Tooltip,
} from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  PlusIcon,
  WaypointsIcon,
  LucideTimer,
  WebhookIcon,
} from "lucide-react";
import React, { useState } from "react";
import Title from "antd/es/typography/Title";
import {
  InvertedCheckBox,
  InvertedRadioBox,
  TItleIconStyle,
} from "../../../../Common/CommonUiStyles";
import IconsAll from "../../../../Components/IconsAll";
import { GrLinkNext } from "react-icons/gr";
import styled from "styled-components";
import { themes } from "../../../../config";
import { unflatten } from "flat";
import axiosInstance from "../../../../Helpers/axios";
import Apis from "../../../../Helpers/Apis";
import { handleFlowActions } from "../../../../Redux/slices/flows.reducer";
import { useNavigate } from "react-router-dom";
import { flattenObject } from "../../../../Helpers/utils";

const HeaderButton = styled(Button)`
  min-width: 56px !important;
  width: 56px !important;
  height: 56px;
  border: transparent;
  display: flex;
  align-items: center;
  justify-content: center;

  &.add-button {
    background: ${() =>
      themes[useSelector((state) => state.theme.name)].components
        .HeaderButtonsStyle.addButtonBg};
    color: ${() =>
      themes[useSelector((state) => state.theme.name)].components
        .HeaderButtonsStyle.addButtonClr};

    &:hover {
      background: ${() =>
        themes[useSelector((state) => state.theme.name)].components
          .HeaderButtonsStyle.addButtonHoverBg} !important;
      color: ${() =>
        themes[useSelector((state) => state.theme.name)].components
          .HeaderButtonsStyle.addButtonHoverClr} !important;
    }
    &[disabled] {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }
`;

const OperationOption = ({ setSelectedOperation, operation, isSelected }) => {
  return (
    <div
      key={operation.id}
      className={`flex justify-between items-center bg-project-base/5 border border-project-base/15 p-4 rounded-lg cursor-pointer ${isSelected && "!broder-[var(--project-color)] cursor-auto"}`}
      onClick={() => setSelectedOperation(operation)}
    >
      <div className="flex items-center gap-5">
        <div
          className={
            "bg-white h-10 w-10 flex items-center justify-center rounded-full"
          }
        >
          {operation.icon}
        </div>
        <div className={"flex flex-col"}>
          <h3>{operation.title}</h3>
          <p>{operation.description}</p>
        </div>
      </div>
      {isSelected && (
        <div
          className={
            "border border-project-base mr-2 rounded-full p-1 cursor-pointer hover:bg-project-base group transition duration-300"
          }
          onClick={(event) => {
            setSelectedOperation({});
            event.stopPropagation();
          }}
        >
          <PlusIcon
            className={"rotate-45 group-hover:text-project-base-text!"}
            size={16}
          />
        </div>
      )}
    </div>
  );
};

const FlowActionForm = ({ operationData = {}, setNodes, setFlowsData }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const schemas = useSelector((state) => state.schemas.schemas);
  const dispatch = useDispatch();

  const navigation = useNavigate();

  const isEditForm = operationData?._id;

  const flowFormConfig = [
    {
      id: "event",
      icon: <WaypointsIcon size={20} />,
      title: t("event_trigger"),
      description: t("event_trigger_description"),
      fields: [
        {
          key: "options.scope",
          type: "select",
          label: t("scope"),
          rules: [{ required: true, message: t("select_scope") }],
          mode: "multiple",
          options: ["create", "update", "delete"].map((item) => ({
            label: item,
            value: item,
          })),
        },
        {
          key: "options.collections",
          type: "select",
          label: t("collections"),
          rules: [{ required: true, message: t("select_collections") }],
          mode: "multiple",
          options: schemas?.map((c) => {
            return {
              label: c?.collection_id,
              value: c?._id,
            };
          }),
          optionFilterProp: "label",
        },
      ],
    },
    {
      id: "webhook",
      icon: <WebhookIcon size={20} />,
      title: t("webhook_trigger"),
      description: t("webhook_trigger_description"),
      fields: [
        {
          key: "options.method",
          type: "select",
          label: t("method"),
          rules: [{ required: true, message: t("select_method") }],
          width: "half",
          options: [
            {
              label: "GET",
              value: "GET",
            },
            {
              label: "POST",
              value: "POST",
            },
          ],
        },
        {
          key: "options.asynchronous",
          type: "checkbox",
          label: t("asynchronous"),
          width: "half",
        },
        {
          key: "options.response_body",
          type: "radio",
          label: t("response_body"),
          options: [
            {
              label: t("last_operation"),
              value: "$last",
            },
            {
              label: t("all_data"),
              value: "$all",
            },
          ],
          rules: [{ required: true, message: t("select_response_body") }],
        },
      ],
    },
    {
      id: "schedule",
      icon: <LucideTimer size={20} />,
      title: t("cron_trigger"),
      description: t("cron_trigger_description"),
      fields: [
        {
          key: "options.cron",
          type: "input",
          label: t("interval"),
          rules: [
            { required: true, message: t("cron_expression_required") },
            {
              validator: (_, value) => {
                const cronRegex =
                  /^(\*|([0-5]?\d)|(\*\/[0-9]+)) (\*|([0-5]?\d)|(\*\/[0-9]+)) (\*|([01]?\d|2[0-3])|(\*\/[0-9]+)) (\*|([01]?\d|2[0-3])|(\*\/[0-9]+)) (\*|([01]?\d|2[0-3])|(\*\/[0-9]+))$/;
                if (!value || cronRegex.test(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t("invalid_cron_expression")));
              },
            },
          ],
          placeholder: "*/5 * * * *",
        },
      ],
    },
    {
      id: "operation",
      icon: <IconsAll.FlowsIcon size={20} />,
      title: t("operation_trigger"),
      description: t("operation_trigger_description"),
      fields: [
        {
          key: "options.response_body",
          type: "radio",
          label: t("response_body"),
          options: [
            {
              label: t("last_operation"),
              value: "$last",
            },
            {
              label: t("all_data"),
              value: "$all",
            },
          ],
          rules: [{ required: true, message: t("select_response_body") }],
        },
      ],
    },
  ];

  const [selectedOperation, setSelectedOperation] = useState(
    flowFormConfig.find((i) => i?.id === operationData?.trigger) ?? {},
  );

  let initialValues = {
    status: "active",
    description: "",
    options: {
      method: "GET",
      asynchronous: false,
      response_body: "$last",
    },
  };

  if (isEditForm) {
    initialValues = {
      ...operationData,
    };
  }

  if (initialValues?.options?.collections?.[0]?.schema_id) {
    initialValues = {
      ...initialValues,
      options: {
        ...initialValues.options,
        collections: initialValues.options.collections.map((c) => c.schema_id),
      },
    };
  }

  initialValues = flattenObject(initialValues);

  const getFlowFormFields = (fields = []) => {
    return fields.map((field, i) => {
      switch (field.type) {
        case "select":
          return (
            <Col
              key={i}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={field.label}
                className="font-bold"
                name={field?.key}
                rules={field?.rules || []}
              >
                <Select
                  showSearch
                  style={{ flex: 1, height: "56px" }}
                  mode={field?.mode}
                  options={field.options ?? []}
                  optionFilterProp={field?.optionFilterProp}
                />
              </Form.Item>
            </Col>
          );
        case "checkbox":
          return (
            <Col
              key={i}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={field.label}
                name={field.key}
                className="font-bold"
                valuePropName="checked"
              >
                <InvertedCheckBox>{t("enabled")}</InvertedCheckBox>
              </Form.Item>
            </Col>
          );
        case "radio":
          return (
            <Col
              key={i}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={field.label}
                name={field.key}
                className="font-bold"
                rules={field?.rules || []}
              >
                <Radio.Group className="flex md:flex-row flex-col gap-7">
                  {field.options.map((option) => (
                    <InvertedRadioBox
                      key={option.value}
                      value={option.value}
                      className={"flex items-center gap-2"}
                    >
                      {option.label}
                    </InvertedRadioBox>
                  ))}
                </Radio.Group>
              </Form.Item>
            </Col>
          );
        case "input":
          return (
            <Col
              key={i}
              className="gutter-row"
              xs={24}
              md={field?.width === "half" ? 12 : 24}
            >
              <Form.Item
                label={field.label}
                className="font-bold"
                name={field?.key}
                rules={field?.rules || []}
              >
                <Input
                  type={"text"}
                  placeholder={field.placeholder}
                  className="project-custom-input"
                />
              </Form.Item>
            </Col>
          );
        default:
          return null;
      }
    });
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();

      const payload = unflatten(values);

      payload.trigger = selectedOperation.id;

      if (payload?.options?.collections?.length > 0) {
        payload.options.collections = payload.options.collections.map((c) => {
          const schema = schemas.find((s) => s._id === c);
          return {
            schema_id: c,
            schema_name: schema.collection_id,
          };
        });
      }

      if (isEditForm) {
        setNodes((nodes) => {
          return nodes.map((node) => {
            if (node.id === operationData._id) {
              return {
                ...node,
                data: { ...(node?.data || {}), ...payload },
              };
            }
            return node;
          });
        });
      } else {
        const response = await axiosInstance.post(Apis.CREATE_FLOW(), payload);
        if (response?.data?.payload?.data?._id) {
          setFlowsData((flows) => [
            {
              ...response?.data?.payload?.data,
            },
            ...flows,
          ]);
        }
        notification.success({ message: t(response?.data?.message) });
        navigation("/settings/flows/" + response?.data?.payload?.data?._id);
      }
      dispatch(
        handleFlowActions({ data: {}, flag: null, nodeId: "", source: {} }),
      );
    } catch (error) {
      error?.response?.data?.message &&
        notification.error({
          message: t(error.response.data.message),
        });
    }
  };

  return (
    <div className="p-4 flow-action-drawer">
      <Flex wrap="wrap" align="center" justify="space-between">
        <Title className="project-header-left-side max-w-[90%]" level={3}>
          <TItleIconStyle>
            <IconsAll.FlowsIcon />
          </TItleIconStyle>
          <Tooltip
            title={isEditForm ? t("edit_flow") : t("create_new_flow")}
            placement="bottomLeft"
            className="truncate"
          >
            {isEditForm ? t("edit_flow") : t("create_new_flow")}
          </Tooltip>
        </Title>
        <HeaderButton
          shape="circle"
          icon={<GrLinkNext size={24} />}
          className="btn add-button"
          onClick={handleSubmit}
          disabled={!selectedOperation?.id}
        ></HeaderButton>
      </Flex>
      <Form
        layout="vertical"
        className="mt-6"
        form={form}
        initialValues={initialValues}
      >
        <Row gutter={28}>
          <Col className="gutter-row" xs={24} md={12}>
            <Form.Item
              label={t("name")}
              className="font-bold"
              name={`name`}
              rules={[{ required: true, message: t("name_required") }]}
            >
              <Input
                type={"text"}
                placeholder={t("enter_flow_name")}
                className="project-custom-input"
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" xs={24} md={12}>
            <Form.Item
              label={t("status")}
              className="font-bold"
              name={`status`}
              rules={[{ required: true, message: t("status_required") }]}
            >
              <Select
                showSearch
                size="large"
                style={{ flex: 1, height: "56px" }}
                options={[
                  {
                    label: t("active"),
                    value: "active",
                  },
                  {
                    label: t("inactive"),
                    value: "inactive",
                  },
                ]}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" xs={24}>
            <Form.Item
              label={t("description")}
              className="font-bold"
              name={`description`}
            >
              <Input.TextArea
                placeholder={t("enter_flow_description")}
                className="project-custom-input"
                rows={4}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" xs={24}>
            <Divider className="my-6" />
          </Col>
          <Col className="gutter-row" xs={24}>
            <div className={"flex flex-col gap-2"}>
              {selectedOperation?.id ? (
                <>
                  <OperationOption
                    setSelectedOperation={setSelectedOperation}
                    operation={selectedOperation}
                    isSelected
                  />
                  <Row gutter={28} className={"mt-6"}>
                    {getFlowFormFields(selectedOperation.fields)}
                  </Row>
                </>
              ) : (
                flowFormConfig.map((operation, i) => (
                  <OperationOption
                    setSelectedOperation={setSelectedOperation}
                    operation={operation}
                    key={i}
                  />
                ))
              )}
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default FlowActionForm;
