import React from "react";

import { Card, Typography } from "antd";
import styled from "styled-components";

const FormTitleStyle = styled(Card)`
  border: unset;
  background: unset;

  .ant-card-body {
    padding: 0;
    display: flex;
    align-items: center;

    &:before {
      display: none;
    }

    .title-icon {
      width: 56px;
      aspect-ratio: 1;
      color: #0085ca;
    }

    .header-title {
      margin: 0;
      font-size: 24px;
      font-weight: 800;
      line-height: 30px;
    }
  }
`;

const FormTitle = ({ title, icon }) => {
  return (
    <FormTitleStyle>
      {icon && (
        <div className="title-icon | flex items-center justify-center">
          {icon}
        </div>
      )}
      <Typography>
        <Typography.Title className="header-title truncate">
          {title}
        </Typography.Title>
      </Typography>
    </FormTitleStyle>
  );
};

export default FormTitle;
