import { Badge, Button, Col, Flex, Row, Segmented, Upload } from "antd";
import React from "react";
import AppInfo from "../../Components/AppInfo";
import usa from "../../Assets/images/flags/us.svg";
import { IoNotificationsOutline } from "react-icons/io5";
import {
  HiArrowTrendingDown,
  HiArrowTrendingUp,
  HiOutlineBriefcase,
} from "react-icons/hi2";
import { MdOutlineAspectRatio } from "react-icons/md";
import { HiOutlineViewGrid } from "react-icons/hi";
import { HiOutlineListBullet } from "react-icons/hi2";
import { FaPlus } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { store } from "../../Redux/store";

const Dashboard = () => {
  const props = {
    action: "//jsonplaceholder.typicode.com/posts/",
    listType: "picture",
    previewFile(file) {
      console.log("Your upload file:", file);
      // Your process logic. Here we just mock to the same file
      return fetch("https://next.json-generator.com/api/json/get/4ytyBoLK8", {
        method: "POST",
        body: file,
      })
        .then((res) => res.json())
        .then(({ thumbnail }) => thumbnail);
    },
  };

  const { t } = useTranslation();
  const user = store.getState()?.user;

  return (
    <Flex className="w-full min-h-dvh">
      <div className="px-4 xl:px-16 w-full bg-(--dashboard-bg)">
        <div className="flex flex-wrap md:items-center md:justify-between pt-9 pb-11">
          <div className="flex items-center">
            <div className="w-[44px] h-[44px] md:w-[64px] md:h-[64px] drop-shadow-md bg-[#ffffff] flex items-center justify-center rounded-xl shrink-0">
              <img src={`/Signals/${user?.latencyData?.status}.svg`} alt="" />
            </div>
            <div className="ms-3">
              <h1 className="text-[1rem] md:text-[40px] font-extrabold leading-[normal] ff-urbanist heading-text-clr">
                {user?.userProfile?.nox_admin
                  ? "NOX"
                  : user?.userProfile?.tenant?.name}
              </h1>
              {user?.userProfile?.tenant?.primary_domain && (
                <p className="text-project-base/80">
                  Website: {user?.userProfile?.tenant?.primary_domain}
                </p>
              )}
            </div>
          </div>
          <button
            type="button"
            className="w-[40px] md:w-[56px] aspect-square grid place-items-center transition-all duration-150 border-solid rounded-full border-2 border-grey-full hover:bg-grey-full ms-auto"
          >
            <MdOutlineAspectRatio size={24} />
          </button>
        </div>

        <section className="mt-9">
          <Row gutter={16}>
            <Col span={24} xl={12}>
              <Row gutter={16}>
                <Col span={24} lg={12} xl={12} className="mb-5">
                  <div className="p-6 dashboard-card rounded-2xl  h-full">
                    <p className=" text-end font-light mb-5">NO-DB1</p>
                    <Row gutter={16}>
                      <Col md={12} lg={12}>
                        {t("chart")}
                      </Col>
                      <Col md={12} lg={12}>
                        <h4 className="heading-text-clr ff-urbanist font-extrabold text-[32px]">
                          429MB
                        </h4>
                        <p className="font-light mb-7">{t("of")} 2.000MB</p>
                        <p className="font-light">
                          {t("db_region")}:{" "}
                          <img
                            src={usa}
                            alt="usa"
                            className="h-[24px] w-[24px] drop-shadow-lg rounded-full inline-block align-middle mx-2 border-2 border-white border-solid"
                          />
                          <span className="text-(--input-field-text-clr)">
                            VS
                          </span>
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col span={24} lg={12} xl={12} className="mb-5">
                  <div className="p-6 dashboard-card rounded-2xl  h-full">
                    <p className=" text-end font-light mb-5">
                      {t("api")} {t("req")}
                    </p>
                    <Row gutter={16}>
                      <Col md={12} lg={12}>
                        {t("chart")}
                      </Col>
                      <Col md={12} lg={12}>
                        <h4 className="heading-text-clr ff-urbanist font-extrabold text-[32px]">
                          9.426x
                        </h4>
                        <p className="font-light mb-7">{t("of")} 10.000</p>
                        <Button className="project-custom-btn bg-project-base leading-3">
                          {t("upgrade")}
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col span={24} className="mb-5">
                  <div className="p-6 dashboard-card rounded-2xl  h-full">
                    <div className="flex items-center">
                      <ul className="me-auto">
                        <li className="inline-flex items-center">
                          <h4 className="heading-text-clr ff-urbanist font-extrabold text-[24px] me-2">
                            {t("visitors")}
                          </h4>
                          <span className="text-[#80C6A8] text-[18px] font-extrabold inline-flex gap-2 items-center">
                            <HiArrowTrendingUp size={24} />
                            12%
                          </span>
                        </li>
                        <li className="inline-flex items-center last:ms-6">
                          <h4 className="heading-text-clr ff-urbanist font-extrabold text-[24px] me-2">
                            {t("clicks")}
                          </h4>
                          <span className="text-[#D04851] text-[18px] font-extrabold inline-flex gap-2 items-center">
                            <HiArrowTrendingDown size={24} />
                            0.7%
                          </span>
                        </li>
                      </ul>
                      <p className=" text-end font-light">
                        {t("per")} {t("month")}
                      </p>
                    </div>
                    <Row gutter={16}>
                      <Col md={12} lg={12}>
                        {t("chart")}
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col span={24} xl={12}>
              <Row gutter={16}>
                <Col span={24} lg={12} xl={12} className="mb-5">
                  <div className="upgrade-card dashboard-card p-6  rounded-2xl shadow-inner border border-solid border-grey-full h-full relative overflow-hidden">
                    {/* <img
                      src={box}
                      alt=""
                      className=""
                    /> */}
                    <svg
                      width="209"
                      height="231"
                      viewBox="0 0 209 231"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="absolute -bottom-4 -right-4 z-0 pointer-events-none upgrade-card-svg"
                    >
                      <mask
                        id="mask0_218_535"
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="209"
                        height="231"
                      >
                        <g clipPath="url(#clip0_218_535)">
                          <path d="M208.403 126.117L192.278 92.6749H192.255C191.57 91.2537 190.346 90.1017 188.775 89.5631L122.88 66.4355V79.3965L168.262 95.3463L104.5 117.714L40.7349 95.3463L86.1203 79.3965V66.4122L20.2003 89.5648C18.6573 90.1034 17.407 91.2553 16.7203 92.6766L0.596915 126.117C-0.138006 127.685 -0.211165 129.522 0.475536 131.116C1.13563 132.733 2.45916 133.981 4.10358 134.57L16.1101 138.784V196.015C16.1101 198.612 17.7511 200.916 20.202 201.797L102.468 230.658C103.128 230.902 103.815 231 104.502 231C105.188 231 105.873 230.902 106.509 230.658L188.775 201.797C191.226 200.916 192.867 198.612 192.867 196.015V138.784L204.9 134.57C206.541 133.981 207.864 132.733 208.528 131.116C209.213 129.523 209.141 127.685 208.403 126.117ZM14.6136 125.284L25.373 102.916L96.0218 127.71L85.2391 150.078L14.6136 125.284ZM98.3746 216.25L28.3609 191.678V143.096L86.3648 163.431C87.0282 163.675 87.7116 163.798 88.375 163.798C90.6778 163.798 92.8593 162.5 93.9135 160.319L98.3746 151.033V216.25ZM180.614 191.678L110.627 216.25V151.033L115.085 160.319C116.141 162.5 118.32 163.798 120.625 163.798C121.285 163.798 121.972 163.677 122.634 163.431L180.614 143.096V191.678ZM123.761 150.078L113.003 127.71L183.629 102.916L194.385 125.284L123.761 150.078Z" />
                          <path d="M81.8838 37.3951L98.3729 20.9101V91.5978C98.3729 94.9806 101.115 97.7234 104.498 97.7234C107.882 97.7234 110.625 94.9822 110.625 91.5978V20.9101L127.115 37.3951C128.312 38.592 129.881 39.1888 131.446 39.1888C133.011 39.1888 134.582 38.5903 135.777 37.3951C138.17 35.0031 138.17 31.1266 135.777 28.7345L108.83 1.79363C106.437 -0.598425 102.56 -0.598425 100.167 1.79363L73.221 28.7329C70.8284 31.1249 70.8284 35.0014 73.221 37.3935C75.6136 39.7855 79.4911 39.7872 81.8838 37.3951Z" />
                        </g>
                      </mask>
                      <g mask="url(#mask0_218_535)">
                        <rect
                          x="-149"
                          y="-260"
                          width="350"
                          height="477"
                          rx="16"
                          fill=""
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_218_535">
                          <rect width="209" height="231" fill="" />
                        </clipPath>
                      </defs>
                    </svg>
                    <div className="relative flex flex-wrap flex-col h-full">
                      <p className="font-light mb-3 lg:mb-10">
                        {t("current")} {t("agreement")}
                      </p>
                      <h3 className="text-[32px] font-extrabold leading-[normal] ff-urbanist heading-text-clr">
                        Charlie
                      </h3>
                      <p className="font-light mb-3 lg:mb-5 text-[16px] heading-text-clr">
                        2.000MB / 10.000 {t("req")} p.m.
                      </p>
                      <p className="font-light mb-3 lg:mb-3">
                        {t("next")} {t("upgrade")}
                      </p>
                      <h3 className="text-[32px] font-extrabold leading-[normal] ff-urbanist">
                        Delta
                      </h3>
                      <p className="font-light mb-3 lg:mb-10 xl:mb-24 text-[16px]">
                        5.000MB / 50.000 {t("req")} p.m.
                        <br />+ {t("choice")} {t("region")} {t("database")}
                      </p>
                      <button
                        type="button"
                        className="rounded-[28px] mt-auto bg-project-base text-project-base-text px-7 transition-all duration-200 py-4 text-base font-medium hover:bg-project-base/90"
                      >
                        {t("upgrade")} {t("to")} Delta
                      </button>
                    </div>
                  </div>
                </Col>
                <Col span={24} lg={12} xl={12} className="mb-5">
                  <Row gutter={16}>
                    <Col
                      span={24}
                      md={8}
                      lg={12}
                      xl={24}
                      xxl={8}
                      className="mb-5"
                    >
                      <a
                        href="#"
                        className="p-6 dashboard-card rounded-2xl  text-center block hover:drop-shadow-xl"
                      >
                        <h4 className="ff-urbanist text-[32px] font-extrabold heading-text-clr">
                          31
                        </h4>
                        <p className="font-light">{t(`page's`)}</p>
                      </a>
                    </Col>
                    <Col
                      span={24}
                      md={16}
                      lg={12}
                      xl={24}
                      xxl={16}
                      className="mb-5"
                    >
                      <div className="p-6 inverted-dashboard-card rounded-2xl flex items-center">
                        <div className="me-auto">
                          <h4 className="ff-urbanist text-[32px] font-extrabold title">
                            17
                          </h4>
                          <p className="font-light">{t("notifications")}</p>
                        </div>

                        <Button
                          className="rounded-full! bell h-[48px]! w-[48px]! grid! place-items-center shrink-0"
                          icon={
                            <Badge
                              dot={true}
                              status="success"
                              className="active-status"
                            >
                              <IoNotificationsOutline size={24} />
                            </Badge>
                          }
                        ></Button>
                      </div>
                    </Col>
                    <Col
                      span={24}
                      md={16}
                      lg={12}
                      xl={24}
                      xxl={16}
                      className="mb-5"
                    >
                      <div className="p-6 dashboard-card rounded-2xl flex items-center">
                        <div className="me-auto">
                          <h4 className="ff-urbanist text-[32px] font-extrabold heading-text-clr">
                            62
                          </h4>
                          <p className="font-light">{t("cases")}</p>
                        </div>
                        <Button
                          className="rounded-full h-[48px]! w-[48px]! grid place-items-center cases-icon hover:bg-project-base-text!"
                          icon={
                            <HiOutlineBriefcase
                              size={24}
                              color="var(--project-base)"
                            />
                          }
                        ></Button>
                      </div>
                    </Col>
                    <Col
                      span={24}
                      md={8}
                      lg={12}
                      xl={24}
                      xxl={8}
                      className="mb-5"
                    >
                      <a
                        href="#"
                        className="p-6 dashboard-card rounded-2xl  text-center block hover:drop-shadow-xl"
                      >
                        <h4 className="ff-urbanist text-[32px] font-extrabold heading-text-clr">
                          192
                        </h4>
                        <p className="font-light">{t("blogs")}</p>
                      </a>
                    </Col>
                    <Col span={24} className="mb-5">
                      <a
                        href="#"
                        className="p-6 dashboard-card rounded-2xl  block hover:drop-shadow-xl"
                      >
                        <h4 className="ff-urbanist text-[32px] font-extrabold heading-text-clr">
                          14
                        </h4>
                        <p className="font-light">{t("users")}</p>
                      </a>
                    </Col>
                    <Col
                      span={24}
                      md={8}
                      lg={12}
                      xl={24}
                      xxl={8}
                      className="mb-5 3xl:mb-0"
                    >
                      <a
                        href="#"
                        className="p-6 dashboard-card rounded-2xl  text-center block hover:drop-shadow-xl"
                      >
                        <h4 className="ff-urbanist text-[32px] font-extrabold heading-text-clr">
                          3
                        </h4>
                        <p className="font-light">{t("vacancies")}</p>
                      </a>
                    </Col>
                    <Col span={24} md={8} lg={12} xl={24} xxl={8} className="">
                      <a
                        href="#"
                        className="p-6 dashboard-card rounded-2xl  text-center block hover:drop-shadow-xl"
                      >
                        <h4 className="ff-urbanist text-[32px] font-extrabold heading-text-clr">
                          12
                        </h4>
                        <p className="font-light">{t("partners")}</p>
                      </a>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="mt-6">
            <Col span={24} xxl={18}>
              <div className="flex items-center">
                <div className="me-auto">
                  <h4 className="heading-text-clr ff-urbanist font-extrabold text-[24px] inline-block me-2">
                    {t("media")}
                  </h4>
                  <Segmented
                    options={[
                      {
                        value: "Kanban",
                        icon: <HiOutlineViewGrid size={16} />,
                      },
                      {
                        value: "List",
                        icon: <HiOutlineListBullet size={18} />,
                      },
                    ]}
                  />
                </div>
                <Upload {...props}>
                  <Button
                    icon={<FaPlus style={{ background: "transparent" }} />}
                    className="project-custom-btn"
                  >
                    {t("upload")}
                  </Button>
                </Upload>
              </div>
            </Col>
          </Row>
        </section>
      </div>
      <AppInfo
        visibleList={["information"]}
        config={{
          msg: t("information_dashboard"),
        }}
      />
    </Flex>
  );
};

export default Dashboard;
