import React, { useEffect, useState } from "react";
import { Button, Collapse, Row } from "antd";
import DynamicForm from "../DynamicForm";
import { unflatten } from "flat";
import { AiOutlineDelete, AiOutlinePlus } from "react-icons/ai";
import { isEqual } from "lodash";

const RecordForm = ({
  item,
  updateFormData,
  childFields = [],
  recordIndex,
  formData,
  initialData,
  validationErrors,
  language,
}) => {
  const [nestedformData, setNestedFormData] = useState(initialData);

  useEffect(() => {
    let currentItem = formData?.[item.path];
    if (currentItem) {
      if (!isEqual(currentItem[recordIndex], nestedformData)) {
        currentItem[recordIndex] = nestedformData;
        updateFormData(currentItem ? currentItem : []);
      }
    }
  }, [nestedformData]);

  useEffect(() => {
    if (JSON.stringify(nestedformData) !== JSON.stringify(initialData)) {
      setNestedFormData(initialData);
    }
  }, [initialData]);

  return (
    <Row
      justify={"space-between"}
      gutter={16}
      className={"my-2 border-black remove-sibling-padding"}
    >
      <DynamicForm
        convertedForm={childFields}
        setFormData={setNestedFormData}
        formData={nestedformData}
        validationErrors={validationErrors}
        language={language}
        isFromItems
      />
    </Row>
  );
};

const CustomItems = ({
  item,
  updateFormData,
  formData,
  validationErrors,
  fieldName,
  language,
}) => {
  const baseKey = item.key;
  const records = formData?.[item.path] || [];
  const handleAddRecord = () => {
    updateFormData([...records, {}]);
  };

  const getNewPath = (fieldPath, baseKey) => {
    if (fieldPath.startsWith(`${baseKey}.`)) {
      return fieldPath.replace(`${baseKey}.`, "");
    }
    // Get the last parent of baseKey (i.e., last part after splitting)
    const baseParts = baseKey.split(".");
    const lastParent = baseParts[baseParts.length - 1];

    if (fieldPath.startsWith(`${lastParent}.`)) {
      return fieldPath.replace(`${lastParent}.`, "");
    }

    return fieldPath; // If no match, return original path
  };

  const transformFieldPaths = (fields) => {
    return fields?.map((field) => {
      // Removed Base Path From Items
      let newPath = getNewPath(field.path, baseKey);
      return {
        ...field,
        path: newPath,
        children: field.children ? transformFieldPaths(field.children) : [],
      };
    });
  };

  const removeRecordWithIndex = (index) => {
    let currentItem = [...records];
    if (currentItem) {
      currentItem.splice(index, 1);
    }
    updateFormData(currentItem ? currentItem : []);
  };

  return (
    <>
      <div
        className={`flex justify-between items-center py-[10px] px-5 border
        border-project-base/50 bg-project-base/10 rounded-xl ${records.length && "rounded-bl-none rounded-br-none"}`}
      >
        <span>
          {fieldName}{" "}
          {item?.meta?.interface === "items" && (
            <span className="text-xs text-project-base/60">(items)</span>
          )}
        </span>
        <Button
          shape={"circle"}
          icon={<AiOutlinePlus />}
          onClick={handleAddRecord}
        ></Button>
      </div>
      <Collapse
        className={
          records.length === 0
            ? "hidden"
            : "rounded-tl-none rounded-tr-none border-project-base/50 border-t-0"
        }
      >
        {records.map((record, index) => {
          if (record?._id) {
            delete record._id;
          }
          return (
            <Collapse.Panel
              header={`${fieldName} ${index + 1}`}
              key={index}
              className="content-items-collapse"
              extra={
                <Button
                  shape={"circle"}
                  icon={<AiOutlineDelete className="text-heading-text" />}
                  className={
                    "bg-delete-base/50 border-delete-base hover:bg-delete-base/80! hover:border-delete-base! ml-4"
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    removeRecordWithIndex(index);
                  }}
                ></Button>
              }
            >
              <RecordForm
                key={index}
                recordIndex={index}
                item={item}
                formData={formData}
                updateFormData={updateFormData}
                initialData={record}
                childFields={transformFieldPaths(item?.children)}
                validationErrors={
                  unflatten(validationErrors)?.[language?.field_path]?.[
                    language?.selectedLanguage
                  ]?.[item.path]?.[index] || {}
                }
                language={language}
              />
            </Collapse.Panel>
          );
        })}
      </Collapse>
    </>
  );
};

export default CustomItems;
