import React, { useEffect, useState } from "react";
import { Flex, Spin } from "antd";
import LeftSideTitlePart from "../../../Components/HeaderParts/LeftSideTitlePart";
import RightSIdeTitlePart from "../../../Components/HeaderParts/RightSIdeTitlePart";
import IconsAll from "../../../Components/IconsAll";
import { Link, useNavigate } from "react-router-dom";
import AppInfo from "../../../Components/AppInfo";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setActiveTab } from "../../../Redux/slices/activeTab.reducer";
import Apis from "../../../Helpers/Apis";
import axiosInstance from "../../../Helpers/axios";
import AddEditRole from "./AddEditRole";
import { DrawerStyle, TableStriped } from "../../../Common/CommonUiStyles";
import NoData from "../../../Components/NoData";

const Toegangscontrole = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [loader, setLoader] = useState(true);
  const path = location.pathname.split("/");

  const columns = [
    {
      title: t("role"),
      dataIndex: "name",
      key: "name",
      render: (record, rowData) => (
        <Link
          to={`/settings/toegangscontrole/${rowData?._id}`}
          onClick={() => {
            dispatch(
              setActiveTab({
                page: "settings",
                tab: path[2],
                redirectionLink: "/settings/toegangscontrole",
              }),
            );
          }}
        >
          {record}
        </Link>
      ),
    },
    {
      title: t("description"),
      dataIndex: "description",
      key: "description",
      render: (record) => {
        return (
          <>
            <span className="opacity-40">{record}</span>
          </>
        );
      },
    },
    {
      title: "Access",
      dataIndex: "tenant",
      key: "tenant",
      render: (record) => {
        return <span className="">{record ? "Management" : "API"}</span>;
      },
    },
  ];

  const [roles, setRoles] = useState([]);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    setLoader(true);
    try {
      const resp = await axiosInstance.get(Apis.GET_ALL_ROLES("true"));
      if (resp.status === 200) setRoles(resp.data.payload.data.data);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoader(false);
    }
  };

  const handleAddRoleClick = () => {
    navigation("/settings/toegangscontrole/add-role");
  };

  return (
    <Flex className="w-full main-content-wrap">
      <div className="px-4 xl:px-16 w-full left-part">
        <div className="primary-header pt-9 pb-5">
          <LeftSideTitlePart
            pageTitle={t("access_control")}
            pageSubTitle={t("setting")}
            pageIcon={true}
            pageInnerIcon={<IconsAll.ToegangsControleIcon />}
            backButton={false}
          />
          <RightSIdeTitlePart
            addButton={true}
            // setIsDrawerOpen={setIsDrawerOpen}
            handleAddClick={handleAddRoleClick}
          />
          <DrawerStyle
            width={870}
            open={isDrawerOpen}
            closeIcon={<IconsAll.CloseIconCustom />}
            onClose={() => {
              setIsDrawerOpen(false);
            }}
          >
            <AddEditRole setIsDrawerOpen={setIsDrawerOpen} init={init} />
          </DrawerStyle>
        </div>

        <section className="mt-4">
          <TableStriped
            columns={columns}
            dataSource={roles}
            pagination={false}
            rowKey={(record) => record?._id}
            scroll={{
              x: 300,
            }}
            locale={{
              emptyText: <NoData title={t("no_data_found")} />,
            }}
            loading={{
              spinning: loader,
              indicator: <Spin indicator={<div className="custom-loader" />} />,
            }}
          />
        </section>
      </div>
      <AppInfo
        visibleList={["information"]}
        config={{
          msg: t("information_access_control"),
        }}
      />
    </Flex>
  );
};

export default Toegangscontrole;
