import IconsAll from "../../../../../Components/IconsAll";
import React, { useCallback, memo } from "react";
import {
  getConnectedEdges,
  Position,
  useEdges,
  useReactFlow,
} from "@xyflow/react";
import TargetHandleWithValidation from "../TargetHandleWithValidation";
import { Edit2Icon, Trash2Icon } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import {
  handleAddDeleteOperation,
  handleFlowActions,
} from "../../../../../Redux/slices/flows.reducer";
import { IoIosArrowRoundForward } from "react-icons/io";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { Skeleton, Switch } from "antd";
import { useTranslation } from "react-i18next";

const OperationHandles = ({ sourceId, sourcePosition }) => {
  const dispatch = useDispatch();

  const handleOperationClick = (handleId) => {
    const actionData = {
      data: {},
      flag: "operation",
      nodeId: "",
      source: {
        id: sourceId,
        position: sourcePosition,
        handleId: handleId,
      },
    };

    dispatch(handleFlowActions(actionData));
  };

  return (
    <>
      <TargetHandleWithValidation
        type={"source"}
        position={Position.Right}
        id={"resolve"}
        style={{ top: "70%" }}
        hint={
          <div className="svg-arrow flex items-center absolute right-[-57px] top-[63%]">
            <IoIosArrowRoundForward
              size={34}
              className="fill-project-base me-[-4px]"
            />
            <AiOutlinePlusCircle size={24} className="fill-project-base" />
          </div>
        }
        onClick={() => handleOperationClick("resolve")}
      />
      <TargetHandleWithValidation
        type={"source"}
        position={Position.Right}
        id={"reject"}
        style={{ top: "80%", right: "2px" }}
        hint={
          <div className="svg-arrow flex items-center absolute right-[-57px] top-[73.6%]">
            <IoIosArrowRoundForward
              size={34}
              className="fill-delete-base/70 me-[-4px]"
            />
            <AiOutlinePlusCircle size={24} className="fill-delete-base/70" />
          </div>
        }
        onClick={() => handleOperationClick("reject")}
      />
      <TargetHandleWithValidation
        type={"target"}
        position={Position.Left}
        id={"target"}
      />
    </>
  );
};

const TriggerHandles = ({ sourceId, sourcePosition }) => {
  const dispatch = useDispatch();

  const handletriggerClick = () => {
    dispatch(
      handleFlowActions({
        data: {},
        flag: "operation",
        nodeId: "",
        source: {
          id: sourceId,
          position: sourcePosition,
          handleId: "trigger",
        },
      }),
    );
  };

  return (
    <>
      <TargetHandleWithValidation
        type={"source"}
        id={"trigger"}
        position={Position.Right}
        onClick={handletriggerClick}
        style={{ top: "70%" }}
        hint={
          <div className="svg-arrow flex items-center absolute right-[-57px] top-[63%]">
            <IoIosArrowRoundForward
              size={34}
              className="fill-project-base me-[-4px]"
            />
            <AiOutlinePlusCircle size={24} className="fill-project-base" />
          </div>
        }
      />
    </>
  );
};

const Slide = ({ id, data = {}, positionAbsoluteX, positionAbsoluteY }) => {
  const metaData = data?.options ?? {};
  const { t } = useTranslation();

  const schemas = useSelector((state) => state.schemas.schemas);
  const { isCanvasDisabled } = useSelector((state) => state.flows);
  const dispatch = useDispatch();
  const { setNodes, setEdges } = useReactFlow();
  const edges = useEdges();
  const sourcePosition = {
    x: positionAbsoluteX,
    y: positionAbsoluteY,
  };

  const handleDeleteClick = useCallback(() => {
    setNodes((prevNodes) => {
      const nodeToDelete = prevNodes.find((node) => node.id === id);
      if (!nodeToDelete) return prevNodes;

      const connectedEdgeIds = getConnectedEdges([nodeToDelete], edges).map(
        (edge) => edge.id,
      );

      setEdges((prevEdges) =>
        prevEdges.filter((edge) => !connectedEdgeIds.includes(edge.id)),
      );

      return prevNodes.filter((node) => node.id !== id);
    });

    dispatch(handleAddDeleteOperation({ nodeId: id }));
  }, [id, edges, setNodes, setEdges, dispatch]);

  const isTrigger = "operation" in data;

  const handleChangeNodeData = useCallback(
    (data) => {
      setNodes((prevNodes) =>
        prevNodes.map((node) => {
          if (node.id === id) {
            return {
              ...node,
              data: {
                ...node.data,
                ...data,
              },
            };
          }
          return node;
        }),
      );
    },
    [id],
  );

  return (
    <>
      {isCanvasDisabled ? (
        <div className={"workspace-flow-card"}>
          <Skeleton active />
        </div>
      ) : (
        <div
          className={
            isTrigger
              ? "workspace-flow-card group"
              : "workspace-flow-card group"
          }
        >
          <div className={"flex items-center gap-2"}>
            {isTrigger ? (
              <div className={"flex justify-between w-full"}>
                <div className="flex gap-2">
                  <IconsAll.FlowsIcon />
                  <h2 className="flow-card-title">Trigger</h2>
                </div>
                <div className={"flex gap-2"}>
                  <Edit2Icon
                    onClick={() => {
                      dispatch(
                        handleFlowActions({
                          data: { ...data },
                          flag: "trigger",
                          nodeId: id,
                          source: {},
                        }),
                      );
                    }}
                    size={14}
                    className="w-4 h-6 cursor-pointer opacity-60 hover:opacity-100"
                  />
                </div>
              </div>
            ) : (
              <div className={"flex justify-between w-full"}>
                <div className="flex gap-2">
                  <IconsAll.FlowsIcon />
                  <h2 className="flow-card-title">Operation</h2>
                </div>
                <div className={"flex gap-2"}>
                  <Edit2Icon
                    onClick={() => {
                      dispatch(
                        handleFlowActions({
                          data: { ...data },
                          flag: "operation",
                          nodeId: id,
                          source: {},
                        }),
                      );
                    }}
                    size={14}
                    className="w-4 h-6 cursor-pointer opacity-60 hover:opacity-100"
                  />
                  <Trash2Icon
                    onClick={handleDeleteClick}
                    size={14}
                    className="w-4 h-6 cursor-pointer opacity-60 hover:opacity-100"
                  />
                </div>
              </div>
            )}
          </div>
          {data?.type && (
            <span className="text-project-base block text-sm mt-1 opacity-70">
              {data.type}
            </span>
          )}
          <p className="text-heading-text my-3 text-sm leading-[1.3]">
            {data.name ?? ""}
          </p>
          {Object.keys(metaData)
            .filter(
              (key) =>
                typeof metaData[key] === "string" &&
                !["schema_id"].includes(key),
            )
            .slice(0, 2)
            .map((key, index) => (
              <div key={index} className="my-2">
                <p className="text-heading-text">{key}</p>
                <p className="text-heading-text opacity-50 whitespace-nowrap text-ellipsis overflow-hidden block">
                  {key === "collection"
                    ? schemas.find((i) => i?._id === metaData[key])
                        ?.collection_id || ""
                    : metaData[key]}
                </p>
              </div>
            ))}
          {isTrigger && (
            <Switch
              checkedChildren={t("active")}
              unCheckedChildren={t("inactive")}
              value={data?.status === "active"}
              onChange={(checked) => {
                handleChangeNodeData({
                  status: checked ? "active" : "inactive",
                });
              }}
              className="check-status absolute bottom-3 left-5 custom-switch"
            />
          )}
          {"operation" in data ? (
            <TriggerHandles sourceId={id} sourcePosition={sourcePosition} />
          ) : (
            <OperationHandles sourceId={id} sourcePosition={sourcePosition} />
          )}
        </div>
      )}
    </>
  );
};

export default memo(Slide);
