import React, { useEffect, useId } from "react";
import {
  Card,
  Form,
  Row,
  Col,
  Input,
  Avatar,
  Select,
  notification,
  Flex,
} from "antd";
import LeftSideTitlePart from "../../Components/HeaderParts/LeftSideTitlePart";
import RightSIdeTitlePart from "../../Components/HeaderParts/RightSIdeTitlePart";
import styled from "styled-components";
import profileLink from "../../Assets/images/Icon/@-icon.svg";
import AppInfo from "../../Components/AppInfo";
import { useTranslation } from "react-i18next";
import { themes } from "../../config";
import { useSelector } from "react-redux";
import Apis from "../../Helpers/Apis";
import axiosInstance from "../../Helpers/axios";
import withRouter from "../../Common/withRouter";
import { setUserProfile } from "../../Redux/slices/userDetails.reducer";
import { useDispatch } from "react-redux";
import Title from "antd/es/skeleton/Title";
import useLanguage from "../../Helpers/custom-hooks/useLanguage";
import { queryClient } from "../..";
import i18n from "../../i18n";
import { hideLoader, showLoader } from "../../Redux/slices/loader.reducer";

// const suffix = (
//   <IoAddCircleOutline
//     style={{
//       fontSize: 16,
//       color: "#0C2340",
//     }}
//   />
// );

const PersonInfoCard = styled(Card)`
  background-color: var(--input-field-bg);
  border: 1px solid var(--input-field-border);
  border-radius: var(--br-12);
  padding: 29px 32px;
  margin: 34px 0 32px;

  .ant-card-body {
    padding: 0;
    display: flex;
    align-items: center;
    gap: 24px;
    flex-flow: wrap;

    &:after,
    &:before {
      display: none;
    }

    .person-img {
      max-width: 124px;
      aspect-ratio: 1;
      border-radius: 100vh;
      overflow: hidden;
      outline: 1.5px solid var(--White, #fff);
      box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.08);

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .person-info {
      .designation {
        color: ${() =>
          themes[useSelector((state) => state.theme.name)].token.grayClr};
        font-family: ${() =>
          themes[useSelector((state) => state.theme.name)].token.fontFamily};
      }
    }

    .role {
      font-size: 14px;
      font-weight: 300;
      width: fit-content;
      background-color: rgba(0, 133, 202, 0.12);
      color: #0085ca;
      padding: 7px 12px;
      border-radius: 8px;
      margin-top: 12px;
    }
  }
`;

const UserProfile = ({ router }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  // const [profileUrl, setProfileUrl] = useState(personImg);
  const userProfile = useSelector((state) => state.user.userProfile);
  const dispatch = useDispatch();
  const id = useId();

  useEffect(() => {
    setFormData();
  }, [userProfile]);

  const setFormData = async () => {
    await form.setFieldsValue({
      first_name: userProfile.first_name,
      last_name: userProfile.last_name,
      email: userProfile.email,
      language: userProfile?.language || "en",
    });
    dispatch(hideLoader());
    // userProfile?.profile_url && setProfileUrl(userProfile?.profile_url);
  };

  const handleSubmit = async () => {
    try {
      dispatch(showLoader());
      const values = await form.validateFields();
      values.email && delete values.email;
      // const file = values.files[0].originFileObj;

      // const formData = new FormData();
      // formData.append("file", file);

      const resp = await axiosInstance.patch(
        Apis.UPDATE_USER_PROFILE(userProfile._id),
        values,
      );
      if (resp.status === 200) {
        dispatch(setUserProfile(resp.data.payload.data));
        notification.success({ message: t("profile_update_success") });
        router.navigate("/dashboard");
        if (!i18n?.store?.data[values?.language]) {
          queryClient.invalidateQueries({
            queryKey: ["groupedTranslations", values.language],
            exact: true,
          });
        }
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      dispatch(hideLoader());
    }
  };

  // const handleFileUpload = (info) => {
  //   try {
  //     if (info.fileList.length > 0 && info.fileList[0]?.originFileObj) {
  //       const reader = new FileReader();
  //       reader.onload = () => {
  //         // setProfileUrl(e.target.result);
  //       };
  //       reader.readAsDataURL(info.fileList[0].originFileObj);
  //     } else {
  //       // setProfileUrl(personImg);
  //     }
  //   } catch (error) {
  //     console.log("Error", error);
  //     notification.error({ message: "File upload unsuccessful" });
  //   }
  // };

  return (
    <Flex className="w-full">
      <div className="px-4 xl:px-16 w-full">
        <div className="primary-header pt-9 pb-5">
          <LeftSideTitlePart
            pageTitle={t("user_profile")}
            // pageSubTitle={t("users")}
            pageIcon={false}
            // backButton={true}
          />
          <RightSIdeTitlePart
            isFilterShow={false}
            // deleteButton={true}
            // archiveButton={true}
            showButton={true}
            handleShowClick={handleSubmit}
          />
        </div>

        <section className="container max-w-4xl mt-4">
          <PersonInfoCard>
            <div className="person-img shrink-0">
              {/* <img src={profileUrl} alt="" /> */}
              <Avatar>
                {userProfile?.first_name?.length &&
                  (userProfile?.first_name[0] || "")?.toUpperCase() +
                    ((userProfile?.last_name?.length &&
                      (userProfile?.last_name[0] || "")?.toUpperCase()) ||
                      "")}
              </Avatar>
            </div>
            <div className="person-info">
              <Title level={2} className="font-extrabold! text-xl!">
                {userProfile.first_name} {userProfile.last_name}
                {/* <span className="font-light text-base designation">
                  , Founder
                </span> */}
              </Title>
              <a
                href="mailto:ietjeniels@nowonline.nl"
                className="flex items-center mt-1 text-slate-600 leading-7"
              >
                <img
                  src={profileLink}
                  alt="go to profile"
                  className="inline-block mr-2"
                />
                {userProfile.email}
              </a>
              {/* <span className="flex items-center mt-1 text-slate-600 leading-7">
                <img
                  src={srcLocation}
                  alt="go to profile"
                  className="inline-block mr-2"
                />
                Doetinchem
              </span>
              <p className="role">Super Admin</p> */}
            </div>
          </PersonInfoCard>
          <Form layout="vertical" form={form} onFinish={handleSubmit}>
            <Row gutter={28}>
              <Col className="gutter-row" xs={24} md={12}>
                <Form.Item
                  label={t("first_name")}
                  className="font-bold"
                  name={`first_name`}
                  rules={[{ required: true }]}
                >
                  <Input type="text" className="project-custom-input" />
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} md={12}>
                <Form.Item
                  label={t("last_name")}
                  className="font-bold"
                  name={`last_name`}
                  // rules={[{ required: true }]}
                >
                  <Input type="text" className="project-custom-input" />
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} md={12}>
                <Form.Item
                  label={t("email")}
                  className="font-bold"
                  name={`email`}
                >
                  <Input
                    type="email"
                    disabled
                    className="project-custom-input"
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} md={12}>
                <Form.Item label={t("password")} className="font-bold">
                  <Input.Password
                    disabled
                    className="project-custom-input"
                    value={`test1234`}
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} md={12}>
                <Form.Item
                  label={t("language")}
                  className="font-bold"
                  htmlFor={`${id}-language`}
                  name={`language`}
                >
                  <Select
                    id={`${id}-language`}
                    options={useLanguage()}
                    showSearch
                    filterOption={(input, option) =>
                      option?.label
                        ?.toLowerCase()
                        ?.includes(input?.toLowerCase())
                    }
                  />
                </Form.Item>
              </Col>
              {/* <Col className="gutter-row" span={24}>
                <Form.Item
                  name="files"
                  label="Upload"
                  // rules={[{ required: true, message: "Please upload a file!" }]}
                >
                  <Upload
                    name="file"
                    maxCount={1}
                    accept=".jpg,.png"
                    beforeUpload={() => false}
                    onChange={(e) => handleFileUpload(e)}
                  >
                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                  </Upload>
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} md={12}>
                <Form.Item label={t("residence")} className="font-bold">
                  <Input
                    type="text"
                    value="Ietje"
                    className="project-custom-input"
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} md={12}>
                <Form.Item label={t("title")} className="font-bold">
                  <Input
                    type="text"
                    value="Niels"
                    className="project-custom-input"
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={24}>
                <Form.Item
                  label={t("description")}
                  name="TextArea"
                  className="font-bold"
                  rules={[{ message: "Textarea (4 regels)" }]}
                >
                  <Input.TextArea className="rounded-xl" />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={24}>
                <Form.Item label={t("tags")} className="font-bold">
                  <Select
                    mode="multiple"
                    placeholder="jack"
                    style={{ flex: 1 }}
                    options={[
                      { value: "jack", label: "Jack" },
                      { value: "lucy", label: "Lucy" },
                      { value: "Yiminghe", label: "yiminghe" },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={24}>
                <Form.Item label={t("tags")} className="font-bold">
                  <Title
                    level={3}
                    className="project-header-left-side"
                  >
                    <HiOutlineUserCircle className="stroke-primary" />{" "}
                    {t("users")} {t("preferences").toLowerCase()}
                  </Title>
                  <Divider className="border-1 w-full my-2" />
                </Form.Item>
              </Col>
              
              <Col className="gutter-row" xs={24} md={12}>
                <Form.Item
                  label={
                    t("two") + "-" + t("factor") + " " + t("authentication")
                  }
                  className="font-bold"
                >
                  <Checkbox defaultChecked={false} disabled value="Pear">
                    Disabled
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={24}>
                <Form.Item
                  label={t("email_notifications")}
                  className="font-bold"
                >
                  <Checkbox
                    defaultChecked={false}
                    value="Pear"
                    className="align-middle"
                  />
                  <span className="ms-3 align-middle font-normal">
                    {t("send_notifications")}
                  </span>
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={24}>
                <Form.Item label={t("tags")} className="font-bold">
                  <Title
                    level={3}
                    className="project-header-left-side"
                  >
                    <IconsAll.ThemeIcon />
                    {t("theme") + " " + t("setting").toLowerCase()}
                  </Title>
                  <Divider className="border-1 w-full my-2" />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={24}>
                <Form.Item
                  label={t("standard") + " " + t("appearance").toLowerCase()}
                  className="font-bold"
                >
                  <Select
                    placeholder="jack"
                    style={{ flex: 1 }}
                    options={[
                      { value: "jack", label: "Jack" },
                      { value: "lucy", label: "Lucy" },
                      { value: "Yiminghe", label: "yiminghe" },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={24}>
                <StandardLightTheme />
              </Col>
              <Col className="gutter-row" span={24}>
                <span className="flex items-center gap-1 text-base font-extrabold">
                  {t("light") + " " + t("theme") + " " + t("customization")}
                </span>
              </Col>
              <Col className="gutter-row" span={24}>
                <LightThemeCustomization />
              </Col>
              <Col className="gutter-row mt-5" span={24}>
                <StandardDarkTheme />
              </Col>
              <Col className="gutter-row" span={24}>
                <span className="flex items-center gap-1 text-base font-extrabold">
                  {t("dark") +
                    " " +
                    t("theme").toLowerCase() +
                    " " +
                    t("customization")}
                </span>
              </Col>
              <Col className="gutter-row" span={24}>
                <LightThemeCustomization />
              </Col>

              <Col className="gutter-row mt-6" span={24}>
                <Form.Item label="" className="font-bold text-red-500">
                  <Title
                    level={3}
                    className="project-header-left-side"
                  >
                    <HiOutlineShieldCheck className="stroke-primary" />
                    {t("admin") + " " + t("options")}
                  </Title>
                  <Divider className="border-1 w-full my-2" />
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24} md={12}>
                <Form.Item label={t("status")} className="font-bold">
                  <Select
                    placeholder="Actief"
                    style={{ flex: 1 }}
                    options={[
                      { value: "Actief", label: "Actief" },
                      { value: "lucy", label: "Lucy" },
                      { value: "Yiminghe", label: "yiminghe" },
                    ]}
                  />
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24} md={12}>
                <Form.Item label={t("role")} className="font-bold">
                  <Select
                    placeholder="Super Admin"
                    style={{ flex: 1 }}
                    options={[
                      { value: "Super Admin", label: "Super Admin" },
                      { value: "lucy", label: "Lucy" },
                      { value: "Yiminghe", label: "yiminghe" },
                    ]}
                  />
                </Form.Item>
              </Col>

              <Col className="gutter-row" span={24}>
                <Form.Item label={t("role")} className="font-bold">
                  <Input
                    placeholder={t("token_msg")}
                    suffix={suffix}
                    style={{ flex: 1, height: 54 }}
                  />
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24} md={12}>
                <Form.Item label={t("provider")} className="font-bold">
                  <Select
                    placeholder="Default"
                    style={{ flex: 1 }}
                    options={[
                      { value: "Default", label: "Default" },
                      { value: "lucy", label: "Lucy" },
                      { value: "Yiminghe", label: "yiminghe" },
                    ]}
                  />
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24} md={12}>
                <Form.Item
                  label={t("external") + " " + t("identification")}
                  className="font-bold"
                >
                  <Input
                    placeholder={
                      t("placeholder") + " " + t("for") + " " + t("input")
                    }
                    style={{ flex: 1, height: 54 }}
                  />
                </Form.Item>
              </Col> */}
            </Row>
          </Form>
        </section>
      </div>
      <AppInfo
        visibleList={["information"]}
        config={{
          msg: t("information_user_profile"),
        }}
      />
    </Flex>
  );
};

export default withRouter(UserProfile);
