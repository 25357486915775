import { Select } from "antd";
import React, { useEffect } from "react";
import { getTranslatedLabel } from "../../../Helpers/utils";

const CustomDropdownMultiple = ({
  item,
  updateFormData,
  formData,
  isValidationError,
}) => {
  useEffect(() => {
    if (formData?.[item.path] === undefined) {
      updateFormData(item?.schema_definition?.default || []);
    }
  }, [formData?.[item.path]]);
  return (
    <>
      <Select
        mode="multiple"
        options={item?.meta?.options?.choices?.sort(
          (a, b) => a?.sort - b?.sort,
        )}
        placeholder={getTranslatedLabel(item?.meta?.options?.placeholder)}
        maxTagCount={`responsive`}
        value={formData?.[item.path]}
        filterOption={(input, option) =>
          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
        }
        onChange={(value) => updateFormData(value)}
        disabled={!!item?.meta?.readonly}
        className={`${isValidationError ? "border-[red]" : ""}`}
      />
    </>
  );
};

export default CustomDropdownMultiple;
