import React, { useEffect, useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";
import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay,
  MeasuringStrategy,
  defaultDropAnimation,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import {
  flattenTree,
  getProjection,
  getChildCount,
  removeChildrenOf,
} from "./utilities";
import { SortableTreeItem } from "./SortableTreeItem";
import {
  updateSchemaById,
  updateSchemaSort,
} from "../../Redux/slices/schema.reducer";
import { useDispatch } from "react-redux";
import { notification } from "antd";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../Helpers/axios";
import Apis from "../../Helpers/Apis";

const measuring = {
  droppable: {
    strategy: MeasuringStrategy.Always,
  },
};

const dropAnimation = {
  ...defaultDropAnimation,
  dragSourceOpacity: 0.5,
};

const DataModalSort = ({
  collapsible = true,
  items,
  indicator,
  indentationWidth = 20,
  openUpdateFolderModal,
  handleMenuClick,
}) => {
  // const [items, setItems] = useState(() => dataSource);
  const [activeId, setActiveId] = useState(null);
  const [overId, setOverId] = useState(null);
  const [offsetLeft, setOffsetLeft] = useState(0);
  const [currentPosition, setCurrentPosition] = useState(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const flattenedItems = useMemo(() => {
    const flattenedTree = flattenTree(items);
    const collapsedItems = flattenedTree.reduce(
      (acc, { children, meta, _id }) => {
        return meta?.collapse === "open" && children.length > 0
          ? [...acc, _id]
          : acc;
      },
      [],
    );

    return removeChildrenOf(
      flattenedTree,
      activeId ? [activeId, ...collapsedItems] : collapsedItems,
    );
  }, [activeId, items]);
  const projected =
    activeId && overId
      ? getProjection(
          flattenedItems,
          activeId,
          overId,
          offsetLeft,
          indentationWidth,
        )
      : null;
  const sensorContext = useRef({
    items: flattenedItems,
    offset: offsetLeft,
  });
  const sensors = useSensors(
    useSensor(PointerSensor),
    // useSensor(KeyboardSensor, {
    //   coordinateGetter,
    // })
  );

  const sortedIds = useMemo(
    () => flattenedItems.map(({ _id }) => _id),
    [flattenedItems],
  );
  const activeItem = activeId
    ? flattenedItems.find(({ _id }) => _id === activeId)
    : null;

  useEffect(() => {
    sensorContext.current = {
      items: flattenedItems,
      offset: offsetLeft,
    };
  }, [flattenedItems, offsetLeft]);

  const announcements = {
    onDragStart(id) {
      return `Picked up ${id}.`;
    },
    onDragMove(id, overId) {
      return getMovementAnnouncement("onDragMove", id, overId);
    },
    onDragOver(id, overId) {
      return getMovementAnnouncement("onDragOver", id, overId);
    },
    onDragEnd(id, overId) {
      return getMovementAnnouncement("onDragEnd", id, overId);
    },
    onDragCancel(id) {
      return `Moving was cancelled. ${id} was dropped in its original position.`;
    },
  };

  const updateSort = async (payload) => {
    try {
      dispatch(updateSchemaSort(payload));
      await axiosInstance.patch(Apis.UPDATE_SCHEMA_SORT(), payload);
    } catch (error) {
      notification.error({ message: t("something_went_wrong") });
    }
  };

  function handleDragStart({ active: { id: activeId } }) {
    setActiveId(activeId);
    setOverId(activeId);

    const activeItem = flattenedItems.find(({ _id }) => _id === activeId);
    if (activeItem) {
      setCurrentPosition({
        parent: activeItem.parent,
        overId: activeId,
      });
    }

    document.body.style.setProperty("cursor", "grabbing");
  }

  function handleDragMove({ delta }) {
    setOffsetLeft(delta.x);
  }

  function handleDragOver({ over }) {
    setOverId(over?.id ?? null);
  }

  function handleDragEnd({ active, over }) {
    resetState();

    if (projected && over) {
      const { depth, parent } = projected;
      const clonedItems = JSON.parse(JSON.stringify(flattenTree(items)));
      const overIndex = clonedItems.findIndex(({ _id }) => _id === over.id);
      const activeIndex = clonedItems.findIndex(({ _id }) => _id === active.id);
      const activeTreeItem = clonedItems[activeIndex];
      clonedItems[activeIndex] = { ...activeTreeItem, depth, parent };

      let position;
      if (overIndex === 0 && activeIndex !== overIndex) {
        position = "before";
      } else if (overIndex > activeIndex) {
        position = "after";
      } else {
        position = "before";
      }

      updateSort({
        draggedItemId: active?.id,
        newParentId: parent,
        position,
        targetItemId: over.id,
      });
    }
  }

  function handleDragCancel() {
    resetState();
  }

  function resetState() {
    setOverId(null);
    setActiveId(null);
    setOffsetLeft(0);
    setCurrentPosition(null);

    document.body.style.setProperty("cursor", "");
  }

  function handleCollapse(item) {
    const collapseState = item?.meta?.collapse === "open" ? "closed" : "open";

    // const toggleCollapseForChildren = (currentItem, collapseState) => {
    //   if (currentItem?.children?.length > 0) {
    //     currentItem.children.forEach((child) => {
    //       dispatch(
    //         updateSchemaById({
    //           id: child?._id,
    //           updatedData: {
    //             "meta.collapse": collapseState,
    //           },
    //         }),
    //       );
    //       toggleCollapseForChildren(child, collapseState);
    //     });
    //   }
    // };
    // toggleCollapseForChildren(item, collapseState);

    dispatch(
      updateSchemaById({
        id: item?._id,
        updatedData: {
          "meta.collapse": collapseState,
        },
      }),
    );
  }

  function getMovementAnnouncement(eventName, activeId, overId) {
    if (overId && projected) {
      if (eventName !== "onDragEnd") {
        if (
          currentPosition &&
          projected.parent === currentPosition.parent &&
          overId === currentPosition.overId
        ) {
          return;
        } else {
          setCurrentPosition({
            parent: projected.parent,
            overId,
          });
        }
      }

      const clonedItems = JSON.parse(JSON.stringify(flattenTree(items)));
      const overIndex = clonedItems.findIndex(({ _id }) => _id === overId);
      const activeIndex = clonedItems.findIndex(({ _id }) => _id === activeId);
      const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);

      const previousItem = sortedItems[overIndex - 1];

      let announcement;
      const movedVerb = eventName === "onDragEnd" ? "dropped" : "moved";
      const nestedVerb = eventName === "onDragEnd" ? "dropped" : "nested";

      if (!previousItem) {
        const nextItem = sortedItems[overIndex + 1];
        announcement = `${activeId} was ${movedVerb} before ${nextItem._id}.`;
      } else {
        if (projected.depth > previousItem.depth) {
          announcement = `${activeId} was ${nestedVerb} under ${previousItem._id}.`;
        } else {
          let previousSibling = previousItem;
          while (previousSibling && projected.depth < previousSibling.depth) {
            const parent = previousSibling.parent;
            previousSibling = sortedItems.find(({ _id }) => _id === parent);
          }

          if (previousSibling) {
            announcement = `${activeId} was ${movedVerb} after ${previousSibling._id}.`;
          }
        }
      }

      return announcement;
    }

    return;
  }

  return (
    <DndContext
      announcements={announcements}
      sensors={sensors}
      collisionDetection={closestCenter}
      measuring={measuring}
      onDragStart={handleDragStart}
      onDragMove={handleDragMove}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
    >
      <SortableContext items={sortedIds} strategy={verticalListSortingStrategy}>
        {flattenedItems.map((item) => {
          return (
            <SortableTreeItem
              item={item}
              key={item?._id}
              id={item?._id}
              icon={item?.icon}
              value={item?.collection_name}
              depth={
                item?._id === activeId && projected
                  ? projected?.depth
                  : item?.depth
              }
              indentationWidth={indentationWidth}
              indicator={indicator}
              onCollapse={
                collapsible && item?.children.length
                  ? () => handleCollapse(item)
                  : undefined
              }
              openUpdateFolderModal={openUpdateFolderModal}
              handleMenuClick={handleMenuClick}
            />
          );
        })}
        {createPortal(
          <DragOverlay
            dropAnimation={dropAnimation}
            modifiers={indicator ? [adjustTranslate] : undefined}
          >
            {activeId && activeItem ? (
              <SortableTreeItem
                id={activeId}
                item={activeItem}
                depth={activeItem.depth}
                clone
                childCount={getChildCount(items, activeId) + 1}
                value={activeId}
                indentationWidth={indentationWidth}
                openUpdateFolderModal={openUpdateFolderModal}
                handleMenuClick={handleMenuClick}
              />
            ) : null}
          </DragOverlay>,
          document.body,
        )}
      </SortableContext>
    </DndContext>
  );
};

const adjustTranslate = ({ transform }) => {
  return {
    ...transform,
    y: transform.y - 25,
  };
};

export default DataModalSort;
