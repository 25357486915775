import React, { useEffect, useState } from "react";
import {
  Flex,
  Form,
  Row,
  Col,
  Select,
  Input,
  Spin,
  notification,
  Button,
  Modal,
} from "antd";
import LeftSideTitlePart from "../../Components/HeaderParts/LeftSideTitlePart";
import RightSIdeTitlePart from "../../Components/HeaderParts/RightSIdeTitlePart";
import AppInfo from "../../Components/AppInfo";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../Helpers/axios";
import Apis from "../../Helpers/Apis";
import { setActiveTab } from "../../Redux/slices/activeTab.reducer";
import { useDispatch, useSelector } from "react-redux";
import { hideLoader } from "../../Redux/slices/loader.reducer";
import { IoWarningOutline } from "react-icons/io5";

const AddUser = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loader = useSelector((state) => state.loader.isLoading);
  const path = location.pathname.split("/");
  const isEdit = path[3] && path[3] !== "add";
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formSubmitLoading, setFormSubmitLoading] = useState(false);
  const [form] = Form.useForm();
  const initialFormValues = {
    role: "",
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    auth0Id: "",
  };

  const getRoles = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(Apis.GET_ROLES({}));
      setRoles(response.data?.payload?.data?.data || []);
      form.setFieldsValue({ role: response.data?.payload?.data?.data[0]?._id });
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
      dispatch(hideLoader());
    }
  };

  const handleFormSubmit = async () => {
    try {
      setFormSubmitLoading(true);
      const values = await form.validateFields();

      if (isEdit) {
        const formattedValues = {
          first_name: values.first_name,
          last_name: values.last_name,
          role: values.role,
        };
        await axiosInstance.patch(Apis.UPDATE_USER(path[3]), formattedValues);
        form.resetFields();
        dispatch(
          setActiveTab({
            page: "users",
            tab: "all-users",
            redirectionLink: "/users/all-users",
          }),
        );
        notification.success({ message: t("user_updated_successfully") });
      } else {
        const formattedValues = {
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email,
          password: values.password,
          role: values.role,
        };
        await axiosInstance.post(Apis.ADD_TENANT_USER(), formattedValues);
        form.resetFields();
        dispatch(
          setActiveTab({
            page: "users",
            tab: "all-users",
            redirectionLink: "/users/all-users",
          }),
        );
        notification.success({ message: t("user_added_successfully") });
      }
    } catch (err) {
      console.log("err", err);
      err?.response?.data?.message &&
        notification.error({
          message: err?.response?.data?.message,
        });
    } finally {
      setFormSubmitLoading(false);
    }
  };

  useEffect(() => {
    getRoles();
    if (isEdit) {
      getAndSetUsersData();
    }
  }, []);

  const getAndSetUsersData = async () => {
    try {
      const resp = await axiosInstance.get(Apis.GET_USER(path[3]));
      const userData = resp.data?.payload?.data;

      if (resp.status === 200 && userData) {
        const { role, first_name, last_name, email, auth0Id } = userData;

        form.setFieldsValue({
          role: role?._id || null,
          first_name: first_name || "",
          last_name: last_name || "",
          email: email || "",
          password: "testpassword",
          auth0Id,
        });
      } else {
        console.warn("No user data found.");
      }
    } catch (error) {
      error?.data?.message &&
        notification.error({
          message: error?.data?.message,
        });
    }
  };

  if (loader) {
    return <></>;
  }

  const handleResetPassword = async () => {
    const email = form.getFieldValue("email");

    Modal.confirm({
      title: "Confirm Password Reset",
      content: (
        <>
          <p>{t("password_reset_msg", { email })}</p>
          <p>{t("reset_password_confirm")}</p>
        </>
      ),
      icon: (
        <div className="modal-icon">
          <IoWarningOutline size={24} />
        </div>
      ),
      okText: t("send"),
      cancelText: t("cancel"),
      okButtonProps: { className: "modal-primary-btn" },
      cancelButtonProps: { className: "modal-default-btn" },
      className: "modal-confirm",
      onOk: async () => {
        try {
          const resp = await axiosInstance.post(Apis.RESET_PASSWORD(), {
            email,
          });

          if (resp.status === 200) {
            notification.success({
              message: `The password reset link has been sent to ${email}.`,
            });
          }
        } catch (error) {
          console.error("Error:", error);
          error?.response?.data?.message &&
            notification.error({
              message: error?.response?.data?.message,
            });
        }
      },
    });
  };

  return (
    <Flex className="w-full">
      <div className="px-4 xl:px-16 w-full">
        <div className="primary-header pt-9 pb-5">
          <div
            onClick={() => {
              dispatch(
                setActiveTab({
                  page: path[1],
                  tab: t("all_users"),
                  redirectionLink: "/users/all-users",
                }),
              );
            }}
          >
            <LeftSideTitlePart
              pageTitle={isEdit ? t("update_user") : t("add_user")}
              pageSubTitle={t("users")}
              pageIcon={false}
              backButton={true}
            />
          </div>
          <RightSIdeTitlePart
            showButton={true}
            handleShowClick={handleFormSubmit}
            buttonLoading={formSubmitLoading}
            showDisabled={formSubmitLoading}
          />
        </div>

        {loading ? (
          <Spin
            spinning={loader}
            indicator={<div className="custom-loader" />}
            className={"absolute top-1/2 left-1/2"}
          ></Spin>
        ) : roles.length > 0 ? (
          <section className="container max-w-4xl mt-4">
            <Form
              layout="vertical"
              form={form}
              initialValues={initialFormValues}
              disabled={formSubmitLoading}
            >
              <Row gutter={28}>
                <Col className="gutter-row" xs={24} md={12}>
                  <Form.Item
                    label={t("first_name")}
                    name="first_name"
                    className="font-bold"
                    rules={[{ required: true }]}
                  >
                    <Input type="text" className="project-custom-input" />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} md={12}>
                  <Form.Item
                    label={t("last_name")}
                    name="last_name"
                    className="font-bold"
                    rules={[{ required: true }]}
                  >
                    <Input type="text" className="project-custom-input" />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} md={12}>
                  <Form.Item
                    label={t("email")}
                    name="email"
                    className="font-bold"
                    rules={[{ required: true }]}
                  >
                    <Input
                      type="email"
                      disabled={isEdit || formSubmitLoading}
                      className="project-custom-input"
                      autoComplete="new-email"
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} md={8}>
                  <Form.Item
                    label={t("password")}
                    name="password"
                    className="font-bold"
                    rules={[{ required: true }]}
                  >
                    <Input.Password
                      disabled={isEdit || formSubmitLoading}
                      className="project-custom-input"
                      autoComplete="new-password"
                      visibilityToggle={!isEdit && !formSubmitLoading}
                    />
                  </Form.Item>
                </Col>

                <Col className="gutter-row" xs={24} md={4}>
                  {isEdit && (
                    <Button
                      className="mt-9 project-custom-btn rounded-full bg-white border-grey-base/90 text-black-base"
                      onClick={handleResetPassword}
                    >
                      {t("reset_password")}
                    </Button>
                  )}
                </Col>

                {isEdit && (
                  <Col className="gutter-row" xs={24} md={12}>
                    <Form.Item
                      label={t("auth0Id")}
                      name="auth0Id"
                      className="font-bold"
                    >
                      <Input
                        disabled
                        className="project-custom-input"
                        autoComplete="new-email"
                      />
                    </Form.Item>
                  </Col>
                )}

                <Col className="gutter-row" xs={24}>
                  <Form.Item
                    label={t("role")}
                    name="role"
                    className="font-bold"
                    rules={[{ required: true }]}
                  >
                    <Select
                      placeholder="Super Admin"
                      style={{ flex: 1 }}
                      options={roles}
                      fieldNames={{ label: "name", value: "_id" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </section>
        ) : (
          <div>{t("no_roles_found")}</div>
        )}
      </div>
      <AppInfo />
    </Flex>
  );
};

export default AddUser;
