import React, { useEffect, useState } from "react";
import { Drawer, Flex, Modal, notification, Skeleton, Table, Tabs } from "antd";
import IconsAll from "../../Components/IconsAll";
import LeftSideTitlePart from "../../Components/HeaderParts/LeftSideTitlePart";
import RightSIdeTitlePart from "../../Components/HeaderParts/RightSIdeTitlePart";
import styled from "styled-components";
import CollectieSetup from "../../Components/Drawers/CollectieSetup";
import AppInfo from "../../Components/AppInfo";
import { useTranslation } from "react-i18next";
import { themes } from "../../config";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import axiosInstance from "../../Helpers/axios";
import Apis from "../../Helpers/Apis";
import {
  deleteSchemaById,
  updateSchemaById,
  updateSchemaMany,
} from "../../Redux/slices/schema.reducer";
import { deleteFieldBySchemaId } from "../../Redux/slices/fields.reducer";
import { SystemDefaultCollection } from "./DataModels/SystemDefaultCollection";
import FolderModal from "./FolderModal";
import DataModalSort from "./DataModalSort";
import { buildTree, filterTree } from "./utilities";
import { setActiveTab } from "../../Redux/slices/activeTab.reducer";

export const TableSort = styled(Table)`
  .ant-table {
    background: none;
    table {
      background-color: transparent;
      border-spacing: 0 1rem;
      .ant-table-row {
        .ant-table-cell {
          padding: 14px 6px;
          background: var(--body-bg);
          font-family: "Sometype Mono", monospace;
          font-weight: 500;
          color: var(--heading-text);
          &:empty {
            display: none;
          }
          &:first-child {
            border-radius: var(--br-12) 0 0 var(--br-12);
            border-left: 1px solid
              ${() =>
                themes[useSelector((state) => state.theme.name)].components
                  .Table.borderColor} !important;
            padding-left: 24px;
          }
          &:last-child {
            border-radius: 0 var(--br-12) var(--br-12) 0;
            border-right: 1px solid
              ${() =>
                themes[useSelector((state) => state.theme.name)].components
                  .Table.borderColor} !important;
            padding-right: 24px;
          }
        }
      }
    }
    .gray-clr {
      color: ${() =>
        themes[useSelector((state) => state.theme.name)].token.grayClr};
    }
    .ant-table-thead {
      display: none;
    }
    .ant-table-cell {
      border-bottom: 1px solid
        ${() =>
          themes[useSelector((state) => state.theme.name)].components.Table
            .borderColor} !important;
      border-top: 1px solid
        ${() =>
          themes[useSelector((state) => state.theme.name)].components.Table
            .borderColor} !important;
      &.ant-table-column-has-sorters {
        &:hover {
          background-color: ${() =>
            themes[useSelector((state) => state.theme.name)].components.Table
              .sortedBg} !important;
        }
      }
      &.ant-table-column-sort {
        background-color: ${() =>
          themes[useSelector((state) => state.theme.name)].components.Table
            .sortedBg};
      }
    }

    .imgLocationClr {
      color: ${() =>
        themes[useSelector((state) => state.theme.name)].components.Table
          .imgLocationClr};
      margin-left: 10px;
    }
  }
`;

const DrawerStyle = styled(Drawer)`
  .ant-drawer-header {
    padding: 0;
    border: unset !important;
    .ant-drawer-header-title {
      .ant-drawer-close {
        width: 56px;
        height: 56px;
        position: absolute;
        left: -65px;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 30px;
        background: var(--color-white);
        border-radius: 100vh;
        box-shadow: 0px 4px 12px 0px rgba(8, 24, 44, 0.16);
        @media screen and (max-width: 991px) {
          left: calc(50% - 28px);
          bottom: 1rem;
          top: auto;
        }
      }
    }
  }
  .ant-drawer-body {
    padding: 0;
    .ant-tabs {
      @media screen and (max-width: 991px) {
        flex-flow: wrap;
      }
      .ant-tabs-nav {
        padding-top: 40px;
        width: min(100%, 200px);
        min-height: 100vh;
        background-color: #f5f6f7;
        @media screen and (max-width: 991px) {
          width: 100%;
          min-height: auto;
        }
        .ant-tabs-nav-wrap {
          &:before,
          &:after,
          .ant-tabs-ink-bar {
            display: none;
          }
          .ant-tabs-nav-list {
            padding-inline: 10px;
            @media screen and (max-width: 991px) {
              flex-direction: row;
              margin-bottom: 1rem;
            }
            .ant-tabs-tab {
              border-radius: 8px;
              margin-top: 5px;
              > div {
                color: #000 !important;
                font-size: 16px;
                font-style: normal;
              }
              &.ant-tabs-tab-active {
                background-color: rgba(from var(--project-base) r g b / 20%);
              }
              @media screen and (max-width: 991px) {
                display: inline-block;
              }
            }
          }
        }
      }
    }

    .ant-tabs-content-holder {
      .ant-tabs-tabpane {
        padding-left: 0;
      }
    }
  }
`;

const DataModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const schemas = useSelector((state) => state?.schemas?.schemas);
  const systemCollections = useSelector(
    (state) => state?.schemas.system_collections,
  );
  const [folderModel, setFolderModel] = useState(false);
  const [activeFolder, setActiveFolder] = useState();

  useEffect(() => {
    init();
  }, [schemas]);

  const openUpdateFolderModal = (folder_id) => {
    setActiveFolder(schemas?.find((col) => col?._id === folder_id));
    setFolderModel(true);
  };

  const init = async () => {
    setTimeout(() => {
      setLoading(false);
    }, 300);
  };

  const drawerItems = [
    {
      key: "1",
      label: t("collection_setup"),
      children: (
        <CollectieSetup
          setIsDrawerOpen={setIsDrawerOpen}
          init={init}
          dataSource={schemas}
        />
      ),
      closable: true,
      closeIcon: <IconsAll.BlogIcon />,
    },
    // {
    //   key: "2",
    //   label: t("optional") + " " + t("fields").toLowerCase(),
    //   children: <OptioneleVelden />,
    // },
  ];

  const handleMenuClick = async (id, type, visible) => {
    if (type === "delete") {
      try {
        setLoading(true);
        let resp = await axiosInstance.delete(Apis.DELETE_A_SCHEMA(id));
        if (resp.status === 200) {
          resp = resp.data.payload.data;
          notification.success({ message: t("collection_delete_success") });
          dispatch(deleteSchemaById(id));
          dispatch(
            setActiveTab({
              page: "content",
              tab: null,
              redirectionLink: null,
            }),
          );
          dispatch(
            updateSchemaMany({
              filter: {
                parent: id,
              },
              updatedData: {
                parent: resp?.parent ? resp?.parent : null,
              },
            }),
          );
          if (!resp?.meta?.is_folder) dispatch(deleteFieldBySchemaId(id));
        }
      } catch (error) {
        notification.error({
          message: error?.response?.data?.message || t("something_went_wrong"),
        });
        console.log("error", error);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        const resp = await axiosInstance.patch(
          Apis.UPDATE_SCHEMA_VISIBILITY(id),
          {
            visible: !visible,
          },
        );
        if (resp.status === 200) {
          notification.success({ message: t("visibility_update_success") });
          dispatch(
            updateSchemaById({ id: id, updatedData: { visible: !visible } }),
          );
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  const handleShareClick = async () => {
    setLoading(true);
    try {
      const resp = await axiosInstance.get(Apis.GET_SWAGGER());
      if (resp.status === 200) {
        window.open(resp.data?.payload?.data, "_blank");
      }
    } catch (error) {
      console.log("Error", error);
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <Flex className="w-full main-content-wrap">
      <div className="px-4 xl:px-16 w-full left-part">
        <div className="primary-header pt-9 pb-5">
          <LeftSideTitlePart
            pageTitle={t("data_model")}
            pageSubTitle={t("setting")}
            pageIcon={true}
            pageInnerIcon={<IconsAll.DataModalIcon />}
            backButton={false}
          />
          <RightSIdeTitlePart
            isFilterShow={true}
            searchTermPlaceholder={t("search")}
            addButton={true}
            addDisabled={loading}
            isDrawerOpen={isDrawerOpen}
            setIsDrawerOpen={setIsDrawerOpen}
            searchTerm={searchTerm}
            searchDisabled={
              (!schemas?.length || !systemCollections?.length) &&
              (!searchTerm || searchTerm === "")
            }
            setSearchTerm={setSearchTerm}
            folderButton
            folderDisabled={loading}
            handleFolderClick={() => {
              setActiveFolder(null), setFolderModel(true);
            }}
            shareButton
            handleShareClick={handleShareClick}
            shareDisabled={loading}
          />
          <DrawerStyle
            width={870}
            open={isDrawerOpen}
            onClose={() => setIsDrawerOpen(false)}
            closeIcon={<IconsAll.CloseIconCustom />}
            destroyOnClose
          >
            <Tabs
              defaultActiveKey="1"
              items={drawerItems}
              tabPosition={"left"}
            />
          </DrawerStyle>
        </div>

        <section className="mt-4">
          {!loading ? (
            <>
              <DataModalSort
                items={filterTree(buildTree([...schemas]), searchTerm)}
                openUpdateFolderModal={openUpdateFolderModal}
                handleMenuClick={handleMenuClick}
              />
              <SystemDefaultCollection
                dataSource={systemCollections?.filter((d) =>
                  d?.collection_id?.includes(searchTerm),
                )}
              />
            </>
          ) : (
            <>
              <Skeleton size="large" active />
              <br />
              <Skeleton size="large" active />
              <br />
              <Skeleton size="large" active />
            </>
          )}
        </section>
        <Modal
          open={folderModel}
          footer={null}
          title={activeFolder ? t("update_folder") : t("create_folder")}
          onOk={() => setFolderModel(false)}
          okButtonProps={{ className: "modal-primary-btn" }}
          cancelButtonProps={{ className: "modal-default-btn" }}
          onCancel={() => {
            setFolderModel(false);
          }}
          destroyOnClose
          className="!max-w-[660px] w-full!"
        >
          <FolderModal
            setFolderModel={setFolderModel}
            dataSource={schemas}
            activeFolder={activeFolder}
          />
        </Modal>
      </div>
      <AppInfo
        visibleList={["information"]}
        config={{
          msg: t("information_data_model"),
        }}
      />
    </Flex>
  );
};

export default DataModal;
