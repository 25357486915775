import Title from "antd/es/typography/Title";
import React, { useState } from "react";
import IconsAll from "../IconsAll";
import styled from "styled-components";
import {
  Button,
  Flex,
  Form,
  Row,
  Typography,
  Col,
  Input,
  Divider,
  notification,
  Spin,
  Tooltip,
} from "antd";
import { GrLinkNext } from "react-icons/gr";
import { useTranslation } from "react-i18next";
import { themes } from "../../config";
import { useSelector } from "react-redux";
import axiosInstance from "../../Helpers/axios";
import Apis from "../../Helpers/Apis";
import { InvertedCheckBox } from "../../Common/CommonUiStyles";
import { useNavigate } from "react-router-dom";
import { addField } from "../../Redux/slices/fields.reducer";
import { addSchema } from "../../Redux/slices/schema.reducer";
import { useDispatch } from "react-redux";
const { Text } = Typography;

const TItleIconStyle = styled(Typography)`
  min-width: 56px !important;
  width: 56px !important;
  height: 56px;
  border-radius: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(from var(--project-base) r g b / 10%);
  color: ${() =>
    themes[useSelector((state) => state.theme.name)].components
      .HeaderButtonsStyle.backButtonClr};
`;

const HeaderButton = styled(Button)`
  min-width: 56px !important;
  width: 56px !important;
  height: 56px;
  border: transparent;
  display: flex;
  align-items: center;
  justify-content: center;

  &.add-button {
    background: ${() =>
      themes[useSelector((state) => state.theme.name)].components
        .HeaderButtonsStyle.addButtonBg};
    color: ${() =>
      themes[useSelector((state) => state.theme.name)].components
        .HeaderButtonsStyle.addButtonClr};

    &:hover {
      background: ${() =>
        themes[useSelector((state) => state.theme.name)].components
          .HeaderButtonsStyle.addButtonHoverBg} !important;
      color: ${() =>
        themes[useSelector((state) => state.theme.name)].components
          .HeaderButtonsStyle.addButtonHoverClr} !important;
    }
  }
`;

const CollectieSetup = ({ setIsDrawerOpen, init, dataSource }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleAddCollection = async () => {
    let isError = false;
    try {
      const values = await form.validateFields();
      setLoader(true);
      const resp = await axiosInstance.post(Apis.ADD_A_SCHEMA(), {
        collection_name: values.collection_name,
        visible: true,
        meta: {
          sort:
            Math.max(0, ...(dataSource?.map((field) => field?.sort) || [])) + 1,
          singleton: values?.singleton,
        },
        fields: {
          sort: values.sort,
          status: values.status,
          created_by: values.created_by,
          created_at: values.created_at,
          updated_at: values.updated_at,
          updated_by: values.updated_by,
        },
      });

      dispatch(
        addField({
          singleObject: false,
          data: resp.data?.payload?.data?.fields,
        }),
      );
      delete resp.data?.payload?.data?.fields;
      dispatch(
        addSchema({ singleObject: true, data: resp.data?.payload?.data }),
      );

      if (resp.status === 200) {
        notification.success({ message: t("collection_add_success") });
        navigate(`/settings/data-model/${resp.data?.payload?.data?._id}`);
      }
      await form.resetFields();
    } catch (error) {
      notification.error({
        message: error?.response?.data?.message || t("something_went_wrong"),
      });
      isError = true;
      return;
    } finally {
      setLoader(false);
      if (!isError) {
        setIsDrawerOpen(false);
        init();
      }
    }
  };

  const handleChange = (e) => {
    form.setFieldValue(
      "collection_name",
      e.target.value
        .toLowerCase()
        .replace(/[^a-z0-9_ ]/g, "")
        .replace(/ /g, "_"),
    );
  };

  return (
    <div className="p-10">
      <Flex wrap="wrap" align="center" justify="space-between">
        <Title className="project-header-left-side max-w-[90%]" level={3}>
          <TItleIconStyle>
            <IconsAll.ContentIcon />
          </TItleIconStyle>
          <Tooltip
            title={t("create_new_collection")}
            placement="bottomLeft"
            className="truncate"
          >
            {t("create_new_collection")}
          </Tooltip>
        </Title>
        <HeaderButton
          shape="circle"
          icon={<GrLinkNext size={24} />}
          className="btn add-button"
          onClick={handleAddCollection}
        ></HeaderButton>
      </Flex>
      {loader ? (
        <Spin
          spinning={loader}
          fullscreen
          indicator={<div className="custom-loader" />}
        ></Spin>
      ) : (
        <Form layout="vertical" className="mt-6" form={form}>
          <Row gutter={28}>
            <Col className="gutter-row" xs={24} md={12}>
              <Form.Item
                label={t("name")}
                name="collection_name"
                className="font-bold mb-0"
                rules={[
                  {
                    required: true,
                    message: t("enter_collection"),
                  },
                  {
                    max: 50,
                  },
                ]}
              >
                <Input
                  type="text"
                  onChange={handleChange}
                  placeholder={t("enter_data_model_name")}
                  className="project-custom-input"
                />
              </Form.Item>
              <Text className="font-normal !text-grey-base text-sm mt-2 block">
                {t("collection_case_sensitive")}
              </Text>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label={t("singleton")}
                className="font-bold"
                name={`singleton`}
                valuePropName="checked"
              >
                <InvertedCheckBox>
                  {t("treat_as_single_object")}
                </InvertedCheckBox>
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24}>
              <Divider className="my-6" />
            </Col>
            <Col className="gutter-row" xs={24} md={12}>
              <Form.Item
                label={t("status")}
                className="font-bold"
                name={`status`}
                valuePropName="checked"
              >
                <InvertedCheckBox>{t("status")}</InvertedCheckBox>
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} md={12}>
              <Form.Item
                label={t("to_sort")}
                className="font-bold"
                name={`sort`}
                valuePropName="checked"
              >
                <InvertedCheckBox>{t("sort")}</InvertedCheckBox>
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} md={12}>
              <Form.Item
                label={t("created_on")}
                className="font-bold"
                name={`created_at`}
                valuePropName="checked"
              >
                <InvertedCheckBox>{t("date_created")}</InvertedCheckBox>
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} md={12}>
              <Form.Item
                label={t("created_by")}
                className="font-bold"
                name={`created_by`}
                valuePropName="checked"
              >
                <InvertedCheckBox>{t("user_created")}</InvertedCheckBox>
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} md={12}>
              <Form.Item
                label={t("updated_on")}
                className="font-bold"
                name={`updated_at`}
                valuePropName="checked"
              >
                <InvertedCheckBox>{t("date_updated")}</InvertedCheckBox>
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} md={12}>
              <Form.Item
                label={t("updated_by")}
                className="font-bold"
                name={`updated_by`}
                valuePropName="checked"
              >
                <InvertedCheckBox>{t("user_updated")}</InvertedCheckBox>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
    </div>
  );
};

export default CollectieSetup;
