import React, { useEffect, useState } from "react";
import {
  Col,
  Flex,
  Form,
  Input,
  notification,
  Row,
  Skeleton,
  Switch,
} from "antd";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../../Helpers/axios";
import Apis from "../../../Helpers/Apis";
import withRouter from "../../../Common/withRouter";
import { useDispatch } from "react-redux";
import { handleFlowActions } from "../../../Redux/slices/flows.reducer";
import TextArea from "antd/es/input/TextArea";
import DOMPurify from "dompurify";
import LeftSideTitlePart from "../../../Components/HeaderParts/LeftSideTitlePart";
import RightSIdeTitlePart from "../../../Components/HeaderParts/RightSIdeTitlePart";
import HtmlInputWithPreview from "./Components/HtmlInputWithPreview";

const EditEmailTemplates = ({ router }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const htmlContent = Form.useWatch("html", form);

  const initialFormValues = {
    name: "",
    description: "",
    html: "",
    is_active: true,
  };

  const { field } = router.params;
  const isCreateEmailTemplatePage = field && field === "add";

  const [loader, setLoader] = useState(false);

  const getEmailTemplateData = async () => {
    try {
      setLoader(true);
      const response = await axiosInstance.get(
        Apis.GET_EMAIL_TEMPLATE_DATA({ templateId: field }),
      );
      const data = response?.data?.payload?.data;
      form.setFieldsValue({
        name: data?.name,
        description: data?.description,
        html: data?.html,
        is_active: data?.is_active,
      });
    } catch (error) {
      notification.error({ message: t(error?.data?.message) });
      router.navigate("/settings/email-templates");
    } finally {
      setLoader(false);
    }
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      setLoader(true);
      if (isCreateEmailTemplatePage) {
        await axiosInstance.post(Apis.CREATE_EMAIL_TEMPLATE(), {
          ...values,
          html: DOMPurify.sanitize(values.html),
        });
        notification.success({
          message: t("email_template_was_successfully_created"),
        });
      } else {
        await axiosInstance.put(
          Apis.UPDATE_EMAIL_TEMPLATE({ templateId: field }),
          {
            ...values,
            html: DOMPurify.sanitize(values.html),
          },
        );
        notification.success({
          message: t("email_template_was_successfully_updated"),
        });
      }
      router.navigate("/settings/email-templates");
    } catch (error) {
      error?.response?.data?.message &&
        notification.error({
          message: t(error.response.data.message),
        });
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (!isCreateEmailTemplatePage) {
      getEmailTemplateData();
    }
  }, []);

  return (
    <Flex className="w-full">
      <div className="px-4 xl:px-16 w-full">
        <div className="primary-header pt-9 pb-5">
          <div
            onClick={() => {
              router.navigate(`/settings/email-templates`);
            }}
          >
            <LeftSideTitlePart
              pageTitle={
                loader ? (
                  <Skeleton.Button size="small" active />
                ) : isCreateEmailTemplatePage ? (
                  t("add_email_template")
                ) : (
                  t("edit_email_template")
                )
              }
              pageSubTitle={t("email_templates")}
              backButton={true}
            />
          </div>
          <RightSIdeTitlePart
            showButton={true}
            showDisabled={loader}
            buttonLoading={loader}
            handleShowClick={handleSubmit}
            addDisabled={loader}
            handleAddClick={() => {
              dispatch(
                handleFlowActions({
                  flag: "operation",
                  data: {},
                  nodeId: "",
                  source: {},
                }),
              );
            }}
          />
        </div>
        <section className="mt-4 w-full h-4/5">
          <Form
            layout="vertical"
            form={form}
            initialValues={initialFormValues}
            disabled={loader}
          >
            <Row gutter={28} className="mx-auto max-w-[991px] mt-9">
              <Col className="gutter-row" xs={24}>
                <Form.Item
                  label={t("name")}
                  name="name"
                  className="font-bold"
                  rules={[{ required: true }]}
                >
                  <Input
                    type="text"
                    placeholder={t("enter_the_name")}
                    className="project-custom-input"
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24}>
                <Form.Item
                  label={t("description")}
                  name="description"
                  className="font-bold"
                >
                  <TextArea
                    rows={4}
                    placeholder={t("enter_the_description")}
                    className="project-custom-input pt-4"
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24}>
                <Form.Item
                  label={t("is_active")}
                  name="is_active"
                  className="font-bold"
                  rules={[{ required: true }]}
                  valuePropName={"checked"}
                >
                  <Switch />
                </Form.Item>
              </Col>
            </Row>
            <HtmlInputWithPreview htmlContent={htmlContent} loader={loader} />
          </Form>
        </section>
      </div>
    </Flex>
  );
};

export default withRouter(EditEmailTemplates);
