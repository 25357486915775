import axios from "axios";
import { retrieveLoginPrompt } from "./utils";
import { store } from "../Redux/store";
import { setApiLatency } from "../Redux/slices/userDetails.reducer";
import { notification } from "antd";

const baseUrl = process.env.REACT_APP_SERVER_API_BASE_URL;

const axiosInstance = axios.create({
  baseURL: baseUrl,
  withCredentials: true,
});

axiosInstance.interceptors.request.use((config) => {
  config.metadata = { startTime: Date.now() };

  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  const userData = store.getState().user;
  if (!userData?.userProfile?.nox_admin) {
    config.headers["x-tenant-id"] = userData?.userId;
  }

  return config;
});

let isRefreshing = false;
let failedQueue = [];
let latencyIntervalCount = 0;

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

axiosInstance.interceptors.response.use(
  (response) => {
    if (latencyIntervalCount > 5) {
      const latency = Date.now() - response.config.metadata.startTime;

      latencyIntervalCount = 0;
      store.dispatch(
        setApiLatency({
          latency,
        }),
      );
    }
    latencyIntervalCount++;
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (error.response?.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers.Authorization = `Bearer ${token}`;
            return axiosInstance(originalRequest);
          })
          .catch((err) => Promise.reject(err));
      }

      originalRequest._retry = true;
      isRefreshing = true;

      const refreshToken = localStorage.getItem("refreshToken");

      if (!refreshToken) {
        retrieveLoginPrompt().then((response) => {
          window.open(response?.data, "_self");
        });
        return Promise.reject(error);
      }

      return axios
        .post(`${baseUrl}api/v1/auth/refresh-token`, { refreshToken })
        .then((res) => {
          const { access_token, refresh_token } = res.data.payload.data;
          localStorage.setItem("token", access_token);
          localStorage.setItem("refreshToken", refresh_token);

          axiosInstance.defaults.headers.Authorization = `Bearer ${access_token}`;
          originalRequest.headers.Authorization = `Bearer ${access_token}`;

          processQueue(null, access_token);
          return axiosInstance(originalRequest);
        })
        .catch((err) => {
          processQueue(err, null);
          localStorage.clear();
          retrieveLoginPrompt().then((response) =>
            window.open(response?.data, "_self"),
          );
          return Promise.reject(err);
        })
        .finally(() => {
          isRefreshing = false;
        });
    }

    if (error.response?.status === 429) {
      notification.error({ message: error.response?.data?.message });
    }

    return Promise.reject(error);
  },
);

export default axiosInstance;
