import React, { useEffect, useState } from "react";
import UpdateInterface from "./EditFields/UpdateInterface";
import UpdateSchema from "./EditFields/UpdateSchema";
// import InputFieldTabInterface from "./EditFields/InputFieldTabInterface";
// import InputFieldTabDisplay from "./EditFields/InputFieldTabDiaplay";
import UpdateField from "./EditFields/UpdateField";
import UpdateRelationShip from "./EditFields/UpdateRelationship";
import UpdateValidations from "./EditFields/UpdateValidations";
import { DrawerSidebarTabs } from "../../Common/DrawerSidebarTabStyle";
import { useTranslation } from "react-i18next";
import { updateFieldById } from "../../Redux/slices/fields.reducer";
import { useDispatch } from "react-redux";
import axiosInstance from "../../Helpers/axios";
import Apis from "../../Helpers/Apis";
import { notification } from "antd";

const EditFields = ({
  dataSource,
  activeItem,
  setIsDrawerOpen,
  setActiveItem,
  isDrawerOpen,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [translationsList, setTranslationsList] = useState([]);

  useEffect(() => {
    getTranslations();
  }, []);

  const getTranslations = async () => {
    try {
      const resp = await axiosInstance.get(Apis.GET_TRANSLATIONS("", true));
      if (resp.status === 200) setTranslationsList(resp.data.payload.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const updateField = async (_id, payload) => {
    setLoader(true);
    try {
      const resp = await axiosInstance.put(Apis.UPDATE_A_FIELD(_id), payload);
      if (resp.status === 200) {
        dispatch(updateFieldById({ id: _id, updatedData: payload }));
        notification.success({ message: t("field_update_success") });
        setIsDrawerOpen(false);
        setActiveItem(null);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoader(false);
    }
  };

  const items = [
    {
      key: "1",
      label: t("schema"),
      children: (
        <UpdateSchema
          loader={loader}
          updateField={updateField}
          dataSource={dataSource}
          activeItem={activeItem}
        />
      ),
    },
    ["list-m2o", "list-m2m", "list-o2m", "list-m2a", "translations"].includes(
      activeItem?.meta?.interface,
    ) && {
      key: "7",
      label: t("relationship"),
      children: (
        <UpdateRelationShip
          loader={loader}
          updateField={updateField}
          dataSource={dataSource}
          activeItem={activeItem}
        />
      ),
    },
    {
      key: "2",
      label: t("field"),
      children: (
        <UpdateField
          loader={loader}
          updateField={updateField}
          dataSource={dataSource}
          activeItem={activeItem}
          translationsList={translationsList}
          getTranslations={getTranslations}
        />
      ),
    },
    ![
      "object",
      "json",
      "file",
      "file-image",
      "files",
      "items",
      "translations",
    ].includes(activeItem?.meta?.interface) && {
      key: "3",
      label: t("interface"),
      children: (
        <UpdateInterface
          loader={loader}
          updateField={updateField}
          dataSource={dataSource}
          activeItem={activeItem}
          translationsList={translationsList}
          getTranslations={getTranslations}
        />
      ),
    },
    // {
    //   key: "4",
    //   label: "Display",
    //   children: <InputFieldTabDisplay />,
    // },
    ![
      "object",
      "json",
      "file",
      "file-image",
      "files",
      "items",
      "translations",
    ].includes(activeItem?.meta?.interface) && {
      key: "5",
      label: t("validation"),
      children: (
        <UpdateValidations
          loader={loader}
          updateField={updateField}
          dataSource={dataSource}
          activeItem={activeItem}
          isDrawerOpen={isDrawerOpen}
          translationsList={translationsList}
          getTranslations={getTranslations}
        />
      ),
    },
    // {
    //   key: "6",
    //   label: "Conditions",
    //   children: <InputFieldTabInterface />,
    // },
  ];

  return (
    <>
      {activeItem && <DrawerSidebarTabs tabPosition={"left"} items={items} />}
    </>
  );
};

export default EditFields;
