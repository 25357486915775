import React, { useEffect, useState } from "react";
import { BsInfoCircle } from "react-icons/bs";
import { Button, Modal } from "antd";
import Media from "../../../Pages/MediaLibrary/Media";
import FilesList from "./FilesList";
import CustomDragger from "./CustomDragger";

import { DrawerStyle, NotHasField } from "../../../Common/CommonUiStyles";
import { useTranslation } from "react-i18next";
import { setSelectedFolderData } from "../../../Redux/slices/media.reducer";
import { useDispatch } from "react-redux";

const CustomFiles = ({ item, updateFormData, formData, isValidationError, language }) => {
  const [files, setFiles] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    setFiles(formData?.[item?.path] ? formData?.[item?.path] : []);
  }, [language?.selectedLanguage]);

  useEffect(() => {
    updateFormData(
      item?.meta?.interface === "files"
        ? files?.map((f) => f?._id)
        : files?.length
          ? files[0]?._id
          : "",
    );
  }, [files]);

  const updateFilesList = (newFiles) => {
    setFiles((prev) => {
      return item?.meta?.interface === "files"
        ? [...prev, ...newFiles]
        : newFiles;
    });
  };

  const handleDelete = (img_id) => {
    setFiles((prev) => prev.filter((p) => p?._id !== img_id));
  };

  return (
    <div>
      {!files?.length ? (
        <NotHasField
          className={`items-center gap-3 font-normal rounded-xl ${isValidationError ? "!bg-delete-base !border-l-delete-base" : ""}`}
        >
          <BsInfoCircle size={20} />
          <p className="font-normal">{t("no_items")}</p>
        </NotHasField>
      ) : (
        files &&
        Array.isArray(files) &&
        files?.map((file) => (
          <FilesList
            key={file?._id}
            file={file}
            iFace={item?.meta?.interface}
            handleDelete={handleDelete}
            setFiles={setFiles}
          />
        ))
      )}
      <div className="mt-4">
        <Button
          disabled={!!item.meta?.readonly}
          onClick={() => setIsModalOpen(true)}
          className="project-custom-btn rounded-full border-dark bg-project-base! text-project-base-text! mr-3"
        >
          {t("upload_file")}
        </Button>
        <Button
          disabled={!!item.meta?.readonly}
          className="project-custom-btn rounded-full bg-white border-grey-base/90 text-black-base"
          onClick={() => {
            dispatch(setSelectedFolderData({}));
            setShowDrawer(true);
          }}
        >
          {t("add_existing")}
        </Button>
      </div>
      <Modal
        title={t("add_file")}
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        okText={t("ok")}
        cancelText={t("cancel")}
        okButtonProps={{ className: "modal-primary-btn" }}
        cancelButtonProps={{ className: "modal-default-btn" }}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
      >
        <CustomDragger
          iFace={item?.meta?.interface}
          setIsModalOpen={setIsModalOpen}
          updateFilesList={updateFilesList}
          folder={item?.meta?.folder}
          cropImage={item?.meta?.interface === "file-image"}
        />
      </Modal>
      <DrawerStyle
        width={991}
        onClose={() => setShowDrawer(false)}
        open={showDrawer}
        destroyOnClose
        className="table-in-drawer add-existing-file-drawer remove-padding w-full!"
      >
        <Media
          iFace={item?.meta?.interface}
          updateContentFilesList={updateFilesList}
          setShowDrawer={setShowDrawer}
          folderScope={item?.meta?.folder}
        />
      </DrawerStyle>
    </div>
  );
};

export default CustomFiles;
