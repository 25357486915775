import React, { useEffect } from "react";
import LeftSideTitlePart from "../../../Components/HeaderParts/LeftSideTitlePart";
import { Checkbox, Col, Flex, Form, Row, Tooltip } from "antd";
import RightSIdeTitlePart from "../../../Components/HeaderParts/RightSIdeTitlePart";
import { useTranslation } from "react-i18next";
import IconsAll from "../../../Components/IconsAll";
import { InvertedCheckBox } from "../../../Common/CommonUiStyles";
import { getFieldsBySchemaId } from "../../../Helpers/functions";
import { useSelector } from "react-redux";
import { useWatch } from "antd/es/form/Form";

const FieldPermissions = ({
  selectedSchema,
  setPermissions,
  permissions,
  setIsDrawerOpen,
  DrawerTitle,
}) => {
  const { rowData, action } = selectedSchema;
  const { t } = useTranslation();
  const allFields = useSelector((state) => state?.fields?.fields);
  const [form] = Form.useForm();
  const fields = useWatch("fields", form);

  const schemaData = getFieldsBySchemaId({
    schemas: [rowData],
    fields: allFields,
    schemaId: rowData?._id,
  });

  const primaryField = schemaData?.fields?.find(
    (field) => field.path === "_id",
  );

  const initialValues = {
    fields: [],
  };

  const foundedPermission = permissions.find(
    (permission) =>
      permission.action === action && permission.collection_id === rowData?._id,
  );
  if (foundedPermission) {
    initialValues.fields = foundedPermission.fields_id;
  }

  const handleSubmit = async () => {
    const values = await form.validateFields();
    setPermissions((prev) => {
      const existingPermission = prev.find(
        (permission) =>
          permission.collection_id === rowData?._id &&
          permission.action === action,
      );

      const newPermission = {
        action: action,
        collections: rowData?.collection_name,
        collection_id: rowData?._id,
        full_access: values.fields.length === schemaData?.fields?.length,
        fields: values.fields.map(
          (field) => schemaData?.fields?.find((f) => f._id === field)?.path,
        ),
        fields_id: values.fields,
      };

      if (existingPermission) {
        Object.assign(existingPermission, newPermission);
        return [...prev];
      } else {
        return [...prev, newPermission];
      }
    });
    setIsDrawerOpen(false);
  };

  useEffect(() => {
    if (fields?.length === 1 && fields?.[0] !== primaryField?._id) {
      form.setFieldsValue({ fields: [...fields, primaryField?._id] });
    }
  }, [fields]);

  return (
    <div>
      <Flex wrap="wrap" align="center" justify="space-between">
        <LeftSideTitlePart
          pageTitle={<DrawerTitle />}
          pageIcon={true}
          pageInnerIcon={<IconsAll.DataModalIcon />}
          backButton={false}
        />
        <RightSIdeTitlePart showButton={true} handleShowClick={handleSubmit} />
      </Flex>
      <Form
        form={form}
        initialValues={initialValues}
        layout={"vertical"}
        className="mt-6"
      >
        <Form.Item
          label={t("fields")}
          htmlFor={`allow-other`}
          className="font-bold"
          name={`fields`}
        >
          <Checkbox.Group>
            <Row gutter={[16, 16]}>
              {schemaData?.fields?.map((field) => {
                return (
                  <Col span={12} key={field._id}>
                    <InvertedCheckBox
                      id={`allow-other-${field._id}`}
                      value={field._id}
                      disabled={
                        field.path === "_id" &&
                        fields?.length > 1 &&
                        fields?.includes(field._id)
                      }
                      className="toegangscontrole-field-permissions-checkbox"
                    >
                      <Tooltip
                        title={field.path}
                        placement="bottomLeft"
                        className="truncate block"
                      >
                        {field.path}
                      </Tooltip>
                    </InvertedCheckBox>
                  </Col>
                );
              })}
            </Row>
          </Checkbox.Group>
        </Form.Item>
      </Form>
    </div>
  );
};

export default FieldPermissions;
