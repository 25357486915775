import React from "react";
import { Flex } from "antd";
import LeftSideTitlePart from "../../Components/HeaderParts/LeftSideTitlePart";
import IconsAll from "../../Components/IconsAll";
import RightSIdeTitlePart from "../../Components/HeaderParts/RightSIdeTitlePart";
import { useTranslation } from "react-i18next";
import AppInfo from "../../Components/AppInfo";

const MarketPlace = () => {
  const { t } = useTranslation();
  return (
    <Flex className="w-full">
      <div className="px-4 xl:px-16 w-full">
        <div className="primary-header pt-9 pb-5">
          <LeftSideTitlePart
            pageTitle={t("marketplace")}
            pageSubTitle={t("setting")}
            pageIcon={true}
            pageInnerIcon={<IconsAll.RequestFeatureIcon />}
            backButton={false}
          />
          <RightSIdeTitlePart
            isFilterShow={true}
            searchTermPlaceholder={t("search_in_configuration")}
            addButton={true}
          />
        </div>
        <section className="mt-4">
          <div className="container">
            <p className="text-lg text-heading-text leading-7">
              We breiden NOX uit met een Marketplace voor Add-Ons, waarmee je
              extra functionaliteiten eenvoudig kunt activeren en integreren in
              jouw omgeving. Momenteel zijn we druk bezig met de ontwikkeling,
              zodat je in de toekomst je NOX-omgeving nog krachtiger en
              flexibeler kunt maken.
            </p>
          </div>
        </section>
      </div>
      <AppInfo
        visibleList={["information"]}
        config={{
          msg: t("information_translations"),
        }}
      />
    </Flex>
  );
};
export default MarketPlace;
