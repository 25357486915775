import { Editor } from "@monaco-editor/react";
import React from "react";
import { Spin } from "antd"; // Assuming you are using Ant Design for the loader
import styled from "styled-components";

const EditorContainer = styled.div`
  position: relative;
`;

const Loader = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
`;

const CodeEditorInput = ({
  value = "",
  onChange,
  defaultLanguage,
  loading,
  height = "300px",
}) => {
  return (
    <EditorContainer>
      {loading && (
        <Loader>
          <Spin size="large" indicator={<div className="custom-loader" />} />
        </Loader>
      )}
      <Editor
        height={height}
        className="custom-monaco-editor"
        defaultLanguage={defaultLanguage}
        value={value}
        onChange={(val) => onChange?.(val || "")}
        options={{
          minimap: { enabled: false },
          formatOnPaste: true,
          formatOnType: true,
          readOnly: loading, // Disable editor while loading
        }}
      />
    </EditorContainer>
  );
};

export default CodeEditorInput;
