import React from "react";
import { Navigate } from "react-router-dom";
import axiosInstance from "../Helpers/axios";
import { useDispatch } from "react-redux";
import { setUser } from "../Redux/slices/userDetails.reducer";
import { Loader } from "lucide-react";
import { showLoader } from "../Redux/slices/loader.reducer";
// import { Navigate } from "react-router-dom";
// import { useProfile } from "../Common/Hooks/UserHooks";

const AuthProtected = ({ children, user, requiredRoles }) => {
  const token = localStorage.getItem("token");
  if (!user && token) {
    showLoader();
    return <></>;
  }
  if (
    requiredRoles?.length > 0 &&
    user &&
    !requiredRoles.includes(
      user?.nox_admin
        ? "nox-admin"
        : user?.role?.admin_access
          ? "admin-access"
          : "app-access",
    )
  ) {
    return <Navigate to="/not-found" />;
  }
  //   const { userProfile, loading, token } = useProfile();
  const params = new URLSearchParams(window.location.search);
  const dispatch = useDispatch();

  const queryToken = params.get("at");
  if (queryToken) {
    localStorage.setItem("token", queryToken);
    axiosInstance.defaults.headers.common = {
      Authorization: "Bearer " + queryToken,
    };
  }
  const refreshToken = params.get("rt");
  if (refreshToken) {
    localStorage.setItem("refreshToken", refreshToken);
  }
  const tenantId = params.get("tenantId");
  if (tenantId) {
    // localStorage.setItem("tenantId", tenantId);
    dispatch(setUser({ userId: tenantId }));
  }

  if (!localStorage.getItem("token")) {
    return <Loader />;
  }

  if (window.location.pathname === "/") {
    return <Navigate to="/dashboard" />;
  }

  return <>{children}</>;
};

export default AuthProtected;
