import React, { useCallback, useEffect, useState } from "react";
import { Dropdown, Flex, Modal, Spin } from "antd";
import IconsAll from "../../../Components/IconsAll";
import LeftSideTitlePart from "../../../Components/HeaderParts/LeftSideTitlePart";
import RightSIdeTitlePart from "../../../Components/HeaderParts/RightSIdeTitlePart";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../../Helpers/axios";
import Apis from "../../../Helpers/Apis";
import { DrawerStyle, TableStriped } from "../../../Common/CommonUiStyles";
import { Link } from "react-router-dom";
import { usePagination } from "../../../Helpers/custom-hooks/usePagination";
import { handleFlowActions } from "../../../Redux/slices/flows.reducer";
import { useDispatch, useSelector } from "react-redux";
import FlowActionForm from "./Components/FlowActionForm";
import { debounce } from "lodash";
import withRouter from "../../../Common/withRouter";
import { LuPencil, LuTrash2 } from "react-icons/lu";

const Flows = ({ router }) => {
  const { t } = useTranslation();
  const { pagination, updatePagination } = usePagination(
    1,
    10,
    "createdAt",
    true,
  );
  const dispatch = useDispatch();
  const isActionDrawerOpen = useSelector(
    (state) => state.flows.isActionDrawerOpen,
  );

  const [flowsData, setFlowsData] = useState([]);
  const [searchFlow, setSearchFlow] = useState("");
  const [loader, setLoader] = useState(true);

  const columns = [
    {
      title: t("status"),
      dataIndex: "status",
      key: "status",
      render: (record) => (
        <span
          className={`${record === "active" ? "bg-project-base border-project-base text-project-base-text" : "bg-[var(--input-disabled-field-bg)] border-[var(--input-disabled-field-bg)] text-[var(--input-disabled-color)]"} 'block rounded-full px-3 py-1 leading-7 border h-auto text-sm`}
        >
          {t(record)}
        </span>
      ),
    },
    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
      render: (record, rowData) => (
        <Link to={`/settings/flows/${rowData?._id}`}>{record}</Link>
      ),
    },
    {
      title: t("description"),
      dataIndex: "description",
      key: "description",
      render: (record) => (
        <span className="whitespace-nowrap text-ellipsis overflow-hidden block">
          {record}
        </span>
      ),
    },
    {
      title: "",
      dataIndex: "_id",
      key: "actions",
      render: (record, rowData) => (
        <Dropdown
          menu={{
            items: [
              {
                key: "edit",
                label: t("edit"),
                icon: <LuPencil size={16} />,
                onClick: () => {
                  console.log("Edit", record);
                  router.navigate(`/settings/flows/${record}`);
                },
              },
              {
                key: "delete",
                label: t("delete"),
                danger: true,
                icon: <LuTrash2 size={16} />,
                onClick: () => {
                  Modal.confirm({
                    title: t("delete_flow"),
                    content: t("delete_flow_confirmation", {
                      selectedFlow: rowData?.name,
                    }),
                    icon: (
                      <div className="modal-icon">
                        <IconsAll.TrashIcon />
                      </div>
                    ),
                    okText: t("delete"),
                    okType: "primary",
                    cancelText: t("cancel"),
                    maskClosable: true,
                    className: "modal-delete",
                    onOk: async () => {
                      await axiosInstance.delete(
                        Apis.DELETE_FLOW({ flow_id: record }),
                      );
                      await getFlows(
                        pagination.currentPage,
                        pagination.pageSize,
                        searchFlow,
                      );
                    },
                  });
                },
              },
            ],
          }}
          trigger={["click"]}
        >
          <div>
            <IconsAll.MoreIcon />
          </div>
        </Dropdown>
      ),
    },
  ];

  const getFlows = async (page, size, search) => {
    try {
      setLoader(true);
      const response = await axiosInstance.get(
        Apis.GET_FLOWS({
          limit: size,
          page: page,
          filter: search,
        }),
      );
      setFlowsData(response.data?.payload?.data?.data || []);
      updatePagination(
        "totalItems",
        response.data?.payload?.meta?.total_found || 0,
      );
      updatePagination("currentPage", page || 1);
      updatePagination("pageSize", size);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getFlows(pagination.currentPage, pagination.pageSize, searchFlow);
  }, []);

  const getSearchedFlow = useCallback(
    debounce((search, page) => {
      getFlows(page, pagination.pageSize, search);
    }, 700),
    [pagination.pageSize],
  );

  return (
    <Flex className="w-full">
      <div className="px-4 xl:px-16 w-full">
        <div className="primary-header pt-9 pb-5">
          <LeftSideTitlePart
            pageTitle={t("flows")}
            pageSubTitle={t("setting")}
            pageIcon={true}
            pageInnerIcon={<IconsAll.FlowsIcon />}
            backButton={false}
          />
          <RightSIdeTitlePart
            isFilterShow={true}
            searchTerm={searchFlow}
            setSearchTerm={setSearchFlow}
            debouncedInit={getSearchedFlow}
            addButton={true}
            handleAddClick={() => {
              dispatch(
                handleFlowActions({
                  flag: "trigger",
                  data: {},
                  nodeId: "",
                  source: {},
                }),
              );
            }}
          />
        </div>
        <section className="mt-4 w-full h-4/5">
          <TableStriped
            columns={columns}
            dataSource={flowsData}
            rowKey={(record) => record?._id}
            pagination={{
              pageSizeOptions: [10, 25, 50],
              current: pagination.currentPage,
              pageSize: pagination.pageSize,
              total: pagination.totalItems,
              hideOnSinglePage: true,
              showSizeChanger: true,
              locale: { items_per_page: "" },
              onChange: async (page, size) => {
                await getFlows(page, size);
              },
            }}
            loading={{
              spinning: loader,
              indicator: <Spin indicator={<div className="custom-loader" />} />,
            }}
          />
          <DrawerStyle
            width={870}
            open={isActionDrawerOpen}
            onClose={() => {
              dispatch(
                handleFlowActions({
                  flag: null,
                  data: {},
                  nodeId: "",
                  source: {},
                }),
              );
            }}
            closeIcon={<IconsAll.CloseIconCustom />}
            destroyOnClose
          >
            <FlowActionForm setFlowsData={setFlowsData} />
          </DrawerStyle>
        </section>
      </div>
    </Flex>
  );
};

export default withRouter(Flows);
