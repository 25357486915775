import { Input, InputNumber } from "antd";
import React, { useEffect } from "react";
import { getTranslatedLabel } from "../../../Helpers/utils";

const CustomInput = ({ item, updateFormData, formData, isValidationError }) => {
  useEffect(() => {
    if (formData?.[item.path] === undefined) {
      updateFormData(
        item.schema_definition?.default
          ? item.schema_definition?.default
          : item?.type === "String"
            ? ""
            : item?.type === "Number"
              ? 0
              : null,
      );
    }
  }, [formData?.[item.path]]);

  const handleChange = (value) => {
    updateFormData(value);
  };

  return item?.type === "Number" ? (
    <InputNumber
      defaultValue={
        item?.schema_definition?.default &&
        item?.schema_definition?.default !== ""
          ? item?.schema_definition?.default
          : 0
      }
      placeholder={getTranslatedLabel(item?.meta?.options?.placeholder)}
      id={item?._id}
      value={formData?.[item.path]}
      onChange={handleChange}
      disabled={!!item?.meta?.readonly}
      className={`project-custom-input ${isValidationError ? "border-[red]" : ""}`}
    />
  ) : (
    <Input
      defaultValue={item?.schema_definition?.default || ""}
      placeholder={getTranslatedLabel(item?.meta?.options?.placeholder)}
      id={item?._id}
      disabled={item?.type === "ObjectId" || !!item?.meta?.readonly}
      value={formData?.[item.path]}
      onChange={(e) => handleChange(e.target.value)}
      className={`project-custom-input ${isValidationError ? "border-[red]" : ""}`}
    />
  );
};

export default CustomInput;
