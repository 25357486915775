import React from "react";
import withRouter from "../Common/withRouter";
import { ConfigProvider, Layout } from "antd";
import SidebarLayout from "./SidebarLayout";
import { ThemeProvider, styled } from "styled-components";
import { useSelector } from "react-redux";
import { themes } from "../config";
const { Content } = Layout;

// Define the StyleLayout styled component outside the functional component
const StyleLayout = styled(Layout)`
  margin-left: ${() =>
    themes[useSelector((state) => state.theme.name)].components.Menu
      .verticalSidebarWidth}px;
  position: relative;
  // padding: calc(${() =>
    themes[useSelector((state) => state.theme.name)].token
      .controlHeight}px * 2) 24px 0;
  padding: 0 24px 0;

  .ant-breadcrumb {
    ol {
      justify-content: end;
    }
  }

  @media screen and (max-width: 768px) {
    margin-left: 0;
  }
`;

const LayoutComponents = ({ children }) => {
  const currentTheme = useSelector((state) => state.theme);
  return (
    <ThemeProvider theme={currentTheme}>
      <ConfigProvider theme={currentTheme}>
        <SidebarLayout />
        <Layout style={{ minHeight: "100vh" }}>
          <StyleLayout
            id="antLayoutContent"
            style={{
              marginLeft: "64px",
              padding: 0,
              background: "var(--body-bg)",
              zIndex: 1,
            }}
          >
            <Content>{children}</Content>
          </StyleLayout>
        </Layout>
      </ConfigProvider>
    </ThemeProvider>
  );
};

export default withRouter(LayoutComponents);
