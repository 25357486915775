/* eslint-disable react/react-in-jsx-scope */
import { Navigate } from "react-router-dom";
import Dashboard from "../Pages/Dashboard";
import UserProfile from "../Pages/Authentication/UserProfile";
import Content from "../Pages/Content/Content";
import Media from "../Pages/MediaLibrary/Media";
import Help from "../Pages/Help/Help";
import Instellingen from "../Pages/Instellingen/Instellingen";
import Gebruikers from "../Pages/Gebruikers/Gebruikers";
import TenantList from "../Pages/Tenant/TenantList";
import AdminSettings from "../Pages/Admin/Settings/AdminSettings";
import NotFound from "../Pages/NotFound/NotFound";

const authProtectedRoutes = [
  // ======= Dashboard =========
  { path: "*", component: <Navigate to="/dashboard" /> },
  { path: "/", exact: true, component: <Navigate to="/dashboard" /> },
  {
    path: "/dashboard",
    component: <Dashboard />,
    roles: ["nox-admin", "admin-access", "app-access"],
  },

  // ======= Content =========
  {
    path: "/content/:table?/:action?/:id?",
    exact: true,
    component: <Content />,
    roles: ["admin-access", "app-access"],
  },

  // ======= Users =========
  {
    path: "/users/:pages?/:page?",
    exact: true,
    component: <Gebruikers />,
    roles: ["nox-admin", "admin-access", "app-access"],
  },
  {
    path: "/admin/settings/:pages?/:field?",
    exact: true,
    component: <AdminSettings />,
    roles: ["nox-admin"],
  },

  // ======= Media library =========
  {
    path: "/media-library/:folderId?",
    exact: true,
    component: <Media />,
    roles: ["admin-access"],
  },
  {
    path: "/media-library/file/:fileId/edit",
    exact: true,
    component: <Media />,
    roles: ["admin-access"],
  },

  // ======= App Guide / help =========
  {
    path: "/help",
    exact: true,
    component: <Help />,
    roles: ["admin-access"],
  },

  // ======= Settings =========
  {
    path: "/settings/:pages?/:field?",
    exact: true,
    component: <Instellingen />,
    roles: ["admin-access"],
  },

  // ======= Tenant List =========
  {
    path: "/tenant-list",
    exact: true,
    component: <TenantList />,
    roles: ["nox-admin"],
  },

  // user profile
  {
    path: "/user-profile",
    component: <UserProfile />,
    roles: ["nox-admin", "admin-access", "app-access"],
  },
];

const publicRoutes = [
  // Authentication
  {
    path: "/not-found",
    component: <NotFound />,
    roles: ["nox-admin", "admin-access", "app-access"],
  },
];

export { publicRoutes, authProtectedRoutes };
