import React, { useEffect, useRef, useState } from "react";
import { Flex, Card, Menu, Image, Spin } from "antd";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import NoxVersion from "../Assets/images/Icon/Side_bar_icon/nox-version.svg";
import { useDispatch, useSelector } from "react-redux";
import { setActiveTab } from "../Redux/slices/activeTab.reducer";
import { themes } from "../config";
import { store } from "../Redux/store";
import useScreenWidth from "../Helpers/custom-hooks/useScreenWidth";
import { tabletBreakpoint } from "../Helpers/utils";
import Backdrop from "../Layouts/Backdrop";
import { CgMenuRightAlt } from "react-icons/cg";
import { IoMdClose } from "react-icons/io";
import * as LucideIcons from "lucide-react";
import { LuDatabase } from "react-icons/lu";

// Card Style
export const SubMenuHeaderStyle = styled(Card)`
  background-color: var(--simplebar-active-bg);
  border-bottom: 1px solid var(--input-field-border);
  border-block-start: none;
  border-inline-end: 1px solid var(--input-field-border);
  width: 100%;
  min-height: calc(
    ${() =>
        themes[useSelector((state) => state.theme.name)].companyLogoHeight}px *
      4
  );
  border-radius: 0;
  display: flex;
  align-items: center;
  padding-inline-start: 10px;
  overflow: hidden;
  .ant-card-body {
    padding: 0;

    .company-info-main {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-inline: 15px;
    }

    .company-info {
      > * {
        margin-bottom: 0;
      }

      .company-info-title {
        font-size: 16px;
        font-weight: 700;
        font-family: ${() =>
          themes[useSelector((state) => state.theme.name)].token.fontFamily};
        color: var(--heading-text);
        line-height: 1;
      }

      p {
        font-size: 14px;
        font-weight: 300;
        color: var(--heading-text);
        opacity: 0.6;
        max-width: 140px;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
`;

export const SubMenuListStyle = styled(Menu)`
  background-color: var(--simplebar-active-bg);
  padding-left: 5px;
  border-inline-end: 1px solid var(--input-field-border) !important;
  height: calc(100vh - 114px);
  overflow-y: auto;

  .ant-menu-item {
    display: flex;
    align-items: center;
    border: 1px solid transparent;
    .ant-menu-item-icon {
      flex-shrink: 0;
    }
    .ant-menu-title-content {
      text-overflow: unset !important;
      width: 100%;
      color: var(--heading-text);
      a {
        color: var(--heading-text);
        font-size: 16px;
        font-weight: 400;
      }
    }

    &.ant-menu-item-selected {
      background-color: var(--sidebar-active-menu-bg);
      border: 1px solid var(--sidebar-active-menu-border);
      color: ${() =>
        themes[useSelector((state) => state.theme.name)].components.Menu
          .itemBg};
    }
  }

  .ant-menu-item-divider {
    margin: 16px 24px;
    border-color: var(--input-field-border);
  }
  .ant-menu-submenu {
    .ant-menu-submenu-title {
      .ant-menu-title-content {
        font-size: 16px;
        font-weight: 400;
        color: var(--heading-text);
        a {
          color: var(--heading-text);
        }
      }
    }
  }
`;

export const SidebarContainer = styled(Flex)`
  background-color: var(--simplebar-active-bg);

  .resize-handle {
    position: absolute;
    width: 4px;
    height: 100%;
    top: 0;
    right: 0;
    cursor: ew-resize;
    background-color: transparent;
  }

  .resize-handle:hover {
    background-color: rgba(0, 0, 0, 0.2);
    width: 4px;
  }
`;

function getItem(className, label, key, icon, children, theme, type) {
  return {
    key,
    icon,
    children,
    label,
    theme,
    type,
    className,
  };
}

const SubMenu = ({
  items,
  isSubMenuLogo,
  isSubMenuVersion,
  currentPage,
  openKeys,
  onOpenChange,
  defaultOpenKeys,
  isItemsLoading,
  setCurrentCollection,
  disabled,
}) => {
  // eslint-disable-next-line no-unused-vars
  const { icons, createLucideIcon, ...filteredIcons } = LucideIcons;
  const containerRef = useRef(null);
  const activeTab = useSelector((state) => state.activeTab[currentPage]);
  const dispatch = useDispatch();
  const screenWidth = useScreenWidth();
  const isTabletOrSmaller = screenWidth <= tabletBreakpoint;
  const [showSubMenu, setShowSubMenu] = useState(!isTabletOrSmaller);
  const { selectedFolderData } = useSelector((state) => state.media);
  const [menuOpenKeys, setMenuOpenKeys] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    currentPage === "content" &&
      setMenuOpenKeys((prev) => [
        ...prev,
        ...(findParentTabs(activeTab?.currentTab, items) || []),
      ]);
  }, [activeTab?.currentTab]);

  useEffect(() => {
    isTabletOrSmaller && setShowSubMenu((prev) => !prev);
  }, [selectedFolderData]);

  function getItems(items) {
    let parsedItem = [];
    (items || [])?.forEach((item) => {
      parsedItem.push(
        getItem(
          item?.name?.toString() === activeTab?.currentTab?.toString()
            ? "bg-(--sidebar-active-menu-bg)! truncate sachin"
            : "",
          currentPage === "media" || item?.meta?.is_folder ? (
            item?.name
          ) : (
            <Link
              to={item?.link}
              onClick={(e) => {
                e.preventDefault(), e.stopPropagation(), handleMenuClick(item);
              }}
              className="block truncate w-[90%]"
            >
              {item?.name}
            </Link>
          ),
          currentPage === "media" ? item?.key : item?.name,
          currentPage === "content" ? (
            item?.meta?.icon ? (
              filteredIcons[item?.meta?.icon] &&
              React.createElement(filteredIcons[item?.meta?.icon], {
                size: 22,
                color: item?.meta?.color || "var(--project-base)",
              })
            ) : (
              <LuDatabase size={22} color="var(--project-base)" />
            )
          ) : (
            item?.icon
          ),
          ...(item?.children
            ? [
                currentPage === "content"
                  ? getItems(item.children)
                  : item.children,
              ]
            : []),
        ),
      );
      if (item?.divider === true) {
        parsedItem.push({
          type: "divider",
        });
      }
    });
    return parsedItem;
  }

  const handleMenuClick = (item) => {
    isTabletOrSmaller && setShowSubMenu(false);
    if (item?.link) {
      setCurrentCollection && setCurrentCollection(item);
      navigate(item?.link);
      dispatch(
        setActiveTab({
          page: currentPage,
          tab: item?.key || item?.name,
          redirectionLink: item?.link,
        }),
      );
    }
  };

  const handleMouseDown = (e) => {
    const startX = e.clientX;
    const startWidth = containerRef.current.offsetWidth;
    const handleMouseMove = (e) => {
      const diffInX = e.clientX - startX;
      const newWidth = startWidth + diffInX;
      if (newWidth >= 200 && newWidth <= 600) {
        containerRef.current.style.width = `${newWidth}px`;
      }
    };

    const handleMouseUp = () => {
      localStorage.setItem("sidebarWidth", containerRef.current.offsetWidth);
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const user = store.getState()?.user;

  const findParentTabs = (activeTabName, items, parents = []) => {
    for (let item of items) {
      if (item.name === activeTabName) {
        return parents;
      }
      if (item.children) {
        const result = findParentTabs(activeTabName, item.children, [
          ...parents,
          item.name,
        ]);
        if (result) {
          return result;
        }
      }
    }
    return null;
  };

  useEffect(() => {
    // Load stored width from localStorage
    const savedWidth = localStorage.getItem("sidebarWidth");
    if (savedWidth && containerRef.current) {
      containerRef.current.style.width = `${savedWidth}px`;
    }
  }, []);

  return showSubMenu ? (
    <SidebarContainer vertical className="xl:z-[0] z-[9999]">
      <div
        ref={containerRef}
        className={`w-[220px] top-0 bottom-0 xl:z-[1] z-[9999] lg:min-h-screen modal-sidebar-media ${isTabletOrSmaller ? "fixed" : "sticky"}`}
      >
        {isSubMenuLogo ? (
          <SubMenuHeaderStyle>
            <Flex gap={12} className="company-info-main">
              <img
                src={`/Signals/${user.latencyData?.status}.svg`}
                size={24}
                alt="nox"
              />
              <div className="company-info">
                <h3 className="company-info-title">
                  {user?.userProfile?.tenant?.name}
                </h3>
                {user?.userProfile?.tenant?.primary_domain && (
                  <p>Website: {user?.userProfile?.tenant?.primary_domain}</p>
                )}
              </div>
            </Flex>
          </SubMenuHeaderStyle>
        ) : null}
        {isItemsLoading ? (
          <Spin
            indicator={<div className="custom-loader" />}
            className={"mt-5"}
          />
        ) : (
          <SubMenuListStyle
            items={getItems(items)}
            onClick={({ key }) =>
              handleMenuClick(items.find((i) => i.name === key))
            }
            selectedKeys={[activeTab?.currentTab]}
            openKeys={menuOpenKeys}
            onOpenChange={(openKeys) => setMenuOpenKeys(openKeys)}
            defaultOpenKeys={defaultOpenKeys}
            mode={items?.some((item) => item?.children) ? "inline" : undefined}
            {...(currentPage === "media"
              ? { openKeys: openKeys, onOpenChange }
              : {})}
            className="custom-scrollbar pr-0 remove-nested-bg"
            activeKey={[activeTab?.currentTab]}
            inlineIndent={12}
            disabled={disabled}
          />
        )}
        {isSubMenuVersion ? (
          <div className="px-6 pt-3 custom-mt-auto bg-[var(--simplebar-active-bg)] border-t border-[var(--input-field-border)] border-e">
            <Image preview={false} src={NoxVersion} alt="" width={21} />
            <span className="align-bottom mt-[-7px] ms-2">v1.1.0</span>
          </div>
        ) : null}
        {(currentPage === "media" || currentPage === "content") && (
          <div className="resize-handle" onMouseDown={handleMouseDown}></div>
        )}
        {isTabletOrSmaller && (
          <button
            className="fixed top-[16px] left-[310px] z-10 w-7 h-7 bg-(--logo-bg) flex items-center justify-center rounded-full"
            onClick={() => setShowSubMenu(false)}
          >
            <IoMdClose size={18} className="text-white rotate-180" />
          </button>
        )}
      </div>
      {isTabletOrSmaller && <Backdrop onClick={() => setShowSubMenu(false)} />}
    </SidebarContainer>
  ) : (
    <button
      className="fixed top-1 !z-[99] w-10 h-10 bg-(--logo-bg) flex items-center justify-center rounded-e-md"
      onClick={() => setShowSubMenu(true)}
    >
      <CgMenuRightAlt size={26} className="text-white rotate-180" />
    </button>
  );
};

export default SubMenu;
