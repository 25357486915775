import TextArea from "antd/es/input/TextArea";
import React, { useEffect } from "react";
import { getTranslatedLabel } from "../../../Helpers/utils";

const CustomTextArea = ({
  item,
  updateFormData,
  formData,
  isValidationError,
}) => {
  useEffect(() => {
    if (formData?.[item.path] === undefined) {
      updateFormData(item.schema_definition?.default || "");
    }
  }, [formData?.[item.path]]);
  return (
    <TextArea
      placeholder={getTranslatedLabel(item?.meta?.options?.placeholder)}
      onChange={(e) => updateFormData(e.target.value || "")}
      value={formData?.[item?.path] || ""}
      defaultValue={item?.schema_definition?.default || ""}
      className={`project-custom-input ${isValidationError ? "border-[red]" : ""}`}
      disabled={!!item?.meta?.readonly}
      rows={4}
    />
  );
};

export default CustomTextArea;
