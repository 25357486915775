import { arrayMove } from "@dnd-kit/sortable";

function getDragDepth(offset, indentationWidth) {
  return Math.round(offset / indentationWidth);
}

export function getProjection(
  items,
  activeId,
  overId,
  dragOffset,
  indentationWidth,
) {
  const overItemIndex = items.findIndex(({ _id }) => _id === overId);
  const activeItemIndex = items.findIndex(({ _id }) => _id === activeId);
  const activeItem = items[activeItemIndex];
  const newItems = arrayMove(items, activeItemIndex, overItemIndex);
  const previousItem = newItems[overItemIndex - 1];
  const nextItem = newItems[overItemIndex + 1];
  const dragDepth = getDragDepth(dragOffset, indentationWidth);
  const projectedDepth = activeItem.depth + dragDepth;
  const maxDepth = getMaxDepth({
    previousItem,
  });
  const minDepth = getMinDepth({ nextItem });
  let depth = projectedDepth;

  if (projectedDepth >= maxDepth) {
    depth = maxDepth;
  } else if (projectedDepth < minDepth) {
    depth = minDepth;
  }

  return { depth, maxDepth, minDepth, parent: getParentId() };

  function getParentId() {
    if (depth === 0 || !previousItem) {
      return null;
    }

    if (depth === previousItem.depth) {
      return previousItem.parent;
    }

    if (depth > previousItem.depth) {
      return previousItem._id;
    }

    const newParent = newItems
      .slice(0, overItemIndex)
      .reverse()
      .find((item) => item.depth === depth)?.parent;

    return newParent ?? null;
  }
}

function getMaxDepth({ previousItem }) {
  if (previousItem) {
    return previousItem.depth + 1;
  }

  return 0;
}

function getMinDepth({ nextItem }) {
  if (nextItem) {
    return nextItem.depth;
  }

  return 0;
}

function flatten(items, parent = null, depth = 0) {
  return items.reduce((acc, item, index) => {
    return [
      ...acc,
      { ...item, parent, depth, index },
      ...flatten(item.children, item._id, depth + 1),
    ];
  }, []);
}

export function flattenTree(items) {
  return flatten(items);
}

export function buildTree(flattenedItems) {
  const root = { _id: "root", children: [] };
  const nodes = { [root._id]: root };

  const items = flattenedItems
    .sort((a, b) => (a.meta?.sort || 0) - (b?.meta?.sort || 0))
    .map((item) => ({ ...item, children: [] }));

  for (const item of items) {
    const { _id, children } = item;
    const parent = item.parent ?? root._id;
    const trueParent = nodes[parent] ?? findItem(items, parent);

    nodes[_id] = { _id, children };
    trueParent?.children.push(item);
  }
  return root?.children;
}

export function filterTree(items, searchTerm) {
  if (!searchTerm) return items;

  function filter(items) {
    const filteredItems = [];

    for (const item of items) {
      const matches = item?.collection_id
        ?.toLowerCase()
        ?.includes(searchTerm.toLowerCase());

      const filteredChildren = item.children ? filter(item.children) : [];

      if (matches || filteredChildren.length > 0) {
        filteredItems.push({
          ...item,
          children: filteredChildren,
        });
      }
    }
    return filteredItems;
  }

  return filter(items);
}

export function findItem(items, itemId) {
  return items.find(({ _id }) => _id === itemId);
}

export function findItemDeep(items, itemId) {
  for (const item of items) {
    const { _id, children } = item;

    if (_id === itemId) {
      return item;
    }

    if (children.length) {
      const child = findItemDeep(children, itemId);

      if (child) {
        return child;
      }
    }
  }

  return undefined;
}

export function setProperty(items, _id, property, setter) {
  for (const item of items) {
    if (item._id === _id) {
      item[property] = setter(item[property]);
      continue;
    }

    if (item.children.length) {
      item.children = setProperty(item.children, _id, property, setter);
    }
  }

  return [...items];
}

function countChildren(items, count = 0) {
  return items.reduce((acc, { children }) => {
    if (children.length) {
      return countChildren(children, acc + 1);
    }

    return acc + 1;
  }, count);
}

export function getChildCount(items, id) {
  if (!id) {
    return 0;
  }

  const item = findItemDeep(items, id);

  return item ? countChildren(item.children) : 0;
}

export function removeChildrenOf(items, ids) {
  const excludeParentIds = [...ids];

  return items.filter((item) => {
    if (item.parent && excludeParentIds.includes(item.parent)) {
      if (item.children.length) {
        excludeParentIds.push(item._id);
      }
      return false;
    }

    return true;
  });
}
