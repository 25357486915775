import IconsAll from "../IconsAll";
import React from "react";

export const newFieldsList = {
  group_fields: [
    {
      id: "object",
      icon: <IconsAll.GroupFields />,
      fieldsList: [
        {
          name: "name",
          required: true,
          placeholder: "a_unique_name",
          width: "half",
        },
        {
          name: "group",
          placeholder: "group",
          width: "half",
        },
        {
          name: "type",
          required: true,
          fieldTypes: {
            disabled: true,
            allowedTypes: [{ value: "Object", label: "Object" }],
          },
          width: "half",
        },
      ],
      initialValues: { type: "Object" },
      nativeFieldType: "Object",
      schemaType: "Object",
    },
    {
      id: "items",
      icon: <IconsAll.ItemsFields />,
      fieldsList: [
        {
          name: "name",
          required: true,
          placeholder: "a_unique_name",
          width: "half",
        },
        {
          name: "group",
          placeholder: "group",
          width: "half",
        },
        {
          name: "type",
          required: true,
          fieldTypes: {
            disabled: true,
            allowedTypes: [{ value: "Array", label: "Array" }],
          },
          width: "half",
        },
      ],
      initialValues: { type: "Array" },
      nativeFieldType: "Object",
      schemaType: "Array",
    },
  ],
  text_and_numbers: [
    {
      id: "input",
      icon: <IconsAll.FieldInput />,
      fieldsList: [
        {
          name: "name",
          required: true,
          placeholder: "a_unique_name",
          width: "half",
        },
        {
          name: "group",
          placeholder: "group",
          width: "half",
        },
        {
          name: "type",
          required: true,
          fieldTypes: {
            allowedTypes: [
              { value: "String", label: "String" },
              { value: "ObjectId", label: "UniqueId" },
              { value: "Number", label: "Number" },
            ],
          },
          width: "half",
        },
        {
          name: "default",
          placeholder: "default_value",
          width: "half",
        },
        {
          name: "required",
          width: "half",
        },
        {
          name: "placeholder",
          width: "half",
        },
        // {
        //   name: "icon_left",
        //   width: "half",
        // },
        // {
        //   name: "icon_right",
        //   width: "half",
        // },
      ],
      initialValues: {
        type: "String",
        // icon_left: "Icon 1",
        // icon_right: "Icon 1",
      },
      nativeFieldType: "Single",
      schemaType: "String",
    },

    {
      id: "autocomplete",
      icon: <IconsAll.FieldInput />,
      fieldsList: [
        {
          name: "name",
          required: true,
          placeholder: "a_unique_name",
          width: "half",
        },
        {
          name: "group",
          placeholder: "group",
          width: "half",
        },
        {
          name: "type",
          required: true,
          fieldTypes: {
            disabled: true,
            allowedTypes: [{ value: "String", label: "String" }],
          },
          width: "half",
        },
        {
          name: "default",
          placeholder: "default_value",
          width: "half",
        },
        {
          name: "required",
          width: "half",
        },
        {
          name: "url",
          width: "half",
          placeholder: "https://example.com/search?q={{value}}",
        },
        {
          name: "results_path",
          width: "half",
          // placeholder: "result.predictions",
        },
        {
          name: "text_path",
          width: "half",
          // placeholder: "structured_main_text",
        },
        {
          name: "value_path",
          width: "half",
          // placeholder: "structured_main_value",
        },
        {
          name: "trigger",
          width: "half",
        },
        {
          name: "rate",
          width: "half",
        },
        {
          name: "placeholder",
          width: "half",
        },
        // {
        //   name: "font",
        //   width: "half",
        // },
        // {
        //   name: "icon_left",
        //   width: "half",
        // },
        // {
        //   name: "icon_right",
        //   width: "half",
        // },
      ],

      initialValues: {
        type: "String",
        trigger: "Throttle",
        rate: 500,
        // font: "Serif",
        // icon_left: "Icon 1",
        // icon_right: "Icon 1",
      },
      nativeFieldType: "Single",
      schemaType: "String",
    },
    // {
    //   id: "block_editor",
    // icon: <IconsAll.FieldBlockEditor />,
    // label: "Block Editor",
    // },
    // {
    //   id: "code",
    //   label: (
    //     <>
    //       <div className="custom-tabs | min-h-24 min-w-36 flex items-center justify-center mb-1 rounded-md">
    //         <IconsAll.FieldBlockEditor />
    //       </div>
    //       Code
    //     </>
    //   ),
    // },
    {
      id: "textarea",
      icon: <IconsAll.FieldTextArea />,
      fieldsList: [
        {
          name: "name",
          required: true,
          placeholder: "a_unique_name",
          width: "half",
        },
        {
          name: "group",
          placeholder: "group",
          width: "half",
        },
        {
          name: "type",
          required: true,
          fieldTypes: {
            disabled: true,
            allowedTypes: [{ value: "String", label: "String" }],
          },
          width: "half",
        },
        {
          name: "required",
          width: "half",
        },
        {
          name: "default",
          placeholder: "default_value",
          width: "full",
        },
        {
          name: "placeholder",
          width: "half",
        },
        // {
        //   name: "font",
        //   width: "half",
        // },
        // {
        //   name: "icon_left",
        //   width: "half",
        // },
        // {
        //   name: "icon_right",
        //   width: "half",
        // },
      ],
      initialValues: {
        type: "String",
        // font: "Serif",
        // icon_left: "Icon 1",
        // icon_right: "Icon 1",
      },
      nativeFieldType: "Single",
      schemaType: "String",
    },
    {
      id: "wysiwyg",
      icon: <IconsAll.FieldMarkDown />,
      fieldsList: [
        {
          name: "name",
          required: true,
          placeholder: "a_unique_name",
          width: "half",
        },
        {
          name: "group",
          placeholder: "group",
          width: "half",
        },
        {
          name: "type",
          required: true,
          fieldTypes: {
            disabled: true,
            allowedTypes: [{ value: "String", label: "String" }],
          },
          width: "half",
        },
        {
          name: "required",
          width: "half",
        },
        {
          name: "default",
          placeholder: "default_value",
          width: "full",
        },
        // {
        //   name: "toolbar",
        //   placeholder: "Select Item...",
        //   width: "half",
        // },
        // {
        //   name: "font",
        //   width: "half",
        // },
        // {
        //   name: "folder",
        //   width: "half",
        // },
        // {
        //   name: "static_access_token",
        //   width: "half",
        // },
      ],
      initialValues: {
        type: "String",
        // font: "Serif",
      },
      nativeFieldType: "Single",
      schemaType: "String",
    },
    // {
    //   id: "markdown",
    // icon: <IconsAll.FieldMarkDown />,
    // label: "Markdown",
    // },
    {
      id: "tags",
      icon: <IconsAll.FieldTags />,
      fieldsList: [
        {
          name: "name",
          required: true,
          placeholder: "a_unique_name",
          width: "half",
        },
        {
          name: "group",
          placeholder: "group",
          width: "half",
        },
        {
          name: "type",
          required: true,
          fieldTypes: {
            disabled: true,
            allowedTypes: [{ value: "Array", label: "Array" }],
          },
          width: "half",
        },
        {
          name: "default",
          placeholder: "default_value",
          width: "half",
        },
        {
          name: "required",
          width: "half",
        },
        {
          name: "placeholder",
          width: "half",
        },
        // {
        //   name: "alphabetize",
        //   width: "half",
        // },
        // {
        //   name: "allow_other",
        //   width: "half",
        // },
        // {
        //   name: "whitespace",
        //   width: "half",
        // },
        // {
        //   name: "capitalization",
        //   width: "half",
        // },
        // {
        //   name: "icon_left",
        //   width: "half",
        // },
        // {
        //   name: "icon_right",
        //   width: "half",
        // },
      ],
      initialValues: {
        type: "Array",
        // whitespace: "Replace with hyphen",
        // capitalization: "Convert Uppercase",
        // icon_left: "Icon 1",
        // icon_right: "Icon 1",
      },
      nativeFieldType: "Array",
      schemaType: "String",
    },
    {
      id: "json",
      icon: <IconsAll.FieldTextArea />,
      fieldsList: [
        {
          name: "name",
          required: true,
          placeholder: "a_unique_name",
          width: "half",
        },
        {
          name: "group",
          placeholder: "group",
          width: "half",
        },
        {
          name: "type",
          required: true,
          fieldTypes: {
            disabled: true,
            allowedTypes: [{ value: "String", label: "String" }],
          },
          width: "half",
        },
        {
          name: "required",
          width: "half",
        },
        {
          name: "default",
          placeholder: "default_value",
          width: "full",
        },
        {
          name: "placeholder",
          width: "half",
        },
        // {
        //   name: "font",
        //   width: "half",
        // },
        // {
        //   name: "icon_left",
        //   width: "half",
        // },
        // {
        //   name: "icon_right",
        //   width: "half",
        // },
      ],
      initialValues: {
        type: "String",
        // font: "Serif",
        // icon_left: "Icon 1",
        // icon_right: "Icon 1",
      },
      nativeFieldType: "Single",
      schemaType: "String",
    },
  ],
  selection: [
    {
      id: "toggle",
      icon: <IconsAll.FieldToggle />,
      label: "Toggle",
      fieldsList: [
        {
          name: "name",
          required: true,
          placeholder: "a_unique_name",
          width: "half",
        },
        {
          name: "group",
          placeholder: "group",
          width: "half",
        },
        {
          name: "type",
          required: true,
          fieldTypes: {
            disabled: true,
            allowedTypes: [{ value: "Boolean", label: "Boolean" }],
          },
          width: "half",
        },
        {
          name: "default",
          placeholder: "default_value",
          width: "half",
        },
        {
          name: "required",
          width: "half",
        },
        {
          name: "placeholder",
          width: "half",
        },
        // {
        //   name: "icon_on",
        //   width: "half",
        // },
        // {
        //   name: "icon_off",
        //   width: "half",
        // },
        // {
        //   name: "color_on",
        //   width: "half",
        // },
        // {
        //   name: "color_off",
        //   width: "half",
        // },
        // {
        //   name: "label",
        //   width: "half",
        // },
      ],
      initialValues: {
        type: "Boolean",
        // whitespace: "Replace with hyphen",
        // capitalization: "Convert Uppercase",
        // icon_left: "Icon 1",
        // icon_right: "Icon 1",
      },
      nativeFieldType: "Single",
      schemaType: "Boolean",
    },
    {
      id: "datetime",
      icon: <IconsAll.FieldDateTime />,
      fieldsList: [
        {
          name: "name",
          required: true,
          placeholder: "a_unique_name",
          width: "half",
        },
        {
          name: "group",
          placeholder: "group",
          width: "half",
        },
        {
          name: "type",
          required: true,
          fieldTypes: {
            allowedTypes: [
              { value: "datetime", label: "Datetime" },
              { value: "date", label: "Date" },
              { value: "time", label: "Time" },
              { value: "timestamp", label: "Timestamp" },
            ],
          },
          width: "half",
        },
        {
          name: "default",
          placeholder: "default_value",
          width: "half",
        },
        {
          name: "required",
          width: "half",
        },
        {
          name: "placeholder",
          width: "half",
        },
        // {
        //   name: "include_seconds",
        //   width: "half",
        // },
        // {
        //   name: "use_24_hrs_format",
        //   width: "half",
        // },
      ],
      initialValues: {
        type: "datetime",
        // use_24_hrs_format: true,
      },
      nativeFieldType: "Single",
      schemaType: "Date",
    },
    // {
    //   id: "repeater",
    // icon: <IconsAll.FieldRepeater />,
    // label: "Repeater",
    // },
    // {
    //   id: "fieldmap",
    // icon: <IconsAll.FieldMap />,
    // label: "Map",
    // },
    {
      id: "color",
      icon: <IconsAll.FieldColor />,
      label: "Color",
      fieldsList: [
        {
          name: "name",
          required: true,
          placeholder: "a_unique_name",
          width: "half",
        },
        {
          name: "group",
          placeholder: "group",
          width: "half",
        },
        {
          name: "type",
          required: true,
          fieldTypes: {
            disabled: true,
            allowedTypes: [{ value: "String", label: "String" }],
          },
          width: "half",
        },
        {
          name: "required",
          width: "half",
        },
      ],
      initialValues: {
        type: "String",
      },
      nativeFieldType: "Single",
      schemaType: "String",
    },
    {
      id: "dropdown",
      icon: <IconsAll.FieldDropdown />,
      fieldsList: [
        {
          name: "name",
          required: true,
          placeholder: "a_unique_name",
          width: "half",
        },
        {
          name: "group",
          placeholder: "group",
          width: "half",
        },
        {
          name: "type",
          required: true,
          fieldTypes: {
            disabled: true,
            allowedTypes: [{ value: "String", label: "String" }],
          },
          width: "half",
        },
        {
          name: "required",
          width: "half",
        },
        {
          name: "placeholder",
          width: "half",
        },
        {
          name: "choices",
          placeholder: "default_value",
          width: "half",
        },
        {
          name: "default",
          placeholder: "default_value",
          width: "full",
        },
        // {
        //   name: "allow_other",
        //   width: "half",
        // },
        // {
        //   name: "allow_none",
        //   width: "half",
        // },
        // {
        //   name: "icon",
        //   width: "half",
        // },
      ],
      initialValues: {
        type: "String",
        // icon: "Manual",
      },
      nativeFieldType: "Single",
      schemaType: "String",
    },
    // {
    //   id: "icon",
    // icon: <IconsAll.FieldIcon />,
    // label: "Icon",
    // },
    {
      id: "checkboxes",
      icon: <IconsAll.FieldCheckboxes />,
      fieldsList: [
        {
          name: "name",
          required: true,
          placeholder: "a_unique_name",
          width: "half",
        },
        {
          name: "group",
          placeholder: "group",
          width: "half",
        },
        {
          name: "type",
          required: true,
          fieldTypes: {
            disabled: true,
            allowedTypes: [{ value: "Array", label: "Array" }],
          },
          width: "half",
        },
        {
          name: "required",
          width: "half",
        },
        {
          name: "choices",
          placeholder: "default_value",
          width: "half",
        },
        {
          name: "default",
          placeholder: "default_value",
          width: "full",
        },
        // {
        //   name: "allow_other",
        //   width: "half",
        // },
        // {
        //   name: "color",
        //   width: "half",
        // },
        // {
        //   name: "icon_on",
        //   width: "half",
        // },
        // {
        //   name: "icon_off",
        //   width: "half",
        // },
        // {
        //   name: "per_page",
        //   width: "half",
        // },
      ],
      initialValues: {
        type: "Array",
        // icon_on: "Search for icon",
        // icon_off: "Search for icon",
        // color: "#1677ff",
        // per_page: 8,
      },
      nativeFieldType: "Array",
      schemaType: "String",
    },
    // {
    //   id: "checkboxtree",
    // icon: <IconsAll.FieldCheckboxTree />,
    // label: "Checkboxes (Tree)",
    // },
    {
      id: "dropdown_multiple",
      icon: <IconsAll.FieldDropdownMultiple />,
      fieldsList: [
        {
          name: "name",
          required: true,
          placeholder: "a_unique_name",
          width: "half",
        },
        {
          name: "group",
          placeholder: "group",
          width: "half",
        },
        {
          name: "type",
          required: true,
          fieldTypes: {
            disabled: true,
            allowedTypes: [{ value: "String", label: "String" }],
          },
          width: "half",
        },
        {
          name: "required",
          width: "half",
        },
        {
          name: "choices",
          placeholder: "default_value",
          width: "half",
        },
        {
          name: "default",
          placeholder: "default_value",
          width: "full",
        },
        {
          name: "placeholder",
          width: "half",
        },
        // {
        //   name: "allow_other",
        //   width: "half",
        // },
        // {
        //   name: "allow_none",
        //   width: "half",
        // },
        // {
        //   name: "icon",
        //   width: "half",
        // },
      ],
      initialValues: {
        type: "Array",
        // icon: "Manual",
      },
      nativeFieldType: "Array",
      schemaType: "String",
    },
    {
      id: "radio",
      icon: <IconsAll.FieldRadioButtons />,
      fieldsList: [
        {
          name: "name",
          required: true,
          placeholder: "a_unique_name",
          width: "half",
        },
        {
          name: "group",
          placeholder: "group",
          width: "half",
        },
        {
          name: "type",
          required: true,
          fieldTypes: {
            disabled: true,
            allowedTypes: [{ value: "String", label: "String" }],
          },
          width: "half",
        },
        {
          name: "required",
          width: "half",
        },
        {
          name: "placeholder",
          width: "half",
        },
        {
          name: "choices",
          placeholder: "default_value",
          width: "half",
        },
        {
          name: "default",
          placeholder: "default_value",
          width: "full",
        },
        // {
        //   name: "allow_other",
        //   width: "half",
        // },
        // {
        //   name: "color",
        //   width: "half",
        // },
        // {
        //   name: "icon_on",
        //   width: "half",
        // },
        // {
        //   name: "icon_off",
        //   width: "half",
        // },
      ],
      initialValues: {
        type: "String",
        // icon: "Manual",
      },
      nativeFieldType: "Single",
      schemaType: "String",
    },
  ],
  media: [
    {
      id: "file",
      icon: <IconsAll.FieldFile />,
      fieldsList: [
        {
          name: "name",
          required: true,
          placeholder: "a_unique_name",
          width: "half",
        },
        {
          name: "group",
          placeholder: "group",
          width: "half",
          blackList: ["items"],
        },
        {
          name: "type",
          required: true,
          fieldTypes: {
            disabled: true,
            allowedTypes: [{ value: "ObjectId", label: "UniqueId" }],
          },
          width: "half",
        },
        {
          name: "required",
          width: "half",
        },
        {
          name: "folder",
          width: "half",
        },
      ],
      initialValues: {
        type: "ObjectId",
      },
      nativeFieldType: "Single",
      schemaType: "ObjectId",
    },
    {
      id: "file-image",
      icon: <IconsAll.FieldImage />,
      label: "",
      fieldsList: [
        {
          name: "name",
          required: true,
          placeholder: "a_unique_name",
          width: "half",
        },
        {
          name: "group",
          placeholder: "group",
          width: "half",
          blackList: ["items"],
        },
        {
          name: "type",
          required: true,
          fieldTypes: {
            disabled: true,
            allowedTypes: [{ value: "ObjectId", label: "UniqueId" }],
          },
          width: "half",
        },
        {
          name: "required",
          width: "half",
        },
        {
          name: "folder",
          width: "half",
        },
        // {
        //   name: "crop",
        //   width: "half",
        // },
      ],
      initialValues: {
        type: "ObjectId",
      },
      nativeFieldType: "Single",
      schemaType: "ObjectId",
    },
    {
      id: "files",
      icon: <IconsAll.FieldFiles />,
      fieldsList: [
        {
          name: "name",
          required: true,
          placeholder: "a_unique_name",
          width: "half",
        },
        {
          name: "group",
          placeholder: "group",
          width: "half",
          blackList: ["items"],
        },
        {
          name: "type",
          required: true,
          fieldTypes: {
            disabled: true,
            allowedTypes: [{ value: "Alias", label: "Alias" }],
          },
          width: "half",
        },
        {
          name: "required",
          width: "half",
        },
        {
          name: "folder",
          width: "half",
        },
      ],
      initialValues: {
        type: "Alias",
      },
      nativeFieldType: "Array",
      schemaType: "Alias",
    },
  ],
  relational: [
    {
      id: "list-m2m",
      icon: <IconsAll.FieldBuilder />,
      fieldsList: [
        {
          name: "name",
          required: true,
          placeholder: "a_unique_name",
          width: "half",
        },
        {
          name: "group",
          placeholder: "group",
          width: "half",
          blackList: ["items"],
        },
        {
          name: "type",
          required: true,
          fieldTypes: {
            disabled: true,
            allowedTypes: [{ value: "Alias", label: "Alias" }],
          },
          width: "half",
        },
        // {
        //   name: "layout",
        //   width: "half",
        // },
        {
          name: "required",
          width: "half",
        },
        {
          name: "foreign_key_table",
          width: "half",
        },
        {
          name: "display_template",
          width: "full",
        },
        // {
        //   name: "creating_items",
        //   width: "half",
        // },
        // {
        //   name: "selecting_items",
        //   width: "half",
        // },
        // {
        //   name: "per_page",
        //   width: "half",
        // },
        // {
        //   name: "junction_fields_location",
        //   width: "half",
        // },
        // {
        //   name: "allow_duplicates",
        //   width: "half",
        // },
        // {
        //   name: "item_link",
        //   width: "half",
        // },
      ],
      initialValues: {
        type: "Alias",
        // creating_items: true,
        // selecting_items: true,
        // junction_fields_location: "Bottom",
      },
      nativeFieldType: "Array",
      schemaType: "Alias",
    },
    {
      id: "list-m2a",
      icon: <IconsAll.FieldBuilder />,
      fieldsList: [
        {
          name: "name",
          required: true,
          placeholder: "a_unique_name",
          width: "half",
        },
        {
          name: "group",
          placeholder: "group",
          width: "half",
          blackList: ["items"],
        },
        {
          name: "type",
          required: true,
          fieldTypes: {
            disabled: true,
            allowedTypes: [{ value: "Alias", label: "Alias" }],
          },
          width: "half",
        },
        // {
        //   name: "layout",
        //   width: "half",
        // },
        {
          name: "required",
          width: "half",
        },
        {
          name: "foreign_key_table",
          width: "full",
          multiple: true,
        },
        {
          name: "display_template",
          width: "full",
        },
        // {
        //   name: "creating_items",
        //   width: "half",
        // },
        // {
        //   name: "selecting_items",
        //   width: "half",
        // },
        // {
        //   name: "per_page",
        //   width: "half",
        // },
        // {
        //   name: "junction_fields_location",
        //   width: "half",
        // },
        // {
        //   name: "allow_duplicates",
        //   width: "half",
        // },
        // {
        //   name: "item_link",
        //   width: "half",
        // },
      ],
      initialValues: {
        type: "Alias",
        // creating_items: true,
        // selecting_items: true,
        // junction_fields_location: "Bottom",
      },
      nativeFieldType: "Array",
      schemaType: "Alias",
    },
    {
      id: "list-o2m",
      icon: <IconsAll.FieldBuilder />,
      fieldsList: [
        {
          name: "name",
          required: true,
          placeholder: "a_unique_name",
          width: "half",
        },
        {
          name: "group",
          placeholder: "group",
          width: "half",
          blackList: ["items"],
        },
        {
          name: "type",
          required: true,
          fieldTypes: {
            disabled: true,
            allowedTypes: [{ value: "Alias", label: "Alias" }],
          },
          width: "half",
        },
        // {
        //   name: "layout",
        //   width: "half",
        // },
        {
          name: "required",
          width: "half",
        },
        {
          name: "foreign_key_table",
          width: "half",
        },
        {
          name: "foreign_key_column",
          width: "half",
        },
        {
          name: "display_template",
          width: "full",
        },
        // {
        //   name: "creating_items",
        //   width: "half",
        // },
        // {
        //   name: "selecting_items",
        //   width: "half",
        // },
        // {
        //   name: "allow_duplicates",
        //   width: "half",
        // },
        // {
        //   name: "item_link",
        //   width: "half",
        // },
      ],
      initialValues: {
        type: "Alias",
        // creating_items: true,
        // selecting_items: true,
      },
      nativeFieldType: "Array",
      schemaType: "Alias",
    },
    // {
    //   id: "tree_view",
    //   label: (
    //     <>
    //       <div className="custom-tabs | min-h-24 min-w-36 flex items-center justify-center mb-1 rounded-md">
    //         <IconsAll.FieldTreeview />
    //       </div>
    //       Tree View
    //     </>
    //   ),
    // },
    {
      id: "list-m2o",
      icon: <IconsAll.FieldManyToOne />,
      fieldsList: [
        {
          name: "name",
          required: true,
          placeholder: "a_unique_name",
          width: "half",
        },
        {
          name: "group",
          placeholder: "group",
          width: "half",
          blackList: ["items"],
        },
        {
          name: "type",
          required: true,
          fieldTypes: {
            disabled: true,
            allowedTypes: [{ value: "ObjectId", label: "UniqueId" }],
          },
          width: "half",
        },
        {
          name: "required",
          width: "half",
        },
        {
          name: "foreign_key_table",
          width: "half",
        },
        {
          name: "display_template",
          width: "full",
        },
        // {
        //   name: "creating_items",
        //   width: "half",
        // },
        // {
        //   name: "selecting_items",
        //   width: "half",
        // },
      ],
      initialValues: {
        type: "ObjectId",
        // creating_items: true,
        // selecting_items: true,
      },
      nativeFieldType: "Single",
      schemaType: "ObjectId",
    },
    {
      id: "translations",
      icon: <IconsAll.FieldTranslations />,
      fieldsList: [
        {
          name: "name",
          required: true,
          placeholder: "a_unique_name",
          width: "half",
        },
        {
          name: "group",
          placeholder: "group",
          width: "half",
          blackList: ["items"],
        },
        {
          name: "type",
          required: true,
          fieldTypes: {
            disabled: true,
            allowedTypes: [{ value: "Alias", label: "Alias" }],
          },
          width: "half",
        },
        // {
        //   name: "default",
        //   placeholder: "default_value",
        //   width: "half",
        // },
        // {
        //   name: "required",
        //   width: "half",
        // },
        {
          name: "default_language",
          width: "half",
          placeholder: "enter_default_language",
        },
        {
          name: "language_collection",
          width: "full",
        },
        {
          name: "language_indicator_field",
          width: "full",
        },
        {
          name: "language_direction_field",
          width: "full",
        },
        // {
        //   name: "use_current_user_language",
        //   width: "half",
        // },
        // {
        //   name: "default_split_view_state",
        //   width: "half",
        // },
      ],
      initialValues: {
        type: "Alias",
        // creating_items: true,
        // selecting_items: true,
        // junction_fields_location: "Bottom",
      },
      nativeFieldType: "Single",
      schemaType: "Alias",
    },
  ],
};
