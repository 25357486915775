import React from "react";
import { Handle, useNodeConnections, useNodeId } from "@xyflow/react";
import { IoCloseCircleOutline } from "react-icons/io5";
import { GoCheckCircle } from "react-icons/go";
import { LiaDotCircleSolid } from "react-icons/lia";

const TargetHandleWithValidation = ({
  position,
  type,
  id,
  style = {},
  hint = null,
  onClick = () => {},
}) => {
  const nodeId = useNodeId();

  const connections = useNodeConnections({
    id: nodeId,
    handleId: id,
    handleType: type,
  });

  const isSourceReject = connections.some(
    (connection) => connection.sourceHandle === "reject",
  );

  return (
    <div onClick={onClick}>
      <Handle
        id={id}
        style={style}
        type={type}
        position={position}
        className="bg-[var(--body-bg)]! w-6! h-6!"
        isConnectable={connections.length === 0}
        isValidConnection={(connection) =>
          connection.source !== connection.target
        }
      >
        {(id === "resolve" || id === "trigger") && (
          <GoCheckCircle
            size={26}
            className="text-project-base pointer-events-none relative -top-[3px] -left-[3px]"
          />
        )}
        {id === "reject" && (
          <IoCloseCircleOutline
            size={30}
            className="text-delete-base/70 pointer-events-none relative -top-[3px] -left-[3px]"
          />
        )}
        {id === "target" && (
          <LiaDotCircleSolid
            size={30}
            className={`text-${connections.length > 0 ? (isSourceReject ? "delete-base/70" : "(--project-base)") : "(--input-field-border)"} pointer-events-none relative -top-[3px] -left-[3px]`}
          />
        )}
      </Handle>
      {connections.length === 0 && hint}
    </div>
  );
};

export default TargetHandleWithValidation;
