import React, { useState } from "react";
import { LuDatabase } from "react-icons/lu";
import { capitalizeAndFormat } from "../../../Helpers/utils";
import * as LucideIcons from "lucide-react";
import { DrawerStyle } from "../../../Common/CommonUiStyles";
import ContentTable from "../../../Pages/Content/ContentTable";

const CollectionCard = ({ collection, setRelationalData }) => {
  // eslint-disable-next-line no-unused-vars
  const { icons, createLucideIcon, ...filteredIcons } = LucideIcons;
  const [showDrawer, setShowDrawer] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  return (
    <>
      <div
        onClick={() => {
          setShowDrawer(true);
        }}
        className="p-4 flex flex-col items-center justify-center bg-[var(--simplebar-active-bg)] rounded-xl shadow-xs hover:cursor-pointer hover:bg-project-base/50"
      >
        {collection?.meta?.icon ? (
          <span className="flex items-center justify-center">
            {filteredIcons[collection?.meta?.icon] &&
              React.createElement(filteredIcons[collection?.meta?.icon], {
                size: 16,
                color: collection?.meta?.color || "var(--project-base)",
              })}
          </span>
        ) : (
          <LuDatabase size={22} color="var(--project-base)" />
        )}
        <span className="my-1">
          {capitalizeAndFormat(collection?.collection_id)}
        </span>
      </div>

      <DrawerStyle
        width={870}
        open={showDrawer}
        className="table-in-drawer"
        onClose={() => {
          setShowDrawer(false), setSelectedRows([]);
        }}
        destroyOnClose
      >
        <ContentTable
          tableType={`m2a`}
          tableId={collection?._id}
          setShowDrawer={setShowDrawer}
          setRelationalData={setRelationalData}
          item={{ meta: { interface: "list-m2a" } }}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      </DrawerStyle>
    </>
  );
};

export default CollectionCard;
