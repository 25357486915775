import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  operationData: {},
  operationNodeId: "",
  handleId: "",
  source: {},
  isActionDrawerOpen: false,
  deleteOperationIds: [],
  isCanvasDisabled: false,
};

const flowsSlice = createSlice({
  name: "flows",
  initialState,
  reducers: {
    handleFlowActions(state, action) {
      const { data, flag, nodeId, handleId, source } = action.payload;
      state.operationData = data;
      state.isActionDrawerOpen = flag;
      state.operationNodeId = nodeId;
      state.handleId = handleId;
      state.source = source;
    },
    handleAddDeleteOperation(state, action) {
      const { nodeId } = action.payload;
      state.deleteOperationIds.push(nodeId);
    },
    clearDeleteOperationIds(state) {
      state.deleteOperationIds = [];
    },
    setIsCanvasDisabled(state, action) {
      state.isCanvasDisabled = action.payload;
    },
  },
});

export const {
  handleFlowActions,
  clearDeleteOperationIds,
  handleAddDeleteOperation,
  setIsCanvasDisabled,
} = flowsSlice.actions;
export default flowsSlice.reducer;
