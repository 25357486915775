import { Dropdown, Row } from "antd";
import React, { useEffect, useState } from "react";
import { getForeignCollectionDetails } from "../../../Helpers/utils";
import axiosInstance from "../../../Helpers/axios";
import Apis from "../../../Helpers/Apis";
import { useDispatch, useSelector } from "react-redux";
import {
  getAndSetPresets,
  getFieldsBySchemaId,
} from "../../../Helpers/functions";
import DynamicForm from "../DynamicForm";
import { BiTrash } from "react-icons/bi";
import {
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
  MdOutlineFlip,
} from "react-icons/md";

const CustomTranslations = ({
  item,
  formData,
  setFormData,
  validationErrors,
  language,
  updateFormData,
}) => {
  const [languages, setLanguages] = useState([]);
  const [dropdowns, setDropdowns] = useState({
    dp1: {
      selectedLanguage: "",
      isActive: true,
      isOpen: false,
    },
    dp2: {
      selectedLanguage: "",
      isActive: false,
      isOpen: false,
    },
  });
  const [collectionFields, setCollectionFields] = useState([]);
  const schemaPresets = useSelector((state) => state?.schemas?.presets);
  const dispatch = useDispatch();
  const schemas = useSelector((state) => state?.schemas?.schemas);
  const fields = useSelector((state) => state?.fields?.fields);
  const myRelations = getForeignCollectionDetails({
    collection: item?.schema_id,
    field: item?.field,
    iFace: item?.meta?.interface,
    findJunction: true,
  });
  const currentCollection = schemas?.find(
    (col) => col?._id === myRelations?.junction_collection_id,
  );

  useEffect(() => {
    getLanguages();
  }, []);

  useEffect(() => {
    const defaultLanguage = item?.meta?.options?.default_language;
    const initialLanguage =
      defaultLanguage &&
      languages?.find((lang) => lang?.code === defaultLanguage)?._id
        ? languages?.find((lang) => lang?.code === defaultLanguage)?._id
        : languages?.[0]?._id || "";

    setDropdowns((prev) => {
      return {
        ...prev,
        ...(languages?.[0] && {
          dp1: {
            ...prev?.dp1,
            selectedLanguage: initialLanguage,
          },
        }),
        ...(languages?.[1] && {
          dp2: {
            ...prev?.dp2,
            isActive: true,
            selectedLanguage: languages?.[1]?._id,
          },
        }),
      };
    });
  }, [languages]);

  const getLanguages = async () => {
    try {
      let resp1 = await getAndSetPresets({
        schemaId: myRelations?.foreign_collection_id,
        dispatch,
        presets: schemaPresets,
      });
      const resp2 = await axiosInstance.post(
        Apis.DEFAULT_GET_CONTENT(
          schemas?.find(
            (col) => col?._id === myRelations?.foreign_collection_id,
          )?.collection_id,
          "",
          { page: 1 },
        ),
        {
          columns: resp1?.layout_query?.tabular?.fields?.map((item) => ({
            field_name: item.field_name,
            display_label: item.display_label,
            field_details: item.field_details,
          })),
        },
      );
      if (resp2.status === 200) {
        setLanguages(resp2.data.payload.data);
      }

      const resp3 = await getFieldsBySchemaId({
        schemas,
        fields,
        schemaId: currentCollection?._id,
        populated: false,
      });

      setCollectionFields(resp3?.fields);
    } catch (error) {
      console.log("Error", error);
    }
  };

  const handleClick = (dropdownKey) => {
    if (language) {
      const updatedFormData = { ...formData };
      delete updatedFormData?.[item?.path]?.[
        dropdowns?.[dropdownKey]?.selectedLanguage
      ];
      updateFormData(updatedFormData);
    } else {
      setFormData((prev) => {
        const updatedFormData = { ...prev };
        delete updatedFormData?.[item?.path]?.[
          dropdowns?.[dropdownKey]?.selectedLanguage
        ];
        return updatedFormData;
      });
    }

    setDropdowns((prev) => ({
      ...prev,
      [dropdownKey]: {
        ...prev?.[dropdownKey],
        selectedLanguage: "",
        ...(dropdownKey === "dp2" && { isActive: false }),
      },
    }));
  };

  return (
    <div
      className={`grid grid-cols-${dropdowns?.dp2?.isActive ? "2" : "1"} gap-4 grow`}
    >
      <div className="flex grow flex-col gap-4">
        {dropdowns?.dp1?.isActive && (
          <>
            <Dropdown
              disabled={item?.meta?.readonly}
              menu={{
                items: languages?.map((lang) => ({
                  label: lang?.name,
                  key: lang?._id,
                  onClick: () =>
                    setDropdowns((prev) => ({
                      ...prev,
                      dp1: {
                        ...prev?.dp1,
                        selectedLanguage: lang?._id,
                      },
                    })),
                })),
              }}
              trigger={["click"]}
              onOpenChange={(open) =>
                setDropdowns((prev) => ({
                  ...prev,
                  dp1: {
                    ...prev?.dp1,
                    isOpen: open,
                  },
                }))
              }
            >
              <div className="bg-project-base/5 border border-project-base/15 text-sm text-project-base font-semibold min-h-12 px-3 py-2 rounded-lg flex items-center cursor-pointer">
                <span className="me-auto">
                  {
                    languages?.find(
                      (lang) => lang?._id === dropdowns?.dp1?.selectedLanguage,
                    )?.name
                  }
                </span>
                <div className="flex items-center gap-2">
                  {!item?.meta?.readonly && !dropdowns?.dp2?.isActive && (
                    <MdOutlineFlip
                      onClick={(e) => {
                        e.stopPropagation();
                        setDropdowns((prev) => ({
                          ...prev,
                          dp2: {
                            ...prev?.dp2,
                            isActive: true,
                          },
                        }));
                      }}
                      className="text-xl text-project-base"
                    />
                  )}
                  {!item?.meta?.readonly &&
                    dropdowns?.dp1?.selectedLanguage && (
                      <BiTrash
                        onClick={(e) => {
                          e.stopPropagation();
                          handleClick("dp1");
                        }}
                        className="text-xl text-delete-base"
                      />
                    )}
                  {dropdowns?.dp1?.isOpen ? (
                    <MdKeyboardArrowUp className="text-xl text-heading-text" />
                  ) : (
                    <MdKeyboardArrowDown className="text-xl text-heading-text" />
                  )}
                </div>
              </div>
            </Dropdown>
            {dropdowns?.dp1?.selectedLanguage && (
              <Row gutter={16}>
                <DynamicForm
                  form={collectionFields
                    ?.filter(
                      (f) => f?.meta?.interface !== "none" && !f?.meta?.hidden,
                    )
                    ?.sort((a, b) => a?.meta?.sort - b?.meta?.sort)}
                  setFormData={setFormData}
                  formData={formData}
                  language={{
                    selectedLanguage: dropdowns?.dp1?.selectedLanguage,
                    field_path: item.path,
                  }}
                  validationErrors={validationErrors}
                />
              </Row>
            )}
          </>
        )}
      </div>

      {dropdowns?.dp2?.isActive && (
        <div className="flex grow flex-col gap-4">
          <Dropdown
            disabled={item?.meta?.readonly}
            menu={{
              items: languages?.map((lang) => ({
                label: lang?.name,
                key: lang?._id,
                onClick: () =>
                  setDropdowns((prev) => ({
                    ...prev,
                    dp2: {
                      ...prev?.dp2,
                      selectedLanguage: lang?._id,
                    },
                  })),
              })),
            }}
            trigger={["click"]}
            onOpenChange={(open) =>
              setDropdowns((prev) => ({
                ...prev,
                dp2: {
                  ...prev?.dp2,
                  isOpen: open,
                },
              }))
            }
          >
            <div className="bg-project-base/5 backdrop-hue-rotate-90 border border-project-base/15 text-sm text-project-base font-semibold min-h-12 px-3 py-2 rounded-lg flex items-center cursor-pointer">
              <span className="me-auto">
                {
                  languages?.find(
                    (lang) => lang?._id === dropdowns?.dp2?.selectedLanguage,
                  )?.name
                }
              </span>
              <div className="flex items-center gap-2">
                {!item?.meta?.readonly && (
                  <MdOutlineFlip
                    onClick={(e) => {
                      e.stopPropagation();
                      setDropdowns((prev) => ({
                        ...prev,
                        dp2: {
                          ...prev?.dp2,
                          isActive: !prev?.dp2?.isActive,
                        },
                      }));
                    }}
                    className="text-xl text-project-base"
                  />
                )}
                {!item?.meta?.readonly && dropdowns?.dp2?.selectedLanguage && (
                  <BiTrash
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClick("dp2");
                    }}
                    className="text-xl text-delete-base"
                  />
                )}
                {dropdowns?.dp2?.isOpen ? (
                  <MdKeyboardArrowUp className="text-xl text-heading-text" />
                ) : (
                  <MdKeyboardArrowDown className="text-xl text-heading-text" />
                )}
              </div>
            </div>
          </Dropdown>
          {dropdowns?.dp2?.selectedLanguage && (
            <Row gutter={16}>
              <DynamicForm
                form={collectionFields
                  ?.filter(
                    (f) => f?.meta?.interface !== "none" && !f?.meta?.hidden,
                  )
                  ?.sort((a, b) => a?.meta?.sort - b?.meta?.sort)}
                setFormData={setFormData}
                formData={formData}
                language={{
                  selectedLanguage: dropdowns?.dp2?.selectedLanguage,
                  field_path: item.path,
                }}
                validationErrors={validationErrors}
              />
            </Row>
          )}
        </div>
      )}
    </div>
  );
};

export default CustomTranslations;
