import React, { useEffect, useId } from "react";
import { Form, Row, Col, Flex, Input, Select } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { themes } from "../../../config";
import LeftSideTitlePart from "../../HeaderParts/LeftSideTitlePart";
import IconsAll from "../../IconsAll";
import RightSIdeTitlePart from "../../HeaderParts/RightSIdeTitlePart";
import { IoMdArrowBack } from "react-icons/io";
import { IoArrowForward } from "react-icons/io5";
import {
  capitalizeAndFormat,
  getForeignCollectionDetails,
} from "../../../Helpers/utils";
import TextArea from "antd/es/input/TextArea";

const FormStyling = styled(Form)`
  background-color: var(--form-input-wrapper);
  border-top: 1px solid
    ${() => themes[useSelector((state) => state.theme.name)].token.grayClr};
  border-bottom: 1px solid
    ${() => themes[useSelector((state) => state.theme.name)].token.grayClr};
  padding-block-start: 34px !important;

  .relation-arrow {
    position: absolute;
    left: 0;
    transform: translate(-50%, 24px);
    &.arrow-position-inverted {
      bottom: 0;
      transform: translate(-50%, -45px);
    }
  }
`;

const UpdateRelationShip = ({ activeItem, dataSource }) => {
  const { t } = useTranslation();
  const id = useId();
  const [form] = Form.useForm();
  const schemas = useSelector((state) => state?.schemas?.schemas);

  const myRelations = getForeignCollectionDetails({
    collection: activeItem?.schema_id,
    field: activeItem?.field,
    iFace: activeItem?.meta?.interface,
    findJunction: ["list-m2m", "list-m2a", "translations"].includes(
      activeItem?.meta?.interface,
    ),
    getRelationaShipDetails: true,
  });

  useEffect(() => {
    setFormData();
  }, [activeItem?._id]);

  const setFormData = async () => {
    await form.setFieldsValue(myRelations);
  };

  const handleSelectChange = async (data, updated_collections) => {
    await form.setFieldsValue({
      foreign_collection: updated_collections?.map((col) => col?.value),
      foreign_collection_ref: updated_collections
        ?.map((col) => col?.label)
        ?.join(", "),
    });
  };

  return (
    <Flex wrap="wrap" align="center" justify="space-between">
      <LeftSideTitlePart
        pageTitle={
          capitalizeAndFormat(activeItem?.meta?.interface) +
          " " +
          capitalizeAndFormat(activeItem?.type) +
          " (" +
          capitalizeAndFormat(dataSource?.collection_name) +
          ")"
        }
        pageIcon={true}
        pageInnerIcon={<IconsAll.ContentIcon />}
      />
      <RightSIdeTitlePart showButton showDisabled />
      <FormStyling form={form} layout="vertical" className="mt-4 w-full">
        <Row gutter={28}>
          <Col
            className="gutter-row"
            xs={24}
            md={
              ["list-m2m", "list-m2a", "translations"].includes(
                activeItem?.meta?.interface,
              )
                ? 8
                : 12
            }
          >
            <Form.Item
              label={t("this_collection")}
              htmlFor={`${id}-this_collection`}
              className="font-bold"
              name={`this_collection`}
            >
              <Input disabled id={`${id}-this_collection`} className="h-14" />
            </Form.Item>
          </Col>
          {["list-m2m", "list-m2a", "translations"].includes(
            activeItem?.meta?.interface,
          ) && (
            <Col className="gutter-row" xs={24} md={8}>
              <Form.Item
                label={t("junction_collection")}
                htmlFor={`${id}-junction_collection`}
                className="font-bold"
                name={`junction_collection`}
              >
                <Input
                  id={`${id}-junction_collection`}
                  className="h-14"
                  disabled
                />
              </Form.Item>
            </Col>
          )}

          <Col
            className="gutter-row"
            xs={24}
            md={
              ["list-m2m", "list-m2a", "translations"].includes(
                activeItem?.meta?.interface,
              )
                ? 8
                : 12
            }
          >
            <Form.Item
              label={t("foreign_collection")}
              htmlFor={`${id}-foreign_collection`}
              className="font-bold"
              name={`foreign_collection`}
            >
              {["list-m2a"].includes(activeItem?.meta?.interface) ? (
                <Select
                  showSearch
                  size="large"
                  style={{ flex: 1, height: "56px" }}
                  id={`${id}-foreign_collection`}
                  mode={"multiple"}
                  options={schemas
                    ?.filter(
                      (c) =>
                        c?.visible &&
                        c?._id !== activeItem?.schema_id &&
                        !c?.meta?.is_folder,
                    )
                    ?.map((c) => {
                      return {
                        label: c?.collection_id,
                        value: c?._id,
                      };
                    })}
                  maxTagCount={0}
                  maxTagPlaceholder={(selectedValues) =>
                    `${selectedValues.length} ${selectedValues.length > 1 ? "items" : "item"}`
                  }
                  onChange={handleSelectChange}
                  disabled
                />
              ) : (
                <Input
                  disabled
                  id={`${id}-foreign_collection`}
                  className="h-14"
                />
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={28}>
          <Col
            className="gutter-row"
            xs={24}
            md={
              ["list-m2m", "list-m2a", "translations"].includes(
                activeItem?.meta?.interface,
              )
                ? 8
                : 12
            }
          >
            <Form.Item
              htmlFor={`${id}-this_field`}
              className="font-bold"
              name={`this_field`}
            >
              <Input disabled id={`${id}-this_field`} className="h-14" />
            </Form.Item>
          </Col>

          {["list-m2m", "list-m2a", "translations"].includes(
            activeItem?.meta?.interface,
          ) && (
            <>
              <Col className="gutter-row" xs={24} md={8}>
                <span className="relation-arrow">
                  <IoArrowForward />
                </span>
                <Form.Item
                  htmlFor={`${id}-junction_field_this`}
                  className="font-bold"
                  name={`junction_field_this`}
                >
                  <Input
                    disabled
                    id={`${id}-junction_field_this`}
                    className="h-14"
                  />
                </Form.Item>
                {["list-m2a"].includes(activeItem?.meta?.interface) && (
                  <>
                    <Form.Item
                      htmlFor={`${id}-junction_field_ref`}
                      className="font-bold"
                      name={`junction_field_ref`}
                    >
                      <Input
                        id={`${id}-junction_field_ref`}
                        value={`junction_field_ref`}
                        className="h-14"
                        disabled
                      />
                    </Form.Item>
                  </>
                )}
              </Col>
            </>
          )}

          {["list-m2a"].includes(activeItem?.meta?.interface) && (
            <Col className="gutter-row" xs={24} md={8}>
              <span className="relation-arrow arrow-position-inverted">
                <IoMdArrowBack />
              </span>
              <Form.Item
                htmlFor={`${id}-foreign_collection_ref`}
                className="font-bold"
                name={`foreign_collection_ref`}
              >
                <TextArea
                  id={`${id}-foreign_collection_ref`}
                  value={`foreign_collection_ref`}
                  rows={4}
                  style={{
                    height: "8.5rem",
                    resize: "none",
                    whiteSpace: "pre-wrap",
                    wordWrap: "break-word",
                  }}
                  disabled
                />
              </Form.Item>
            </Col>
          )}

          {["list-m2m", "list-m2a", "translations"].includes(
            activeItem?.meta?.interface,
          ) && (
            <Col className="gutter-row" xs={24} md={8} offset={8}>
              <Form.Item
                htmlFor={`${id}-junction_field_foreign`}
                className="font-bold"
                name={`junction_field_foreign`}
              >
                <Input
                  id={`${id}-junction_field_foreign`}
                  className="h-14"
                  disabled
                />
              </Form.Item>
            </Col>
          )}

          <Col
            className="gutter-row"
            xs={24}
            md={
              ["list-m2m", "list-m2a", "translations"].includes(
                activeItem?.meta?.interface,
              )
                ? 8
                : 12
            }
          >
            <span className="relation-arrow">
              {activeItem?.meta?.interface === "list-o2m" ? (
                <IoArrowForward />
              ) : (
                <IoMdArrowBack />
              )}
            </span>
            <Form.Item
              htmlFor={`${id}-foreign_field`}
              className="font-bold"
              name={`foreign_field`}
            >
              <Input id={`${id}-foreign_field`} className="h-14" disabled />
            </Form.Item>
          </Col>
        </Row>
      </FormStyling>
    </Flex>
  );
};

export default UpdateRelationShip;
