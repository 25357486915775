import React from "react";
import { Flex } from "antd";
import LeftSideTitlePart from "../../Components/HeaderParts/LeftSideTitlePart";
import RightSIdeTitlePart from "../../Components/HeaderParts/RightSIdeTitlePart";
import IconsAll from "../../Components/IconsAll";
import { useTranslation } from "react-i18next";

const Administrator = () => {
  const { t } = useTranslation();

  return (
    <Flex className="w-full">
      <div className="px-4 xl:px-16 w-full">
        <div className="primary-header pt-9 pb-5">
          <LeftSideTitlePart
            pageTitle={t("administrator")}
            pageSubTitle={t("users")}
            pageIcon={true}
            pageInnerIcon={<IconsAll.AdministratorIcon />}
            backButton={false}
          />
          <RightSIdeTitlePart isFilterShow={true} addButton={true} />
        </div>

        <section className="mt-4"></section>
      </div>
    </Flex>
  );
};

export default Administrator;
