// src/components/GlobalLoader.js
import { Spin } from "antd";
import React from "react";
import { useSelector } from "react-redux";

const GlobalLoader = () => {
  const isLoading = useSelector((state) => state.loader.isLoading);
  if (!isLoading) return null;

  return (
    <Spin
      spinning={true}
      fullscreen
      indicator={<div className="custom-loader" />}
    ></Spin>
  );
};

export default GlobalLoader;
