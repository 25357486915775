import React, { useState, useCallback, useMemo, memo, useEffect } from "react";
import LeftSideTitlePart from "../../../Components/HeaderParts/LeftSideTitlePart";
import IconsAll from "../../../Components/IconsAll";
import { useTranslation } from "react-i18next";
import { Flex, Form, Row, Col, Input, Select, Spin, Button } from "antd";
import useLanguage from "../../../Helpers/custom-hooks/useLanguage";
import { CollapsWithLine, DrawerStyle } from "../../../Common/CommonUiStyles";
import { isEqual } from "lodash"; // Import lodash for deep comparison
import {
  DndContext,
  closestCenter,
  useSensor,
  useSensors,
  PointerSensor,
} from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
  arrayMove,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { TfiLock } from "react-icons/tfi";
import {
  IoIosCheckboxOutline,
  IoIosSquareOutline,
  IoMdClose,
} from "react-icons/io";
import { FaGripLines } from "react-icons/fa6";
import RightSIdeTitlePart from "../../../Components/HeaderParts/RightSIdeTitlePart";
import AppInfo from "../../../Components/AppInfo";
import AddLink from "./AddLink";
import * as LucideIcons from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import {
  addSidebarItem,
  deleteSidebarItem,
  updateSidebarItem,
} from "../../../Redux/slices/sidebar.reducer";
import { hideLoader, showLoader } from "../../../Redux/slices/loader.reducer";
import axiosInstance from "../../../Helpers/axios";
import Apis from "../../../Helpers/Apis";
const { ...filteredIcons } = LucideIcons;

const DraggableItem = memo(
  ({ item, onToggleVisibility, onRemove, openEditLink }) => {
    const { attributes, listeners, setNodeRef, transform, transition } =
      useSortable({ id: item.id });

    const style = useMemo(
      () => ({
        transform: CSS.Transform.toString(transform),
        transition,
        marginBottom: "1rem",
        boxShadow: "none",
        border: "1px solid var(--input-field-border)",
        padding: "0.5rem 1rem",
      }),
      [transform, transition],
    );

    const IconComponent = item?.icon.toLocaleLowerCase().includes("iconsall")
      ? IconsAll[`${item?.icon.split(".")[1]}`]
      : filteredIcons[item?.icon];
    return (
      <div
        ref={setNodeRef}
        style={style}
        {...attributes}
        className="flex items-center justify-between p-3 bg-white shadow-md rounded-md mb-2 cursor-grab"
      >
        <div className="flex items-center gap-3">
          <span style={{ cursor: "grab" }} {...listeners}>
            <FaGripLines
              size={24}
              color={"rgba(from var(--heading-text) r g b / 50%)"}
            />
          </span>
          <div>
            <IconComponent
              className="ant-menu-item-icon"
              size={22}
              color="var(--project-base)"
              strokeColor={"var(--project-base)"}
            />
          </div>
          <div
            className={`flex flex-col ${item.type === "link" ? "cursor-pointer" : ""}`}
            onClick={() => {
              if (item.type === "link") openEditLink(item);
            }}
          >
            <span className="font-semibold">{item.label}</span>
            <span className="text-sm">{item.path}</span>
          </div>
        </div>
        <div>
          {item.type === "locked" && (
            <TfiLock size={20} color="var(--project-base)" />
          )}
          {item.type === "checkbox" && (
            <span
              onClick={(e) => {
                e.stopPropagation();
                onToggleVisibility(item.id, !item.visible);
              }}
              className="cursor-pointer"
            >
              {item.visible ? (
                <IoIosCheckboxOutline size={20} color="var(--project-base)" />
              ) : (
                <IoIosSquareOutline size={20} color="var(--project-base)" />
              )}
            </span>
          )}
          {item.type === "link" && (
            <IoMdClose
              onClick={(e) => {
                e.stopPropagation();
                onRemove(item.id);
              }}
              size={20}
              color="var(--project-base)"
              style={{ cursor: "pointer" }}
            />
          )}
        </div>
      </div>
    );
  },
);

DraggableItem.displayName = "DraggableItem";

function Settings() {
  const { t } = useTranslation();
  const [loader, setLoader] = useState(false);
  const [form] = Form.useForm();
  const [editLink, setEditLink] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [hasMenuChanged, setHasMenuChanged] = useState(false);
  const [changedItems, setChangedItems] = useState([]);
  const dispatch = useDispatch();
  const sidebar = useSelector((state) => state.sidebar);
  const defaultSetting = sidebar?.setting;
  const userProfile = useSelector((state) => state.user.userProfile);
  const initialForm = {
    project_name: sidebar?.setting?.project_name,
    project_description: sidebar?.setting?.project_description,
    project_url: sidebar?.setting?.project_url,
    language: sidebar?.setting?.language || "en",
  };
  const [update, setUpdate] = useState(null);

  const getSorted = (items) => {
    return items
      ?.filter((item) => {
        if (item.type === "link" || item.type === "checkbox") return true;

        const adminKeys = ["dashboard", "users", "adminSettings"];
        const managerKeys = [
          "dashboard",
          "content",
          "media-library",
          "settings",
          "users",
        ];
        const userKeys = ["content", "users"];

        return userProfile?.nox_admin
          ? adminKeys.includes(item.id)
          : userProfile?.role?.admin_access
            ? managerKeys.includes(item.id)
            : userKeys.includes(item.id);
      })
      .sort((a, b) => a.sort - b.sort);
  };

  const handleForm = (name, value) => {
    const modifiedValue = value;
    // Update main form values
    form.setFieldsValue({
      [name]: modifiedValue,
    });

    // Update main form values
    form.setFields([
      {
        name,
        errors: [], // Remove any existing error for this field
      },
    ]);
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    handleForm(name, value);
  };

  const updateSetting = async () => {
    let formData = { ...update, items: sidebar?.items };

    dispatch(showLoader());
    try {
      await axiosInstance.post(Apis.UPDATE_SETTING(), formData);
    } catch (error) {
      console.log("error", error);
    } finally {
      dispatch(hideLoader());
      setIsChanged(false);
      setUpdate(null);
      setHasMenuChanged(false);
    }
  };

  const handleSubmit = async () => {
    setLoader(true);
    if (hasMenuChanged) {
      changedItems?.forEach(async (module) => {
        const changeType = module.changeType;
        let data = module;
        delete data.changeType;
        switch (changeType) {
          case "added":
            await dispatch(addSidebarItem(data));
            break;

          case "modified":
            await dispatch(
              updateSidebarItem({ id: data.id, updatedData: data }),
            );
            break;

          case "removed":
            if (module.id) {
              await dispatch(deleteSidebarItem(module.id));
            }
            break;

          default:
            break;
        }
      });
      setChangedItems([]);
    }

    let formData = { ...form.getFieldsValue() };
    setLoader(false);

    setUpdate(formData);
  };

  const [items, setItems] = useState([]);

  const handleToggleVisibility = useCallback((id, newState) => {
    setItems((prev) =>
      prev.map((item) =>
        item.id === id && item.visible !== newState
          ? { ...item, visible: newState }
          : item,
      ),
    );
  }, []);

  const handleRemove = useCallback((id) => {
    setItems((prev) => prev.filter((item) => item.id !== id));
  }, []);

  const handleDragEnd = useCallback((event) => {
    const { active, over } = event;
    if (!over || active.id === over.id) return;

    setItems((prev) => {
      const updatedItems = [...prev];
      const oldIndex = updatedItems.findIndex((item) => item.id === active.id);
      const newIndex = updatedItems.findIndex((item) => item.id === over.id);

      if (oldIndex === -1 || newIndex === -1) return prev;

      const reorderedItems = arrayMove(updatedItems, oldIndex, newIndex);

      return reorderedItems.map((item, index) => ({
        ...item,
        sort: index + 1,
      }));
    });
  }, []);

  const openEditLink = (item) => {
    setEditLink(item);
    setIsDrawerOpen(true);
  };

  const closeEditLink = () => {
    setEditLink(null);
    setIsDrawerOpen(false);
  };

  const handleAdd = (data) => {
    const { name, icon, url } = data;
    const newLink = {
      id: name?.toLowerCase(),
      icon: icon,
      label: name,
      path: url,
      sort: items.length + 1,
      type: "link",
      visible: true,
    };
    setItems((prev) => [...prev, newLink]);
  };

  const handleUpdate = (id, updatedData) => {
    const { name, icon, url } = updatedData;

    setItems((prev) =>
      prev.map((item) =>
        item.id === id ? { ...item, icon: icon, label: name, path: url } : item,
      ),
    );
  };

  const sensors = useSensors(useSensor(PointerSensor));

  const handleValuesChange = (_, allValues) => {
    const hasChanged = Object.keys(initialForm).some(
      (key) => allValues[key] !== initialForm[key],
    );
    setIsChanged(hasChanged);
  };

  useEffect(() => {
    if (defaultSetting) {
      form.setFieldsValue({
        project_name: defaultSetting?.project_name,
        project_description: defaultSetting?.project_description,
        project_url: defaultSetting?.project_url,
        language: defaultSetting?.language || "en",
      });
      setIsChanged(false);
    }
  }, [defaultSetting, form]);

  useEffect(() => {
    if (userProfile && sidebar?.status !== "idle") {
      const fetchedItems = getSorted(sidebar?.items);
      setItems(fetchedItems);
    }
  }, [t, userProfile, sidebar]);

  useEffect(() => {
    if (update) {
      updateSetting();
    }
  }, [update]);

  useEffect(() => {
    const findChanges = () => {
      const updatedItems = [...changedItems];
      const sidebarMap = new Map(
        getSorted(sidebar?.items).map((item) => [item.id, item]),
      );

      items.forEach((item) => {
        const originalItem = sidebarMap.get(item.id);

        if (!originalItem) {
          if (
            !updatedItems.some(
              (i) => i.id === item.id && i.changeType === "added",
            )
          ) {
            updatedItems.push({ ...item, changeType: "added" });
          }
        } else if (!isEqual(item, originalItem)) {
          if (
            !updatedItems.some(
              (i) => i.id === item.id && i.changeType === "added",
            )
          ) {
            const existingIndex = updatedItems.findIndex(
              (i) => i.id === item.id,
            );
            if (existingIndex !== -1) {
              updatedItems[existingIndex] = { ...item, changeType: "modified" };
            } else {
              updatedItems.push({ ...item, changeType: "modified" });
            }
          }
        }
      });

      getSorted(sidebar?.items).forEach((item) => {
        if (!items.some((updatedItem) => updatedItem.id === item.id)) {
          if (
            !updatedItems.some(
              (i) => i.id === item.id && i.changeType === "added",
            )
          ) {
            updatedItems.push({ ...item, changeType: "removed" });
          }
        }
      });

      setChangedItems(updatedItems);
      setHasMenuChanged(updatedItems.length > 0);
    };
    if (items.length > 0 && sidebar?.status !== "idle") {
      findChanges();
    }
  }, [items, sidebar]);

  return (
    <Flex className="w-full">
      <div className="px-4 xl:px-16 w-full">
        <div className="primary-header pt-9 pb-5">
          <LeftSideTitlePart
            pageTitle={t("setting")}
            pageSubTitle={t("setting")}
            pageIcon={true}
            pageInnerIcon={<IconsAll.SettingIcon />}
            backButton={false}
          />
          <RightSIdeTitlePart
            showButton={true}
            handleShowClick={handleSubmit}
            buttonLoading={loader}
            showDisabled={!isChanged && !hasMenuChanged}
          />
          <DrawerStyle
            width={870}
            open={isDrawerOpen}
            onClose={closeEditLink}
            closeIcon={<IconsAll.CloseIconCustom />}
            destroyOnClose
          >
            <AddLink
              link={editLink}
              handleAdd={handleAdd}
              handleUpdate={handleUpdate}
              closeEditLink={closeEditLink}
            />
          </DrawerStyle>
        </div>
        <section className="mt-4 w-full">
          {loader ? (
            <Spin
              spinning={loader}
              fullscreen
              indicator={<div className="custom-loader" />}
            ></Spin>
          ) : (
            <Form
              layout="vertical"
              form={form}
              onFinish={handleSubmit}
              onValuesChange={handleValuesChange}
            >
              <Row gutter={[16, 24]}>
                <Col className="gutter-row" xs={24} md={12}>
                  <Form.Item
                    label={t("project_name")}
                    name="project_name"
                    className="font-bold mb-0"
                    rules={[
                      {
                        required: true,
                        message: t("enter_project_name"),
                      },
                      {
                        max: 50,
                      },
                    ]}
                  >
                    <Input
                      type="text"
                      name="project_name"
                      onChange={handleChange}
                      placeholder={t("enter_project_name")}
                      className="project-custom-input"
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} md={12}>
                  <Form.Item
                    label={t("project_description")}
                    name="project_description"
                    className="font-bold mb-0"
                    rules={[
                      {
                        required: true,
                        message: t("enter_project_description"),
                      },
                      {
                        max: 50,
                      },
                    ]}
                  >
                    <Input
                      type="text"
                      onChange={handleChange}
                      name="project_description"
                      placeholder={t("enter_project_description")}
                      className="project-custom-input"
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} md={12}>
                  <Form.Item
                    label={t("project_url")}
                    name="project_url"
                    className="font-bold mb-0"
                    rules={[
                      {
                        required: true,
                        message: t("enter_url"),
                      },
                      {
                        max: 50,
                      },
                      {
                        pattern:
                          /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/,
                        message: t("enter_url"),
                      },
                    ]}
                  >
                    <Input
                      type="text"
                      name="project_url"
                      onChange={handleChange}
                      placeholder={t("enter_url")}
                      className="project-custom-input"
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} md={12}>
                  <Form.Item
                    label={t("language")}
                    className="font-bold"
                    htmlFor="language"
                    name="language"
                  >
                    <Select
                      id="language"
                      name="language"
                      options={useLanguage()}
                      showSearch
                      filterOption={(input, option) =>
                        option?.label
                          ?.toLowerCase()
                          ?.includes(input?.toLowerCase())
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          )}
        </section>
        <section>
          <CollapsWithLine
            ghost
            items={[
              {
                key: "1",
                label: <span className="collapse-title">{t("modules")}</span>,
                children: (
                  <>
                    <DndContext
                      sensors={sensors}
                      collisionDetection={closestCenter}
                      onDragEnd={handleDragEnd}
                    >
                      <SortableContext
                        items={items}
                        strategy={verticalListSortingStrategy}
                      >
                        {items.map((item) => (
                          <DraggableItem
                            key={item.id}
                            item={item}
                            openEditLink={openEditLink}
                            onToggleVisibility={handleToggleVisibility}
                            onRemove={handleRemove}
                          />
                        ))}
                      </SortableContext>
                    </DndContext>
                    <Button
                      className="submit-button"
                      size={"large"}
                      onClick={() => setIsDrawerOpen(true)}
                    >
                      {t("add_link")}
                    </Button>
                  </>
                ),
              },
            ]}
          />
        </section>
      </div>
      <AppInfo
        visibleList={["information"]}
        config={{
          msg: t("information_setting"),
        }}
      />
    </Flex>
  );
}

export default Settings;
