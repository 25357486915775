import React, { useEffect, useState } from "react";
import axiosInstance from "../../Helpers/axios";
import Apis from "../../Helpers/Apis";
import moment from "moment/moment";
import { DrawerStyle } from "../../Common/CommonUiStyles";
import IconsAll from "../IconsAll";
import { usePagination } from "../../Helpers/custom-hooks/usePagination";
import { Pagination, Typography, Collapse, Spin } from "antd";
import { compareAndFilterRevisionField } from "../../Helpers/utils";
import RevisionDrawer from "./RevisionDrawer";
import { useTranslation } from "react-i18next";
const { Panel } = Collapse;

const Revision = ({ config }) => {
  const { t } = useTranslation();
  const [revisionData, setRevisionData] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const { pagination, updatePagination } = usePagination(1, 10);
  const [activeKeys, setActiveKeys] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    init();
  }, []);

  const init = async (
    page = pagination.currentPage,
    limit = pagination.pageSize,
  ) => {
    try {
      setLoader(true);
      const resp = await axiosInstance.get(
        Apis.GET_REVISION(config?.collection_id, config?.item_id, {
          page,
          limit,
        }),
      );
      if (resp.status === 200) {
        updatePagination(
          "totalItems",
          resp.data?.payload?.meta?.total_found || 0,
        );
        const finalRevisionData = resp.data?.payload?.data?.map(
          (rev, i, original) => {
            return {
              _id: rev?._id,
              date: rev?.date,
              changes: compareAndFilterRevisionField(
                rev?.data,
                rev?.previousRevision,
                config?.collectionFields,
              ),
              ...(resp.data?.payload?.meta?.total_pages ===
                resp.data?.payload?.meta?.current_page &&
                i === original.length - 1 && { is_create: true }),
            };
          },
        );
        if (resp.data?.payload?.meta?.current_page === 1) {
          const { updated_at, ...currentData } = config?.currentData || {};
          finalRevisionData?.unshift({
            _id: "current_record",
            changes: compareAndFilterRevisionField(
              currentData,
              resp.data?.payload?.data[0]?.data,
              config?.collectionFields,
            ),
            date: new Date(updated_at).getTime(),
            is_create: finalRevisionData?.length === 0,
          });
        }
        updatePagination("currentPage", page);
        updatePagination("pageSize", limit);
        let revisions =
          finalRevisionData?.length < limit
            ? finalRevisionData
            : finalRevisionData?.slice(0, -1);
        setRevisionData(revisions);
        setActiveKeys(
          Object.keys(
            revisions?.reduce((acc, rev) => {
              const formattedDate = moment(rev?.date).format("MMM DD");
              acc[formattedDate] = acc[formattedDate] || [];
              acc[formattedDate].push(rev);
              return acc;
            }, {}),
          ),
        );
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoader(false);
    }
  };

  return (
    <ul className="revision-items-wrapper">
      {loader ? (
        <Spin
          indicator={<div className="custom-loader" />}
          size="default"
          className="flex justify-center"
        />
      ) : (
        <div>
          <Collapse
            activeKey={activeKeys}
            onChange={(keys) => setActiveKeys(keys)}
          >
            {Object.entries(
              revisionData?.reduce((acc, rev) => {
                const formattedDate = moment(rev?.date).format("MMM DD");
                acc[formattedDate] = acc[formattedDate] || [];
                acc[formattedDate].push(rev);
                return acc;
              }, {}),
            ).map(([date, items]) => {
              return (
                <Panel
                  header={
                    moment(date, "MMM DD").isSame(moment(), "day")
                      ? t("today")
                      : moment(date, "MMM DD").isSame(
                            moment().subtract(1, "days"),
                            "day",
                          )
                        ? t("yesterday")
                        : date
                  }
                  collapsible
                  key={date}
                  className="revision-items-wrapper"
                >
                  {items?.map((rev, i) => (
                    <li
                      className="revision-items"
                      key={i}
                      onClick={() => {
                        setIsDrawerOpen(true);
                        setSelectedData(rev);
                      }}
                    >
                      <Typography className="revision-status">
                        <span data-v-c6c39c2e="" className="dot update"></span>
                        {rev?.is_create ? t("created") : t("updated")}{" "}
                        {rev?.changes?.length} {t("fields")}
                      </Typography>
                      <Typography className="revision-date-time">
                        {moment(rev?.date).format("HH:mm:ss a")}
                      </Typography>
                    </li>
                  ))}
                </Panel>
              );
            })}
          </Collapse>
        </div>
      )}
      <Pagination
        align="center"
        pageSize={pagination.pageSize}
        current={pagination.currentPage}
        total={pagination.totalItems}
        onChange={(page, size) => {
          init(page, size);
        }}
        className="py-8"
        // hideOnSinglePage
      />
      <DrawerStyle
        width={870}
        open={isDrawerOpen}
        onClose={() => {
          setIsDrawerOpen(false);
        }}
        closeIcon={<IconsAll.CloseIconCustom />}
      >
        <RevisionDrawer
          selectedData={selectedData}
          isCreate={selectedData?.is_create}
          setFormData={config?.setFormData}
          setIsDrawerOpen={setIsDrawerOpen}
        />
      </DrawerStyle>
    </ul>
  );
};

export default Revision;
