import React, { useEffect, useState } from "react";
import { Flex, Input, Tag, Tooltip } from "antd";

const CustomTags = ({
  item,
  updateFormData,
  formData,
  isValidationError,
  language,
}) => {
  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState("");
  //   const [editInputIndex, setEditInputIndex] = useState(-1);
  //   const [editInputValue, setEditInputValue] = useState("");
  //   const editInputRef = useRef(null);

  const handleClose = (removedTag) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    setTags(newTags);
    updateFormData(newTags || []);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    if (inputValue && !tags.includes(inputValue)) {
      const updatedTags = [...tags, inputValue];
      setTags(updatedTags);
      updateFormData(updatedTags || []);
    }
    setInputValue("");
  };

  //   const handleEditInputChange = (e) => {
  //     setEditInputValue(e.target.value);
  //   };

  //   const handleEditInputConfirm = () => {
  //     const newTags = [...tags];
  //     newTags[editInputIndex] = editInputValue;
  //     setTags(newTags);
  //     setEditInputIndex(-1);
  //     setEditInputValue("");
  //   };

  //   useEffect(() => {
  //     editInputRef.current?.focus();
  //   }, [editInputValue]);

  useEffect(() => {
    setTags((prev) => {
      return Array.from(
        new Set([
          ...(language?.selectedLanguage ? [] : prev),
          ...(item?.schema_definition?.default || []),
          ...(formData?.[item.path] || []),
        ]),
      );
    });
  }, [language?.selectedLanguage]);

  useEffect(() => {
    updateFormData(tags || []);
  }, [tags]);

  useEffect(() => {
    if (formData?.[item.path] === undefined) {
      updateFormData(item?.schema_definition?.default || []);
    }
  }, [formData?.[item.path]]);
  return (
    <div>
      <Input
        type="text"
        className={`project-custom-input px-3 mb-2 ${isValidationError ? "border-[red]" : ""}`}
        value={inputValue}
        onChange={handleInputChange}
        onBlur={handleInputConfirm}
        onPressEnter={handleInputConfirm}
        disabled={!!item?.meta?.readonly}
      />
      <Flex gap="4px 0" wrap>
        {tags?.map((tag, index) => {
          //   if (editInputIndex === index) {
          //     return (
          //       <Input
          //         ref={editInputRef}
          //         key={tag}
          //         value={editInputValue}
          //         onChange={handleEditInputChange}
          //         onBlur={handleEditInputConfirm}
          //         onPressEnter={handleEditInputConfirm}
          //       />
          //     );
          //   }
          return tag.length > 20 ? (
            <Tooltip title={tag} key={tag + "" + index}>
              <Tag
                key={tag}
                onClick={() => handleClose(tag)}
                className="font-normal custom-tag"
              >
                {`${tag.slice(0, 20)}...`}
              </Tag>
            </Tooltip>
          ) : (
            <Tag
              key={tag}
              onClick={() => handleClose(tag)}
              className="font-normal custom-tag"
            >
              <span>{tag}</span>
            </Tag>
          );
        })}
      </Flex>
    </div>
  );
};

export default CustomTags;
