import React, { useCallback, useEffect, useState } from "react";
import { Checkbox, Col, Modal, notification, Radio, Row, Spin } from "antd";
import LeftSideTitlePart from "../../Components/HeaderParts/LeftSideTitlePart";
import RightSIdeTitlePart from "../../Components/HeaderParts/RightSIdeTitlePart";
import IconsAll from "../../Components/IconsAll";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setActiveTab } from "../../Redux/slices/activeTab.reducer";
import axiosInstance from "../../Helpers/axios";
import Apis from "../../Helpers/Apis";
import withRouter from "../../Common/withRouter";
import { BsCheckLg } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {
  capitalizeAndFormat,
  copyToClipboard,
  flattenObject,
} from "../../Helpers/utils";
import SelectTableColumns from "../../Components/SelectTableColumns";
import { TableStriped } from "../../Common/CommonUiStyles";
import { usePagination } from "../../Helpers/custom-hooks/usePagination";
import { debounce } from "lodash";
import NoData from "../../Components/NoData";
import AppInfo from "../../Components/AppInfo";
import { getAndSetPresets, getFieldsBySchemaId } from "../../Helpers/functions";
import * as LucideIcons from "lucide-react";
import { setPreset } from "../../Redux/slices/schema.reducer";

dayjs.extend(utc);

const ContentTable = ({
  router,
  item,
  tableType,
  tableId,
  setShowDrawer,
  setRelationalData,
  selectedRows,
  setSelectedRows,
}) => {
  // eslint-disable-next-line no-unused-vars
  const { icons, createLucideIcon, ...filteredIcons } = LucideIcons;
  const { t } = useTranslation();
  const path = location.pathname.split("/");
  const activeTab = useSelector((state) => state.activeTab[path[1]]);
  const dispatch = useDispatch();
  const [contentData, setContentData] = useState([]);
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [collectionData, setCollectionData] = useState(null);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [selectOpen, setSelectOpen] = useState(false);
  const [columnWidths, setColumnWidths] = useState({});
  const currentLng = useSelector((state) => state?.user?.userProfile?.language);
  const fields = useSelector((state) => state?.fields?.fields);
  const schemas = useSelector((state) => state?.schemas?.schemas);
  const system_collections = useSelector(
    (state) => state?.schemas?.system_collections,
  );
  const schemaPresets = useSelector((state) => state?.schemas?.presets);
  const { pagination, updatePagination } = usePagination(
    1,
    10,
    "createdAt",
    true,
  );
  const [loading, setLoading] = useState(true);

  const handleResizeStart = (key) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    const startX = e.clientX;
    const startWidth = columnWidths[key] || 150;

    const handleMouseMove = (moveEvent) => {
      setColumnWidths((prev) => ({
        ...prev,
        [key]: Math.max(startWidth + (moveEvent.clientX - startX), 50),
      }));
    };

    const handleMouseUp = () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleDeleteClick = async () => {
    setOpen(false);
    setLoading(true);
    try {
      if (path[2]) {
        const resp = await axiosInstance.put(
          Apis.DEFAULT_DELETE_CONTENT(collectionData?.collection_id),
          selectedRows,
        );
        if (resp.status === 200) {
          setSelectedRows([]);
          notification.success({ message: t("delete_content_success") });
          init(true);
        }
      }
    } catch (error) {
      notification.error({
        message: error?.response?.data?.message || t("something_went_wrong"),
      });
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  const init = async (
    getCollectionData = false,
    search = "",
    page = pagination.currentPage,
    limit = pagination.pageSize,
    updatePreset = true,
    preset = {},
  ) => {
    if (path[2] || tableId) {
      try {
        let resp1;
        let resp2;
        if (getCollectionData) {
          resp1 = getFieldsBySchemaId({
            schemas: [...schemas, ...system_collections],
            fields,
            schemaId: tableId || path[2],
            populated: true,
          });
          if (!tableId && !resp1?.visible) {
            router.navigate("/content");
            return;
          }
          setCollectionData(resp1);
          if (updatePreset) {
            resp2 = await getAndSetPresets({
              schemaId: tableId || path[2],
              dispatch,
              presets: schemaPresets,
            });
            setSelectedColumns(resp2?.layout_query?.tabular?.fields);
          }
        }

        const resp3 = await axiosInstance.post(
          Apis.DEFAULT_GET_CONTENT(
            getCollectionData
              ? resp1?.collection_id
              : collectionData?.collection_id,
            search,
            {
              page,
              limit,
            },
          ),
          {
            columns: (getCollectionData && updatePreset
              ? resp2?.layout_query?.tabular?.fields
              : preset
            )?.map((item) => ({
              field_name: item.field_name,
              display_label: item.display_label,
              field_details: item.field_details,
            })),
          },
        );
        if (resp3.status === 200) {
          setContentData(resp3.data.payload.data);
          updatePagination(
            "totalItems",
            resp3.data?.payload?.meta?.total_found || 0,
          );
          updatePagination("currentPage", page);
          updatePagination("pageSize", limit);
        }
      } catch (error) {
        console.log("error", error);
        notification.error({
          message: t("something_went_wrong"),
        });
        router.navigate("/content");
        setContentData([]);
        updatePagination("totalItems", 0);
        updatePagination("currentPage", 1);
        updatePagination("pageSize", pagination.pageSize);
      } finally {
        setLoading(false);
      }
    }
  };

  const debouncedInit = useCallback(
    debounce((search, page) => {
      setLoading(true), init(true, search, page);
    }, 1000),
    [path[2]],
  );

  const generateColumns = () => {
    if (!collectionData?.fields?.length) return [];

    let cols = collectionData?.fields?.map((f) => {
      return {
        title: (
          <div
            className="resizable-header"
            style={{ width: columnWidths[f.path] }}
          >
            {f?.meta?.translations?.find((t) => t?.language === currentLng)
              ?.translation || f.field}
            <div
              role="draggable"
              className="resize-handle"
              onMouseDown={handleResizeStart(f.path)}
              /* onDoubleClick={() => {
                  setColumnWidths((prev) => ({
                    ...prev,
                    [f.path]: "auto",
                  }));
                }} */
            ></div>
          </div>
        ),
        dataIndex: f?.path.replaceAll(".", "-"),
        key: f.path,
        width: columnWidths[f.path],
        /* onHeaderCell: (column) => ({
            width: column.width,
            onResize: handleResize(index),
          }), */
        render: (record) => {
          let content;
          if (
            f?.meta?.interface === "dropdown_multiple" ||
            f?.meta?.interface === "dropdown"
          ) {
            const choices = f?.meta?.options?.choices || [];
            content = Array.isArray(record)
              ? record
                  .map((item) => choices?.find((c) => c?.value === item)?.label)
                  .join(",")
              : choices?.find((c) => c?.value === record)?.label;
          } else if (typeof record === "boolean") {
            content = record ? (
              <BsCheckLg size={24} />
            ) : (
              <AiOutlineClose size={24} />
            );
          } else if (f?.meta?.interface === "datetime") {
            if (record) {
              switch (f?.type) {
                case "datetime":
                  content = dayjs(record).format("MMMM Do, YYYY h:mm:ss A");
                  break;
                case "date":
                  content = dayjs(record).format("MMMM Do, YYYY");
                  break;
                case "time":
                  content = record;
                  break;
                case "timestamp":
                  content = dayjs(record).format("MMMM Do, YYYY h:mm:ss A");
                  break;
                default:
                  content = dayjs(record).format("MMMM Do, YYYY h:mm:ss A");
              }
            } else {
              content = "";
            }
          } else if (f?.meta?.interface === "wysiwyg") {
            content = <div dangerouslySetInnerHTML={{ __html: record }} />;
          } else if (Array.isArray(record) && record.length > 0) {
            if (typeof record[0] === "object") {
              if (Array.isArray(record[0])) {
                content = record.flat().join(",");
              } else {
                content = record?.map((r) => r?._id).join(",");
              }
            } else {
              content = record?.join(",");
            }
          } else if (f?.meta?.interface === "color") {
            content = (
              <div
                style={{
                  display: "flex",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    backgroundColor: record,
                    width: "20px",
                    height: "20px",
                    borderRadius: "6px",
                    marginRight: "5px",
                  }}
                ></div>
                {record}
              </div>
            );
          } else {
            content = record;
          }

          return <div>{content}</div>;
        },
      };
    });

    cols = cols.filter((col) => {
      return selectedColumns?.some((field) => {
        return field.field_name === col.key;
      });
    });

    (cols || [])?.unshift({
      title: (
        <div
          className="resizable-header"
          style={{ width: columnWidths["select"] }}
        >
          <Checkbox
            onChange={(e) =>
              setSelectedRows(
                e.target.checked ? contentData?.map((f) => f?._id) : [],
              )
            }
            disabled={
              (tableType === "relations" &&
                item?.meta?.interface === "list-m2o") ||
              loading
            }
            checked={
              selectedRows?.length > 0 &&
              selectedRows?.length === contentData?.length
            }
          >
            {t("select_all")}
          </Checkbox>
          <div
            className="resize-handle"
            // onMouseDown={handleResizeStart("select")}
            /* onDoubleClick={() => {
              setColumnWidths((prev) => ({
                ...prev,
                select: "auto",
              }));
            }} */
          ></div>
        </div>
      ),
      dataIndex: "select",
      key: "select",
      width: columnWidths["select"],
      render: (text, record) =>
        item?.meta?.interface === "list-m2o" ? (
          <Radio
            onChange={(e) => handleRowSelect(e.target.checked, record)}
            checked={selectedRows?.includes(record?._id)}
          />
        ) : (
          <Checkbox
            onChange={(e) => handleRowSelect(e.target.checked, record)}
            checked={
              item?.meta?.interface === "list-m2a"
                ? selectedRows?.find((rec) => rec?._id === record?._id)
                : selectedRows?.includes(record?._id)
            }
          />
        ),
    });
    return cols;
  };

  const columns = generateColumns(collectionData?.fields);

  const handleRowSelect = (checked, record) => {
    if (item?.meta?.interface === "list-m2o") {
      setSelectedRows([record._id]);
    } else if (item?.meta?.interface === "list-m2a") {
      setSelectedRows((prevSelectedRows) => {
        if (checked) {
          return [
            ...prevSelectedRows,
            {
              ...record,
              tableId,
              collection: collectionData?.collection_id,
            },
          ];
        } else {
          return prevSelectedRows.filter((rec) => rec?._id !== record._id);
        }
      });
    } else {
      setSelectedRows((prevSelectedRows) => {
        if (checked) {
          return [...prevSelectedRows, record._id];
        } else {
          return prevSelectedRows.filter((id) => id !== record._id);
        }
      });
    }
  };

  const handleDataAdd = async () => {
    setRelationalData((prev) =>
      tableType === "m2a"
        ? [
            ...prev,
            ...(selectedRows.map((row) => ({ ...row, item: row?._id })) || []),
          ]
        : selectedRows?.map((row) => contentData?.find((c) => c?._id === row)),
    );
    setSelectedRows([]);
    setShowDrawer(false);
  };

  const handleColumnsAdd = async (selectedColumns) => {
    setLoading(true);
    try {
      const resp = await axiosInstance.put(
        Apis.UPDATE_PRESET(path[2]),
        selectedColumns,
      );
      if (resp.status === 200) {
        const respData = resp.data.payload.data;
        setSelectedColumns(respData?.layout_query?.tabular?.fields);
        dispatch(setPreset({ schemaId: path[2], preset: respData }));
        init(
          true,
          searchTerm,
          pagination.currentPage,
          pagination.pageSize,
          false,
          respData?.layout_query?.tabular?.fields || [],
        );
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setSelectOpen(false);
      setLoading(false);
    }
  };

  const handleArchiveButton = async () => {
    await copyToClipboard(
      JSON.stringify({ columns: selectedColumns }, null, 2),
      t("columns_copy_success"),
      t("something_went_wrong"),
    );
  };

  useEffect(() => {
    searchTerm && searchTerm !== "" && setSearchTerm("");
    if (path[2] || tableId) {
      setContentData([]);
      setLoading(true);
      init(true, "", 1);
    }
    return () => {
      setSelectedColumns([]);
      setSelectedRows([]);
    };
  }, [path[2], schemas, fields]);

  return (
    <>
      <Row className="main-content-wrap">
        <Col className="px-8 xl:px-16 left-part" span={24}>
          {tableType === "content" ? (
            <div className="primary-header pt-9 pb-5 | drawer-header-style">
              <LeftSideTitlePart
                pageTitle={
                  capitalizeAndFormat(collectionData?.collection_name) || ""
                }
                pageSubTitle={t("content")}
                pageIcon={true}
                pageInnerIcon={
                  collectionData?.icon ? (
                    filteredIcons[collectionData?.icon] &&
                    React.createElement(filteredIcons[collectionData?.icon], {
                      size: 16,
                      color: collectionData?.color || "var(--project-base)",
                    })
                  ) : (
                    <IconsAll.DataModalIcon />
                  )
                }
                backButton={false}
              />
              <RightSIdeTitlePart
                isFilterShow={true}
                searchDisabled={
                  !contentData?.length && (!searchTerm || searchTerm === "")
                }
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                searchTermPlaceholder={t("search_in_collection")}
                addButton={true}
                addDisabled={!path[2] || loading}
                deleteButton={selectedRows?.length > 0}
                deleteDisabled={!selectedRows?.length}
                handleDeleteClick={() => setOpen(true)}
                handleAddClick={() => {
                  router.navigate(`/content/${path[2]}/add`);
                  dispatch(
                    setActiveTab({
                      page: "content",
                      tab: path[2],
                      redirectionLink: `/content/${path[2]}/add`,
                    }),
                  );
                }}
                debouncedInit={debouncedInit}
                archiveButton
                handleArchiveButton={handleArchiveButton}
                archiveDisabled={loading}
              />
            </div>
          ) : (
            <div className="primary-header pt-9 pb-5 | drawer-header-style">
              <LeftSideTitlePart
                pageTitle={
                  capitalizeAndFormat(collectionData?.collection_id) || ""
                }
                pageSubTitle={t("content")}
                pageIcon={true}
                pageInnerIcon={<IconsAll.PaginasIcon />}
                backButton={false}
              />
              <RightSIdeTitlePart
                isFilterShow={true}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                showButton={true}
                showDisabled={!selectedRows?.length}
                handleShowClick={handleDataAdd}
                debouncedInit={debouncedInit}
              />
            </div>
          )}
          <section className="mt-4">
            <div className="relative">
              <TableStriped
                columns={columns}
                dataSource={contentData?.map((c) => flattenObject(c))}
                rowKey={(record) => record?._id}
                locale={{
                  emptyText: <NoData title={t("no_data_found")} />,
                }}
                onRow={(record) => {
                  return {
                    onClick: (e) => {
                      if (e.target.type === "checkbox") return;
                      if (tableType === "content") {
                        router.navigate(
                          `${activeTab?.redirectionLink}/edit/${record?._id}`,
                        );
                      } else {
                        handleRowSelect(
                          !selectedRows?.includes(record?._id),
                          record,
                        );
                      }
                    },
                  };
                }}
                pagination={{
                  pageSizeOptions: [10, 25, 50],
                  current: pagination.currentPage,
                  pageSize: pagination.pageSize,
                  total: pagination.totalItems,
                  hideOnSinglePage: true,
                  showSizeChanger: true,
                  locale: { items_per_page: "" },
                  onChange: async (page, size) => {
                    page !== pagination.currentPage && setLoading(true);
                    await init(true, searchTerm, page, size);
                  },
                  onShowSizeChange: async (page, size) => {
                    setLoading(true);
                    await init(true, searchTerm, page, size);
                  },
                }}
                loading={{
                  spinning: loading,
                  indicator: (
                    <Spin indicator={<div className="custom-loader" />} />
                  ),
                }}
              />

              {!loading &&
                contentData?.length > 0 &&
                tableType === "content" && (
                  <SelectTableColumns
                    currentColumns={selectedColumns}
                    columnOptions={collectionData?.fields}
                    handleColumnsAdd={handleColumnsAdd}
                    selectOpen={selectOpen}
                    setSelectOpen={setSelectOpen}
                  />
                )}
            </div>
          </section>
        </Col>
        <Modal
          open={open}
          title={t("delete_content")}
          okText={t("delete_content")}
          onOk={handleDeleteClick}
          cancelText={t("cancel")}
          onCancel={() => setOpen(false)}
          className="modal-delete"
          footer={(_, { OkBtn, CancelBtn }) => (
            <>
              <CancelBtn />
              <OkBtn />
            </>
          )}
        >
          <p className="my-6">{t("delete_content_confirm")}</p>
        </Modal>
      </Row>
      {tableType === "content" && (
        <AppInfo
          visibleList={["information"]}
          config={{
            msg: t("information_content_table", {
              collection_name: capitalizeAndFormat(
                collectionData?.collection_name,
              ),
            }),
          }}
        />
      )}
    </>
  );
};

export default withRouter(ContentTable);
