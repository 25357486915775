import React from "react";
import styled from "styled-components";
import { Button, Flex, Input, Spin } from "antd";
import { CiSearch } from "react-icons/ci";
import { FiFolderPlus, FiPlus } from "react-icons/fi";
import { IoFilter, IoArchiveOutline } from "react-icons/io5";
import {
  RiDeleteBin6Line,
  RiEqualizerLine,
  RiShareBoxLine,
  RiUserAddLine,
} from "react-icons/ri";
import { BsCheckLg } from "react-icons/bs";
import { themes } from "../../config";
import { useSelector } from "react-redux";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { FaClockRotateLeft } from "react-icons/fa6";
import { LuListRestart } from "react-icons/lu";

const HeaderInputStyle = styled(Input)`
  min-height: 56px;
  border-radius: 100vh !important;
  padding: 13px 24px;
  background-color: var(--input-field-bg);
  color: var(--input-field-text-clr);
  border: 1px solid var(--input-field-border);
  &:hover {
    background-color: var(--input-field-bg);
  }
  &:focus {
    background-color: var(--input-field-bg);
  }
  &:has(.ant-input:focus) {
    background-color: var(--input-field-bg);
  }
  > .ant-input-prefix {
    margin-inline-end: 10px;
  }
`;

const HeaderButton = styled(Button)`
  min-width: 56px !important;
  width: 56px !important;
  height: 56px;
  border: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  > .ant-btn-icon {
    display: grid;
  }
  &.folder-button {
    background: ${() =>
      themes[useSelector((state) => state.theme.name)].components
        .HeaderButtonsStyle.folderButtonBg};
    color: ${() =>
      themes[useSelector((state) => state.theme.name)].components
        .HeaderButtonsStyle.folderButtonClr};

    &:hover {
      background: ${() =>
        themes[useSelector((state) => state.theme.name)].components
          .HeaderButtonsStyle.folderButtonHoverBg} !important;
      color: ${() =>
        themes[useSelector((state) => state.theme.name)].components
          .HeaderButtonsStyle.folderButtonHoverClr}!important;
    }
    &[disabled] {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }
  &.add-button {
    background: var(--project-base);
    color: ${() =>
      themes[useSelector((state) => state.theme.name)].components
        .HeaderButtonsStyle.addButtonClr};

    &:hover {
      background: ${() =>
        themes[useSelector((state) => state.theme.name)].components
          .HeaderButtonsStyle.addButtonHoverBg} !important;
      color: ${() =>
        themes[useSelector((state) => state.theme.name)].components
          .HeaderButtonsStyle.addButtonHoverClr} !important;
    }
    &[disabled] {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }

  &.delete-button {
    background: ${() =>
      themes[useSelector((state) => state.theme.name)].components
        .HeaderButtonsStyle.deleteButtonBg};
    color: ${() =>
      themes[useSelector((state) => state.theme.name)].components
        .HeaderButtonsStyle.deleteButtonClr};

    &:hover {
      background: ${() =>
        themes[useSelector((state) => state.theme.name)].components
          .HeaderButtonsStyle.deleteButtonHoverBg} !important;
      color: ${() =>
        themes[useSelector((state) => state.theme.name)].components
          .HeaderButtonsStyle.deleteButtonHoverClr} !important;
    }
    &[disabled] {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }

  &.submit-button {
    background: var(--project-base);
    color: var(--project-base-text);

    &:hover {
      background: ${() =>
        themes[useSelector((state) => state.theme.name)].components
          .HeaderButtonsStyle.submitButtonHoverBg} !important;
      color: ${() =>
        themes[useSelector((state) => state.theme.name)].components
          .HeaderButtonsStyle.submitButtonHoverClr} !important;
    }
    &[disabled] {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }

  &.archive-button {
    background: ${() =>
      themes[useSelector((state) => state.theme.name)].components
        .HeaderButtonsStyle.archiveButtonBg};
    color: ${() =>
      themes[useSelector((state) => state.theme.name)].components
        .HeaderButtonsStyle.archiveButtonClr};

    &:hover {
      background: ${() =>
        themes[useSelector((state) => state.theme.name)].components
          .HeaderButtonsStyle.archiveButtonHoverBg} !important;
      color: ${() =>
        themes[useSelector((state) => state.theme.name)].components
          .HeaderButtonsStyle.archiveButtonHoverClr} !important;
    }
    &[disabled] {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }

  &.disabled-submit-button {
    cursor: not-allowed;
    background: ${() =>
      themes[useSelector((state) => state.theme.name)].components
        .HeaderButtonsStyle.disabledSubmitButtonBg};
    color: ${() =>
      themes[useSelector((state) => state.theme.name)].components
        .HeaderButtonsStyle.disabledSubmitButtonClr};

    &:hover {
      background: ${() =>
        themes[useSelector((state) => state.theme.name)].components
          .HeaderButtonsStyle.disabledSubmitButtonHoverBg} !important;
      color: ${() =>
        themes[useSelector((state) => state.theme.name)].components
          .HeaderButtonsStyle.disabledSubmitButtonHoverClr} !important;
    }
  }
`;

const RightSIdeTitlePart = ({
  isFilterShow,
  folderButton,
  folderDisabled,
  handleFolderClick,
  // setOpen,
  addButton,
  userAdd,
  handleUserAddClick,
  deleteButton,
  handleDeleteClick,
  showButton,
  buttonLoading,
  archiveButton,
  archiveDisabled,
  handleArchiveButton,
  equalizerButton,
  handleEqualizerButton,
  equalizerDisabled,
  logsButton,
  handleLogsButton,
  logsDisabled,
  disabledSubmitButton,
  isDrawerOpen,
  setIsDrawerOpen,
  handleShowClick,
  handleAddClick,
  showDisabled,
  addDisabled,
  deleteDisabled,
  searchTerm,
  setSearchTerm,
  searchDisabled,
  debouncedInit,
  searchTermPlaceholder,
  revertButton,
  handleRevertClick,
  revertDisabled,
  shareButton,
  handleShareClick,
  shareDisabled,
}) => {
  return (
    <Flex className="flex items-center justify-start md:justify-center gap-3 mt-3 lg:mt-0">
      {isFilterShow && (
        <HeaderInputStyle
          size="large"
          placeholder={searchTermPlaceholder}
          value={searchTerm}
          disabled={searchDisabled}
          onChange={(e) => {
            debouncedInit && debouncedInit(e.target.value, 1),
              setSearchTerm(e.target.value);
          }}
          prefix={<CiSearch size={20} />}
          suffix={
            <>
              {searchTerm && (
                <AiOutlineCloseCircle
                  onClick={() => {
                    debouncedInit && debouncedInit(""), setSearchTerm("");
                  }}
                  size={20}
                />
              )}
              <IoFilter size={20} />
            </>
          }
        />
      )}
      {folderButton && (
        <HeaderButton
          shape="circle"
          icon={<FiFolderPlus size={24} />}
          className="btn folder-button"
          disabled={folderDisabled}
          onClick={handleFolderClick}
        />
      )}
      {userAdd && (
        <HeaderButton
          shape="circle"
          icon={<RiUserAddLine size={24} />}
          className="btn folder-button"
          onClick={() => {
            if (handleUserAddClick) {
              handleUserAddClick();
            }
          }}
        />
      )}
      {addButton && (
        <HeaderButton
          shape="circle"
          icon={<FiPlus size={24} />}
          className="btn add-button"
          onClick={() => {
            if (setIsDrawerOpen) setIsDrawerOpen(!isDrawerOpen);
            if (handleAddClick) handleAddClick();
          }}
          disabled={addDisabled}
        />
      )}
      {deleteButton && (
        <HeaderButton
          shape="circle"
          icon={<RiDeleteBin6Line size={24} />}
          className="btn delete-button"
          onClick={handleDeleteClick}
          disabled={deleteDisabled}
        />
      )}
      {archiveButton && (
        <HeaderButton
          shape="circle"
          icon={<IoArchiveOutline size={24} />}
          className="btn archive-button"
          onClick={handleArchiveButton}
          disabled={archiveDisabled}
        />
      )}
      {equalizerButton && (
        <HeaderButton
          shape="circle"
          icon={<RiEqualizerLine size={24} />}
          className="btn archive-button"
          onClick={handleEqualizerButton}
          disabled={equalizerDisabled}
        />
      )}
      {logsButton && (
        <HeaderButton
          shape="circle"
          icon={<LuListRestart size={24} />}
          className="btn archive-button"
          onClick={handleLogsButton}
          disabled={logsDisabled}
        />
      )}
      {revertButton && (
        <HeaderButton
          shape="circle"
          icon={<FaClockRotateLeft size={24} />}
          className="btn archive-button"
          onClick={handleRevertClick}
          disabled={revertDisabled}
        />
      )}
      {showButton && (
        <HeaderButton
          shape="circle"
          icon={
            buttonLoading ? (
              <Spin indicator={<div className="custom-loader" />} />
            ) : (
              <BsCheckLg size={24} />
            )
          }
          className="btn submit-button"
          onClick={handleShowClick}
          disabled={showDisabled}
        />
      )}
      {disabledSubmitButton && (
        <HeaderButton
          shape="circle"
          icon={<BsCheckLg size={24} />}
          className="btn disabled-submit-button"
        />
      )}
      {shareButton && (
        <HeaderButton
          shape="circle"
          icon={<RiShareBoxLine size={24} />}
          className="btn archive-button"
          onClick={handleShareClick}
          disabled={shareDisabled}
        />
      )}
    </Flex>
  );
};

export default RightSIdeTitlePart;
