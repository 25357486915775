import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  fields: [],
  relations: [],
};

const fields = createSlice({
  name: "fields",
  initialState,
  reducers: {
    setFields: (state, action) => {
      return { ...state, fields: action.payload };
    },
    setFieldsRelations: (state, action) => {
      return { ...state, relations: action.payload };
    },
    addFieldsRelations: (state, action) => {
      return {
        ...state,
        relations: [...state.relations, ...(action.payload || [])],
      };
    },
    addField: (state, action) => {
      const { singleObject, data } = action.payload;
      if (singleObject) {
        state.fields.push(data);
      } else {
        state.fields = [...state.fields, ...data];
      }
    },
    updateFieldById: (state, action) => {
      const { id, updatedData } = action.payload;
      const index = state.fields.findIndex((f) => f._id === id);

      if (["object", "items"].includes(updatedData.meta?.interface)) {
        let oldPath = state.fields?.find((field) => field?._id === id)?.path;
        let newPath = updatedData?.path;

        if (oldPath !== newPath) {
          state.fields.forEach((field) => {
            if (
              field?.schema_id === updatedData?.schema_id &&
              field.path?.startsWith(oldPath)
            ) {
              field.path = `${newPath}${field.path.replace(oldPath, "")}`;
            }
          });
        }
      }

      if (index !== -1) {
        for (const [key, value] of Object.entries(updatedData)) {
          const keys = key.split(".");
          let target = state.fields[index];

          for (let i = 0; i < keys.length - 1; i++) {
            target = target[keys[i]] ??= {};
          }

          target[keys[keys.length - 1]] = value;
        }
      }
    },
    matchAndReplaceField: (state, action) => {
      const { matchKey, matchValue, newField } = action.payload;

      state.fields = state.fields.filter((item) => {
        return item[matchKey] !== matchValue;
      });
      state.fields.push(...newField);
    },
    deleteFieldById: (state, action) => {
      state.fields = state.fields.filter((f) => f._id !== action.payload);
    },
    deleteFieldBySchemaId: (state, action) => {
      state.fields = state.fields.filter((f) => f.schema_id !== action.payload);
    },
  },
});

export const {
  setFields,
  setFieldsRelations,
  addFieldsRelations,
  addField,
  updateFieldById,
  matchAndReplaceField,
  deleteFieldById,
  deleteFieldBySchemaId,
} = fields.actions;
export default fields.reducer;
