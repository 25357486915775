import Joi from "joi";
import { capitalizeAndFormat, objectId } from "./utils";
import { isEmpty } from "lodash";
import { store } from "../Redux/store";
import dayjs from "dayjs";

const extraValidationsByInterface = {
  "list-m2a": {
    type: "object",
    keys: {
      sort: {
        type: "number",
        validations: {
          rules: [
            {
              rule: "required",
              required: { value: false },
            },
          ],
        },
      },
      item: {
        type: "string",
        validations: {
          rules: [
            {
              rule: "required",
              required: { value: true },
            },
            {
              rule: "objectid",
              objectid: {},
            },
          ],
        },
      },
      collection: {
        type: "string",
        validations: {
          rules: [
            {
              rule: "required",
              required: { value: true },
            },
          ],
        },
      },
    },
  },
};

const createDynamicJoiSchema = (definition) => {
  const createJoiSchema = (schema) => {
    let joiSchema = Joi;
    if (schema.type) {
      joiSchema = Joi[schema.type]();
    }

    const applyRule = (rule, object) => {
      switch (rule) {
        case "when": {
          const { field, is, then, otherwise } = object;
          joiSchema = joiSchema.when(field, {
            is: createJoiSchema(is),
            then: createJoiSchema(then),
            otherwise: createJoiSchema(otherwise),
          });
          break;
        }
        case "separatedValue":
          joiSchema = joiSchema[object?.value](...schema[object?.value]);
          break;
        case "contains":
          joiSchema = joiSchema.custom((val, helpers) => {
            const checkValue = object.insensitive ? val.toLowerCase() : val;
            const targetValue = object.insensitive
              ? object.value.toLowerCase()
              : object.value;
            if (!checkValue.includes(targetValue)) {
              return helpers.error("any.invalid", {
                message: `Must contain '${object.value}'${object.insensitive ? " (insensitive)" : ""}`,
              });
            }
            return val;
          });
          break;
        case "date":
          joiSchema = joiSchema.iso();
          break;
        case "doesNotContain":
          joiSchema = joiSchema.custom((val, helpers) => {
            const checkValue = object.insensitive ? val.toLowerCase() : val;
            const targetValue = object.insensitive
              ? object.value.toLowerCase()
              : object.value;
            if (checkValue.includes(targetValue)) {
              return helpers.error("any.invalid", {
                message: `Must not contain '${object.value}'${object.insensitive ? " (insensitive)" : ""}`,
              });
            }
            return val;
          });
          break;
        case "startsWith":
          joiSchema = joiSchema.custom((val, helpers) => {
            const checkValue = object.insensitive ? val.toLowerCase() : val;
            const targetValue = object.insensitive
              ? object.value.toLowerCase()
              : object.value;
            if (!checkValue.startsWith(targetValue)) {
              return helpers.error("any.invalid", {
                message: `Must start with '${object.value}'${object.insensitive ? " (insensitive)" : ""}`,
              });
            }
            return val;
          });
          break;
        case "doesNotStartWith":
          joiSchema = joiSchema.custom((val, helpers) => {
            const checkValue = object.insensitive ? val.toLowerCase() : val;
            const targetValue = object.insensitive
              ? object.value.toLowerCase()
              : object.value;
            if (checkValue.startsWith(targetValue)) {
              return helpers.error("any.invalid", {
                message: `Must not start with '${object.value}'${object.insensitive ? " (insensitive)" : ""}`,
              });
            }
            return val;
          });
          break;
        case "endsWith":
          joiSchema = joiSchema.custom((val, helpers) => {
            const checkValue = object.insensitive ? val.toLowerCase() : val;
            const targetValue = object.insensitive
              ? object.value.toLowerCase()
              : object.value;
            if (!checkValue.endsWith(targetValue)) {
              return helpers.error("any.invalid", {
                message: `Must end with '${object.value}'${object.insensitive ? " (insensitive)" : ""}`,
              });
            }
            return val;
          });
          break;
        case "doesNotEndWith":
          joiSchema = joiSchema.custom((val, helpers) => {
            const checkValue = object.insensitive ? val.toLowerCase() : val;
            const targetValue = object.insensitive
              ? object.value.toLowerCase()
              : object.value;
            if (checkValue.endsWith(targetValue)) {
              return helpers.error("any.invalid", {
                message: `Must not end with '${object.value}'${object.insensitive ? " (insensitive)" : ""}`,
              });
            }
            return val;
          });
          break;
        case "equals":
          joiSchema = joiSchema.custom((val, helpers) => {
            if (
              (schema?.interface === "datetime" && schema.type !== "string"
                ? val?.toISOString()
                : val) !== object?.value
            ) {
              return helpers.error("any.invalid", {
                message: `Must equal '${object?.value}'`,
              });
            }
            return val;
          });
          break;
        case "doesNotEqual":
          joiSchema = joiSchema.custom((val, helpers) => {
            if (
              (schema?.interface === "datetime" && schema.type !== "string"
                ? val?.toISOString()
                : val) === object?.value
            ) {
              return helpers.error("any.invalid", {
                message: `Must not equal '${object?.value}'`,
              });
            }
            return val;
          });
          break;
        case "lessThan":
          joiSchema = joiSchema.custom((val, helpers) => {
            if (schema?.interface === "datetime") {
              if (schema?.isTimeOnly) {
                const today = dayjs().format("YYYY-MM-DD");
                let date1 = dayjs(`${today}T${val}`);
                let date2 = dayjs(`${today}T${object?.value}`);
                if (date1.isSame(date2) || date1.isAfter(date2)) {
                  return helpers.error("any.invalid", {
                    message: `Must be less than '${object?.value}'`,
                  });
                }
              }
              if (
                dayjs(
                  schema.type === "string" ? val : val?.toISOString(),
                ).isSame(dayjs(object?.value)) ||
                dayjs(
                  schema.type === "string" ? val : val?.toISOString(),
                ).isAfter(dayjs(object?.value))
              )
                return helpers.error("any.invalid", {
                  message: `Must be less than '${dayjs(object?.value)?.format()}'`,
                });
            } else {
              if (val >= object?.value) {
                return helpers.error("any.invalid", {
                  message: `Must be less than '${object?.value}'`,
                });
              }
            }
            return val;
          });
          break;
        case "lessThanOrEqualTo":
          joiSchema = joiSchema.custom((val, helpers) => {
            if (schema?.interface === "datetime") {
              if (schema?.isTimeOnly) {
                const today = dayjs().format("YYYY-MM-DD");
                let date1 = dayjs(`${today}T${val}`);
                let date2 = dayjs(`${today}T${object?.value}`);
                if (date1.isAfter(date2)) {
                  return helpers.error("any.invalid", {
                    message: `Must be less than '${object?.value}'`,
                  });
                }
              }
              if (
                dayjs(
                  schema.type === "string" ? val : val?.toISOString(),
                ).isAfter(dayjs(object?.value))
              )
                return helpers.error("any.invalid", {
                  message: `Must be less than or equal to'${dayjs(object?.value)?.format()}'`,
                });
            } else {
              if (val >= object?.value) {
                return helpers.error("any.invalid", {
                  message: `Must be less than or equal to'${object?.value}'`,
                });
              }
            }
            return val;
          });
          break;
        case "greaterThan":
          joiSchema = joiSchema.custom((val, helpers) => {
            if (schema?.interface === "datetime") {
              if (schema?.isTimeOnly) {
                const today = dayjs().format("YYYY-MM-DD");
                let date1 = dayjs(`${today}T${val}`);
                let date2 = dayjs(`${today}T${object?.value}`);
                if (date1.isSame(date2) || date1.isBefore(date2)) {
                  return helpers.error("any.invalid", {
                    message: `Must be greater than '${object?.value}'`,
                  });
                }
              }
              if (
                dayjs(
                  schema.type === "string" ? val : val?.toISOString(),
                ).isSame(dayjs(object?.value)) ||
                dayjs(
                  schema.type === "string" ? val : val?.toISOString(),
                ).isBefore(dayjs(object?.value))
              )
                return helpers.error("any.invalid", {
                  message: `Must be greater than '${dayjs(object?.value)?.format()}'`,
                });
            } else {
              if (val >= object?.value) {
                return helpers.error("any.invalid", {
                  message: `Must be greater than '${object?.value}'`,
                });
              }
            }
            return val;
          });
          break;
        case "greaterThanOrEqualTo":
          joiSchema = joiSchema.custom((val, helpers) => {
            if (schema?.interface === "datetime") {
              if (schema?.isTimeOnly) {
                const today = dayjs().format("YYYY-MM-DD");
                let date1 = dayjs(`${today}T${val}`);
                let date2 = dayjs(`${today}T${object?.value}`);
                if (date1.isBefore(date2)) {
                  return helpers.error("any.invalid", {
                    message: `Must be greater than or equal to '${object?.value}'`,
                  });
                }
              }
              if (
                dayjs(
                  schema.type === "string" ? val : val?.toISOString(),
                ).isBefore(dayjs(object?.value))
              )
                return helpers.error("any.invalid", {
                  message: `Must be greater than or equal to '${dayjs(object?.value)?.format()}'`,
                });
            } else {
              if (val >= object?.value) {
                return helpers.error("any.invalid", {
                  message: `Must be greater than or equal to '${object?.value}'`,
                });
              }
            }
            return val;
          });
          break;
        case "isEmpty":
          joiSchema = joiSchema.custom((val, helpers) => {
            if (!isEmpty(val)) {
              return helpers.error("any.invalid", { message: `Must be empty` });
            }
            return val;
          });
          break;
        case "isNotEmpty":
          joiSchema = joiSchema.custom((val, helpers) => {
            if (isEmpty(val)) {
              return helpers.error("any.invalid", {
                message: `Must not be empty`,
              });
            }
            return val;
          });
          break;
        case "isNull":
          joiSchema = joiSchema.custom((val, helpers) => {
            if (object?.value && val !== null) {
              return helpers.error("any.invalid", { message: `Must be null` });
            }
            return val;
          });
          break;
        case "isNotNull":
          joiSchema = joiSchema.custom((val, helpers) => {
            if (object?.value && val === null) {
              return helpers.error("any.invalid", {
                message: `Must not be null`,
              });
            }
            return val;
          });
          break;
        case "isOneOf":
          joiSchema = joiSchema.custom((val, helpers) => {
            if (!object?.value.includes(val)) {
              return helpers.error("any.invalid", {
                message: `Must be one of ${object?.value.join(", ")}`,
              });
            }
            return val;
          });
          break;
        case "isNotOneOf":
          joiSchema = joiSchema.custom((val, helpers) => {
            if (object?.value.includes(val)) {
              return helpers.error("any.invalid", {
                message: `Must not be one of ${object?.value}`,
              });
            }
            return val;
          });
          break;
        case "matchesRegExp":
          joiSchema = joiSchema.custom((val, helpers) => {
            if (!new RegExp(object?.value).test(val)) {
              return helpers.error("any.invalid", {
                message: `Must match the regular expression ${object?.value}`,
              });
            }
            return val;
          });
          break;
        case "objectid":
          joiSchema = joiSchema.custom(objectId);
          break;
        case "required":
          if (object?.value) joiSchema = joiSchema.required();
          break;
        case "allow":
          joiSchema = joiSchema.allow(...(object?.value || {}));
          break;
        case "any":
          joiSchema = joiSchema.any();
          break;
        default:
          if (object?.value) {
            joiSchema = joiSchema[rule]();
          } else if (
            typeof object?.value !== "object" &&
            object?.value !== null
          ) {
            joiSchema = joiSchema[rule](object?.value);
          }
          break;
      }
    };

    if (schema.keys) {
      const keys = {};
      for (const [key, value] of Object.entries(schema.keys)) {
        keys[key] = createJoiSchema(value);
      }
      joiSchema = joiSchema.keys(keys);
    } else if (schema.items) {
      joiSchema = joiSchema.items(createJoiSchema(schema.items));
    } else {
      for (const data of schema?.validations?.rules || []) {
        applyRule(data.rule, data[data.rule]);
      }
    }
    return joiSchema;
  };

  const keys = {};
  for (const [key, value] of Object.entries(definition)) {
    keys[key] = createJoiSchema(value);
  }
  return Joi.object(keys);
};

const convertFieldsToJoiFormat = (fields) => {
  const convertedData = {};

  function addToConvertedData(currentLevel, pathParts, field, validationRules) {
    pathParts.forEach((part, index) => {
      let existingField = currentLevel?.[part];

      if (!existingField) {
        let validationData = {};
        let type = field.schema_definition?.type?.toLowerCase();
        let itsAliasType = {};

        if (type === "alias") {
          type = "array";
          if (field.meta?.interface === "list-m2a") {
            itsAliasType = {
              items: {
                ...extraValidationsByInterface["list-m2a"],
              },
            };
          } else {
            itsAliasType = {
              items: {
                type: "string",
                validations: {
                  rules: [...validationRules],
                },
              },
            };
          }
        } else if (type === "objectid") {
          type = "string";
        } else if (
          field.type?.toLowerCase() === "array" &&
          field.field_type?.toLowerCase() === "object" &&
          type !== field.type?.toLowerCase()
        ) {
          type = "array";
          itsAliasType = {
            items: {
              type: field.schema_definition.type?.toLowerCase(),
              validations: {
                rules: [...validationRules],
              },
            },
          };
        }

        if (type !== "object" && type !== "array") {
          validationData = {
            validations: {
              rules: [...validationRules],
            },
          };
        }

        const newField = {
          [part]: {
            type:
              index === pathParts.length - 1 ? type?.toLowerCase() : "object",
            ...validationData,
            ...itsAliasType,
          },
        };

        existingField = newField;
        currentLevel[part] = newField[part];
      }

      if (index < pathParts.length - 1) {
        if (existingField.type?.toLowerCase() === "object") {
          if (!existingField.keys) {
            existingField.keys = {};
          }
          currentLevel = existingField.keys;
        } else if (existingField.type?.toLowerCase() === "array") {
          if (!existingField.items) {
            existingField.items = {
              type: "object",
              keys: {},
            };
          }
          currentLevel = existingField.items.keys;
        }
      }
    });
  }

  fields.forEach((field) => {
    let type = field.type?.toLowerCase();
    let isTimeOnly = false;
    let validationRules = [
      {
        rule: "required",
        required: { value: !!field?.meta?.required },
      },
    ];

    if (!field?.meta?.required && field?.meta?.nullable) {
      validationRules.push({
        rule: "allow",
        allow: { value: ["", null] },
      });
    }

    if (["date", "time"].includes(type)) {
      isTimeOnly = type === "time";
      type = "string";
    }

    if (["datetime", "timestamp"].includes(type)) {
      type = "date";
      validationRules.push({
        rule: "date",
      });
    }

    if (field.type === "Alias" || field.type === "ObjectId") {
      type = "string";
      validationRules.push({
        rule: "objectid",
        objectid: {},
      });
    }

    if (field?.meta?.interface === "translations") {
      type = "any";
      validationRules = [];
    }

    if (
      field?.type?.toLowerCase() === "array" &&
      !["array", "alias"].includes(
        field?.schema_definition?.type?.toLowerCase(),
      )
    ) {
      type = field?.schema_definition?.type?.toLowerCase();
    }

    validationRules.push(...(field?.meta?.validations?.rules || []));

    if (field?.meta?.hidden) {
      validationRules = [];
    }
    if (field.field_type === "Single") {
      convertedData[field.field] = {
        interface: field?.meta?.interface,
        type: type,
        isTimeOnly,
        validations: {
          rules: [...validationRules],
        },
      };
    } else if (
      field.field_type === "Object" &&
      field?.meta?.interface !== "translations"
    ) {
      addToConvertedData(
        convertedData,
        field.path.split("."),
        field,
        validationRules,
      );
    } else if (field.field_type === "Array") {
      if (field.meta?.interface === "list-m2a") {
        convertedData[field.field] = {
          interface: field?.meta?.interface,
          type: field.field_type?.toLowerCase(),
          isTimeOnly,
          items: {
            ...extraValidationsByInterface["list-m2a"],
          },
        };
      } else {
        convertedData[field.field] = {
          interface: field?.meta?.interface,
          type: field.field_type?.toLowerCase(),
          isTimeOnly,
          items: {
            type: type,
            validations: {
              rules: [...validationRules],
            },
          },
        };
      }
    }
  });

  return convertedData;
};

export const JoiValidateData = async ({
  data,
  fields,
  schema = null,
  isGeneratedSchema = false,
  prefix = null,
}) => {
  const currentLng = store.getState()?.user?.userProfile?.language;
  let joiSchema = schema;

  if (!isGeneratedSchema) {
    joiSchema = await createDynamicJoiSchema(
      convertFieldsToJoiFormat(
        fields.sort(
          (a, b) => a?.path?.split(".")?.length - b?.path?.split(".")?.length,
        ),
      ),
    );
  }
  const { error, value } = joiSchema
    .prefs({
      errors: { label: "key", wrap: { label: false } },
      abortEarly: false,
    })
    .validate(data);

  if (error) {
    const { details } = error;
    const errorData = {};
    details.map((item) => {
      errorData[prefix ? prefix + item.path.join(".") : item.path.join(".")] = {
        error_msg: item.message?.replace(
          item?.context?.label,
          fields
            ?.find((field) => field?.path === item.path.join("."))
            ?.meta?.translations?.find((t) => t?.language === currentLng)
            ?.translation || capitalizeAndFormat(item?.context?.label),
        ),
        ...(item?.context?.message && {
          context_error_msg:
            item?.context?.label + " " + item?.context?.message,
        }),
      };
    });
    return {
      status: false,
      error: errorData,
    };
  }
  return {
    status: true,
    payload: value,
  };
};
