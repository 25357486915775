import {
  Collapse,
  Divider,
  Flex,
  Pagination,
  Spin,
  Steps,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import moment from "moment/moment";
import axiosInstance from "../../../../Helpers/axios";
import Apis from "../../../../Helpers/Apis";
import { LuPlay } from "react-icons/lu";
import { DrawerStyle, TItleIconStyle } from "../../../../Common/CommonUiStyles";
import IconsAll from "../../../../Components/IconsAll";
import { usePagination } from "../../../../Helpers/custom-hooks/usePagination";
import { VscChecklist } from "react-icons/vsc";
const { Title, Paragraph } = Typography;

const FlowActivityDetail = ({ activity }) => {
  const items = Object.keys(activity?.data || {}).map((key) => ({
    title: key,
    description: (
      <Collapse
        size="small"
        items={Object.keys(activity.data[key]).map((date) => ({
          key: date,
          label: date,
          children: (
            <Paragraph
              className="!bg-[transparent] !mb-0"
              copyable={{
                text: JSON.stringify(activity.data[key][date], null, 2),
              }}
            >
              <pre className="!bg-[transparent] !py-0 !m-0 !border-0 text-sm font-normal text-(--heading-text)">
                {JSON.stringify(activity.data[key][date], null, 2)}
              </pre>
            </Paragraph>
          ),
        }))}
      />
    ),
  }));
  return (
    <div>
      <Steps
        direction="vertical"
        size="small"
        current={items.length - 1}
        items={items}
        status={"finish"}
        progressDot
      />
    </div>
  );
};

const FlowActivity = ({ flowId }) => {
  const [loader, setLoader] = useState(false);
  const [activities, setActivities] = useState([]);
  const [selectedActivity, setSelectedActivity] = useState({});
  const { pagination, updatePagination } = usePagination(
    1,
    10,
    "timestamp",
    true,
  );

  const convertTimestamp = (timestamp) => {
    const date = moment(timestamp);
    const timeString = date.format("hh:mm:ss A");
    const relativeTime = date.fromNow();
    return `${timeString} (${relativeTime})`;
  };

  const transformActivitiesData = (data = []) => {
    return data.reduce((acc, activity) => {
      const date = new Date(activity.timestamp).toLocaleDateString();
      if (!acc[date]) {
        acc[date] = [];
      }

      acc[date].push({
        ...activity,
        label: convertTimestamp(activity.timestamp),
      });
      return acc;
    }, {});
  };

  const getFlowActivity = async ({
    page = pagination.currentPage,
    limit = pagination.pageSize,
  }) => {
    try {
      setLoader(true);
      const response = await axiosInstance.get(
        Apis.GET_FLOW_ACTIVITY({
          flow_id: flowId,
          page,
          limit,
          pagination: true,
          sortBy: "timestamp",
          descending: true,
        }),
      );
      setActivities(
        transformActivitiesData(response.data?.payload?.data?.data ?? []),
      );
      updatePagination(
        "totalItems",
        response.data?.payload?.data?.meta?.total_found || 0,
      );
      updatePagination("currentPage", page);
      updatePagination("pageSize", limit);
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getFlowActivity({});
  }, []);

  return (
    <div>
      {loader ? (
        <Spin
          indicator={<div className="custom-loader" />}
          className={"m-auto"}
        />
      ) : (
        <div className="flow-activity-wrapper">
          <Collapse
            size="small"
            bordered={false}
            ghost
            items={Object.keys(activities).map((date) => ({
              key: date,
              label: (
                <Divider
                  orientation="left"
                  className="!border-(--input-field-border) !border-t-0 !my-0 before:hidden ps-0 !text-heading-text"
                >
                  {date}
                </Divider>
              ),
              children: activities[date].map((activity) => (
                <div
                  key={activity._id}
                  className={
                    "flex gap-2 items-center pb-2 group cursor-pointer"
                  }
                  onClick={() => setSelectedActivity(activity)}
                >
                  <LuPlay className="text-project-base/80 group-hover:text-project-base" />
                  <span className="text-project-base/80 group-hover:text-project-base">
                    {activity.label}
                  </span>
                </div>
              )),
            }))}
            defaultActiveKey={Object.keys(activities)[0]}
          />
          <Pagination
            pageSize={pagination.pageSize}
            size={"small"}
            current={pagination.currentPage}
            total={pagination.totalItems}
            onChange={(page, size) => {
              getFlowActivity({ page, limit: size });
            }}
            onShowSizeChange={(current, size) =>
              getFlowActivity({ page: current, limit: size })
            }
            disabled={loader}
            className="py-8"
          />
          <DrawerStyle
            width={870}
            open={selectedActivity._id}
            onClose={() => {
              setSelectedActivity({});
            }}
            closeIcon={<IconsAll.CloseIconCustom />}
            destroyOnClose
          >
            <div className="py-4 flow-action-drawer flow-activity-details-drawer">
              <Flex wrap="wrap" align="center" justify="space-between">
                <Title className="project-header-left-side" level={3}>
                  <TItleIconStyle>
                    <VscChecklist size={20} className="text-project-base" />
                  </TItleIconStyle>
                  {selectedActivity.label}
                </Title>
              </Flex>
              <FlowActivityDetail activity={selectedActivity} />
            </div>
          </DrawerStyle>
        </div>
      )}
    </div>
  );
};

export default FlowActivity;
