import React, { useEffect, useState } from "react";
import { Tooltip } from "antd";
import { RiDeleteBin6Line } from "react-icons/ri";
import { NotHasField } from "../../../Common/CommonUiStyles";
import CollectionCard from "./CollectionCard";
import { useSelector } from "react-redux";
import { BsInfoCircle } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  rectIntersection,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { FaGripLines } from "react-icons/fa6";
import { useSortable } from "@dnd-kit/sortable";
import {
  capitalizeAndFormat,
  getForeignCollectionDetails,
} from "../../../Helpers/utils";

const ItemSort = ({ data, highlightedItem, setRelationalData }) => {
  const { attributes, listeners, setNodeRef, isDragging } = useSortable({
    id: data?.item,
  });

  const dragStyles = isDragging
    ? "opacity-90 bg-[rgba(var(--project-base-rgb),0.1)] shadow-lg"
    : "opacity-100 bg-[var(--input-field-bg)] shadow-sm";

  const transitionStyles = isDragging
    ? "transition-none"
    : "transition-transform ease-in-out duration-250";

  return (
    <div
      className={`flex justify-between border border-[var(--input-field-border)] bg-[var(--input-field-bg)] p-3 m-2 rounded-xl items-center highlighting-drag ${dragStyles} ${transitionStyles} ${
        highlightedItem === data?.item ? "bg-project-base/5 shadow-md" : ""
      }`}
      {...attributes}
      {...listeners}
      ref={setNodeRef}
    >
      <ul className="flex gap-4 flex-1 items-center">
        <li className="cursor-pointer list-none">
          <FaGripLines color="gray" size={24} />
        </li>
        <li className="font-normal">{data?.collection}:</li>
        <li className="font-normal">{data?.item}</li>
      </ul>
      <ul className="flex gap-4 items-center">
        <li className="flex items-center">
          <button
            type="button"
            title="Delete"
            className="text-delete-base"
            onClick={() =>
              setRelationalData((prev) =>
                prev?.filter((rel) => rel?.item !== data?.item),
              )
            }
          >
            <Tooltip title="Delete Item">
              <RiDeleteBin6Line size={24} />
            </Tooltip>
          </button>
        </li>
      </ul>
    </div>
  );
};

const CustomManyToAny = ({
  item,
  updateFormData,
  formData,
  isValidationError,
}) => {
  const [relationalData, setRelationalData] = useState([]);
  const [highlightedItem, setHighlightedItem] = useState({});
  const [activeItem, setActiveItem] = useState(null);
  const schemas = useSelector((state) => state.schemas.schemas);
  const { t } = useTranslation();
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 20,
    },
  });

  const myRelations = getForeignCollectionDetails({
    collection: item?.schema_id,
    field: item?.field,
    iFace: item?.meta?.interface,
    findJunction: true,
  });

  const keyboardSensor = useSensor(KeyboardSensor);
  const sensors = useSensors(mouseSensor, keyboardSensor);

  useEffect(() => {
    setRelationalData(
      formData?.[item?.path] && formData?.[item?.path]?.length > 0
        ? formData?.[item?.path]?.sort((a, b) => a?.sort - b?.sort)
        : [],
    );
  }, []);

  useEffect(() => {
    updateFormData(
      relationalData?.map((rel, i) => {
        return {
          collection: rel?.collection,
          item: rel?.item,
          sort: i + 1,
        };
      }),
    );
  }, [relationalData]);

  const onDragEnd = (event) => {
    const { active, over } = event;
    if (active && over) {
      if (active.id !== over?.id) {
        setRelationalData((items) => {
          const oldIndex = items.findIndex((item) => item.item === active.id);
          const newIndex = items.findIndex((item) => item.item === over.id);
          return arrayMove(items, oldIndex, newIndex);
        });
      }
      setHighlightedItem({});
    }
  };

  return (
    <>
      {relationalData?.length > 0 ? (
        <DndContext
          sensors={sensors}
          collisionDetection={rectIntersection}
          onDragStart={({ active }) =>
            setActiveItem(relationalData?.find((rl) => rl?.item === active.id))
          }
          onDragEnd={onDragEnd}
          onDragOver={({ over }) => {
            setHighlightedItem(over?.id);
          }}
        >
          <SortableContext
            items={relationalData.map((item) => item.item)}
            strategy={verticalListSortingStrategy}
          >
            {relationalData?.map((rl, i) => (
              <ItemSort
                key={rl?.item}
                data={rl}
                index={i}
                highlightedItem={highlightedItem}
                setRelationalData={setRelationalData}
              />
            ))}
          </SortableContext>
          <DragOverlay>
            {activeItem ? (
              <div
                className={`flex justify-between border border-blue-300 p-3 my-2 rounded-lg items-center`}
              >
                <ul className="flex gap-4 flex-1 items-center">
                  <li className="cursor-pointer list-none">
                    <FaGripLines color="gray" size={24} />
                  </li>
                  <li className="font-normal">
                    {capitalizeAndFormat(activeItem?.collection)}:
                  </li>
                  <li className="font-normal">{activeItem?.item}</li>
                </ul>
              </div>
            ) : null}
          </DragOverlay>
        </DndContext>
      ) : (
        <NotHasField
          className={`items-center gap-3 font-normal rounded-xl ${isValidationError ? "!bg-delete-base !border-l-delete-base" : ""}`}
        >
          <BsInfoCircle size={20} />
          <p className="font-normal">{t("no_items")}</p>
        </NotHasField>
      )}

      <div className="grid grid-cols-3 gap-2 mt-5">
        {myRelations?.foreign_collection_id?.map((rel, i) => {
          return (
            <CollectionCard
              key={i}
              collection={schemas?.find((schema) => schema?._id === rel)}
              setRelationalData={setRelationalData}
              relationalData={relationalData}
            />
          );
        })}
      </div>
    </>
  );
};

export default CustomManyToAny;
