import { Col, Form, Row } from "antd";
import CodeEditorInput from "../../Flows/Components/CodeEditorInput";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";
import DOMPurify from "dompurify";

const HtmlInputWithPreview = ({ htmlContent, loader }) => {
  const { t } = useTranslation();

  const [height, setHeight] = useState(300);
  const [sanitizedHtml, setSanitizedHtml] = useState("");

  const updateHeight = debounce(() => {
    const previewDiv = document.getElementById("previewDiv");
    if (previewDiv) {
      setHeight(previewDiv.scrollHeight);
    }
  }, 300);

  const updateSanitizedHtml = debounce((html) => {
    setSanitizedHtml(DOMPurify.sanitize(html));
    updateHeight();
  }, 300);

  useEffect(() => {
    updateSanitizedHtml(htmlContent);
  }, [htmlContent]);

  return (
    <Row gutter={28} className="">
      <Col className="gutter-row" xs={24} xxl={12}>
        <Form.Item
          label={t("html")}
          name="html"
          className="font-bold"
          rules={[{ required: true }]}
        >
          <CodeEditorInput
            loading={loader}
            defaultLanguage={"html"}
            height={`${height}px`}
          />
        </Form.Item>
      </Col>
      <Col className="gutter-row" xs={24} xxl={12}>
        <span className="text-sm font-bold mb-2 inline-flex">Preview</span>
        <div
          id={"previewDiv"}
          className="text-base px-6 py-4 rounded-(--br-12) w-full min-h-[300px] max-h-[800px] overflow-auto border border-(--input-field-border)"
          dangerouslySetInnerHTML={{
            __html: sanitizedHtml,
          }}
        ></div>
      </Col>
    </Row>
  );
};

export default HtmlInputWithPreview;
