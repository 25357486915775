import React, { useState } from "react";
import { Badge, Collapse } from "antd";
import { BsInfoCircle } from "react-icons/bs";
import styled from "styled-components";
import { IoTriangleOutline } from "react-icons/io5";
import Revision from "./InfoPanel/Revision";
import Information from "./InfoPanel/Information";
import { useTranslation } from "react-i18next";
import { IoClose } from "react-icons/io5";
import { RiArrowRightSLine } from "react-icons/ri";

export const OtherAppInfo = styled((props) => <Collapse {...props} />)`
    background: var(--app-info-bar-bg);
    border: 0;
    border-radius: 0;
    flex-shrink: 0;
    min-height: 100vh;
    z-index: 50;
`;
const AppInfo = ({ config, visibleList }) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const [activeKey, setActiveKey] = useState([]);

  const items = [
    {
      key: "information",
      label: isExpanded ? (
        <div
          onClick={() =>
            setActiveKey((prev) => {
              return prev?.includes("information") ? [] : ["information"];
            })
          }
          className="flex justify-start information-tab"
        >
          <BsInfoCircle size={20} />
          {<span className="other-info-bar-title">{t("information")}</span>}
        </div>
      ) : (
        <BsInfoCircle
          size={20}
          onClick={() => {
            setIsExpanded(true),
              setActiveKey((prev) =>
                prev?.includes("information") ? [] : ["information"],
              );
          }}
          className="other-info-bar-icon"
        />
      ),
      children: <Information msg={config?.msg} />,
      showArrow: isExpanded,
    },
    {
      key: "revision",
      label: isExpanded ? (
        <div
          style={{ display: "flex", justifyContent: "start" }}
          onClick={() => {
            setActiveKey((prev) => {
              return prev?.includes("revision") ? [] : ["revision"];
            });
          }}
        >
          <IoTriangleOutline size={24} />
          {<span className="other-info-bar-title">{t("revision")}</span>}
        </div>
      ) : (
        <Badge
          // count={5}
          status="success"
          className="revision-count"
          onClick={() => {
            setIsExpanded((prev) => !prev),
              setActiveKey((prev) =>
                prev?.includes("revision") ? [] : ["revision"],
              );
          }}
        >
          <IoTriangleOutline size={24} className="other-info-bar-icon" />
        </Badge>
      ),
      children: <Revision config={config} />,
      showArrow: isExpanded,
    },
  ].filter((item) => visibleList?.includes(item.key));

  return (
    <>
      <OtherAppInfo
        items={items}
        accordion
        activeKey={activeKey}
        expandIcon={({ isActive, panelKey }) => (
          <RiArrowRightSLine
            style={{
              rotate: `${isActive ? 90 + "deg" : 0 + "deg"}`,
            }}
            onClick={() => setActiveKey(isActive ? [] : [panelKey])}
            size={24}
          />
        )}
        expandIconPosition={`end`}
        className={`other-info-bar-wrapper ${isExpanded ? "custom-collapse-active" : ""}`}
      />
      {isExpanded && (
        <button
          type="button"
          onClick={() => {
            setIsExpanded(false), setActiveKey([]);
          }}
          className="close-button"
        >
          <IoClose size={24} />
        </button>
      )}
    </>
  );
};

export default AppInfo;
