import { Col, Flex, Form, Input, Row, Select, Spin } from "antd";
import React, { useState } from "react";
import LeftSideTitlePart from "../../../Components/HeaderParts/LeftSideTitlePart";
import RightSIdeTitlePart from "../../../Components/HeaderParts/RightSIdeTitlePart";
import IconsAll from "../../../Components/IconsAll";
import { useTranslation } from "react-i18next";
import * as LucideIcons from "lucide-react";
import { IoLinkOutline } from "react-icons/io5";
import { useSelector } from "react-redux";

function AddLink({ handleAdd, closeEditLink, link, handleUpdate }) {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [loader, setLoader] = useState(false);
  const { ...filteredIcons } = LucideIcons;
  const [isChanged, setIsChanged] = useState(false);
  const sidebar = useSelector((state) => state.sidebar);
  const sidebarItems = sidebar?.items;
  const initialForm = link
    ? { name: link?.label, url: link?.path, icon: link?.icon || "Link" }
    : { name: null, url: null, icon: "Link" };

  const handleValuesChange = (_, allValues) => {
    // Check if any field has changed
    const hasChanged = Object.keys(initialForm).some(
      (key) => allValues[key] !== initialForm[key],
    );

    // Check if all required fields have values
    const allFieldsFilled = allValues.name && allValues.url && allValues.icon;

    setIsChanged(hasChanged && allFieldsFilled);
  };

  const handleForm = (name, value) => {
    const modifiedValue = value;
    // Update main form values
    form.setFieldsValue({
      [name]: modifiedValue,
    });

    // Update main form values
    form.setFields([
      {
        name,
        errors: [], // Remove any existing error for this field
      },
    ]);
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    handleForm(name, value);
  };

  const handleSubmit = async () => {
    const isError = form
      .getFieldsError()
      .some((field) => field.errors.length > 0);
    if (isError) return;
    setLoader(true);
    if (link && link?.id) {
      handleUpdate(link?.id, form.getFieldsValue());
    } else {
      handleAdd(form.getFieldsValue());
    }
    setLoader(false);
    closeEditLink();
  };
  const disallowed = sidebarItems
    ?.filter((item) => item.type === "link")
    ?.map((item) => {
      return item.label;
    });

  const validateName = (_, value) => {
    if (!value) return Promise.resolve(); // If empty, let required rule handle it
    if (disallowed.includes(value)) {
      return Promise.reject(new Error("Please Select Unique Name"));
    }
    return Promise.resolve();
  };

  return (
    <Flex className="w-full">
      <div className="px-4 xl:px-16 w-full">
        <div className="primary-header pt-9 pb-5">
          <LeftSideTitlePart
            pageTitle={t("custom_link")}
            pageSubTitle={t("custom_link")}
            pageIcon={true}
            pageInnerIcon={
              <IoLinkOutline
                className="ant-menu-item-icon"
                size={22}
                color="var(--project-base)"
              />
            }
            backButton={false}
          />
          <RightSIdeTitlePart
            showButton={true}
            handleShowClick={handleSubmit}
            buttonLoading={loader}
            showDisabled={!isChanged}
          />
        </div>
        <section className="mt-4 w-full">
          {loader ? (
            <Spin
              spinning={loader}
              fullscreen
              indicator={<div className="custom-loader" />}
            ></Spin>
          ) : (
            <Form
              layout="vertical"
              form={form}
              onFinish={handleSubmit}
              initialValues={initialForm}
              onValuesChange={handleValuesChange}
            >
              <Row gutter={[16, 24]}>
                <Col className="gutter-row" xs={24} md={12}>
                  <Form.Item
                    label={t("name")}
                    name="name"
                    className="font-bold mb-0"
                    rules={[
                      {
                        required: true,
                        message: t("enter_name"),
                      },
                      {
                        max: 50,
                      },
                      { validator: validateName },
                    ]}
                  >
                    <Input
                      type="text"
                      name="name"
                      onChange={handleChange}
                      placeholder={t("enter_name")}
                      className="project-custom-input"
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} md={12}>
                  <Form.Item
                    label={t("icon")}
                    className="font-bold"
                    htmlFor={`icon`}
                    name={`icon`}
                    rules={[
                      {
                        required: true,
                        message: "Select an Icon",
                      },
                    ]}
                  >
                    <Select
                      id={`icon`}
                      options={Object.keys(filteredIcons).map((iconName) => {
                        const IconComponent = filteredIcons[iconName];
                        return {
                          value: iconName,
                          label: (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                              }}
                            >
                              <IconComponent
                                size={16}
                                color="var(--project-base)"
                              />
                              {iconName}
                            </div>
                          ),
                        };
                      })}
                      showSearch
                      filterOption={(input, option) =>
                        option?.value
                          ?.toLowerCase()
                          ?.includes(input?.toLowerCase())
                      }
                      style={{ width: "100%" }}
                      placeholder="Select an Icon"
                      allowClear={{
                        clearIcon: (
                          <div className="right-4 relative">
                            <IconsAll.CloseIconCustom />
                          </div>
                        ),
                      }}
                      optionLabelProp="label"
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24}>
                  <Form.Item
                    label={t("url")}
                    name="url"
                    className="font-bold mb-0"
                    rules={[
                      {
                        required: true,
                        message: "URL is required field.",
                      },
                      {
                        max: 50,
                      },
                      {
                        pattern:
                          /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/,
                        message: t("enter_url"),
                      },
                    ]}
                  >
                    <Input
                      type="text"
                      name="url"
                      onChange={handleChange}
                      placeholder={t("enter_url")}
                      className="project-custom-input"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          )}
        </section>
      </div>
    </Flex>
  );
}

export default AddLink;
