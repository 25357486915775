import React, { useEffect, useId } from "react";
import { IoLanguage } from "react-icons/io5";
import { Col, Form, Input, notification, Row, Select } from "antd";
import { useTranslation } from "react-i18next";
import TextArea from "antd/es/input/TextArea";
import LeftSideTitlePart from "../HeaderParts/LeftSideTitlePart";
import RightSIdeTitlePart from "../HeaderParts/RightSIdeTitlePart";
import IconsAll from "../IconsAll";
import useLanguage from "../../Helpers/custom-hooks/useLanguage";
import { FormStyling } from "../../Common/CommonUiStyles";

const CreateChoices = ({ setChoices, setOpen, choiceType, activeChoice }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const id = useId();
  useEffect(() => {
    if (activeChoice) {
      setFormData();
    }
  }, [activeChoice]);

  const setFormData = async () => {
    await form.setFieldsValue(activeChoice);
  };
  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      setChoices((prev) => {
        if (
          prev?.length > 0 &&
          prev?.some((item) => item?.value === values?.value)
        ) {
          if (!activeChoice || activeChoice?.value !== values?.value) {
            notification.error({ message: t("duplicate_value_error") });
            return prev;
          }
        }

        if (activeChoice) {
          return prev?.map((item) => {
            return item?.label === activeChoice?.label ? values : item;
          });
        }
        return prev?.length > 0 ? [...prev, values] : [values];
      });
      setOpen(false);
      await form.resetFields();
    } catch (error) {
      console.log("Error", error);
    }
  };

  const generateChoiceForm = () => {
    switch (choiceType) {
      case "dropdown_multiple":
      case "dropdown":
        return (
          <>
            <Col className="gutter-row" xs={24} md={12}>
              <Form.Item
                label={t("text")}
                className="font-bold"
                htmlFor={`${id}-template`}
                name={`label`}
                rules={[{ required: true }]}
              >
                <Input
                  placeholder={t("enter_a_text")}
                  id={`${id}-template`}
                  className="project-custom-input"
                  suffix={<IoLanguage size={20} color="gray" />}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} md={12}>
              <Form.Item
                label={t("value")}
                className="font-bold"
                htmlFor={`${id}-template`}
                name={`value`}
                rules={[{ required: true }]}
              >
                <Input
                  placeholder={t("enter_a_value")}
                  id={`${id}-template`}
                  className="project-custom-input"
                  suffix={<IoLanguage size={20} color="gray" />}
                />
              </Form.Item>
            </Col>
            {/* <Col className="gutter-row" xs={24} md={12}>
              <Form.Item
                label={t("icon")}
                htmlFor={`${id}-icon`}
                className="font-bold"
                name={`icon`}
              >
                <Select
                  size="large"
                  placeholder="Icon 1"
                  style={{ flex: 1, height: "56px" }}
                  id={`${id}-icon`}
                  options={[
                    {
                      value: "Icon 1",
                      label: "Icon 1",
                    },
                    {
                      value: "Icon 2",
                      label: "Icon 2",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} md={12}>
              <Form.Item
                label={t("color")}
                htmlFor={`${id}-color`}
                className="font-bold"
                name={`color`}
              >
                <ColorPicker
                  id={`${id}-color`}
                  showText
                  className="w-full justify-start px-3 py-[14.08px] font-normal rounded-xl"
                  format="hex"
                  onChange={(_, hex) => form.setFieldsValue({ color: hex })}
                />
              </Form.Item>
            </Col> */}
          </>
        );
      case "checkboxes":
        return (
          <>
            <Col className="gutter-row" xs={24} md={12}>
              <Form.Item
                label={t("text")}
                className="font-bold"
                htmlFor={`${id}-template`}
                name={`label`}
                rules={[{ required: true }]}
              >
                <Input
                  placeholder={t("enter_a_text")}
                  id={`${id}-template`}
                  className="project-custom-input"
                  suffix={<IoLanguage size={20} color="gray" />}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} md={12}>
              <Form.Item
                label={t("value")}
                className="font-bold"
                htmlFor={`${id}-template`}
                name={`value`}
                rules={[{ required: true }]}
              >
                <Input
                  placeholder={t("enter_a_value")}
                  id={`${id}-template`}
                  className="project-custom-input"
                  suffix={<IoLanguage size={20} color="gray" />}
                />
              </Form.Item>
            </Col>
          </>
        );
      case "radio":
        return (
          <>
            <Col className="gutter-row" xs={24} md={12}>
              <Form.Item
                label={t("text")}
                className="font-bold"
                htmlFor={`${id}-template`}
                name={`label`}
                rules={[{ required: true }]}
              >
                <Input
                  placeholder={t("enter_a_text")}
                  id={`${id}-template`}
                  className="project-custom-input"
                  suffix={<IoLanguage size={20} color="gray" />}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} md={12}>
              <Form.Item
                label={t("value")}
                className="font-bold"
                htmlFor={`${id}-template`}
                name={`value`}
                rules={[{ required: true }]}
              >
                <Input
                  placeholder={t("enter_a_value")}
                  id={`${id}-template`}
                  className="project-custom-input"
                  suffix={<IoLanguage size={20} color="gray" />}
                />
              </Form.Item>
            </Col>
          </>
        );
      case "translation":
        return (
          <>
            <Col className="gutter-row" xs={24}>
              <Form.Item
                label={t("language")}
                className="font-bold"
                htmlFor={`${id}-language`}
                name={`value`}
                rules={[{ required: true }]}
              >
                <Select
                  id={`${id}-language`}
                  options={useLanguage()}
                  showSearch
                  filterOption={(input, option) =>
                    option?.label?.toLowerCase()?.includes(input?.toLowerCase())
                  }
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24}>
              <Form.Item
                label={t("translation")}
                className="font-bold"
                htmlFor={`${id}-translation`}
                name={`label`}
                rules={[{ required: true }]}
              >
                <TextArea
                  placeholder={t("enter_a_translation")}
                  id={`${id}-translation`}
                  className="project-custom-input"
                  rows={4}
                />
              </Form.Item>
            </Col>
          </>
        );
      default:
        return <></>;
    }
  };
  return (
    <div className="py-4">
      <div
        className={`primary-header pb-[24px] ${choiceType === "translation" ? "" : "px-5"}`}
      >
        <LeftSideTitlePart
          pageTitle={activeChoice ? t("edit_choice") : t("add_choice")}
          pageIcon
          pageInnerIcon={<IconsAll.ContentIcon />}
        />
        <RightSIdeTitlePart showButton handleShowClick={handleSubmit} />
      </div>
      <div className={choiceType === "translation" ? "" : "px-5"}>
        <FormStyling layout="vertical" className="" form={form}>
          <Row gutter={28}>{generateChoiceForm()}</Row>
        </FormStyling>
      </div>
    </div>
  );
};

export default CreateChoices;
