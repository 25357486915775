import { cloneDeep } from "lodash";
import axiosInstance from "./axios";
import Apis from "./Apis";
import { setPreset } from "../Redux/slices/schema.reducer";
import { setMediaFolders } from "../Redux/slices/mediaFolders.reducers";
import { getForeignCollectionDetails } from "./utils";

/**
 * Recursively populates relational fields up to the specified level.
 * @param {Array} relationalFields - Fields to process for relational data.
 * @param {Array} fields - Array of all available fields.
 * @param {Number} level - Depth of recursion.
 * @returns {Array} - Populated relational fields.
 */
const getPopulatedRelationalFields = (relationalFields, fields, level) => {
  if (level === 0) return [];

  const populatedFields = [];

  for (let rf of relationalFields) {
    const myRelations = getForeignCollectionDetails({
      collection: rf?.schema_id,
      field: rf?.field,
      iFace: rf?.meta?.interface,
      findJunction: rf?.meta?.interface === "list-m2m",
    });

    const interfaceType = rf?.meta?.interface;
    const relatedFields = fields.filter((f) => {
      const schemaId =
        interfaceType === "list-m2m" || interfaceType === "files"
          ? myRelations?.junction_collection_id || rf.meta.junction_schema_id
          : myRelations?.foreign_collection_id ||
            rf.schema_definition.foreign_key_table_id;
      return (
        f.schema_id === schemaId &&
        (["list-m2m", "file", "file-image", "files"].includes(interfaceType)
          ? f.field !== "_id"
          : f.meta.interface !== "none")
      );
    });

    for (let item of relatedFields) {
      populatedFields.push({
        ...item,
        path: `${rf.path}.${item.path}`,
      });
    }

    const nestedFields = getPopulatedRelationalFields(
      relatedFields
        .filter(
          (f) =>
            interfaceType === "list-m2m" ||
            interfaceType === "files" ||
            [
              "list-o2m",
              "list-m2o",
              "list-m2m",
              "file",
              "file-image",
              "files",
            ].includes(f.meta.interface),
        )
        .map((f) => ({
          ...f,
          meta: {
            ...f.meta,
            interface: interfaceType === "files" ? "file" : f.meta.interface,
          },
          path: `${rf.path}.${f.path}`,
        })),
      fields,
      level - 1,
    );
    populatedFields.push(...nestedFields);
  }

  return populatedFields;
};

/**
 * Fetches fields by schema ID with optional population of relational fields.
 * @param {Object} params - Parameters for the function.
 * @param {Array} params.fields - Array of all available fields.
 * @param {String|Number} params.schemaId - The schema ID to filter fields by.
 * @param {Boolean} params.populated - Whether to populate relational fields.
 * @param {Number} params.level - Depth of relational field population.
 * @returns {Array} - Fields matching the schema ID, optionally with populated relational fields.
 */
const getFieldsBySchemaId = ({
  schemas,
  fields,
  schemaId,
  populated,
  level = 3,
}) => {
  const schemaDetails = cloneDeep(schemas);
  const fieldsDetails = cloneDeep(fields);
  try {
    const schemaDoc = schemaDetails.find((s) => s._id === schemaId);
    if (!schemaDoc) return null;
    const fieldDoc = fieldsDetails.filter(
      (field) => field.schema_id === schemaId,
    );

    if (populated) {
      const relationalFields = fieldDoc.filter((field) =>
        [
          "list-o2m",
          "list-m2o",
          "list-m2m",
          "file",
          "file-image",
          "files",
        ].includes(field.meta.interface),
      );

      const relationalFieldsData = getPopulatedRelationalFields(
        relationalFields,
        fieldsDetails,
        level,
      );
      fieldDoc.push(...relationalFieldsData);
    }

    return { ...schemaDoc, fields: [...fieldDoc] };
  } catch (error) {
    return [];
  }
};

const getAndSetPresets = async ({ dispatch, schemaId, presets }) => {
  if (presets[schemaId]) {
    return presets[schemaId];
  } else {
    const response = await axiosInstance.get(Apis.GET_PRESET(schemaId));
    if (response.status === 200) {
      const data = response.data.payload.data
        ? response.data.payload.data[0]
        : {};
      dispatch(setPreset({ schemaId, preset: data }));
      return data;
    }
  }
};

const getAndSetMediaFolders = async ({ folders, dispatch }) => {
  if (folders?.length > 0) {
    return folders;
  } else {
    try {
      let response = await axiosInstance.get(
        Apis.GET_ALL_FOLDERS({ pagination: "false" }),
      );
      if (response.status === 200) {
        dispatch(setMediaFolders(response?.data?.payload?.data));
        return response?.data?.payload?.data;
      }
    } catch (error) {
      console.log("error", error);
    }
  }
};

export { getFieldsBySchemaId, getAndSetPresets, getAndSetMediaFolders };
