import React from "react";
import { EdgeLabelRenderer, getBezierPath, useReactFlow } from "@xyflow/react";
import { MdClose } from "react-icons/md";

export default function ButtonEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  sourceHandleId,
}) {
  const { setEdges } = useReactFlow();
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const onEdgeClick = () => {
    setEdges((edges) => {
      return edges.filter((edge) => edge.id !== id);
    });
  };

  return (
    <>
      <path
        markerEnd={markerEnd}
        style={{ ...style, fill: "transparent" }}
        id={id}
        d={edgePath}
        className={`${["resolve", "trigger"].includes(sourceHandleId) ? "resolve-edge-stroke" : "reject-edge-stroke"}`}
      />
      <EdgeLabelRenderer>
        <div
          className="absolute origin-center"
          style={{
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            pointerEvents: "all",
          }}
        >
          <button
            className={`w-8 h-8 flex justify-center items-center border-2 cursor-pointer rounded-full p-0 bg-white ${["resolve", "trigger"].includes(sourceHandleId) ? "border-project-base" : "border-delete-base/70"}`}
            onClick={onEdgeClick}
          >
            <MdClose
              size={16}
              className={`${["resolve", "trigger"].includes(sourceHandleId) ? "text-project-base" : "text-delete-base/70"}`}
            />
          </button>
        </div>
      </EdgeLabelRenderer>
    </>
  );
}
