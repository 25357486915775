import React from "react";
import Apis from "./Apis";
import axiosInstance from "./axios";
import { useTranslation } from "react-i18next";
import { store } from "../Redux/store";
import { NotHasField } from "../Common/CommonUiStyles";
import { AiOutlineMenu } from "react-icons/ai";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import defaultFavicon from "../Assets/images/logo-sm.svg";
import { notification } from "antd";
import dayjs from "dayjs";

export const objectId = (value) => {
  return value.match(/^[0-9a-fA-F]{24}$/);
};

export async function retrieveLoginPrompt() {
  try {
    let response = await axiosInstance.get(Apis.LOGIN());
    return response;
  } catch (err) {
    console.log(err);
    throw err;
  }
}

export const tabletBreakpoint = 1247;

export function capitalizeAndFormat(str) {
  return str
    ?.split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export const getTranslatedLabel = (label) => {
  const { t } = useTranslation("tenant");
  return t(label?.replace("$t:", ""));
};

export const convertToTreeData = (data, type) => {
  const root = {};
  const nodeMap = new Map();

  data.sort(
    (a, b) => a?.path?.split(".")?.length - b?.path?.split(".")?.length,
  );

  data.forEach((item) => {
    const pathParts = item.path.split(".");
    const key = pathParts.join(".");

    let node = {
      label: pathParts[pathParts.length - 1],
      display_label: pathParts[pathParts.length - 1],
      key: key,
    };

    switch (type) {
      case "tree-select":
        node = {
          label: pathParts[pathParts.length - 1],
          value: key,
          field_type: item?.field_type,
          type: item?.type,
          children: [],
          _id: item._id,
          field_details: [{ path: item.path, field_id: item._id }],
        };
        break;
      case "tree-fields":
        node = {
          ...item,
          title: pathParts[pathParts.length - 1],
          value: key,
          children: [],
          key: key,
          field_details: [{ path: item.path, field_id: item._id }],
        };
        break;
      case "tree-columns":
        node = {
          label: pathParts[pathParts.length - 1],
          display_label: pathParts[pathParts.length - 1],
          key: key,
          field_name: key,
          children: [],
          field_details: [{ path: item.path, field_id: item._id }],
          _id: item?._id,
        };
        break;

      default:
        node = {
          ...item,
          title: pathParts[pathParts.length - 1],
          value: key,
          children: [],
          field_details: [{ path: item.path, field_id: item._id }],
        };
        break;
    }

    nodeMap.set(key, node);

    if (pathParts.length === 1) {
      root[key] = node;
    } else {
      const parentPath = pathParts.slice(0, -1).join(".");
      if (nodeMap.has(parentPath)) {
        node.field_details.unshift(...nodeMap.get(parentPath).field_details);
        nodeMap.get(parentPath).children.push(node);
      }
    }
  });

  const removeEmptyChildren = (nodes) => {
    return nodes.map(({ children, ...node }) =>
      children.length
        ? { ...node, children: removeEmptyChildren(children) }
        : node,
    );
  };

  return removeEmptyChildren(Object.values(root));
};

export const convertTreeDataToOptions = (treeData, labelField, keyField) => {
  const convertNode = (node) => {
    const { [keyField]: key, [labelField]: label, children } = node;
    const convertedNode = {
      label: label,
      key: key,
    };
    if (children && children.length > 0) {
      convertedNode.children = children.map(convertNode);
    }
    return convertedNode;
  };

  return treeData.map(convertNode);
};

export const downloadFile = (url, fileName) => {
  const link = document.createElement("a");
  link.href = url;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const getDisplayImage = async (img_id, height, width) => {
  try {
    const resp2 = await axiosInstance.get(
      Apis.GET_DISPLAY_IMAGE(img_id, height, width),
      {
        responseType: "blob",
      },
    );
    if (resp2.status === 200) return URL.createObjectURL(resp2.data);
  } catch (error) {
    return null;
  }
};

export const removeNullAndUndefined = (obj) => {
  Object.entries(obj).forEach(([key, value]) => {
    if (value === undefined || value === null) {
      delete obj[key];
    } else if (Array.isArray(value) && value.length < 1) {
      delete obj[key];
    } else if (typeof value === "object") {
      removeNullAndUndefined(value);
    }
  });
  return obj;
};

export const CustomInfoText = (icon, text) => {
  return (
    <NotHasField className="items-center gap-3">
      {icon}
      {text}
    </NotHasField>
  );
};

export const TableRow = ({ children, ...props }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props["data-row-key"],
  });
  const style = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleY: 1,
      },
    ),
    transition,
    ...(isDragging
      ? {
          position: "relative",
          zIndex: 9999,
        }
      : {}),
  };
  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if (child.key === "sort") {
          return React.cloneElement(child, {
            children: (
              <AiOutlineMenu
                ref={setActivatorNodeRef}
                style={{
                  touchAction: "none",
                  cursor: "move",
                }}
                {...listeners}
              />
            ),
          });
        }
        return React.cloneElement(child, { key: child.key || index });
      })}
    </tr>
  );
};

export const compareAndFilterRevisionField = (
  current,
  previous,
  collectionFields,
) => {
  const currentLng = store.getState().user?.userProfile?.language;
  const flatten = (obj, prefix = "") =>
    Object.keys(obj || {}).reduce((res, key) => {
      const path = prefix ? `${prefix}.${key}` : key;
      if (
        obj[key] &&
        typeof obj[key] === "object" &&
        !Array.isArray(obj[key])
      ) {
        Object.assign(res, flatten(obj[key], path));
      } else {
        res[path] = Array.isArray(obj[key]) ? obj[key].join(",") : obj[key];
      }
      return res;
    }, {});

  const flattenedCurrent = flatten(current);
  const flattenedPrevious = flatten(previous);

  const changes = [];
  new Set([
    ...Object.keys(flattenedCurrent),
    ...Object.keys(flattenedPrevious),
  ]).forEach((key) => {
    const field = collectionFields?.find((f) => f?.field === key);
    const fromValue =
      flattenedPrevious[key] ||
      (typeof flattenedCurrent[key] === "boolean" ? "false" : "No Value");
    const toValue =
      flattenedCurrent[key] ||
      (typeof flattenedPrevious[key] === "boolean" ? "false" : "No Value");
    if (fromValue !== toValue) {
      changes.push({
        field_key: key,
        field_interface: field?.meta?.interface,
        field_translation:
          field?.meta?.translations?.find((t) => t?.language === currentLng)
            ?.translation || key,
        from: fromValue,
        to: toValue,
      });
    }
  });

  return changes;
};

export const updateStandardThemeVariables = (newVariables) => {
  const element = document.documentElement;
  if (element) {
    Object.keys(newVariables).forEach((variable) => {
      element.style.setProperty(variable, newVariables[variable]);
    });
  }
};

export const updateFavicon = (faviconURL) => {
  const favicon = document.getElementById("favicon");
  if (favicon) {
    favicon.href = faviconURL;
  }
};

function contrastingColor(hexColor) {
  // Convert hex to RGB
  const r = parseInt(hexColor.slice(1, 3), 16) / 255;
  const g = parseInt(hexColor.slice(3, 5), 16) / 255;
  const b = parseInt(hexColor.slice(5, 7), 16) / 255;

  // Calculate luminance
  const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

  // Return opposite color
  return luminance > 0.5 ? "#000000" : "#FFFFFF";
}

export const configureTheme = async (themeConfig = null) => {
  const tinycolor2 = require("tinycolor2");

  let resolvedConfig = themeConfig;
  try {
    if (!resolvedConfig) {
      const response = await axiosInstance.get(
        Apis.GET_CONFIGURATION({ type: "appearance" }),
      );
      if (response.status === 200) {
        resolvedConfig = response.data?.payload?.data?.[0];
      }
    }
    updateFavicon(
      resolvedConfig?.favicon
        ? await getDisplayImage(resolvedConfig?.favicon)
        : defaultFavicon,
    );
    updateStandardThemeVariables({
      "--project-base": resolvedConfig?.project_color,
      "--project-base-text": tinycolor2.mix(
        contrastingColor(resolvedConfig?.project_color),
        resolvedConfig?.project_color,
        10,
      ), //contrastingColor(resolvedConfig?.project_color),
      "--theme--primary": resolvedConfig?.project_color,
      "--input-field-bg": tinycolor2.mix(
        resolvedConfig?.project_color,
        "#ffffff",
        100,
      ),
      "--input-field-border": tinycolor2.mix(
        resolvedConfig?.project_color,
        "#ffffff",
        80,
      ),
      "--input-field-text-clr": tinycolor2.mix(
        resolvedConfig?.project_color,
        "#000000",
        30,
      ),
      "--input-disabled-field-bg": tinycolor2.mix(
        resolvedConfig?.project_color,
        "#ffffff",
        86,
      ),
      "--input-disabled-color": tinycolor2.mix(
        contrastingColor(resolvedConfig?.project_color),
        resolvedConfig?.project_color,
        60,
      ),
      "--body-bg": tinycolor2.mix(resolvedConfig?.project_color, "#ffffff", 92),
      "--table-row-strips": tinycolor2.mix(
        resolvedConfig?.project_color,
        "#ffffff",
        92,
      ),
      "--dashboard-card-bg": tinycolor2(resolvedConfig?.project_color)
        .lighten(35)
        .toString(),
      "--drawer-bg": tinycolor2(resolvedConfig?.project_color)
        .lighten(35)
        .toString(),
    });
    return resolvedConfig;
  } catch (error) {
    updateStandardThemeVariables({
      "--project-base": "#4a41cd",
      "--project-base-text": "rgb(237, 236, 250)",
      "--theme--primary": "#4a41cd",
      "--input-field-bg": "rgb(255, 255, 255)",
      "--input-field-border": "rgb(219, 217, 245)",
      "--input-field-text-clr": "rgb(52, 46, 144)",
      "--input-disabled-field-bg": "rgb(230, 228, 248)",
      "--input-disabled-color": "rgb(146, 141, 225)",
      "--body-bg": "rgb(241, 240, 251)",
      "--table-row-strips": "rgb(241, 240, 251)",
      "--dashboard-card-bg": "#d1cef2",
      "--drawer-bg": "#d1cef2",
    });
    return null;
  }
};

export const copyToClipboard = async (stringToCopy, successMsg, errorMsg) => {
  try {
    await navigator.clipboard.writeText(stringToCopy);
    notification.success({
      message: successMsg,
    });
  } catch (error) {
    notification.error({ message: errorMsg });
  }
};

export const getForeignCollectionDetails = ({
  collection,
  field,
  iFace,
  findJunction = false,
  getRelationaShipDetails = false,
}) => {
  const relationData = store.getState()?.fields?.relations;

  if (
    iFace === "list-o2m" ||
    iFace === "list-m2m" ||
    iFace === "translations" ||
    iFace === "files" ||
    iFace === "list-m2a"
  ) {
    let main_table = relationData?.find(
      (d) => d.one_collection_id === collection && d.one_field === field,
    );
    let relational = relationData?.find(
      (d) =>
        d.many_collection === main_table?.many_collection &&
        d.junction_field === main_table?.many_field,
    );
    let isJunction = main_table?.junction_field && findJunction;
    return getRelationaShipDetails
      ? {
          this_collection: main_table?.one_collection,
          this_field: "_id",
          foreign_collection:
            iFace === "list-m2m" || iFace === "translations"
              ? relational?.one_collection
              : iFace === "list-m2a"
                ? relational?.one_allowed_collections_id
                : main_table?.many_collection,
          foreign_field:
            iFace === "list-m2m" || iFace === "translations"
              ? "_id"
              : iFace === "list-m2a"
                ? "Primary Key"
                : main_table?.many_field,
          ...(isJunction && {
            junction_collection: relational?.many_collection,
            junction_field_this: relational?.junction_field,
            junction_field_foreign:
              iFace === "list-m2a" ? "item" : relational?.many_field,
          }),
          ...(iFace === "list-m2a" && {
            junction_field_ref: "collection",
            foreign_collection_ref:
              relational?.one_allowed_collections?.join(", "),
          }),
        }
      : {
          foreign_collection_id: isJunction
            ? iFace === "list-m2a"
              ? relational?.one_allowed_collections_id
              : relational?.one_collection_id
            : main_table?.many_collection_id,
          ...(isJunction && {
            junction_collection_id: relational?.many_collection_id,
            junction_field: main_table?.junction_field,
            junction_field_local: main_table?.many_field,
          }),
        };
  } else if (
    iFace === "list-m2o" ||
    iFace === "file" ||
    iFace === "file-image"
  ) {
    const main_table = relationData?.find(
      (d) => d.many_collection_id === collection && d.many_field === field,
    );

    return getRelationaShipDetails
      ? {
          this_collection: main_table?.many_collection,
          this_field: main_table?.many_field,
          foreign_collection: main_table?.one_collection,
          foreign_field: "_id",
        }
      : {
          foreign_collection_id: main_table?.one_collection_id,
        };
  }

  return {};
};

export function generateTree(
  data,
  idKey = "_id",
  parentKey = "parent",
  alwaysIncludeChildren = false,
  sortComparator = null,
) {
  const map = new Map();
  const roots = [];
  const nullParentItems = [];

  for (const item of data) {
    const id = item[idKey];
    map.set(
      id,
      alwaysIncludeChildren ? { ...item, children: [] } : { ...item },
    );
  }

  for (const item of data) {
    const node = map.get(item[idKey]);
    const parentId = item[parentKey];

    if (parentId) {
      const parent = map.get(parentId);
      parent
        ? (parent.children || (parent.children = [])).push(node)
        : nullParentItems.push(node);
    } else {
      roots.push(node);
    }
  }

  if (nullParentItems.length) roots.push(...nullParentItems);

  if (sortComparator) {
    const comparator =
      typeof sortComparator === "function"
        ? sortComparator
        : (a, b) =>
            (a[sortComparator] ?? "").localeCompare(b[sortComparator] ?? "");

    const stack = [];
    roots.sort(comparator);

    for (const root of roots) stack.push(root);
    while (stack.length) {
      const node = stack.pop();
      if (node.children) {
        node.children.sort(comparator);
        for (const child of node.children) stack.push(child);
      }
    }
  }

  return roots;
}

export const generateDisplayLabel = (template, data) => {
  return template.replace(/{{(.*?)}}/g, (_, key) => {
    return data?.[key] !== undefined ? data[key] : "";
  });
};

export const formatDate = (value, fieldType) => {
  if (!value) return null;

  const date = dayjs(value);
  if (fieldType === "timestamp" || fieldType === "datetime") {
    return date.millisecond(0).toISOString();
  } else if (fieldType === "date") {
    return date.format("YYYY-MM-DD");
  } else if (fieldType === "time") {
    return date.format("HH:mm:ss");
  }
  return value;
};

export const convertToJson = (value) => {
  let result = { jsonData: null, isValidJson: false };

  if (!value) return result;

  if (typeof value === "object" && value !== null) {
    result.jsonData = value;
    result.isValidJson = true;
    return result;
  }

  try {
    const parsed = JSON.parse(value);
    result.jsonData = parsed;
    result.isValidJson = true;
  } catch (error) {
    result.jsonData = null;
    result.isValidJson = false;
  }

  return result;
};

export const flattenObject = (obj, prefix = "") => {
  let result = {};

  for (let key in obj) {
    if (!Object.prototype.hasOwnProperty.call(obj, key)) continue;

    let newKey = prefix ? `${prefix}.${key}` : key;

    if (Array.isArray(obj[key])) {
      result[newKey] = obj[key].map((item) =>
        typeof item === "object" && item !== null ? flattenObject(item) : item,
      );
    } else if (typeof obj[key] === "object" && obj[key] !== null) {
      Object.assign(result, flattenObject(obj[key], newKey));
    } else {
      result[newKey] = obj[key];
    }
  }

  return result;
};
