import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  latencyData: {
    status: "Good",
  },
};

const user = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      return { ...state, userId: action.payload.userId };
    },
    setUserProfile: (state, action) => {
      return { ...state, userProfile: action.payload };
    },
    setApiLatency: (state, action) => {
      const { latency } = action.payload;

      const getSignalStrength = (latency) => {
        if (latency === null) return "NoSignal";
        if (latency < 300) return "Excellent";
        if (latency < 500) return "Good";
        if (latency < 800) return "OK";
        if (latency < 1000) return "Moderate";
        if (latency < 1300) return "Poor";
        return "VeryPoor";
      };

      state.latencyData = {
        latency,
        status: getSignalStrength(latency),
      };
    },
  },
});

export const { setUser, setUserProfile, setApiLatency } = user.actions;
export default user.reducer;
