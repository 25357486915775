import { Input, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { FiPlus } from "react-icons/fi";
import ContentTable from "../../../Pages/Content/ContentTable";
import { RiDeleteBin6Line } from "react-icons/ri";
import {
  generateDisplayLabel,
  getForeignCollectionDetails,
  getTranslatedLabel,
} from "../../../Helpers/utils";
import { DrawerStyle } from "../../../Common/CommonUiStyles";

const CustomRelationalField = ({
  item,
  updateFormData,
  formData,
  isValidationError,
  language,
}) => {
  const [showDrawer, setShowDrawer] = useState(false);
  const [relationalData, setRelationalData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const placeholder = getTranslatedLabel(item?.meta?.options?.placeholder);

  const myRelations = getForeignCollectionDetails({
    collection: item?.schema_id,
    field: item?.field,
    iFace: item?.meta?.interface,
    findJunction: item?.meta?.interface === "list-m2m",
  });

  useEffect(() => {
    setRelationalData(formData?.[item?.path] ? formData?.[item?.path] : []);
  }, [language?.selectedLanguage]);

  useEffect(() => {
    updateFormData(
      item?.meta?.interface === "list-m2o"
        ? relationalData?.length
          ? relationalData?.[0]?._id
          : null
        : relationalData?.map((d) => d?._id),
    );
  }, [relationalData]);

  return (
    <>
      {(item?.meta?.interface !== "list-m2o" || !relationalData?.length) && (
        <Input
          suffix={
            <Tooltip title={!item?.meta?.readonly ? "Add Relation" : ""}>
              <FiPlus
                size={24}
                onClick={() => {
                  if (!item?.meta?.readonly) setShowDrawer(true);
                }}
                className={!item?.meta?.readonly ? "cursor-pointer" : ""}
              />
            </Tooltip>
          }
          defaultValue={item?.schema_definition?.default}
          placeholder={placeholder}
          id={item?._id}
          disabled={!!item?.meta?.readonly}
          // value={formData?.[item.path]}
          onChange={(e) => updateFormData(e.target.value)}
          className={`project-custom-input custom-relational-field-input ${isValidationError ? "border-[red]" : ""}`}
        />
      )}

      {Array.isArray(relationalData) &&
        relationalData?.length > 0 &&
        relationalData?.map((d, i) => {
          return (
            <div
              key={i}
              className="min-h-[56px] flex justify-between border border-[#E1E3E6] p-3 my-3 rounded-xl items-center"
            >
              <ul className="flex gap-4 flex-1 items-center">
                <li className="font-normal">
                  {item?.meta?.display_options?.template
                    ? generateDisplayLabel(
                        item?.meta?.display_options?.template,
                        d,
                      )
                    : d?._id}
                </li>
              </ul>
              <ul className="flex gap-4 items-center">
                <li className="flex items-center">
                  <button
                    type="button"
                    title="Delete"
                    className="text-delete-base"
                    onClick={() =>
                      setRelationalData((prev) => prev?.filter((e) => e !== d))
                    }
                  >
                    <Tooltip title="Delete Item">
                      <RiDeleteBin6Line size={24} />
                    </Tooltip>
                  </button>
                </li>
              </ul>
            </div>
          );
        })}

      <DrawerStyle
        width={870}
        open={showDrawer}
        className="table-in-drawer"
        onClose={() => {
          setShowDrawer(false), setSelectedRows([]);
        }}
        destroyOnClose
      >
        <ContentTable
          tableType={`relations`}
          tableId={
            myRelations?.foreign_collection_id ||
            item?.schema_definition?.foreign_key_table_id
          }
          setShowDrawer={setShowDrawer}
          setRelationalData={setRelationalData}
          relationalData={relationalData || []}
          item={item}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      </DrawerStyle>
    </>
  );
};

export default CustomRelationalField;
