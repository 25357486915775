import { Button, Flex, Form, Input, Row, Col, notification } from "antd";
import React, { useState } from "react";
import LeftSideTitlePart from "../../../Components/HeaderParts/LeftSideTitlePart";
import RightSIdeTitlePart from "../../../Components/HeaderParts/RightSIdeTitlePart";
import { useTranslation } from "react-i18next";
import withRouter from "../../../Common/withRouter";
import { AiOutlinePlus } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import axiosInstance from "../../../Helpers/axios";
import Apis from "../../../Helpers/Apis";

const Add = ({ router }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loader, setLoader] = useState(false);

  const handleSubmit = async () => {
    try {
      const formData = await form.validateFields();
      setLoader(true);
      const object = formData?.items?.reduce((acc, item) => {
        acc[item.key] = item.value;
        return acc;
      }, {});

      const response = await axiosInstance.post(
        Apis.ADD_CONFIGURATION(),
        object,
      );
      router.navigate("/settings/configuration");
      notification.success({ message: response.data.message });
    } catch (error) {
      notification.success({ message: error.response.data.message });
    } finally {
      setLoader(false);
    }
  };

  return (
    <Flex className="w-full main-content-wrap">
      <div className="px-4 xl:px-16 w-full left-part">
        <div className="primary-header pt-9 pb-5">
          <div
            onClick={() => {
              router.navigate(`/settings/configuration`);
            }}
            className="grow"
          >
            <LeftSideTitlePart
              pageTitle={`${t("creating_item_in")} ${t("configuration")}`}
              pageSubTitle={t("configuration")}
              backButton={true}
            />
          </div>
          <RightSIdeTitlePart
            showButton={true}
            handleShowClick={handleSubmit}
            showDisabled={loader}
            buttonLoading={loader}
          />
        </div>

        <section className="container max-w-4xl">
          {!loader && (
            <Form form={form}>
              <Form.List
                name="items"
                initialValue={[{ key: "type", value: "" }]}
              >
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field) => {
                      return (
                        <Row
                          key={field.key}
                          gutter={16}
                          className="justify-start mb-4"
                        >
                          <Col span={11}>
                            <Form.Item
                              {...field}
                              name={[field.name, "key"]}
                              key={`${field.key}_key`}
                              rules={[
                                {
                                  required: true,
                                  message: t("please_enter_the_key"),
                                },
                              ]}
                            >
                              <Input
                                placeholder={t("key")}
                                className={`project-custom-input`}
                                disabled={field.key === 0}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={11}>
                            <Form.Item
                              {...field}
                              name={[field.name, "value"]}
                              key={`${field.key}_value`}
                              rules={[
                                {
                                  required: true,
                                  message: t("please_enter_the_value"),
                                },
                              ]}
                            >
                              <Input
                                placeholder={t("value")}
                                className={`project-custom-input`}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={2}>
                            {field.key !== 0 && (
                              <RiDeleteBin6Line
                                onClick={() => remove(field.name)}
                                size={18}
                                className={`btn text-delete-base cursor-pointer min-h-14`}
                              />
                            )}
                          </Col>
                        </Row>
                      );
                    })}
                    <Row>
                      <Col span={22}>
                        <Form.Item>
                          <Button
                            className="project-custom-btn w-full transition-all duration-200"
                            onClick={() => add()}
                            block
                            icon={<AiOutlinePlus />}
                          >
                            {t("add_item")}
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                )}
              </Form.List>
            </Form>
          )}
        </section>
      </div>
    </Flex>
  );
};

export default withRouter(Add);
