import { DatePicker } from "antd";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import utc from "dayjs/plugin/utc";
import { FaRegClock } from "react-icons/fa6";
import { formatDate, getTranslatedLabel } from "../../../Helpers/utils";
dayjs.extend(utc);

const CustomDatetime = ({
  item,
  updateFormData,
  formData,
  isValidationError,
}) => {
  useEffect(() => {
    if ( formData?.[item.path] === undefined) {
      updateFormData(
        item?.schema_definition?.default
          ? item?.type === "time"
            ? item?.schema_definition?.default
            : dayjs(item?.schema_definition?.default).toISOString()
          : item.type === "timestamp"
            ? dayjs().millisecond(0).toISOString()
            : null,
      );
    }
  }, [formData?.[item.path]]);

  const onOk = (value) => {
    updateFormData(value ? formatDate(value, item?.type) : null);
  };

  return (
    <DatePicker
      size={"large"}
      style={{ height: "56px" }}
      placeholder={getTranslatedLabel(item?.meta?.options?.placeholder)}
      showTime={!(item?.type === "date")}
      picker={item?.type === "time" ? "time" : "date"}
      onOk={onOk}
      defaultValue={
        item?.schema_definition?.default
          ? dayjs(item?.schema_definition?.default)
          : null
      }
      onChange={onOk}
      value={
        formData?.[item.path]
          ? item.type === "time"
            ? dayjs()
                .set("hour", parseInt(formData[item.path].split(":")?.[0], 10))
                .set(
                  "minute",
                  parseInt(formData[item.path].split(":")?.[1], 10),
                )
                .set(
                  "second",
                  parseInt(formData[item.path].split(":")?.[2], 10),
                )
            : dayjs(formData[item.path])
          : null
      }
      className={`font-normal ${isValidationError ? "border-[red]" : ""}`}
      disabled={!!item?.meta?.readonly}
      {...(item?.type === "time" && {
        suffixIcon: <FaRegClock className="text-black-base" size={16} />,
      })}
    />
  );
};

export default CustomDatetime;
