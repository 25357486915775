import { Flex } from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import Title from "antd/es/typography/Title";
import React from "react";
import { GoInbox } from "react-icons/go";

const NoData = ({
  title,
  description,
  icon = <GoInbox size={40} color="#00000040" />,
}) => {
  return (
    <Flex
      className="items-center justify-center"
      style={{ minHeight: "calc(100% - 250px)" }}
    >
      <Flex className="text-center" vertical>
        <div className="w-10 h-10 rounded-full mx-auto flex mb-1 items-center justify-center">
          {icon}
        </div>
        <Title className="!text-heading-text opacity-60 !text-xl">
          {title}
        </Title>
        <Paragraph className="!text-heading-text opacity-60 !text-sm">
          {description}
        </Paragraph>
      </Flex>
    </Flex>
  );
};

export default NoData;
