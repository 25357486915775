import { Input, Modal, notification } from "antd";
import React, { useState } from "react";
import axiosInstance from "../../Helpers/axios";
import Apis from "../../Helpers/Apis";
import { setFolderActionData } from "../../Redux/slices/media.reducer";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const FolderActionsModal = ({
  folderModal,
  setFolderModal,
  isCustomFolderNameInput = false,
  newFolderName,
  setNewFolderName,
  afterCreateAPI = () => {},
  afterRenameAPI = () => {},
  afterDeleteAPI = () => {},
  afterHandleFoldarModalOk = () => {},
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { selectedFolderData, folderActionData } = useSelector(
    (state) => state.media,
  );
  const [folderNameInput, setFolderModalInput] = useState("");

  const getFolderName = () => {
    return isCustomFolderNameInput ? newFolderName : folderNameInput;
  };

  const setFolderName = (value) => {
    return isCustomFolderNameInput
      ? setNewFolderName(value)
      : setFolderModalInput(value);
  };

  const handleFoldarModalOk = async (modalType) => {
    try {
      let folderName = "";
      let apiCall;
      setFolderModal({ type: modalType, isOpen: true, loading: true });

      switch (modalType) {
        case "Create":
          folderName = getFolderName().trim();
          if (!folderName.length) return;
          apiCall = axiosInstance
            .post(Apis.CREATE_FOLDER(), {
              name: folderName,
              parent: selectedFolderData?._id || null,
            })
            .then((resp) => {
              afterCreateAPI(resp);
              return resp;
            });
          break;

        case "Rename":
          folderName = getFolderName().trim();
          if (!folderName.length || folderName === folderActionData?.name)
            return;
          apiCall = axiosInstance
            .put(Apis.UPDATE_FOLDER(folderActionData._id), { name: folderName })
            .then((resp) => {
              afterRenameAPI(folderName);
              return resp;
            });
          break;

        case "Delete":
          apiCall = axiosInstance
            .delete(Apis.DELETE_FOLDER(folderActionData._id))
            .then((resp) => {
              afterDeleteAPI(resp);
              return resp;
            })
            .catch((error) => {
              notification.error({
                message: error?.data?.message || t("something_went_wrong"),
              });
              return Promise.reject(error);
            });
          break;
        default:
          throw new Error(`Unknown action type: ${modalType}`);
      }
      const response = await apiCall;
      if (response.status === 200) {
        notification.success({ message: t("folder_success", { modalType }) });
      }
    } catch (error) {
      console.error(`Error during folder ${modalType}:`, error);
    } finally {
      folderActionData?._id && dispatch(setFolderActionData({}));
      setFolderName("");
      setFolderModal({ type: modalType, isOpen: false, loading: false });
      afterHandleFoldarModalOk();
    }
  };

  return (
    <Modal
      title={folderModal.type + " " + t("folder")}
      open={folderModal.isOpen}
      okText={t("ok")}
      cancelText={t("cancel")}
      okButtonProps={{ className: "modal-primary-btn" }}
      cancelButtonProps={{ className: "modal-default-btn" }}
      onOk={() => handleFoldarModalOk(folderModal.type)}
      onCancel={() => {
        setFolderModal({
          type: folderModal.type,
          isOpen: false,
          loading: false,
        });
        folderActionData?._id && dispatch(setFolderActionData({}));
      }}
      loading={folderModal.loading}
      destroyOnClose
    >
      <p className="mb-3">
        {folderModal.type === "Delete" ? (
          <div>
            {t("delete_folder_confirm", {
              selectedFolder: folderActionData?.name,
            })}
          </div>
        ) : (
          <Input
            placeholder={t("name")}
            className="project-custom-input"
            value={getFolderName()}
            onChange={(e) => setFolderName(e.target.value)}
          />
        )}
      </p>
    </Modal>
  );
};

export default FolderActionsModal;
