import React, { useEffect, useId, useState } from "react";
import { Form, Row, Col, Flex, Button, Spin, TreeSelect, Input } from "antd";
import { useTranslation } from "react-i18next";
import { BsInfoCircle } from "react-icons/bs";
import LeftSideTitlePart from "../../HeaderParts/LeftSideTitlePart";
import IconsAll from "../../IconsAll";
import RightSIdeTitlePart from "../../HeaderParts/RightSIdeTitlePart";
import { DndContext } from "@dnd-kit/core";
import { TableSort } from "../../../Pages/Instellingen/ChangeDataModalCollection";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { AiOutlineClose } from "react-icons/ai";

import {
  DrawerStyle,
  FormStyling,
  InvertedCheckBox,
} from "../../../Common/CommonUiStyles";
import CreateChoices from "../CreateChoices";
import {
  capitalizeAndFormat,
  convertToTreeData,
  CustomInfoText,
  TableRow,
} from "../../../Helpers/utils";
import { newFieldsList } from "../NewFieldsList";
import InputWithTranslations from "../InputWithTranslations";
import AddVertalingen from "../../../Pages/Instellingen/AddVertalingen";

const UpdateField = ({
  activeItem,
  dataSource,
  updateField,
  loader,
  translationsList,
  getTranslations,
}) => {
  const { t } = useTranslation();
  const id = useId();
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [translationChoices, setTranslationChoices] = useState([]);
  const [activeChoice, setActiveChoice] = useState(null);
  const [selectedTranslation, setSelectedTranslation] = useState(null);
  const [isTranslationDrawerOpen, setIsTranslationDrawerOpen] = useState(false);

  const removeItemsByPath = (tree, inputPath) => {
    if (activeItem?.meta?.interface === "object") {
      return tree
        ?.filter((item) => !item?.value?.includes(inputPath))
        ?.map((item) => ({
          ...item,
          children: item?.children
            ? removeItemsByPath(item?.children, inputPath)
            : [],
        }));
    }
    return tree;
  };

  const groupOptions = removeItemsByPath(
    convertToTreeData(
      dataSource?.fields?.filter((f) => f?.meta?.interface === "object"),
      "tree-select",
    ),
    activeItem?.path,
  );

  useEffect(() => {
    setFormData();
    setTranslationChoices([]);
  }, [activeItem?._id]);

  const setFormData = async () => {
    const note = activeItem?.meta?.note;
    const formattedNote = note?.startsWith("$t:")
      ? note.replace("$t:", "")
      : note;
    if (note?.startsWith("$t:")) {
      setSelectedTranslation(formattedNote);
    }
    await form.setFieldsValue({
      required: !!activeItem?.meta?.required,
      readonly: !!activeItem?.meta?.readonly,
      hidden: !!activeItem?.meta?.hidden,
      note: formattedNote,
      group: activeItem?.path?.split(".")?.slice(0, -1)?.join("."),
      default_language: activeItem?.meta?.options?.default_language,
    });
    if (
      activeItem?.meta?.translations &&
      activeItem?.meta?.translations?.length
    )
      setTranslationChoices(
        activeItem?.meta?.translations?.map((t) => {
          return {
            label: t?.translation,
            value: t?.language,
          };
        }),
      );
  };

  const onDragEnd = ({ active, over }) => {
    if (active?.id !== over?.id) {
      setDataSource((previous) => {
        const activeIndex = previous.findIndex((i) => i?.key === active?.id);
        const overIndex = previous?.findIndex((i) => i?.key === over?.id);
        return arrayMove(previous, activeIndex, overIndex);
      });
    }
  };

  const handleSubmit = async () => {
    const values = await form.validateFields();
    let updatedPath = await form.getFieldValue("group");
    let currentPath = activeItem?.path?.split(".")?.slice(0, -1)?.join(".");

    const finalValues = {
      ...activeItem,
      ...(updatedPath !== currentPath && {
        path: updatedPath
          ? updatedPath + "." + activeItem?.field
          : activeItem?.field,
      }),
      ...(updatedPath !== currentPath && {
        field_type: updatedPath
          ? "Object"
          : Object.values(newFieldsList)
              .flat()
              .find((field) => field.id === activeItem?.meta?.interface)
              .nativeFieldType,
      }),
      meta: {
        ...activeItem?.meta,
        required: values.required,
        nullable: !values.required,
        readonly: values.readonly,
        hidden: values.hidden,
        note: selectedTranslation ? "$t:" + selectedTranslation : values?.note,
        options: {
          ...activeItem?.meta?.options,
          ...(values?.default_language !== undefined && {
            default_language: values?.default_language,
          }),
        },
        translations: translationChoices?.map((t) => {
          return { translation: t?.label, language: t?.value };
        }),
      },
    };
    const { _id, ...payload } = finalValues;
    delete payload?.__v;

    updateField(_id, payload);
  };

  const columns = [
    {
      key: "sort",
      width: 50,
    },
    {
      dataIndex: "label",
      align: "left",
      width: "auto",
      render: (record, rowData, index) => {
        return (
          <div
            onClick={() => {
              setActiveChoice({ ...rowData, index });
              setOpen(true);
            }}
          >
            {record}
          </div>
        );
      },
    },
    {
      dataIndex: "value",
      align: "left",
      width: "auto",
      render: (record, rowData, index) => {
        return (
          <div
            onClick={() => {
              setActiveChoice({ ...rowData, index });
              setOpen(true);
            }}
          >
            {record}
          </div>
        );
      },
    },
    {
      dataIndex: "value",
      key: "delete",
      width: 50,
      render: (record) => {
        return (
          <div
            onClick={() =>
              setTranslationChoices((prev) => {
                return prev?.filter((t) => t?.value !== record);
              })
            }
          >
            <AiOutlineClose size={24} />
          </div>
        );
      },
    },
  ];

  return (
    <Flex wrap="wrap" align="center" justify="space-between">
      <LeftSideTitlePart
        pageTitle={
          capitalizeAndFormat(activeItem?.meta?.interface) +
          " " +
          capitalizeAndFormat(activeItem?.type) +
          " (" +
          capitalizeAndFormat(dataSource?.collection_name) +
          ")"
        }
        pageIcon={true}
        pageInnerIcon={<IconsAll.ContentIcon />}
      />
      <RightSIdeTitlePart
        showButton={true}
        handleShowClick={handleSubmit}
        showDisabled={loader}
      />
      {loader ? (
        <Spin
          spinning={loader}
          fullscreen
          indicator={<div className="custom-loader" />}
        />
      ) : (
        <FormStyling
          form={form}
          layout="vertical"
          initialValues={{
            type: "String",
            icon_left: "Icon 1",
            icon_right: "Icon 1",
          }}
          className="mt-4"
        >
          <Row gutter={28}>
            {!["object", "items"].includes(activeItem?.meta?.interface) && (
              <>
                <Col className="gutter-row" xs={24} md={12}>
                  <Form.Item
                    label={t("readonly")}
                    htmlFor={`${id}-Readonly`}
                    className="font-bold"
                    name={`readonly`}
                    valuePropName="checked"
                  >
                    <InvertedCheckBox id={`${id}-Readonly`}>
                      {t("disable_editing_value")}
                    </InvertedCheckBox>
                  </Form.Item>
                </Col>
                {!["translations"].includes(activeItem?.meta?.interface) && (
                  <Col className="gutter-row" xs={24} md={12}>
                    <Form.Item
                      label={t("required")}
                      htmlFor={`${id}-required`}
                      className="font-bold"
                      name={`required`}
                      valuePropName={"checked"}
                    >
                      <InvertedCheckBox id={`${id}-required`}>
                        {t("require_value_to_be_set_on_creation")}
                      </InvertedCheckBox>
                    </Form.Item>
                  </Col>
                )}
                {["translations"].includes(activeItem?.meta?.interface) && (
                  <Col className="gutter-row" xs={24} md={12}>
                    <Form.Item
                      name={`default_language`}
                      className="font-bold"
                      label={t("default_language")}
                      htmlFor={`${id}-default_language`}
                    >
                      <Input
                        placeholder={t("enter_default_language")}
                        id={`${id}-default_language`}
                        className="project-custom-input"
                      />
                    </Form.Item>
                  </Col>
                )}
                <Col className="gutter-row" xs={24} md={12}>
                  <Form.Item
                    label={t("hidden")}
                    htmlFor={`${id}-hidden`}
                    className="font-bold"
                    name={`hidden`}
                    valuePropName={"checked"}
                  >
                    <InvertedCheckBox id={`${id}-hidden`}>
                      {t("hidden_on_detail")}
                    </InvertedCheckBox>
                  </Form.Item>
                </Col>
              </>
            )}

            <Col className="gutter-row" xs={24} md={12}>
              <Form.Item
                name={"group"}
                label={t("group")}
                className="font-bold"
                htmlFor={`${id}-group`}
              >
                <TreeSelect
                  id={`${id}-group`}
                  disabled={
                    groupOptions?.length < 1 ||
                    dataSource?.fields
                      ?.filter((f) =>
                        activeItem?.path
                          ?.split(".")
                          ?.slice(0, -1)
                          ?.includes(f?.field),
                      )
                      ?.some((f) => f?.meta?.interface === "items")
                  }
                  showSearch
                  dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                  placeholder={t("group")}
                  allowClear
                  treeDefaultExpandAll
                  treeTitleRender={(node) => (
                    <span>
                      {node.value}
                      <span className="text-xs text-(--input-field-text-clr) ml-1">
                        ({node.type === "Array" ? "items" : "group"})
                      </span>
                    </span>
                  )}
                  treeData={groupOptions}
                />
              </Form.Item>
            </Col>

            <Col className="gutter-row" xs={24} md={24}>
              <Form.Item
                label={t("note")}
                className="font-bold"
                htmlFor={`${id}-template`}
                name={`note`}
              >
                <InputWithTranslations
                  form={form}
                  selectedTranslation={selectedTranslation}
                  setIsTranslationDrawerOpen={setIsTranslationDrawerOpen}
                  setSelectedTranslation={setSelectedTranslation}
                  translationsList={translationsList}
                  inputFormName={`note`}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} md={24}>
              <Form.Item
                label={t("field_name_translations")}
                htmlFor={`${id}-new-field`}
                className="font-bold"
              >
                {!translationChoices?.length &&
                  CustomInfoText(
                    <BsInfoCircle size={20} />,
                    <p className="font-normal">{t("no_items")}</p>,
                  )}
                <Button
                  className="project-custom-btn border-solid border-project-base-text bg-project-base text-project-base-text mt-4"
                  onClick={() => setOpen(true)}
                >
                  {t("create_new")}
                </Button>
              </Form.Item>
              {translationChoices?.length > 0 && (
                <DndContext
                  modifiers={[restrictToVerticalAxis]}
                  onDragEnd={onDragEnd}
                >
                  <SortableContext
                    items={translationChoices?.map((t) => t?.label)}
                    strategy={verticalListSortingStrategy}
                  >
                    <TableSort
                      style={{ width: "100%" }}
                      pagination={false}
                      components={{
                        body: {
                          row: TableRow,
                        },
                      }}
                      rowKey={(record) =>
                        record?.label + record?.value + new Date().toISOString()
                      }
                      columns={columns}
                      dataSource={translationChoices}
                      scroll={{
                        x: 300,
                      }}
                    />
                  </SortableContext>
                </DndContext>
              )}
            </Col>
          </Row>
          <DrawerStyle
            width={870}
            destroyOnClose
            open={open}
            onClose={() => {
              setOpen(false);
              setActiveChoice(null);
            }}
          >
            <CreateChoices
              setChoices={setTranslationChoices}
              setOpen={setOpen}
              choiceType={"translation"}
              activeChoice={activeChoice}
            />
          </DrawerStyle>
          <DrawerStyle
            width={870}
            open={isTranslationDrawerOpen}
            closeIcon={<IconsAll.CloseIconCustom />}
            onClose={() => setIsTranslationDrawerOpen(false)}
            destroyOnClose
          >
            <AddVertalingen
              setIsTranslationDrawerOpen={setIsTranslationDrawerOpen}
              type={"drawer"}
              getTranslations={getTranslations}
            />
          </DrawerStyle>
        </FormStyling>
      )}
    </Flex>
  );
};

export default UpdateField;
