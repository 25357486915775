import React, { useCallback, useEffect, useId, useState } from "react";
import ReactDOMServer from "react-dom/server";
import {
  Flex,
  Table,
  Button,
  Drawer,
  notification,
  Modal,
  Col,
  Dropdown,
  Skeleton,
  Row,
  Form,
  Select,
  Input,
  ColorPicker,
  TreeSelect,
} from "antd";
import LeftSideTitlePart from "../../Components/HeaderParts/LeftSideTitlePart";
import RightSIdeTitlePart from "../../Components/HeaderParts/RightSIdeTitlePart";
import IconsAll from "../../Components/IconsAll";
import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  rectIntersection,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import styled from "styled-components";
import AppInfo from "../../Components/AppInfo";
import {
  capitalizeAndFormat,
  convertToTreeData,
  getForeignCollectionDetails,
} from "../../Helpers/utils";
import { useTranslation } from "react-i18next";
import { themes } from "../../config";
import { useSelector, useDispatch } from "react-redux";
import { setActiveTab } from "../../Redux/slices/activeTab.reducer";
import { useNavigate, useParams } from "react-router-dom";
import Apis from "../../Helpers/Apis";
import axiosInstance from "../../Helpers/axios";
import CreateNewFields from "../../Components/Create-new-fields/CreateNewFields";
import EditFields from "../../Components/Create-new-fields/EditFields";
import Paragraph from "antd/es/typography/Paragraph";
import { PiSquareHalfLight } from "react-icons/pi";
import { MdFullscreen, MdOutlineEdit } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { getFieldsBySchemaId } from "../../Helpers/functions";
import {
  deleteFieldById,
  matchAndReplaceField,
  updateFieldById,
} from "../../Redux/slices/fields.reducer";
import { hideLoader } from "../../Redux/slices/loader.reducer";
import { FormStyling, InvertedCheckBox } from "../../Common/CommonUiStyles";
import * as LucideIcons from "lucide-react";
import {
  deleteSchemaById,
  updateSchemaById,
} from "../../Redux/slices/schema.reducer";
import FormTitle from "../../Layouts/FormTitle";
import { LuChevronsDownUp, LuChevronsUpDown } from "react-icons/lu";
import { GoPlus } from "react-icons/go";

const DrawerStyleNewField = styled(Drawer)`
  .ant-drawer-header {
    padding: 0;
    border: unset !important;
    .ant-drawer-header-title {
      .ant-drawer-close {
        width: 56px;
        height: 56px;
        position: absolute;
        left: -65px;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 30px;
        background: var(--color-white);
        border-radius: 100vh;
        box-shadow: 0px 4px 12px 0px rgba(8, 24, 44, 0.16);
        @media screen and (max-width: 991px) {
          left: calc(50% - 28px);
          bottom: 1rem;
          top: auto;
        }
      }
    }
  }
  .ant-drawer-body {
    .ant-tabs-nav-wrap {
      padding-top: 1rem;
    }
    .ant-collapse {
      display: grid;
      grid-template-columns: repete(4, 1fr);
      position: relative;

      .ant-collapse-item {
        .ant-collapse-content {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: auto;
          transform: translateY(100%);
        }
      }
    }
  }
`;

const DrawerStyle = styled(Drawer)`
  .ant-drawer-header {
    padding: 0;
    border: unset !important;
    .ant-drawer-header-title {
      .ant-drawer-close {
        width: 56px;
        height: 56px;
        position: absolute;
        left: -65px;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 30px;
        background: var(--color-white);
        border-radius: 100vh;
        box-shadow: 0px 4px 12px 0px rgba(8, 24, 44, 0.16);
        @media screen and (max-width: 991px) {
          left: calc(50% - 28px);
          bottom: 1rem;
          top: auto;
        }
      }
    }
  }
  .ant-drawer-body {
    padding: 0;
    .ant-tabs-nav-wrap {
      padding-top: 1rem;
    }
    .ant-collapse {
      display: grid;
      grid-template-columns: repete(4, 1fr);
      position: relative;

      .ant-collapse-item {
        .ant-collapse-content {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: auto;
          transform: translateY(100%);
        }
      }
    }
    .ant-tabs-left {
      min-height: 100dvh;
      min-height: 100vh;
      .ant-tabs-content-holder {
        padding: 24px;
        .ant-tabs-tabpane {
          padding-left: 0 !important;
        }
      }
    }
  }
`;

export const TableSort = styled(Table)`
.ant-table{
  background-color: var(--body-bg);
}
.ant-table {
  background-color: var(--body-bg);
  table{
    background-color:transparent;
    border-spacing: 0 1rem;
    .ant-table-row{
      .ant-table-cell{
        &:first-child{
          border-radius: var(--br-12) 0 0 var(--br-12);
          border-left: 1px solid var(--input-field-border) !important;
        }
        &:last-child{
          border-radius: 0 var(--br-12) var(--br-12) 0;
          border-right: 1px solid var(--input-field-border) !important;
        }
      }
    }
  }
  .gray-clr {
    color: ${() =>
      themes[useSelector((state) => state.theme.name)].token.grayClr};
  }
  .ant-table-thead{
    display: none;
  }
  .ant-table-cell,
  .ant-table-cell.ant-table-cell-fix-left{
        background-color: var(--input-field-bg) !important;
    }
  }
  .ant-table-cell {
    border-bottom: 1px solid
        ${() =>
          themes[useSelector((state) => state.theme.name)].components.Table
            .borderColor} !important;
      border-top: 1px solid
        ${() =>
          themes[useSelector((state) => state.theme.name)].components.Table
            .borderColor} !important;
    &.ant-table-column-has-sorters {
      &:hover {
        background-color: ${() =>
          themes[useSelector((state) => state.theme.name)].components.Table
            .sortedBg} !important;
      }
    }
    &.ant-table-column-sort {
      background-color: ${() =>
        themes[useSelector((state) => state.theme.name)].components.Table
          .sortedBg};
    }
  }

  .imgLocationClr {
    color: ${() =>
      themes[useSelector((state) => state.theme.name)].components.Table
        .imgLocationClr};
    margin-left: 10px;
  }

}
`;

const TreeItem = ({
  title,
  item,
  handleMenuClick,
  handleContentVisibility,
  handleClick,
  highlightedItem,
}) => {
  const { attributes, listeners, setNodeRef } = useSortable({
    id: item?._id,
  });
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const items = [
    !["object"]?.includes(item?.meta?.interface) && {
      label: (
        <a onClick={() => handleMenuClick("open_model", item)}>
          {t("edit_field")}
        </a>
      ),
      icon: <MdOutlineEdit size={20} />,
      key: "-1",
    },
    !["object"]?.includes(item?.meta?.interface) && {
      label: (
        <a onClick={() => handleMenuClick("half", item)}>{t("half_width")}</a>
      ),
      icon: <PiSquareHalfLight size={20} />,
      key: "0",
    },
    !["object"]?.includes(item?.meta?.interface) && {
      label: (
        <a onClick={() => handleMenuClick("full", item)}>{t("full_width")}</a>
      ),
      icon: <MdFullscreen size={20} />,
      key: "1",
    },
    !["object"]?.includes(item?.meta?.interface) && {
      type: "divider",
    },
    {
      label: (
        <a
          className="!text-[#E0364E]"
          onClick={() => handleMenuClick("delete", item)}
        >
          {t("delete_field", { field: "field" })}
        </a>
      ),
      icon: <RiDeleteBin6Line size={20} className="!text-[#E0364E]" />,
      key: "2",
    },
  ];

  const handleCollapse = () => {
    dispatch(
      updateFieldById({
        id: item?._id,
        updatedData: {
          "meta.collapse": item?.meta?.collapse === "open" ? "closed" : "open",
        },
      }),
    );
  };

  return (
    <Col
      xs={24}
      sm={24}
      md={item?.meta?.width === "half" ? 12 : 24}
      className={
        item?.meta?.collapse === "open"
          ? "remove-sibling-padding remove-radius-bottom"
          : ""
      }
    >
      <Paragraph
        className={`${highlightedItem === item?._id ? "bg-project-base/5  border-1 border-project-base shadow-md" : ""} flex justify-between border border-(--input-field-border) bg-(--input-field-bg) p-3 mx-1 rounded-xl items-center highlighting-drag ${item?.meta?.interface === "none" ? "cursor-not-allowed " : ""} `}
        {...attributes}
        {...listeners}
        ref={setNodeRef}
      >
        <ul className="flex gap-3 flex-1 items-center !mb-0 !list-none">
          <li
            className={
              item?.meta?.interface === "none"
                ? "cursor-pointer | list-none pl-0 flex !ml-0"
                : "cursor-pointer list-none pl-0 flex !ml-0"
            }
          >
            <IconsAll.DragDropIcon />
          </li>
          <li
            className={
              item?.meta?.interface === "none"
                ? "cursor-not-allowed pointer-events-none | list-none pl-0 flex !ml-0"
                : "list-none pl-0 flex !ml-0"
            }
          >
            <button type="button" onClick={(e) => handleClick(e, item)}>
              {title}
              {item?.meta?.required && (
                <sup
                  style={{
                    color: "var(--project-base)",
                    marginLeft: "0.2rem",
                  }}
                >
                  *
                </sup>
              )}
            </button>
          </li>
          <li
            className={` ${item?.meta?.interface === "none" ? "cursor-not-allowed pointer-events-none | list-none pl-0 flex !ml-0" : "list-none pl-0 flex !ml-0"}`}
          >
            <button
              type="button"
              className="w-full text-start text-(--input-disabled-color)"
              onClick={(e) => handleClick(e, item)}
            >
              {item?.meta?.interface === "object"
                ? "group"
                : item?.meta?.interface}
            </button>
          </li>
        </ul>
        <li
          title={t("primary_key")}
          className="cursor-pointer | list-none pl-0 flex mr-3 !ml-0"
        >
          {item?.children?.length > 0 ? (
            item?.meta?.collapse === "open" ? (
              <LuChevronsUpDown
                size={22}
                color="rgba(from var(--heading-text) r g b / 20%)"
                onClick={handleCollapse}
                title={t("expand")}
              />
            ) : (
              <LuChevronsDownUp
                size={22}
                color="rgba(from var(--heading-text) r g b / 20%)"
                onClick={handleCollapse}
                title={t("collapse")}
              />
            )
          ) : (
            <></>
          )}
        </li>
        <ul className="flex gap-3 items-center !mb-0 !list-none">
          {item?.schema_definition?.is_primary ? (
            <li
              title={t("primary_key")}
              className="cursor-pointer | list-none pl-0 flex !ml-0"
            >
              <button type="button">
                <IconsAll.KeyIcon />
              </button>
            </li>
          ) : (
            <></>
          )}
          <li
            className={
              item?.meta?.interface === "none"
                ? "cursor-not-allowed pointer-events-none | list-none pl-0 flex !ml-0"
                : "list-none pl-0 flex !ml-0"
            }
          >
            <button
              onClick={() =>
                handleContentVisibility(!item?.meta?.hidden, item?._id)
              }
            >
              {item?.meta?.hidden ? (
                <IconsAll.EyeCloseIcon />
              ) : (
                <IconsAll.OpenEyeIcon strokeColor={"#88909A"} />
              )}
            </button>
          </li>
          <li
            className={
              item?.meta?.interface === "none"
                ? "cursor-not-allowed pointer-events-none | list-none pl-0 flex !ml-0"
                : "list-none pl-0 flex !ml-0"
            }
          >
            <Dropdown
              overlayStyle={{
                width: "180px",
              }}
              menu={{
                items,
              }}
              arrow
              destroyPopupOnHide={true}
              getPopupContainer={(trigger) => trigger.parentElement}
              placement="bottomRight"
              trigger={["click"]}
            >
              <a onClick={(e) => e.preventDefault()}>
                <IconsAll.MoreIcon />
              </a>
            </Dropdown>
          </li>
        </ul>
      </Paragraph>
    </Col>
  );
};

const Tree = ({
  items,
  depth = 0,
  handleMenuClick,
  handleContentVisibility,
  handleClick,
  highlightedItem,
}) => {
  return (
    <SortableContext
      items={items?.map((f) => f?._id) || []}
      strategy={verticalListSortingStrategy}
    >
      <Row
        className={`w-full ${depth > 0 ? "!pl-4 remove-sibling-padding remove-radius-top" : ""}`}
      >
        {items?.map((item) => (
          <React.Fragment key={item?._id}>
            <TreeItem
              title={item?.field}
              id={item?._id}
              fieldName={item?.fieldName}
              item={item}
              handleMenuClick={handleMenuClick}
              handleContentVisibility={handleContentVisibility}
              handleClick={handleClick}
              highlightedItem={highlightedItem}
            />
            {item?.meta?.collapse === "open" && item?.children && (
              <Tree
                items={item?.children}
                depth={depth + 1}
                handleMenuClick={handleMenuClick}
                handleContentVisibility={handleContentVisibility}
                handleClick={handleClick}
                highlightedItem={highlightedItem}
              />
            )}
          </React.Fragment>
        ))}
      </Row>
    </SortableContext>
  );
};

const ChangeDataModalCollection = () => {
  // eslint-disable-next-line no-unused-vars
  const { icons, createLucideIcon, ...filteredIcons } = LucideIcons;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [dataSource, setDataSource] = useState(null);
  const [activeItem, setActiveItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const path = location.pathname.split("/");
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 20,
    },
  });
  const keyboardSensor = useSensor(KeyboardSensor);
  const sensors = useSensors(mouseSensor, keyboardSensor);
  const fields = useSelector((state) => state?.fields?.fields);
  const schemas = useSelector((state) => state?.schemas?.schemas);
  const [form] = Form.useForm();
  const [highlightedItem, setHighlightedItem] = useState({});
  const [activeSortItem, setActiveSortItem] = useState({});
  const [displayTemplate, setDisplayTemplate] = useState("");
  const id = useId();

  const handleContentVisibility = async (record, id) => {
    try {
      const resp = await axiosInstance.patch(
        Apis.UPDATE_FIELDS_VISIBILITY(dataSource?.collection_id),
        { hidden: record, field: id, schema_id: path[3] },
      );
      if (resp.status === 200) {
        notification.success({ message: t("visibility_update_success") });
        dispatch(
          updateFieldById({
            id: id,
            updatedData: { "meta.hidden": record },
          }),
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    schemas?.length > 0 && init();
  }, [fields, schemas]);

  const handleClick = (e, item) => {
    item?.children && delete item?.children;
    item?.title && delete item?.title;
    item?.key && delete item?.key;
    item?.value && delete item?.value;
    if (e) {
      e?.stopPropagation();
    }
    setOpenEdit(true);
    setActiveItem(item);
  };

  const handleMenuClick = async (type, item) => {
    if (type == "open_model") {
      handleClick("", item);
    }
    if (type === "delete") {
      Modal.confirm({
        title: t("delete_field", { field: item?.field }),
        content: t("delete_field_confirm", { field: item?.field }),
        icon: (
          <div className="modal-icon">
            <RiDeleteBin6Line size={24} />
          </div>
        ),
        okText: t("delete_field", { field: "field" }),
        okType: "primary",
        cancelText: t("cancel"),
        maskClosable: true,
        className: "modal-delete",
        onOk: async () => {
          try {
            const resp = await axiosInstance.delete(
              Apis.DELETE_A_FIELD(item?._id),
            );
            if (resp.status === 200) {
              notification.success({
                message: t("field_delete_success", { field: item?.field }),
              });

              if (
                ["list-m2m", "list-m2a", "translations"].includes(
                  item?.meta?.interface,
                )
              ) {
                const myRelations = getForeignCollectionDetails({
                  collection: item?.schema_id,
                  field: item?.field,
                  iFace: item?.meta?.interface,
                  findJunction: [
                    "list-m2m",
                    "list-m2a",
                    "translations",
                  ].includes(item?.meta?.interface),
                });
                dispatch(deleteFieldById(myRelations?.junction_collection_id));
                dispatch(deleteSchemaById(myRelations?.junction_collection_id));
              }
              dispatch(deleteFieldById(item?._id));
            }
            setActiveItem(null);
          } catch (error) {
            console.log("error", error);
          }
        },
      });
    }
    if (item?.meta?.width !== type && (type === "half" || type === "full")) {
      try {
        let selectedField = dataSource?.fields?.find(
          (f) => f.field === item?.field,
        );
        const { _id, ...restOfSelectedField } = selectedField;
        delete restOfSelectedField.__v;
        const payload = {
          ...restOfSelectedField,
          meta: {
            ...restOfSelectedField.meta,
            width: type,
          },
        };

        const resp = await axiosInstance.put(Apis.UPDATE_A_FIELD(_id), payload);
        if (resp.status === 200) {
          dispatch(
            updateFieldById({
              id: _id,
              updatedData: { "meta.width": type },
            }),
          );
        }
        setActiveItem(null);
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  const init = async () => {
    try {
      const resp1 = getFieldsBySchemaId({
        schemas,
        fields,
        schemaId: params.field,
        populated: false,
      });
      if (!resp1) navigate("/settings/data-model");

      setDataSource(resp1);
      setDataSource((prev) => {
        return {
          ...prev,
          fields: resp1?.fields?.sort((a, b) => a?.meta?.sort - b?.meta?.sort),
        };
      });
      form.setFieldsValue({
        collection: resp1?.collection_id,
        note: resp1?.meta?.note,
        icon: resp1?.meta?.icon,
        color: resp1?.meta?.color,
        hidden: !resp1?.visible,
        singleton: resp1?.meta?.singleton,
      });
      setDisplayTemplate(resp1?.meta?.display_template);
    } catch (error) {
      console.log("error", error);
      notification.error({
        message: t("something_went_wrong"),
      });
      navigate("/settings/data-model");
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 300);
    }
  };

  const onDragEnd = ({ active, over }) => {
    const activePath = dataSource?.fields?.find(
      (f) => f?._id === active?.id,
    )?.path;
    const overPath = dataSource?.fields?.find((f) => f?._id === over?.id)?.path;

    if (
      activePath?.substring(0, activePath?.lastIndexOf(".")) !==
      overPath?.substring(0, overPath.lastIndexOf("."))
    ) {
      return;
    }
    if (active.id !== over?.id) {
      setDataSource((previous) => {
        const activeIndex = previous?.fields.findIndex(
          (i) => i._id === active.id,
        );
        const overIndex = previous?.fields.findIndex((i) => i._id === over?.id);
        return {
          ...previous,
          fields: arrayMove(previous?.fields, activeIndex, overIndex),
        };
      });
    }
    setHighlightedItem({});
  };

  const handleAddClick = async () => {
    const values = await form.validateFields();
    const payload = {
      meta: {
        note: values?.note,
        icon: values?.icon || null,
        color: values?.color,
        singleton: values?.singleton,
        display_template: displayTemplate,
      },
      visible: !values?.hidden,
    };
    try {
      const sortedFields = dataSource?.fields?.map((item, i) => {
        delete item?.__v;
        return { ...item, meta: { ...item?.meta, sort: i + 1 } };
      });

      const [resp1, resp2] = await Promise.all([
        axiosInstance.put(Apis.UPDATE_SCHEMA(params.field), payload),
        axiosInstance.put(
          Apis.UPDATE_FIELD_SORT(params.field),
          sortedFields?.map(({ _id, meta }) => ({
            _id,
            meta: { sort: meta?.sort },
          })),
        ),
      ]);

      if (resp1.status === 200) {
        dispatch(updateSchemaById({ id: params.field, updatedData: payload }));
      }

      if (resp2.status === 200) {
        notification.success({ message: t("schema_update_success") });
        navigate("/settings/data-model");
        dispatch(
          matchAndReplaceField({
            matchKey: "schema_id",
            matchValue: params.field,
            newField: sortedFields,
          }),
        );
        dispatch(
          setActiveTab({
            page: "settings",
            tab: dataSource?.collection_id,
            redirectionLink: "/settings/data-model",
          }),
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleDeleteClick = async () => {
    Modal.confirm({
      title: t("delete_collection"),
      content: t("delete_collection_confirm"),
      icon: (
        <div className="modal-icon">
          <RiDeleteBin6Line size={24} />
        </div>
      ),
      okText: t("delete_collection"),
      okType: "primary",
      okButtonProps: { disabled: loading },
      cancelText: t("cancel"),
      cancelButtonProps: { disabled: loading },
      maskClosable: true,
      className: "modal-delete",
      async onOk() {
        setLoading(true);
        try {
          const resp = await axiosInstance.delete(
            Apis.DELETE_A_SCHEMA(params.field),
          );
          if (resp.status === 200) {
            notification.success({ message: t("collection_delete_success") });
            navigate("/settings/data-model");
            dispatch(deleteSchemaById(params.field));
            dispatch(
              setActiveTab({
                page: "content",
                tab: null,
                redirectionLink: null,
              }),
            );
            dispatch(
              setActiveTab({
                page: "settings",
                tab: "Data Model",
                redirectionLink: "/settings/data-model",
              }),
            );
          }
        } catch (error) {
          console.log("error", error);
          notification.error({
            message: error?.data?.message || t("something_went_wrong"),
          });
        } finally {
          setLoading(false);
          dispatch(hideLoader());
        }
      },
    });
  };

  const handleDisplayTemplateChange = (value, fromSelect = false) => {
    setDisplayTemplate((prev) => (fromSelect ? `${prev}{{${value}}}` : value));
  };

  const onContentBlur = useCallback((event) => {
    const contentEditable = event.currentTarget;
    let updatedTemplate = "";
    contentEditable.childNodes.forEach((node) => {
      if (node.nodeName === "BUTTON") {
        updatedTemplate += `{{${node.textContent}}}`;
      } else if (node.nodeType === Node.TEXT_NODE) {
        updatedTemplate += node.textContent.trim();
      } else if (node.nodeName === "SPAN") {
        updatedTemplate += node.textContent.trim();
      } else if (node.nodeName === "#text") {
        updatedTemplate += <span>{node.textContent.trim()}</span>;
      } else {
        updatedTemplate += node.textContent.trim();
      }
    });

    handleDisplayTemplateChange(updatedTemplate);
  }, []);

  const handleButtonClick = (placeholder) => {
    const updatedTemplate = displayTemplate.replace(`{{${placeholder}}}`, "");
    setDisplayTemplate(updatedTemplate);
  };

  const renderDisplayTemplate = (template = "") => {
    const regex = /{{(.*?)}}/g;
    const parts = template.split(regex);
    return parts.map((part, index) => {
      if (part !== "" && dataSource?.fields?.some((op) => op?.path === part)) {
        return (
          <button
            key={index}
            contentEditable={false}
            onClick={() => handleButtonClick(part)}
            className="text-project-base bg-project-base/10 text-sm font-normal mx-1 rounded-md px-2 py-1 leading-5"
          >
            {part}
          </button>
        );
      }
      return (
        <span
          key={index}
          className="text-sm font-normal text-[var(--input-field-text-clr)] h-[56px] block leading-14"
        >
          {part}
        </span>
      );
    });
  };

  return (
    <Flex className="w-full">
      <section className="w-full">
        <div className="px-4 xl:px-16 w-full">
          <div className="primary-header pt-9 pb-5">
            <div
              onClick={() => {
                navigate("/settings/data-model");
                dispatch(
                  setActiveTab({
                    page: "settings",
                    tab: "Data Model",
                    redirectionLink: "/settings/data-model",
                  }),
                );
              }}
            >
              <LeftSideTitlePart
                pageTitle={
                  loading ? (
                    <Skeleton.Button size="small" active />
                  ) : (
                    capitalizeAndFormat(dataSource?.collection_name)
                  )
                }
                pageSubTitle={t("setting")}
                backButton={true}
                // backLink="/settings/data-modal"
              />
            </div>
            <RightSIdeTitlePart
              deleteButton={true}
              showButton={true}
              deleteDisabled={dataSource?.system_default || loading}
              handleShowClick={handleAddClick}
              showDisabled={
                dataSource?.system_default ||
                !dataSource?.fields?.length ||
                loading
              }
              handleDeleteClick={handleDeleteClick}
            />
            <DrawerStyleNewField
              width={870}
              open={isDrawerOpen}
              onClose={() => {
                setIsDrawerOpen(false);
              }}
              closeIcon={<IconsAll.CloseIconCustom />}
              // destroyOnClose
            >
              <CreateNewFields
                isDrawerOpen={isDrawerOpen}
                setIsDrawerOpen={setIsDrawerOpen}
                setDataSource={setDataSource}
                dataSource={dataSource}
              />
            </DrawerStyleNewField>
            <DrawerStyle
              width={870}
              open={openEdit}
              onClose={() => {
                setOpenEdit(false);
                setActiveItem(null);
              }}
              closeIcon={<IconsAll.CloseIconCustom />}
              destroyOnClose
            >
              <EditFields
                isDrawerOpen={openEdit}
                setIsDrawerOpen={setOpenEdit}
                activeItem={activeItem}
                setActiveItem={setActiveItem}
                dataSource={dataSource}
              />
            </DrawerStyle>
          </div>
          <section className="mt-4 max-w-4xl container">
            <h2 className="text-base text-(--notication-status-svg) font-bold mb-5">
              {t("fields")} & {t("layout")}
              <span className="font-normal text-project-base opacity-60 text-sm ml-2">
                {t("saves_automatically")}
              </span>
            </h2>
            {loading ? (
              <Skeleton size="large" active />
            ) : (
              <>
                <DndContext
                  // modifiers={[restrictToVerticalAxis]}
                  collisionDetection={rectIntersection}
                  onDragEnd={onDragEnd}
                  onDragOver={({ over }) => {
                    setHighlightedItem(over?.id);
                  }}
                  sensors={sensors}
                  onDragStart={({ active }) => {
                    if (dataSource?.system_default) return;
                    setActiveSortItem(active?.id);
                  }}
                >
                  <Tree
                    items={convertToTreeData(dataSource?.fields, "tree-fields")}
                    handleMenuClick={handleMenuClick}
                    handleContentVisibility={handleContentVisibility}
                    handleClick={handleClick}
                    highlightedItem={highlightedItem}
                  />
                  <DragOverlay>
                    <div className="flex p-4 bg-white border-1 border-blue-500 border-opacity-30 rounded-md shadow-md">
                      <ul className="flex gap-3 flex-1 items-center !mb-0 !list-none">
                        <li className={`list-none pl-0 flex !ml-0`}>
                          <IconsAll.DragDropIcon />
                        </li>
                        <li className={`list-none pl-0 flex !ml-0`}>
                          <button
                            type="button"
                            onClick={(e) => handleClick(e, item)}
                          >
                            {
                              dataSource?.fields?.find(
                                (item) => item?._id === activeSortItem,
                              )?.field
                            }
                          </button>
                        </li>
                      </ul>
                    </div>
                  </DragOverlay>
                </DndContext>
                <Button
                  className="project-custom-btn w-full"
                  onClick={() => setIsDrawerOpen(true)}
                  disabled={dataSource?.system_default}
                >
                  {t("add_field")}
                </Button>
              </>
            )}
          </section>
          <section className="my-9 max-w-4xl container">
            <div>
              <FormTitle
                title={t("collection_setup")}
                icon={<IconsAll.ContentIcon />}
              />
            </div>
            <FormStyling layout="vertical" form={form}>
              <Row gutter={16}>
                <Col className="gutter-row" xs={24} md={12}>
                  <Form.Item
                    label={t("collection")}
                    name="collection"
                    className="font-bold"
                    htmlFor={`${id}-collection`}
                  >
                    <Input
                      className="project-custom-input"
                      id={`${id}-collection`}
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} md={12}>
                  <Form.Item
                    label={t("note")}
                    name="note"
                    className="font-bold"
                    htmlFor={`${id}-note`}
                    rules={[
                      {
                        max: 50,
                      },
                    ]}
                  >
                    <Input
                      className="project-custom-input"
                      placeholder={t("collection_description")}
                      id={`${id}-note`}
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} md={12}>
                  <Form.Item
                    label={t("icon")}
                    className="font-bold"
                    htmlFor={`${id}-icon`}
                    name={`icon`}
                  >
                    <Select
                      id={`${id}-icon`}
                      options={Object.keys(filteredIcons).map((iconName) => {
                        const IconComponent = filteredIcons[iconName];
                        return {
                          value: iconName,
                          label: (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                              }}
                            >
                              <IconComponent
                                size={16}
                                color="var(--project-base)"
                              />
                              {iconName}
                            </div>
                          ),
                        };
                      })}
                      showSearch
                      filterOption={(input, option) =>
                        option?.value
                          ?.toLowerCase()
                          ?.includes(input?.toLowerCase())
                      }
                      style={{ width: "100%" }}
                      placeholder="Select an Icon"
                      allowClear={{
                        clearIcon: (
                          <div className="right-4 relative">
                            <IconsAll.CloseIconCustom />
                          </div>
                        ),
                      }}
                      optionLabelProp="label"
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} md={12}>
                  <Form.Item
                    label={t("color")}
                    className="font-bold"
                    name={`color`}
                    htmlFor={`color`}
                  >
                    <ColorPicker
                      defaultValue={() => form.getFieldValue("color")}
                      id={`color`}
                      showText
                      className="project-custom-color-picker border-gray-base"
                      defaultFormat="hex"
                      onChange={(_, hex) => {
                        form.setFieldValue("color", hex);
                      }}
                      allowClear
                      onClear={() => {
                        form.setFieldValue("color", null);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} md={12}>
                  <Form.Item
                    name="hidden"
                    label={t("hidden")}
                    valuePropName="checked"
                    className="font-bold"
                  >
                    <InvertedCheckBox>
                      {t("hide_within_the_app")}
                    </InvertedCheckBox>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} md={12}>
                  <Form.Item
                    name="singleton"
                    label={t("singleton")}
                    valuePropName="checked"
                    className="font-bold"
                  >
                    <InvertedCheckBox>
                      {t("treat_as_single_object")}
                    </InvertedCheckBox>
                  </Form.Item>
                </Col>

                {dataSource?.fields?.length > 0 && (
                  <Col
                    key={"display_template"}
                    className="gutter-row"
                    xs={24}
                    md={24}
                  >
                    <Form.Item
                      label={t("display_template")}
                      className="font-bold relative"
                      htmlFor={`${id}-display-template`}
                      name={"display_template"}
                    >
                      <div className="display-template-input | ant-input ant-input-outlined">
                        <div
                          contentEditable
                          onBlur={onContentBlur}
                          dangerouslySetInnerHTML={{
                            __html: renderDisplayTemplate(
                              displayTemplate,
                            ).reduce((html, element) => {
                              return (
                                html +
                                (typeof element === "string"
                                  ? element
                                  : ReactDOMServer.renderToStaticMarkup(
                                      element,
                                    ))
                              );
                            }, ""),
                          }}
                          className="focus-visible:border-project-base! focus-visible:outline-0! whitespace-nowrap overflow-auto custom-scrollbar flex items-center content-editable"
                        ></div>
                      </div>
                      <TreeSelect
                        popupClassName="display-template-tree-select w-740 custom-tree-select"
                        suffixIcon={
                          <GoPlus
                            size={24}
                            color={"var(--input-field-text-clr)"}
                          />
                        }
                        showSearch
                        treeData={convertToTreeData(
                          dataSource?.fields?.filter(
                            (f) =>
                              ![
                                "list-m2a",
                                "list-m2m",
                                "list-m2o",
                                "list-o2m",
                                "file",
                                "file-image",
                                "files",
                                "items",
                              ].includes(f?.meta?.interface),
                          ),
                          "tree-select",
                        )}
                        value={null}
                        onChange={(value) =>
                          handleDisplayTemplateChange(value, true)
                        }
                      />
                    </Form.Item>
                  </Col>
                )}
              </Row>
            </FormStyling>
          </section>
        </div>
      </section>
      <AppInfo
        visibleList={["information"]}
        config={{
          msg: t("information_data_model_fields"),
        }}
      />
    </Flex>
  );
};

export default ChangeDataModalCollection;
