import React, { useEffect, useId, useState } from "react";
import LeftSideTitlePart from "../../HeaderParts/LeftSideTitlePart";
import RightSIdeTitlePart from "../../HeaderParts/RightSIdeTitlePart";
import IconsAll from "../../IconsAll";
import {
  Form,
  Input,
  notification,
  Tag,
  Button,
  Image,
  Modal,
  Row,
  Col,
  Typography,
  Divider,
  Drawer,
  Spin,
} from "antd";
import { useTranslation } from "react-i18next";
import TextArea from "antd/es/input/TextArea";
import axiosInstance from "../../../Helpers/axios";
import Apis from "../../../Helpers/Apis";
import { downloadFile, getDisplayImage } from "../../../Helpers/utils";
import CustomDragger from "./CustomDragger";
import styled from "styled-components";
import {
  IoCloudDownloadOutline,
  IoCloudUploadOutline,
  IoLocationOutline,
} from "react-icons/io5";
import { MdStorage } from "react-icons/md";
import { CiFileOn } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import FileExtension from "./FileExtension";
import EditImage from "./EditImage";
import { useDispatch } from "react-redux";
import { hideLoader } from "../../../Redux/slices/loader.reducer";

const FormStyling = styled(Form)`
  padding: 10px;
`;

const DrawerStyle = styled(Drawer)`
  .ant-drawer-header {
    padding: 0;
    border: unset !important;
    .ant-drawer-header-title {
      .ant-drawer-close {
        width: 56px;
        height: 56px;
        position: absolute;
        left: -65px;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 30px;
        background: var(--color-white);
        border-radius: 100vh;
        box-shadow: 0px 4px 12px 0px rgba(8, 24, 44, 0.16);
        @media screen and (max-width: 991px) {
          left: calc(50% - 28px);
          bottom: 1rem;
          top: auto;
        }
      }
    }
  }
  .ant-drawer-body {
    padding: 0;
    .ant-tabs-nav {
      padding-top: 40px;
      width: min(100%, 200px);
      min-height: 100vh;
      background-color: #f5f6f7;
      .ant-tabs-nav-wrap {
        &:before,
        &:after,
        .ant-tabs-ink-bar {
          display: none;
        }
        .ant-tabs-nav-list {
          padding-inline: 10px;
          .ant-tabs-tab {
            border-radius: 8px;
            margin-top: 5px;
            > div {
              color: #000 !important;
              font-size: 16px;
              font-style: normal;
            }
            &.ant-tabs-tab-active {
              background-color: var(--sidebar-active-menu-bg) !important;
            }
          }
        }
      }
    }

    .ant-tabs-content-holder {
      padding-top: 40px;
      .ant-tabs-tabpane {
        padding-right: 24px;
      }
    }
  }
`;

const EditFiles = ({
  setShowEditFile,
  fileId,
  setFiles,
  assignImageUrl,
  isDrawer,
  getFilesList,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const id = useId();
  const navigate = useNavigate();
  const [fileDetails, setFileDetails] = useState({});
  const [isImageEditOpen, setIsImageEditOpen] = useState(false);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    form.setFieldsValue(fileDetails);
  }, [fileDetails]);

  useEffect(() => {
    getFileDetails();
  }, []);

  const getFileDetails = async () => {
    setLoader(true);
    try {
      const resp = await axiosInstance.get(Apis.GET_FILE(fileId));

      if (resp.status === 200) {
        setFileDetails({
          ...(resp.data?.payload?.data || {}),
          imageUrl: ["image/jpg", "image/jpeg", "image/png"].includes(
            resp.data?.payload?.data?.type,
          )
            ? await getDisplayImage(fileId)
            : null,
        });
      }
    } catch (error) {
      console.log("error", error);
      notification.error({ message: t("something_went_wrong") });
    } finally {
      dispatch(hideLoader());
      setLoader(false);
    }
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      setLoader(true);
      const resp = await axiosInstance.put(Apis.UPDATE_FILE(fileId), values);

      if (resp.status === 200) {
        notification.success({ message: t("file_update_success") });
        isDrawer
          ? setFiles(
              (prev) =>
                prev?.map((f) =>
                  f?._id === fileId ? resp.data?.payload?.data : f,
                ),
              setShowEditFile(false),
            )
          : navigate(`/media-library/${fileDetails?.folder || ""}`);
        getFilesList && getFilesList();
      }
    } catch (error) {
      console.log("error", error);
      notification.error({ message: t("something_went_wrong") });
    } finally {
      setLoader(false);
    }
  };

  const handleReplace = async (data) => {
    isDrawer && assignImageUrl();
    setFileDetails({
      ...(data[0] || {}),
      imageUrl: ["image/jpg", "image/jpeg", "image/png"].includes(data[0]?.type)
        ? await getDisplayImage(fileId)
        : null,
    });
  };

  return (
    <span className="px-4 xl:px-16 w-full left-part">
      <div className="primary-header pt-9 pb-5">
        <LeftSideTitlePart
          pageIcon={true}
          pageInnerIcon={
            <IconsAll.ImportExport strokeColor="var(--project-base)" />
          }
          pageTitle={t("editing_items_in")}
          pageSubTitle={t("users_files")}
          backButton={!isDrawer}
          backLink={`/media-library/${fileDetails?.folder ? fileDetails?.folder : ""}`}
        />
        <RightSIdeTitlePart
          equalizerButton={["image/jpg", "image/jpeg", "image/png"].includes(
            fileDetails?.type,
          )}
          handleEqualizerButton={() => setIsImageEditOpen(true)}
          equalizerDisabled={loader}
          archiveButton
          archiveDisabled={
            loader ||
            !["image/jpg", "image/jpeg", "image/png"].includes(
              fileDetails?.type,
            )
          }
          handleArchiveButton={() =>
            downloadFile(fileDetails?.imageUrl, fileDetails?.filename_download)
          }
          showButton
          handleShowClick={handleSubmit}
          showDisabled={loader}
        />
      </div>
      {loader ? (
        <Spin
          className="flex w-full justify-center"
          indicator={<div className="custom-loader" />}
        />
      ) : (
        <FormStyling
          form={form}
          layout="vertical"
          initialValues={{
            tags: [],
          }}
        >
          <Row gutter={16}>
            <Col md={24}>
              <div className="flex items-center mb-4 flex-col">
                {["image/jpg", "image/jpeg", "image/png"].includes(
                  fileDetails?.type,
                ) ? (
                  <Image
                    src={fileDetails?.imageUrl}
                    preview={false}
                    alt="image"
                    className="min-h-[500px] max-h-[500px] h-auto! w-full! object-contain mx-auto"
                  />
                ) : (
                  <div className="min-h-[300px] max-h-[300px] h-auto! w-full! mx-auto flex justify-center items-center bg-[var(--field-block-bg)] rounded-xl">
                    <FileExtension
                      size={130}
                      extension={fileDetails?.filename_disk?.split(".")?.pop()}
                    />
                  </div>
                )}
                <Button
                  type="link"
                  onClick={() => setIsModalOpen(true)}
                  className="rounded-full border border-dark font-medium h-auto mt-3"
                >
                  {t("replace_file")}
                </Button>
              </div>
            </Col>
            <Col md={24}>
              <Form.Item
                label={t("title")}
                className="font-bold"
                name={`title`}
                htmlFor={`${id}-title`}
                rules={[
                  {
                    max: 200,
                  },
                ]}
              >
                <Input
                  placeholder={t("a_unique_file")}
                  id={`${id}-title`}
                  className="project-custom-input"
                />
              </Form.Item>
            </Col>
            <Col md={24}>
              <Form.Item
                label={t("description")}
                htmlFor={`${id}-description`}
                className="font-bold"
                name={`description`}
              >
                <TextArea
                  id={`${id}-description`}
                  className="font-normal"
                  rows={4}
                />
              </Form.Item>
            </Col>
            <Col md={24}>
              <Form.List name="tags">
                {(fields, { add, remove }) => {
                  const handlePressEnter = (e) => {
                    e.preventDefault();
                    const value = e.target.value.trim();
                    if (value) {
                      add(value);
                      form.setFieldsValue({ inputTag: "" });
                    }
                  };

                  return (
                    <Form.Item label={t("tags")} className="font-bold">
                      <div style={{ marginBottom: 16 }}>
                        {fields?.map((field) => {
                          return (
                            <Tag
                              key={field.key}
                              closable
                              onClose={() => remove(field.name)}
                              style={{ marginBottom: "8px" }}
                            >
                              <Form.Item {...field} name={[field.name]} noStyle>
                                <Input
                                  style={{ border: "none", width: "auto" }}
                                  value={field.value || ""}
                                  readOnly
                                />
                              </Form.Item>
                            </Tag>
                          );
                        })}
                      </div>

                      <Form.Item name="inputTag">
                        <Input
                          placeholder={t("press_enter_to_add_tag")}
                          onPressEnter={handlePressEnter}
                          className="project-custom-input"
                        />
                      </Form.Item>
                    </Form.Item>
                  );
                }}
              </Form.List>
            </Col>
            <Col md={12}>
              <Form.Item
                label={t("location")}
                className="font-bold"
                name={`location`}
                htmlFor={`${id}-location`}
              >
                <Input
                  placeholder={t("optional_location")}
                  id={`${id}-location`}
                  suffix={<IoLocationOutline size={24} />}
                  className="project-custom-input"
                />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                label={t("storage")}
                className="font-bold"
                name={`storage`}
                htmlFor={`${id}-storage`}
              >
                <Input
                  placeholder={t("local")}
                  id={`${id}-storage`}
                  suffix={<MdStorage size={24} />}
                  className="project-custom-input"
                  disabled
                />
              </Form.Item>
            </Col>
            <Col md={24} className="mt-8 mb-3">
              <Typography className="flex items-center gap-3 text-lg font-semibold">
                <CiFileOn size={24} />
                {t("group_fields")}
              </Typography>
              <Divider className="mt-2 mb-4" />
            </Col>
            <Col md={12}>
              <Form.Item
                label={t("filename_disk")}
                className="font-bold"
                name={`filename_disk`}
                htmlFor={`${id}-file-disk`}
              >
                <Input
                  placeholder={t("filename_disk")}
                  id={`${id}-file-disk`}
                  suffix={<IoCloudUploadOutline size={24} />}
                  className="project-custom-input"
                  disabled
                />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                label={t("filename_download")}
                className="font-bold"
                name={`filename_download`}
                htmlFor={`${id}-file-download`}
              >
                <Input
                  placeholder={t("filename_download")}
                  id={`${id}-file-download`}
                  suffix={<IoCloudDownloadOutline size={24} />}
                  className="project-custom-input"
                />
              </Form.Item>
            </Col>
            <Col md={24}>
              <Form.Item
                label={t("mime_type")}
                className="font-bold"
                name={`type`}
                htmlFor={`${id}-mime`}
              >
                <Input
                  placeholder={t("mime_type")}
                  id={`${id}-mime`}
                  className="project-custom-input"
                  disabled={true}
                />
              </Form.Item>
            </Col>
            <Col md={24}>
              <Form.Item
                label={t("file_size")}
                className="font-bold"
                name={`file_size`}
                htmlFor={`${id}-file-size`}
              >
                <Input
                  placeholder={t("file_size")}
                  id={`${id}-file-size`}
                  className="project-custom-input"
                  disabled={true}
                />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                label={t("folder")}
                className="font-bold"
                name={`folder`}
                htmlFor={`${id}-folder`}
              >
                <Input
                  placeholder={t("folder")}
                  id={`${id}-folder`}
                  className="project-custom-input"
                  disabled={true}
                />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                label={t("width")}
                className="font-bold"
                name={`width`}
                htmlFor={`${id}-width`}
              >
                <Input
                  placeholder={t("width")}
                  id={`${id}-width`}
                  className="project-custom-input"
                  disabled={true}
                />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                label={t("height")}
                className="font-bold"
                name={`height`}
                htmlFor={`${id}-height`}
              >
                <Input
                  placeholder={t("height")}
                  id={`${id}-height`}
                  className="project-custom-input"
                  disabled={true}
                />
              </Form.Item>
            </Col>
          </Row>
        </FormStyling>
      )}

      <Modal
        title={t("add_file")}
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
        okText={t("ok")}
        cancelText={t("cancel")}
        okButtonProps={{ className: "modal-primary-btn" }}
        cancelButtonProps={{ className: "modal-default-btn" }}
      >
        <CustomDragger
          setIsModalOpen={setIsModalOpen}
          replaceFileId={fileId}
          updateFilesList={handleReplace}
        />
      </Modal>
      <DrawerStyle
        width={991}
        onClose={() => setIsImageEditOpen(false)}
        destroyOnClose
        open={isImageEditOpen}
        className="table-in-drawer"
      >
        <EditImage
          setIsImageEditOpen={setIsImageEditOpen}
          setFileDetails={setFileDetails}
          fileDetails={fileDetails}
          assignImageUrl={assignImageUrl}
          isDrawer={false}
        />
      </DrawerStyle>
    </span>
  );
};

export default EditFiles;
