import React from "react";
import FormGenerator from "./FormGenerator";
import { convertToTreeData } from "../../Helpers/utils";

const TreeItem = ({
  item,
  setFormData,
  formData,
  validationErrors,
  depth = 0,
  language,
  isFromItems,
}) => {
  return (
    <FormGenerator
      item={item}
      setFormData={setFormData}
      formData={formData}
      validationErrors={validationErrors}
      depth={depth}
      language={language}
      isFromItems={isFromItems}
    />
  );
};

const Tree = ({
  items,
  depth = 0,
  setFormData,
  formData,
  validationErrors,
  language,
  isFromItems,
}) => {
  return (
    <>
      {items?.map((item, i) => (
        <React.Fragment key={item?._id}>
          <TreeItem
            id={item?._id}
            depth={depth || 0}
            item={item}
            setFormData={setFormData}
            formData={formData}
            validationErrors={validationErrors}
            key={item.path + i}
            language={language}
            isFromItems={isFromItems}
          />
          {item?.children && item?.meta?.interface !== "items" && (
            <Tree
              items={item?.children}
              depth={depth + 1}
              setFormData={setFormData}
              formData={formData}
              validationErrors={validationErrors}
              language={language}
              isFromItems={isFromItems}
            />
          )}
        </React.Fragment>
      ))}
    </>
  );
};

const DynamicForm = ({
  form = [],
  setFormData,
  formData,
  validationErrors,
  convertedForm,
  language,
  isFromItems,
}) => {
  return (
    <>
      <Tree
        items={convertedForm ?? convertToTreeData(form, "tree-fields")}
        setFormData={setFormData}
        formData={formData}
        validationErrors={validationErrors}
        language={language}
        isFromItems={isFromItems}
      />
    </>
  );
};

export default DynamicForm;
