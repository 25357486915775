import React, { useCallback, useEffect, useId, useState } from "react";
import ReactDOMServer from "react-dom/server";
import {
  Form,
  Row,
  Col,
  Flex,
  Drawer,
  Spin,
  Button,
  Tooltip,
  TreeSelect,
  Input,
  Select,
  InputNumber,
} from "antd";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import LeftSideTitlePart from "../../HeaderParts/LeftSideTitlePart";
import RightSIdeTitlePart from "../../HeaderParts/RightSIdeTitlePart";
import IconsAll from "../../IconsAll";
import AddVertalingen from "../../../Pages/Instellingen/AddVertalingen";
import { FormStyling } from "../../../Common/CommonUiStyles";
import InputWithTranslations from "../InputWithTranslations";
import {
  capitalizeAndFormat,
  convertToTreeData,
  CustomInfoText,
  getForeignCollectionDetails,
} from "../../../Helpers/utils";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { BsInfoCircle } from "react-icons/bs";
import CreateChoices from "../CreateChoices";
import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  rectIntersection,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { FaGripLines } from "react-icons/fa6";
import { RiDeleteBin6Line } from "react-icons/ri";
import { CSS } from "@dnd-kit/utilities";
import { GoPlus } from "react-icons/go";
import { useSelector } from "react-redux";

const ItemSort = ({
  data,
  highlightedItem,
  setChoices,
  setActiveChoice,
  setOpen,
}) => {
  // console.log("data", data);
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: data?.value,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: isDragging ? "none" : transition || "transform 250ms ease",
    opacity: isDragging ? 0.9 : 1,
    backgroundColor: isDragging
      ? "rgb(from var(--project-base) r g b / 5%)"
      : "bg-(--input-field-bg)",
    boxShadow: isDragging
      ? "0px 8px 16px rgba(0, 0, 0, 0.1)"
      : "0px 2px 8px rgba(0, 0, 0, 0.05)",
    borderRadius: "8px",
  };

  const handleChoiceClick = () => {
    setActiveChoice(data);
    setOpen(true);
  };

  return (
    <div
      className={`flex justify-between items-center border border-(--input-field-border) bg-(--input-field-bg) p-3  m-2 rounded-xl highlighting-drag 
         ${highlightedItem === data?.value ? "bg-project-base/5  shadow-md" : ""}`}
      {...attributes}
      {...listeners}
      style={style}
      ref={setNodeRef}
    >
      <ul
        className="flex gap-4 flex-1 items-center"
        onClick={handleChoiceClick}
      >
        <li className="cursor-pointer list-none">
          <FaGripLines color="gray" size={24} />
        </li>
        <li className="font-normal">{data?.label}</li>
      </ul>
      <button
        type="button"
        title="Delete"
        className="text-delete-base cursor-pointer shrink-0"
        onClick={() =>
          setChoices((prev) =>
            prev?.filter((rel) => rel?.value !== data?.value),
          )
        }
      >
        <Tooltip title="Delete Item">
          <RiDeleteBin6Line size={24} />
        </Tooltip>
      </button>
    </div>
  );
};

const DrawerStyle = styled(Drawer)`
  .ant-drawer-header {
    padding: 0;
    border: unset !important;
    .ant-drawer-header-title {
      .ant-drawer-close {
        width: 56px;
        height: 56px;
        position: absolute;
        left: -65px;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 30px;
        background: var(--color-white);
        border-radius: 100vh;
        box-shadow: 0px 4px 12px 0px rgba(8, 24, 44, 0.16);
        @media screen and (max-width: 991px) {
          left: calc(50% - 28px);
          bottom: 1rem;
          top: auto;
        }
      }
    }
  }
  .ant-drawer-body {
    padding-inline: 0;
    .ant-tabs-nav-wrap {
      padding-top: 1rem;
    }
    .ant-collapse {
      display: grid;
      grid-template-columns: repete(4, 1fr);
      position: relative;

      .ant-collapse-item {
        .ant-collapse-content {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: auto;
          transform: translateY(100%);
        }
      }
    }
    .ant-tabs-left {
      min-height: 100dvh;
      min-height: 100vh;
      .ant-tabs-content-holder {
        padding: 24px;
        .ant-tabs-tabpane {
          padding-left: 0 !important;
        }
      }
    }
  }
`;

const UpdateInterface = ({
  activeItem,
  dataSource,
  updateField,
  loader,
  translationsList,
  getTranslations,
}) => {
  const { t } = useTranslation();
  const id = useId();
  const [form] = Form.useForm();
  const [displayTemplate, setDisplayTemplate] = useState("");
  const [selectedTranslation, setSelectedTranslation] = useState(null);
  const [isTranslationDrawerOpen, setIsTranslationDrawerOpen] = useState(false);
  const [choices, setChoices] = useState([]);
  const [open, setOpen] = useState(false);
  const [activeChoice, setActiveChoice] = useState(null);
  const showChoices = [
    "dropdown",
    "checkboxes",
    "dropdown_multiple",
    "radio",
  ].includes(activeItem?.meta?.interface);
  const showDisplayTemplate = ["list-m2o", "list-o2m", "list-m2m"].includes(
    activeItem?.meta?.interface,
  );
  const myRelations = getForeignCollectionDetails({
    collection: activeItem?.schema_id,
    field: activeItem?.field,
    iFace: activeItem?.meta?.interface,
    findJunction: activeItem?.meta?.interface === "list-m2m",
  });

  const foreignFields = useSelector((state) => state?.fields?.fields)?.filter(
    (field) =>
      field?.schema_id === myRelations?.foreign_collection_id &&
      ![
        "list-m2a",
        "list-m2m",
        "list-m2o",
        "list-o2m",
        "file",
        "file-image",
        "files",
        "items",
      ].includes(field?.meta?.interface),
  );
  const [highlightedItem, setHighlightedItem] = useState({});
  const [activeSortItem, setActiveSortItem] = useState(null);
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 20,
    },
  });

  const keyboardSensor = useSensor(KeyboardSensor);
  const sensors = useSensors(mouseSensor, keyboardSensor);

  useEffect(() => {
    setFormData();
  }, [activeItem?._id]);

  const setFormData = async () => {
    showChoices && setChoices(activeItem?.meta?.options?.choices);
    showDisplayTemplate &&
      setDisplayTemplate(activeItem?.meta?.display_options?.template);
    activeItem?.meta?.options?.placeholder?.includes("$t:") &&
      setSelectedTranslation(
        activeItem?.meta?.options?.placeholder?.replace("$t:", ""),
      );
    form.setFieldsValue({
      placeholder: activeItem?.meta?.options?.placeholder?.includes("$t:")
        ? activeItem?.meta?.options?.placeholder?.replace("$t:", "")
        : activeItem?.meta?.options?.placeholder,
      ...(activeItem?.meta?.interface === "autocomplete" && {
        url: activeItem?.meta?.options?.url,
        results_path: activeItem?.meta?.options?.results_path,
        text_path: activeItem?.meta?.options?.text_path,
        value_path: activeItem?.meta?.options?.value_path,
        trigger: activeItem?.meta?.options?.trigger,
        rate: activeItem?.meta?.options?.rate,
      }),
    });
  };

  const handleSubmit = async () => {
    const values = await form.validateFields();
    const finalValues = {
      ...activeItem,
      meta: {
        ...activeItem?.meta,
        ...(showDisplayTemplate && {
          display_options: {
            template: displayTemplate,
          },
        }),
        options: {
          ...activeItem?.meta?.options,
          placeholder:
            (values?.placeholder && selectedTranslation
              ? "$t:" + values?.placeholder
              : values?.placeholder) || "",
          ...(showChoices && { choices }),
          ...(activeItem?.meta?.interface === "autocomplete" && {
            url: values?.url,
            results_path: values?.results_path,
            text_path: values?.text_path,
            value_path: values?.value_path,
            trigger: values?.trigger,
            rate: values?.rate,
          }),
        },
      },
    };
    const { _id, ...payload } = finalValues;
    delete payload?.__v;

    updateField(_id, payload);
  };

  // const InputCollapse = () => {
  //   return (
  //     <Row gutter={16}>
  //       <Col md={12}>
  //         <Form.Item
  //           label={t("Minimum Value")}
  //           htmlFor={`${id}-minimum`}
  //           className="font-bold"
  //           name={`minimum`}
  //         >
  //           <InputNumber
  //             placeholder={0}
  //             id={`${id}-minimum`}
  //             min={1}
  //             max={10}
  //             className="project-custom-input"
  //           />
  //         </Form.Item>
  //       </Col>
  //       <Col md={12}>
  //         <Form.Item
  //           label={t("Maximum Value")}
  //           htmlFor={`${id}-maximum`}
  //           className="font-bold"
  //           name={`maximum`}
  //         >
  //           <InputNumber
  //             placeholder={0}
  //             id={`${id}-maximum`}
  //             min={1}
  //             max={10}
  //             className="project-custom-input"
  //           />
  //         </Form.Item>
  //       </Col>
  //       <Col md={12}>
  //         <Form.Item
  //           label={t("Step Interval")}
  //           htmlFor={`${id}-interval`}
  //           className="font-bold"
  //           name={`interval`}
  //         >
  //           <InputNumber
  //             placeholder={1}
  //             id={`${id}-interval`}
  //             min={1}
  //             max={10}
  //             className="project-custom-input"
  //           />
  //         </Form.Item>
  //       </Col>
  //       <Col md={12}>
  //         <Form.Item
  //           label={t("Placeholder")}
  //           className="font-bold"
  //           htmlFor={`${id}-placeholder`}
  //           name={`placeholder`}
  //         >
  //           <Input
  //             placeholder={t("Enter a placeholder")}
  //             id={`${id}-placeholder`}
  //             className="project-custom-input"
  //             suffix={<IoLanguage size={20} color="gray" />}
  //           />
  //         </Form.Item>
  //       </Col>
  //       <Col md={12}>
  //         <Form.Item
  //           label={t("Icon Left")}
  //           htmlFor={`${id}-left`}
  //           name={`left`}
  //           className="font-bold"
  //         >
  //           <Select
  //             size="large"
  //             className="font-normal"
  //             placeholder="Search for icon"
  //             style={{ flex: 1, height: "56px" }}
  //             id={`${id}-left`}
  //             options={[
  //               { value: "String", label: "String" },
  //               { value: "ObjectId", label: "ObjectId" },
  //               { value: "Boolean", label: "Boolean" },
  //               { value: "Number", label: "Number" },
  //             ]}
  //           />
  //         </Form.Item>
  //       </Col>
  //       <Col md={12}>
  //         <Form.Item
  //           label={t("Icon Right")}
  //           htmlFor={`${id}-right`}
  //           name={`right`}
  //           className="font-bold"
  //         >
  //           <Select
  //             size="large"
  //             className="font-normal"
  //             placeholder="Search for icon"
  //             style={{ flex: 1, height: "56px" }}
  //             id={`${id}-right`}
  //             options={[
  //               { value: "String", label: "String" },
  //               { value: "ObjectId", label: "ObjectId" },
  //               { value: "Boolean", label: "Boolean" },
  //               { value: "Number", label: "Number" },
  //             ]}
  //           />
  //         </Form.Item>
  //       </Col>
  //       <Col md={12}>
  //         <Form.Item
  //           label={t("Font")}
  //           htmlFor={`${id}-font`}
  //           name={`font`}
  //           className="font-bold"
  //         >
  //           <Select
  //             size="large"
  //             className="font-normal"
  //             style={{ flex: 1, height: "56px" }}
  //             id={`${id}-font`}
  //             options={[
  //               { value: "Sans Serif", label: "Sans Serif" },
  //               { value: "Monospace", label: "Monospace" },
  //               { value: "Serif", label: "Serif" },
  //             ]}
  //           />
  //         </Form.Item>
  //       </Col>
  //     </Row>
  //   );
  // };

  // const DropdownCollapse = () => {
  //   return (
  //     <Row gutter={16}>
  //       <Col md={24}>
  //         <Form.Item
  //           label={t("Choices")}
  //           htmlFor={`${id}-new-field`}
  //           className="font-bold"
  //         >
  //           {hasNewField ? "Yes" : hasNewFieldFunc()}
  //           <Button
  //             className="rounded-3xl  border border-solid border-project-base-text bg-project-base text-project-base-text px-5 py-3 mt-4 font-medium h-auto"
  //             onClick={showDrawer}
  //           >
  //             Create New
  //           </Button>
  //         </Form.Item>
  //       </Col>
  //       <Col md={12}>
  //         <Form.Item
  //           label={t("Allow Other")}
  //           htmlFor={`${id}-allow-other`}
  //           className="font-bold"
  //           name={`allow-other`}
  //           valuePropName=""
  //         >
  //           <InvertedCheckBox id={`${id}-allow-other`}>
  //             Allow Other Values
  //           </InvertedCheckBox>
  //         </Form.Item>
  //       </Col>
  //       <Col md={12}>
  //         <Form.Item
  //           label={t("Allow None")}
  //           htmlFor={`${id}-allow-none`}
  //           className="font-bold"
  //           name={`allow-none`}
  //           valuePropName=""
  //         >
  //           <InvertedCheckBox id={`${id}-allow-none`}>
  //             Allow No Selection
  //           </InvertedCheckBox>
  //         </Form.Item>
  //       </Col>
  //       <Col md={12}>
  //         <Form.Item
  //           label={t("Icon")}
  //           htmlFor={`${id}-left`}
  //           name={`left`}
  //           className="font-bold"
  //         >
  //           <Select
  //             size="large"
  //             className="font-normal"
  //             placeholder="Search for icon"
  //             style={{ flex: 1, height: "56px" }}
  //             id={`${id}-left`}
  //             options={[
  //               { value: "String", label: "String" },
  //               { value: "ObjectId", label: "ObjectId" },
  //               { value: "Boolean", label: "Boolean" },
  //               { value: "Number", label: "Number" },
  //             ]}
  //           />
  //         </Form.Item>
  //       </Col>
  //       <Col md={12}>
  //         <Form.Item
  //           label={t("Placeholder")}
  //           className="font-bold"
  //           htmlFor={`${id}-placeholder`}
  //           name={`placeholder`}
  //         >
  //           <Input
  //             placeholder={t("Enter a placeholder")}
  //             id={`${id}-placeholder`}
  //             className="project-custom-input"
  //             suffix={<IoLanguage size={20} color="gray" />}
  //           />
  //         </Form.Item>
  //       </Col>
  //     </Row>
  //   );
  // };

  // const RadioCollapse = () => {
  //   return (
  //     <Row gutter={16}>
  //       <Col md={24}>
  //         <Form.Item
  //           label={t("Choices")}
  //           htmlFor={`${id}-new-field`}
  //           className="font-bold"
  //         >
  //           {hasNewField ? "Yes" : hasNewFieldFunc()}
  //           <Button
  //             className="rounded-3xl  border border-solid border-project-base-text bg-project-base text-project-base-text px-5 py-3 mt-4 font-medium h-auto"
  //             onClick={showDrawer}
  //           >
  //             Create New
  //           </Button>
  //         </Form.Item>
  //       </Col>
  //     </Row>
  //   );
  // };

  // const SliderCollapse = () => {
  //   return (
  //     <Row gutter={16}>
  //       <Col md={12}>
  //         <Form.Item
  //           label={t("Minimum Value")}
  //           htmlFor={`${id}-minimum`}
  //           className="font-bold"
  //           name={`minimum`}
  //         >
  //           <InputNumber
  //             placeholder={0}
  //             id={`${id}-minimum`}
  //             min={1}
  //             max={10}
  //             className="project-custom-input"
  //           />
  //         </Form.Item>
  //       </Col>
  //       <Col md={12}>
  //         <Form.Item
  //           label={t("Maximum Value")}
  //           htmlFor={`${id}-maximum`}
  //           className="font-bold"
  //           name={`maximum`}
  //         >
  //           <InputNumber
  //             placeholder={0}
  //             id={`${id}-maximum`}
  //             min={1}
  //             max={10}
  //             className="project-custom-input"
  //           />
  //         </Form.Item>
  //       </Col>
  //       <Col md={12}>
  //         <Form.Item
  //           label={t("Step Interval")}
  //           htmlFor={`${id}-interval`}
  //           className="font-bold"
  //           name={`interval`}
  //         >
  //           <InputNumber
  //             placeholder={1}
  //             id={`${id}-interval`}
  //             min={1}
  //             max={10}
  //             className="project-custom-input"
  //           />
  //         </Form.Item>
  //       </Col>
  //       <Col md={12}>
  //         <Form.Item
  //           label={t("Always show value")}
  //           htmlFor={`${id}-show-value`}
  //           className="font-bold"
  //           name={`show-value`}
  //           valuePropName=""
  //         >
  //           <InvertedCheckBox id={`${id}-show-value`}>Enabled</InvertedCheckBox>
  //         </Form.Item>
  //       </Col>
  //     </Row>
  //   );
  // };

  const onDragEnd = (event) => {
    const { active, over } = event;
    if (active && over) {
      if (active.id !== over?.id) {
        setChoices((items) => {
          const oldIndex = items.findIndex((item) => item.value === active.id);
          const newIndex = items.findIndex((item) => item.value === over.id);
          return arrayMove(items, oldIndex, newIndex).map((item, i) => {
            return { ...item, sort: i + 1 };
          });
        });
      }
      setHighlightedItem({});
    }
  };

  const handleDisplayTemplateChange = (value, fromSelect = false) => {
    setDisplayTemplate((prev) => (fromSelect ? `${prev}{{${value}}}` : value));
  };

  const onContentBlur = useCallback((event) => {
    const contentEditable = event.currentTarget;
    let updatedTemplate = "";
    contentEditable.childNodes.forEach((node) => {
      if (node.nodeName === "BUTTON") {
        updatedTemplate += `{{${node.textContent}}}`;
      } else if (node.nodeType === Node.TEXT_NODE) {
        updatedTemplate += node.textContent.trim();
      } else if (node.nodeName === "SPAN") {
        updatedTemplate += node.textContent.trim();
      } else if (node.nodeName === "#text") {
        updatedTemplate += <span>{node.textContent.trim()}</span>;
      } else {
        updatedTemplate += node.textContent.trim();
      }
    });

    handleDisplayTemplateChange(updatedTemplate);
  }, []);

  const handleButtonClick = (placeholder) => {
    const updatedTemplate = displayTemplate.replace(`{{${placeholder}}}`, "");
    setDisplayTemplate(updatedTemplate);
  };

  const renderDisplayTemplate = (template = "") => {
    const regex = /{{(.*?)}}/g;
    const parts = template.split(regex);
    return parts.map((part, index) => {
      if (part !== "" && foreignFields?.some((op) => op?.path === part)) {
        return (
          <button
            key={index}
            contentEditable={false}
            onClick={() => handleButtonClick(part)}
            className="text-project-base bg-project-base/10 text-sm font-normal mx-1 rounded-md px-2 py-1 leading-5"
          >
            {part}
          </button>
        );
      }
      return (
        <span
          key={index}
          className="text-sm font-normal text-[var(--input-field-text-clr)] h-[56px] block leading-14"
        >
          {part}
        </span>
      );
    });
  };

  return (
    <Flex wrap="wrap" align="center" justify="space-between">
      <LeftSideTitlePart
        pageTitle={
          capitalizeAndFormat(activeItem?.meta?.interface) +
          " " +
          capitalizeAndFormat(activeItem?.type) +
          " (" +
          capitalizeAndFormat(dataSource?.collection_name) +
          ")"
        }
        pageIcon={true}
        pageInnerIcon={<IconsAll.ContentIcon />}
      />
      <RightSIdeTitlePart
        showButton={true}
        handleShowClick={handleSubmit}
        showDisabled={loader}
      />
      {loader ? (
        <Spin
          spinning={loader}
          fullscreen
          indicator={<div className="custom-loader" />}
        />
      ) : (
        <FormStyling
          form={form}
          layout="vertical"
          className="mt-4"
          style={{ flexBasis: "100%" }}
          initialValues={{
            type: "String",
            icon_left: "Icon 1",
            icon_right: "Icon 1",
          }}
        >
          <Row gutter={28}>
            {showChoices && (
              <Col className="gutter-row" xs={24}>
                <Form.Item
                  label={t("choices")}
                  htmlFor={`${id}-choices`}
                  className="font-bold"
                >
                  {!choices?.length &&
                    CustomInfoText(
                      <BsInfoCircle size={20} />,
                      <p className="font-normal">{t("add_a_new_choice")}</p>,
                    )}
                  <Button
                    className="project-custom-btn border-solid border-grey-full bg-grey-full text-black-base mt-4"
                    onClick={() => setOpen(true)}
                  >
                    {t("create_new")}
                  </Button>
                </Form.Item>
                {choices?.length > 0 && (
                  <DndContext
                    sensors={sensors}
                    collisionDetection={rectIntersection}
                    onDragStart={({ active }) =>
                      setActiveSortItem(
                        choices?.find((rl) => rl?.value === active.id),
                      )
                    }
                    onDragEnd={onDragEnd}
                    onDragOver={({ over }) => {
                      setHighlightedItem(over?.id);
                    }}
                  >
                    <SortableContext
                      items={choices.map((item) => item.value)}
                      strategy={verticalListSortingStrategy}
                    >
                      {choices?.map((rl, i) => (
                        <ItemSort
                          key={rl?.value}
                          data={rl}
                          index={i}
                          highlightedItem={highlightedItem}
                          setChoices={setChoices}
                          setActiveChoice={setActiveChoice}
                          setOpen={setOpen}
                        />
                      ))}
                    </SortableContext>
                    <DragOverlay>
                      {activeSortItem ? (
                        <div
                          className={`flex justify-between border border-blue-300 p-3 my-2 rounded-lg items-center`}
                        >
                          <ul className="flex gap-4 flex-1 items-center">
                            <li className="cursor-pointer list-none">
                              <FaGripLines color="gray" size={24} />
                            </li>
                            <li className="font-normal">
                              {activeSortItem?.label}
                            </li>
                          </ul>
                        </div>
                      ) : null}
                    </DragOverlay>
                  </DndContext>
                )}
              </Col>
            )}

            <Col className="gutter-row" xs={24} md={24}>
              <Form.Item
                label={t("placeholder")}
                htmlFor={`${id}-placeholder`}
                className="font-bold"
                name={`placeholder`}
              >
                <InputWithTranslations
                  form={form}
                  selectedTranslation={selectedTranslation}
                  setIsTranslationDrawerOpen={setIsTranslationDrawerOpen}
                  setSelectedTranslation={setSelectedTranslation}
                  translationsList={translationsList}
                />
              </Form.Item>
            </Col>

            {activeItem?.meta?.interface === "autocomplete" && (
              <>
                <Col key={`url`} className="gutter-row" xs={24} md={24}>
                  <Form.Item
                    label={t("url")}
                    htmlFor={`${id}-url`}
                    className="font-bold"
                    name={"url"}
                  >
                    <Input
                      placeholder={`"https://example.com/search?q={{value}}"`}
                      id={`${id}-url`}
                      className="project-custom-input"
                    />
                  </Form.Item>
                </Col>

                <Col key={`results_path`} className="gutter-row" xs={24}>
                  <Form.Item
                    label={t("results_path")}
                    htmlFor={`${id}-resultsPath`}
                    className="font-bold"
                    name={`results_path`}
                  >
                    <Input
                      placeholder={t("results_path")}
                      id={`${id}-resultsPath`}
                      className="project-custom-input"
                    />
                  </Form.Item>
                </Col>

                <Col key={`text_path`} className="gutter-row" xs={24} md={12}>
                  <Form.Item
                    label={t("text_path")}
                    htmlFor={`${id}-textPath`}
                    className="font-bold"
                    name={`text_path`}
                  >
                    <Input
                      placeholder={t("text_path")}
                      id={`${id}-textPath`}
                      className="project-custom-input"
                    />
                  </Form.Item>
                </Col>

                <Col key={`value_path`} className="gutter-row" xs={24} md={12}>
                  <Form.Item
                    label={t("value_path")}
                    htmlFor={`${id}-ValuePath`}
                    className="font-bold"
                    name={`value_path`}
                  >
                    <Input
                      placeholder={t("value_path")}
                      id={`${id}-ValuePath`}
                      className="project-custom-input"
                    />
                  </Form.Item>
                </Col>

                <Col key={`trigger`} className="gutter-row" xs={24} md={12}>
                  <Form.Item
                    label={t("trigger")}
                    htmlFor={`${id}-trigger`}
                    className="font-bold"
                    name={`trigger`}
                  >
                    <Select
                      placeholder={t("trigger")}
                      size="large"
                      style={{ flex: 1, height: "56px" }}
                      id={`${id}-trigger`}
                      options={[
                        {
                          value: "Throttle",
                          label: "Throttle",
                        },
                        {
                          value: "Debounce",
                          label: "Debounce",
                        },
                      ]}
                    />
                  </Form.Item>
                </Col>

                <Col key={`rate`} className="gutter-row" xs={24} md={12}>
                  <Form.Item
                    label={t("rate")}
                    htmlFor={`${id}-rate`}
                    className="font-bold"
                    name={`rate`}
                  >
                    <InputNumber
                      placeholder={t("rate")}
                      id={`${id}-rate`}
                      min={1}
                      max={1000}
                      className="project-custom-input"
                    />
                  </Form.Item>
                </Col>
              </>
            )}

            {showDisplayTemplate && (
              <Col
                key={"display_template"}
                className="gutter-row"
                xs={24}
                md={24}
              >
                <Form.Item
                  label={t("display_template")}
                  className="font-bold relative"
                  htmlFor={`${id}-display-template`}
                  name={"display_template"}
                >
                  <div className="display-template-input | ant-input ant-input-outlined">
                    <div
                      contentEditable
                      onBlur={onContentBlur}
                      dangerouslySetInnerHTML={{
                        __html: renderDisplayTemplate(displayTemplate).reduce(
                          (html, element) => {
                            return (
                              html +
                              (typeof element === "string"
                                ? element
                                : ReactDOMServer.renderToStaticMarkup(element))
                            );
                          },
                          "",
                        ),
                      }}
                      className="focus-visible:border-project-base! focus-visible:outline-0! whitespace-nowrap overflow-auto custom-scrollbar flex items-center content-editable"
                    ></div>
                  </div>
                  <TreeSelect
                    popupClassName="display-template-tree-select w-620 custom-tree-select"
                    suffixIcon={
                      <GoPlus size={24} color={"var(--input-field-text-clr)"} />
                    }
                    showSearch
                    treeData={convertToTreeData(foreignFields, "tree-select")}
                    value={null}
                    onChange={(value) =>
                      handleDisplayTemplateChange(value, true)
                    }
                  />
                </Form.Item>
              </Col>
            )}
          </Row>
          {/* <Collapse
        className="edit-field-collapse mb-4"
        collapsible="header"
        defaultActiveKey={["1"]}
        items={[
          {
            key: "1",
            showArrow: false,
            label: (
              <div className="flex flex-wrap flex-row gap-3 items-center">
                <div className="w-8 h-8 flex items-center justify-center rounded-full bg-white">
                  <FiType />
                </div>
                <div className="grow">
                  <div>Input</div>
                  <small>Manually enter a value</small>
                </div>
              </div>
            ),
            children: <InputCollapse />,
          },
        ]}
      />
      <Collapse
        className="edit-field-collapse mb-4"
        collapsible="header"
        defaultActiveKey={["1"]}
        items={[
          {
            key: "2",
            showArrow: false,
            label: (
              <div className="flex flex-wrap flex-row gap-3 items-center">
                <div className="w-8 h-8 flex items-center justify-center rounded-full bg-white">
                  <IoIosArrowDropdown />
                </div>
                <div className="grow">
                  <div>Dropdown</div>
                  <small>Select a value from a dropdown</small>
                </div>
              </div>
            ),
            children: <DropdownCollapse />,
          },
        ]}
      />
      <Collapse
        className="edit-field-collapse mb-4"
        collapsible="header"
        defaultActiveKey={["1"]}
        items={[
          {
            key: "3",
            showArrow: false,
            label: (
              <div className="flex flex-wrap flex-row gap-3 items-center">
                <div className="w-8 h-8 flex items-center justify-center rounded-full bg-white">
                  <IoMdRadioButtonOn />
                </div>
                <div className="grow">
                  <div>Radio Buttons</div>
                  <small>Select one of multiple choices</small>
                </div>
              </div>
            ),
            children: <RadioCollapse />,
          },
        ]}
      />
      <Collapse
        className="edit-field-collapse mb-4"
        collapsible="header"
        defaultActiveKey={["1"]}
        items={[
          {
            key: "4",
            showArrow: false,
            label: (
              <div className="flex flex-wrap flex-row gap-3 items-center">
                <div className="w-8 h-8 flex items-center justify-center rounded-full bg-white">
                  <RxSlider />
                </div>
                <div className="grow">
                  <div>Slider</div>
                  <small>Select a number using a slider</small>
                </div>
              </div>
            ),
            children: <SliderCollapse />,
          },
        ]}
      /> */}
        </FormStyling>
      )}
      <DrawerStyle
        width={870}
        open={isTranslationDrawerOpen}
        closeIcon={<IconsAll.CloseIconCustom />}
        onClose={() => setIsTranslationDrawerOpen(false)}
        destroyOnClose
      >
        <AddVertalingen
          setIsTranslationDrawerOpen={setIsTranslationDrawerOpen}
          type={"drawer"}
          getTranslations={getTranslations}
        />
      </DrawerStyle>
      {showChoices && (
        <DrawerStyle
          width={870}
          destroyOnClose
          open={open}
          onClose={() => {
            setOpen(false);
            setActiveChoice(null);
          }}
        >
          <CreateChoices
            setChoices={setChoices}
            setOpen={setOpen}
            choiceType={activeItem?.meta?.interface}
            activeChoice={activeChoice}
          />
        </DrawerStyle>
      )}
    </Flex>
  );
};

export default UpdateInterface;
