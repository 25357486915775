import { AutoComplete } from "antd";
import axios from "axios";
import React, { useEffect, useState, useCallback } from "react";
import debounce from "lodash/debounce";
import throttle from "lodash/throttle";
import { getTranslatedLabel } from "../../../Helpers/utils";

const getValue = (obj, key, separator = ".") => {
  if (!key) return undefined;
  const fieldPathParts = key.replace(/\[(\d+)\]/g, ".$1").split(separator);
  return fieldPathParts.reduce((acc, part) => acc && acc[part], obj);
};

const CustomAutocomplete = ({
  item,
  updateFormData,
  formData,
  isValidationError,
}) => {
  const [options, setOptions] = useState([]);
  const [, setLoading] = useState(false);
  const [value, setValue] = useState(formData?.[item?.path]);
  const [, setSelected] = useState(false);

  useEffect(() => {
    if (formData?.[item.path] === undefined) {
      updateFormData(item.schema_definition?.default);
      if (item?.schema_definition?.default) {
        setValue(item?.schema_definition?.default);
      }
    }
  }, [formData?.[item.path]]);

  const fetchData = async (query) => {
    setLoading(true);
    try {
      let url = query
        ? item?.meta?.options?.url?.replace("{{value}}", query)
        : item?.meta?.options?.url?.split("?")?.[0];
      if (url) {
        const response = await axios.get(url);
        const data = item?.meta?.options?.results_path
          ? getValue(response?.data, item?.meta?.options?.results_path)
          : response?.data;

        const optionsData = data?.map((d) => {
          return {
            label: item?.meta?.options?.text_path
              ? d[item?.meta?.options?.text_path]
              : d.label,
            value: item?.meta?.options?.value_path
              ? d[item?.meta?.options?.value_path]
              : d.value,
          };
        });
        setOptions(optionsData);
      } else {
        setOptions([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const onSelect = (value) => {
    setSelected(true);
    updateFormData(value);
    setValue(value);
  };

  const createDebouncedOrThrottledFunction = (func, delay, type) => {
    return type === "debounce" ? debounce(func, delay) : throttle(func, delay);
  };

  const debouncedOrThrottledFetch = useCallback(
    createDebouncedOrThrottledFunction(
      fetchData,
      item?.meta?.options?.rate || 800,
      item?.meta?.options?.trigger || "debounce",
    ),
    [item],
  );

  useEffect(() => {
    if (value) {
      setSelected(false);
      debouncedOrThrottledFetch(value);
    } else {
      fetchData("");
    }
  }, [value, debouncedOrThrottledFetch]);

  return (
    <AutoComplete
      placeholder={getTranslatedLabel(item?.meta?.options?.placeholder)}
      value={value}
      options={options}
      onSelect={onSelect}
      defaultValue={item?.schema_definition?.default}
      onSearch={(text) => setValue(text)}
      className={`font-normal ${isValidationError ? "error" : ""}`}
      disabled={!!item?.meta?.readonly}
      // style={{border: "1px solid red", borderRadius: "10px"}}
    />
  );
};

export default CustomAutocomplete;
