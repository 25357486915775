import { CollapsWithLine } from "../../../Common/CommonUiStyles";
import React from "react";
import { useTranslation } from "react-i18next";
import { Settings } from "lucide-react";

export const SystemDefaultCollection = ({ dataSource }) => {
  const { t } = useTranslation();
  return (
    <CollapsWithLine
      ghost
      items={[
        {
          key: "1",
          label: (
            <span className="collapse-title">{t("system_collections")}</span>
          ),
          children: dataSource.map((d) => {
            return (
              <div
                className="bg-(--body-bg) border border-(--input-field-border) text-heading-text rounded-xl text-base font-mono flex items-center p-4 mb-[0.5rem]"
                key={d.collection_id}
              >
                <Settings className="mr-4" /> {d.collection_name}
              </div>
            );
          }),
        },
      ]}
    />
  );
};
