import React, { useEffect, useId, useState } from "react";
import { FormStyling } from "../../Common/CommonUiStyles";
import {
  Button,
  Col,
  ColorPicker,
  Form,
  Input,
  notification,
  Row,
  Select,
} from "antd";
import IconsAll from "../../Components/IconsAll";
import { useTranslation } from "react-i18next";
import * as LucideIcons from "lucide-react";
import axiosInstance from "../../Helpers/axios";
import Apis from "../../Helpers/Apis";
import { addSchema, updateSchemaById } from "../../Redux/slices/schema.reducer";
import { useDispatch } from "react-redux";

const FolderModal = ({ setFolderModel, dataSource, activeFolder }) => {
  const [form] = Form.useForm();
  const id = useId();
  const { t } = useTranslation();
  // eslint-disable-next-line no-unused-vars
  const { icons, createLucideIcon, ...filteredIcons } = LucideIcons;
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (activeFolder) setFormData();
  }, []);

  const setFormData = () => {
    form.setFieldsValue({
      collection_name: activeFolder?.collection_name,
      icon: activeFolder?.meta?.icon,
      color: activeFolder?.meta?.color,
      note: activeFolder?.meta?.note,
    });
  };

  const handleAddFolder = async () => {
    let isError = false;
    try {
      const values = await form.validateFields(); // Validate form fields
      setLoader(true);

      if (activeFolder) {
        const resp = await axiosInstance.put(
          Apis.UPDATE_SCHEMA(activeFolder?._id),
          {
            meta: {
              note: values.note,
              icon: values.icon,
              color: values.color,
            },
          },
        );

        dispatch(
          updateSchemaById({
            id: activeFolder?._id,
            updatedData: {
              "meta.note": values.note,
              "meta.icon": values.icon,
              "meta.color": values.color,
            },
          }),
        );

        if (resp.status === 200) {
          notification.success({ message: t("folder_update_success") });
        }
      } else {
        const resp = await axiosInstance.post(Apis.ADD_A_SCHEMA(), {
          collection_name: values.collection_name,
          visible: true,
          meta: {
            is_folder: true,
            sort:
              Math.max(
                0,
                ...(dataSource?.map((col) => col?.sort)?.filter(Boolean) || []),
              ) + 1,
            note: values.note,
            icon: values.icon,
            color: values.color,
          },
        });

        dispatch(
          addSchema({ singleObject: true, data: resp.data?.payload?.data }),
        );

        if (resp.status === 200) {
          notification.success({ message: t("folder_add_success") });
        }
      }
      await form.resetFields();
    } catch (error) {
      console.log("Error", error);
      error?.data?.message &&
        notification.error({ message: error?.data?.message });
      isError = true;
      return;
    } finally {
      setLoader(false);
      if (!isError) {
        setFolderModel(false);
      }
    }
  };

  const handleChange = (e) => {
    form.setFieldValue(
      "collection_name",
      e.target.value
        .toLowerCase()
        .replace(/[^a-z0-9_ ]/g, "")
        .replace(/ /g, "_"),
    );
  };

  return (
    <FormStyling
      layout="vertical"
      form={form}
      disabled={loader}
      className="px-0! pt-6! pb-0! mt-3 border-b-0!"
    >
      <Row gutter={16}>
        <Col className="gutter-row" xs={24} md={24}>
          <Form.Item
            label={t("folder")}
            name="collection_name"
            className="font-bold"
            htmlFor={`${id}-collection`}
            rules={[{ required: true }]}
          >
            <Input
              className="project-custom-input"
              id={`${id}-collection`}
              disabled={!!activeFolder}
              onChange={handleChange}
            />
          </Form.Item>
        </Col>
        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("icon")}
            className="font-bold"
            htmlFor={`${id}-icon`}
            name={`icon`}
          >
            <Select
              id={`${id}-icon`}
              options={Object.keys(filteredIcons).map((iconName) => {
                const IconComponent = filteredIcons[iconName];
                return {
                  value: iconName,
                  label: (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      <IconComponent size={16} color="var(--project-base)" />
                      {iconName}
                    </div>
                  ),
                };
              })}
              showSearch
              filterOption={(input, option) =>
                option?.value?.toLowerCase()?.includes(input?.toLowerCase())
              }
              style={{ width: "100%" }}
              placeholder={t("select_icon")}
              allowClear={{
                clearIcon: (
                  <div className="right-4 relative">
                    <IconsAll.CloseIconCustom />
                  </div>
                ),
              }}
              optionLabelProp="label"
            />
          </Form.Item>
        </Col>
        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("color")}
            className="font-bold"
            name={`color`}
            htmlFor={`color`}
          >
            <ColorPicker
              // defaultValue={() => form.getFieldValue("color")}
              id={`color`}
              showText
              className="project-custom-color-picker border-gray-base"
              defaultFormat="hex"
              onChange={(_, hex) => {
                form.setFieldValue("color", hex);
              }}
              allowClear
              onClear={() => {
                form.setFieldValue("color", null);
              }}
            />
          </Form.Item>
        </Col>
        <Col className="gutter-row" xs={24} md={24}>
          <Form.Item
            label={t("note")}
            name="note"
            className="font-bold"
            htmlFor={`${id}-note`}
            rules={[
              {
                max: 50,
              },
            ]}
          >
            <Input
              className="project-custom-input"
              placeholder={t("collection_description")}
              id={`${id}-note`}
            />
          </Form.Item>
        </Col>{" "}
        <Col className="gutter-row flex flex-row-reverse" xs={24} md={24}>
          <Button
            className="project-custom-btn me-2 max-w-28 sm:w-28 bg-project-base"
            onClick={handleAddFolder}
            classNames=""
            //   disabled={loader}
          >
            Save
          </Button>
          <Button
            className="project-custom-btn me-2 max-w-28 sm:w-28 bg-[var(--body-bg)]! text-project-base!"
            onClick={() => setFolderModel(false)}
          >
            Cancel
          </Button>
        </Col>
      </Row>
    </FormStyling>
  );
};

export default FolderModal;
