import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  folders: [],
};

const mediaFoldersSlice = createSlice({
  name: "mediaFolders",
  initialState,
  reducers: {
    setMediaFolders: (state, action) => {
      state.folders = action.payload;
    },
    addMediaFolder: (state, action) => {
      state.folders.push(action.payload);
    },
    renameMediaFolder: (state, action) => {
      const { folderId, title } = action.payload;
      console.log("rename slice", { folderId, title });
      state.folders = state.folders.map((folder) =>
        folder._id === folderId ? { ...folder, name: title } : folder,
      );
    },
    deleteMediaFolder: (state, action) => {
      state.folders = state.folders.filter(
        (folder) => folder?._id !== action.payload,
      );
    },

    moveMediaFolder: (state, action) => {
      const { sourceFolderId, destinationFolderId } = action.payload;
      state.folders = state.folders.map((folder) =>
        folder._id === sourceFolderId
          ? { ...folder, parent: destinationFolderId }
          : folder,
      );
    },
  },
});

export const {
  setMediaFolders,
  addMediaFolder,
  renameMediaFolder,
  deleteMediaFolder,
  moveMediaFolder,
} = mediaFoldersSlice.actions;

export default mediaFoldersSlice.reducer;
