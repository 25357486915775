import { Checkbox } from "antd";
import React, { useEffect } from "react";

const CustomCheckbox = ({
  item,
  updateFormData,
  formData,
  isValidationError,
}) => {
  useEffect(() => {
    if (formData?.[item.path] === undefined) {
      updateFormData(item.schema_definition?.default || []);
    }
  }, [formData?.[item.path]]);

  return (
    <Checkbox.Group
      onChange={(checkedValues) => {
        updateFormData(checkedValues);
      }}
      value={formData?.[item?.path]}
      options={item?.meta?.options?.choices?.sort((a, b) => a?.sort - b?.sort)}
      className={`${isValidationError ? "border-[red]" : ""}`}
      disabled={!!item?.meta?.readonly}
    ></Checkbox.Group>
  );
};

export default CustomCheckbox;
