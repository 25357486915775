import { Button, Input, Modal, notification, Progress } from "antd";
import { Upload } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { FiLink2 } from "react-icons/fi";
import { MdOutlineFileUpload } from "react-icons/md";
import styled from "styled-components";
import axiosInstance from "../../../Helpers/axios";
import Apis from "../../../Helpers/Apis";
import {
  AiOutlineCloseCircle,
  AiOutlineReload,
  AiOutlineFileUnknown,
} from "react-icons/ai";
import { IoImageOutline } from "react-icons/io5";
import { FaRegFileLines, FaRegFilePdf } from "react-icons/fa6";
import { BiMoviePlay } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import EditImage from "./EditImage";

const FileDragIcon = styled(MdOutlineFileUpload)`
  width: 30px;
  height: 30px;
`;

const CustomDragger = ({
  iFace,
  setIsModalOpen,
  updateFilesList,
  folder,
  replaceFileId,
  cropImage,
}) => {
  const { Dragger } = Upload;
  const [isUrlModalOpen, setIsUrlModalOpen] = useState(false);
  const [isCropModalOpen, setIsCropModalOpen] = useState(false);
  const urlRef = useRef("");
  const [files, setFiles] = useState({});
  const { t } = useTranslation();
  const [cropImageDetails, setCropImageDetails] = useState({});

  const handleChange = async (info) => {
    const fileDetails = {
      file: info.file,
      name: info.file.name,
      type: info.file.type,
      uid: info.file.uid,
    };
    try {
      if (!navigator.onLine) {
        return setFiles((pre) => ({
          ...pre,
          [fileDetails.uid]: {
            ...fileDetails,
            status: "exception",
            isProcessed: true,
          },
        }));
      }

      const formData = new FormData();
      formData.append("file", info?.file);
      !replaceFileId && formData.append("folder", folder ? folder : "");
      let resp;

      if (replaceFileId) {
        resp = await axiosInstance.put(
          Apis.UPDATE_FILE(replaceFileId),
          formData,
          {
            onUploadProgress: (progressEvent) => {
              setFiles((pre) => ({
                ...pre,
                [fileDetails.uid]: {
                  ...fileDetails,
                  progress: progressEvent.progress,
                  status: "active",
                  isProcessed: false,
                },
              }));
            },
          },
        );
      } else {
        resp = await axiosInstance.post(Apis.UPLOAD_FILE(), formData, {
          onUploadProgress: (progressEvent) => {
            setFiles((pre) => ({
              ...pre,
              [fileDetails.uid]: {
                ...fileDetails,
                progress: progressEvent.progress,
                status: "active",
                isProcessed: false,
              },
            }));
          },
        });
      }

      if (resp.status === 200) {
        setFiles((pre) => ({
          ...pre,
          [fileDetails.uid]: {
            ...pre[fileDetails.uid],
            ...(resp?.data?.payload?.data || {}),
            status: "success",
            isProcessed: true,
          },
        }));
        notification.success({
          message: t("file_upload_success", { fileName: "File" }),
        });
      }
    } catch (error) {
      console.log("error", error);
      notification.error({
        message: t("file_upload_error", { fileName: "File" }),
      });
      setFiles((pre) => ({
        ...pre,
        [fileDetails.uid]: {
          ...pre[fileDetails.uid],
          status: "exception",
          isProcessed: true,
        },
      }));
    }
  };

  const getFileIcon = (fileType) => {
    switch (fileType) {
      case "image/png":
      case "image/jpeg":
      case "image/jpg":
        return <IoImageOutline size={26} />;
      case "application/pdf":
        return <FaRegFilePdf size={26} />;
      case "text/plain":
        return <FaRegFileLines size={26} />;
      case "video/mp4":
        return <BiMoviePlay size={26} />;
      default:
        return <AiOutlineFileUnknown size={26} />;
    }
  };

  useEffect(() => {
    if (Object.keys(files).length) {
      let allProcessed = true;
      let hasValidFiles = false;
      const validFiles = [];
      const exceptionFiles = {};

      for (const key in files) {
        if (!files[key].isProcessed) {
          allProcessed = false;
          break;
        }
        if (files[key].status !== "exception") {
          hasValidFiles = true;
          validFiles.push(files[key]);
        } else {
          exceptionFiles[key] = files[key];
        }
      }

      if (allProcessed) {
        validFiles.length && !cropImage && updateFilesList(validFiles);
        if (cropImage) {
          setCropImageDetails(validFiles?.[0]);
          setIsCropModalOpen(true);
          setIsModalOpen(false);
          return;
        }

        if (hasValidFiles) {
          if (Object.keys(exceptionFiles).length === 0) {
            setFiles({});
            setIsModalOpen(false);
          } else {
            setFiles(exceptionFiles);
          }
        }
      }
    }
  }, [files]);

  const handleImportUrl = () => {
    setIsUrlModalOpen(false);
    console.log("Url", urlRef.current.input.value);
  };
  return (
    <div>
      <Dragger
        name="file"
        multiple={iFace === "files" || iFace === "files-page"}
        customRequest={handleChange}
        showUploadList={false}
        accept={
          iFace === "file-image"
            ? ".png, .jpg, .jpeg, image/png, image/jpeg"
            : "*"
        }
      >
        <p className="w-[min(100%,_440px)] flex items-center justify-center gap-3 mb-3">
          <FileDragIcon size={24} />
          <Button
            onClick={(e) => {
              e.stopPropagation();
              setIsModalOpen(false), setIsUrlModalOpen(true);
            }}
            className="!w-13 !h-13 !rounded-full"
            icon={<FiLink2 size={24} />}
          />
        </p>
        <p className="w-[min(100%,_440px)] ant-upload-text !text-(--heading-text)">
          {t("drag_&_drop")}
        </p>
        <div className="w-[min(100%,_440px)] max-h-[500px] overflow-auto custom-scrollbar">
          {Object.keys(files).length > 0 &&
            Object.keys(files).map((key, index) => (
              <div
                key={index}
                className="flex items-center gap-3 p-3 mt-4 border border-solid rounded-lg border-[#d4d4d4]"
              >
                <div>{getFileIcon(files[key]?.type)}</div>
                <div key={key} className="mr-2 grow text-start w-[50%]">
                  <p className="text-wrap -mb-1">{files[key]?.name}</p>
                  <Progress
                    percent={
                      files[key]?.status === "success"
                        ? 100
                        : Math.floor(files[key]?.progress * 100)
                    }
                    status={files[key]?.status}
                    size="small"
                    showInfo={files[key]?.status !== "exception"}
                  />
                </div>
                {files[key]?.isProcessed && (
                  <Button
                    className="mr-2"
                    icon={<AiOutlineCloseCircle className="!text-delete-base" />}
                    type="text"
                    onClick={(e) => {
                      e.stopPropagation();
                      setFiles((prev) => {
                        const updatedFiles = { ...prev };
                        delete updatedFiles[key];
                        return updatedFiles;
                      });
                    }}
                  />
                )}
                <Button
                  shape="circle"
                  size="small"
                  color="danger"
                  icon={<AiOutlineReload size={16} />}
                  disabled={!files[key]?.isProcessed}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleChange({ file: files[key]?.file });
                  }}
                />
              </div>
            ))}
        </div>
      </Dragger>

      <Modal
        title={t("import_file_from_url")}
        open={isUrlModalOpen}
        onOk={handleImportUrl}
        okText={t("ok")}
        cancelText={t("cancel")}
        okButtonProps={{ className: "modal-primary-btn" }}
        cancelButtonProps={{ className: "modal-default-btn" }}
        onCancel={() => setIsUrlModalOpen(false)}
        destroyOnClose
      >
        <p className="mb-3">
          <Input
            ref={urlRef}
            placeholder={t("url")}
            className="project-custom-input"
          />
        </p>
      </Modal>
      <Modal
        open={isCropModalOpen}
        footer={null}
        onOk={() => setIsCropModalOpen(false)}
        onCancel={() => {
          setIsCropModalOpen(false), setFiles({});
        }}
        destroyOnClose={cropImage}
        maskClosable={false}
      >
        <EditImage
          fileDetails={cropImageDetails}
          setFileDetails={setFiles}
          setCropImageDetails={setCropImageDetails}
          setIsImageEditOpen={setIsCropModalOpen}
          updateFilesList={updateFilesList}
          cropImage
        />
      </Modal>
    </div>
  );
};

export default CustomDragger;
