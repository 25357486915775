import { Col, Form } from "antd";
import React, { useEffect } from "react";
import CustomInput from "./Components/CustomInput";
import CustomColorInput from "./Components/CustomColorInput";
import CustomAutocomplete from "./Components/CustomAutocomplete";
import CustomTextArea from "./Components/CustomTextArea";
import CustomTags from "./Components/CustomTags";
import CustomToggle from "./Components/CustomToggle";
import CustomDatetime from "./Components/CustomDatetime";
import CustomDropdown from "./Components/CustomDropdown";
import CustomCheckbox from "./Components/CustomCheckbox";
import CustomDropdownMultiple from "./Components/CustomDropdownMultiple";
import CustomRadio from "./Components/CustomRadio";
import CustomReactQuill from "./Components/CustomReactQuill";
import CustomRelationalField from "./Components/CustomRelationalField";
import {
  capitalizeAndFormat,
  flattenObject,
  getTranslatedLabel,
} from "../../Helpers/utils";
import ValidationError from "./ValidationError";
import CustomFiles from "./Components/CustomFiles";
import CustomItems from "./Components/CustomItems";
import CustomManyToAny from "./Components/CustomManyToAny";
import CustomJson from "./Components/CustomJson";
import CustomTranslations from "./Components/CustomTranslations";
import { unflatten } from "flat";
import { useSelector } from "react-redux";

const FormGenerator = ({
  item = {},
  formData,
  setFormData,
  validationErrors,
  language,
  isFromItems,
}) => {
  const currentLng = useSelector((state) => state?.user?.userProfile?.language);
  const fieldName =
    item?.meta?.translations?.find((t) => t?.language === currentLng)
      ?.translation || capitalizeAndFormat(item?.field);
  const fieldInterface = item.meta.interface;
  const width = item.meta.width === "half" ? 12 : 24;

  const finalFormData =
    language && !isFromItems
      ? flattenObject(
          unflatten(formData)?.[language?.field_path]?.[
            language?.selectedLanguage
          ] || {},
        )
      : formData;

  const translatedValidationMsg = getTranslatedLabel(
    item?.meta?.validations?.validation_msg,
  );

  const updateFormData = (value) => {
    setFormData((prev) => {
      if (language && !isFromItems) {
        const upadtedFormData = flattenObject(
          prev?.[language?.field_path]?.[language?.selectedLanguage] || {},
        );
        upadtedFormData[item?.path] = value;
        const updatedData = {
          ...prev,
          [language?.field_path]: {
            ...prev?.[language?.field_path],
            [language?.selectedLanguage]: unflatten(upadtedFormData),
          },
        };
        return updatedData;
      } else {
        return { ...prev, [item?.path]: value };
      }
    });
  };

  const translatedNote = getTranslatedLabel(item?.meta?.note);
  const isValidationError =
    !!validationErrors[
      language && !isFromItems
        ? `${language?.field_path}.${language?.selectedLanguage}.${item?.path}`
        : item?.path
    ];
  const help = (
    <>
      {item?.meta?.note && (
        <span className="block text-[12px] font-normal text-(--input-field-text-clr) mb-3 mt-2">
          {translatedNote}
        </span>
      )}
      {validationErrors[
        language && !isFromItems
          ? `${language?.field_path}.${language?.selectedLanguage}.${item?.path}`
          : item?.path
      ] && (
        <ValidationError
          error={{
            ...validationErrors[
              language && !isFromItems
                ? `${language?.field_path}.${language?.selectedLanguage}.${item?.path}`
                : item?.path
            ],
            custom_msg: translatedValidationMsg,
          }}
        />
      )}
    </>
  );

  useEffect(() => {
    if (language && !isFromItems) {
      if (finalFormData?.[item?.path]) {
        setFormData((prev) => {
          return {
            ...prev,
            [language?.field_path]: {
              ...prev?.[language?.field_path],
              [language?.selectedLanguage]: {
                ...prev?.[language?.field_path]?.[language?.selectedLanguage],
                [item?.path]: finalFormData?.[item?.path],
              },
            },
          };
        });
      }
    }
  }, []);

  switch (fieldInterface) {
    case "input":
      return (
        <Col key={item._id} md={width}>
          <Form.Item
            label={fieldName}
            className="font-bold"
            name={item.meta.field}
            htmlFor={item._id}
            rules={[
              {
                required: !!item.meta?.required,
              },
            ]}
            help={help}
          >
            <CustomInput
              item={item}
              formData={finalFormData}
              updateFormData={updateFormData}
              isValidationError={isValidationError}
            />
          </Form.Item>
        </Col>
      );
    case "autocomplete":
      return (
        <Col key={item._id} md={width}>
          <Form.Item
            label={fieldName}
            className="font-bold"
            name={item.meta.field}
            htmlFor={item._id}
            rules={[
              {
                required: !!item.meta?.required,
              },
            ]}
            help={help}
          >
            <CustomAutocomplete
              item={item}
              formData={finalFormData}
              updateFormData={updateFormData}
              isValidationError={isValidationError}
            />
          </Form.Item>
        </Col>
      );
    case "textarea":
      return (
        <Col key={item._id} md={width}>
          <Form.Item
            label={fieldName}
            className="font-bold"
            name={item.meta.field}
            htmlFor={item._id}
            rules={[
              {
                required: !!item.meta?.required,
              },
            ]}
            help={help}
          >
            <CustomTextArea
              item={item}
              formData={finalFormData}
              updateFormData={updateFormData}
              isValidationError={isValidationError}
            />
          </Form.Item>
        </Col>
      );
    case "wysiwyg":
      return (
        <Col key={item._id} md={width}>
          <Form.Item
            label={fieldName}
            className="font-bold"
            name={item.meta.field}
            htmlFor={item._id}
            rules={[
              {
                required: !!item.meta?.required,
              },
            ]}
            help={help}
          >
            <CustomReactQuill
              item={item}
              formData={finalFormData}
              updateFormData={updateFormData}
              isValidationError={isValidationError}
            />
          </Form.Item>
        </Col>
      );
    case "tags":
      return (
        <Col key={item._id} md={width}>
          <Form.Item
            label={fieldName}
            className="font-bold"
            name={item.meta.field}
            htmlFor={item._id}
            rules={[
              {
                required: !!item.meta?.required,
              },
            ]}
            help={help}
          >
            <CustomTags
              item={item}
              formData={finalFormData}
              updateFormData={updateFormData}
              isValidationError={isValidationError}
              language={language}
            />
          </Form.Item>
        </Col>
      );
    case "json":
      return (
        <Col key={item._id} md={width}>
          <Form.Item
            label={fieldName}
            className="font-bold"
            name={item.meta.field}
            htmlFor={item._id}
            rules={[
              {
                required: !!item.meta?.required,
              },
            ]}
            help={help}
          >
            <CustomJson
              item={item}
              formData={finalFormData}
              updateFormData={updateFormData}
              isValidationError={isValidationError}
              language={language}
            />
          </Form.Item>
        </Col>
      );
    case "toggle":
      return (
        <Col key={item._id} md={width}>
          <Form.Item
            label={fieldName}
            className="font-bold"
            name={item.meta.field}
            htmlFor={item._id}
            rules={[
              {
                required: !!item.meta?.required,
              },
            ]}
            help={help}
          >
            <CustomToggle
              item={item}
              formData={finalFormData}
              updateFormData={updateFormData}
              isValidationError={isValidationError}
            />
          </Form.Item>
        </Col>
      );
    case "datetime":
      return (
        <Col key={item._id} md={width}>
          <Form.Item
            label={fieldName}
            className="font-bold"
            name={item.meta.field}
            htmlFor={item._id}
            rules={[
              {
                required: !!item.meta?.required,
              },
            ]}
            help={help}
          >
            <CustomDatetime
              item={item}
              formData={finalFormData}
              updateFormData={updateFormData}
              isValidationError={isValidationError}
            />
          </Form.Item>
        </Col>
      );
    case "dropdown":
      return (
        <Col key={item._id} md={width}>
          <Form.Item
            label={fieldName}
            className="font-bold"
            name={item.meta.field}
            htmlFor={item._id}
            rules={[
              {
                required: !!item.meta?.required,
              },
            ]}
            help={help}
          >
            <CustomDropdown
              item={item}
              formData={finalFormData}
              updateFormData={updateFormData}
              isValidationError={isValidationError}
            />
          </Form.Item>
        </Col>
      );
    case "checkboxes":
      return (
        <Col key={item._id} md={width}>
          <Form.Item
            label={fieldName}
            className="font-bold"
            name={item.meta.field}
            htmlFor={item._id}
            rules={[
              {
                required: !!item.meta?.required,
              },
            ]}
            help={help}
          >
            <CustomCheckbox
              item={item}
              formData={finalFormData}
              updateFormData={updateFormData}
              isValidationError={isValidationError}
            />
          </Form.Item>
        </Col>
      );
    case "dropdown_multiple":
      return (
        <Col key={item._id} md={width}>
          <Form.Item
            label={fieldName}
            className="font-bold"
            name={item.meta.field}
            htmlFor={item._id}
            rules={[
              {
                required: !!item.meta?.required,
              },
            ]}
            help={help}
          >
            <CustomDropdownMultiple
              item={item}
              formData={finalFormData}
              updateFormData={updateFormData}
              isValidationError={isValidationError}
            />
          </Form.Item>
        </Col>
      );
    case "radio":
      return (
        <Col key={item._id} md={width}>
          <Form.Item
            label={fieldName}
            className="font-bold"
            name={item.meta.field}
            htmlFor={item._id}
            rules={[
              {
                required: !!item.meta?.required,
              },
            ]}
            help={help}
          >
            <CustomRadio
              item={item}
              formData={finalFormData}
              updateFormData={updateFormData}
              isValidationError={isValidationError}
            />
          </Form.Item>
        </Col>
      );
    case "file":
      return (
        <Col key={item._id} md={width}>
          <Form.Item
            label={fieldName}
            className="font-bold"
            name={item.meta.field}
            htmlFor={item._id}
            rules={[
              {
                required: !!item.meta?.required,
              },
            ]}
            help={help}
          >
            <CustomFiles
              item={item}
              formData={finalFormData}
              updateFormData={updateFormData}
              isValidationError={isValidationError}
              language={language}
            />
          </Form.Item>
        </Col>
      );
    case "file-image":
      return (
        <Col key={item._id} md={width}>
          <Form.Item
            label={fieldName}
            className="font-bold"
            name={item.meta.field}
            htmlFor={item._id}
            rules={[
              {
                required: !!item.meta?.required,
              },
            ]}
            help={help}
          >
            <CustomFiles
              item={item}
              formData={finalFormData}
              updateFormData={updateFormData}
              isValidationError={isValidationError}
              language={language}
            />
          </Form.Item>
        </Col>
      );
    case "files":
      return (
        <Col key={item._id} md={width}>
          <Form.Item
            label={fieldName}
            className="font-bold"
            name={item.meta.field}
            htmlFor={item._id}
            rules={[
              {
                required: !!item.meta?.required,
              },
            ]}
            help={help}
          >
            <CustomFiles
              item={item}
              formData={finalFormData}
              updateFormData={updateFormData}
              isValidationError={isValidationError}
              language={language}
            />
          </Form.Item>
        </Col>
      );
    case "list-m2m":
      return (
        <Col key={item._id} md={width}>
          <Form.Item
            label={fieldName}
            className="font-bold"
            name={item.meta.field}
            htmlFor={item._id}
            rules={[
              {
                required: !!item.meta?.required,
              },
            ]}
            help={help}
          >
            <CustomRelationalField
              item={item}
              formData={finalFormData}
              updateFormData={updateFormData}
              isValidationError={isValidationError}
              language={language}
            />
          </Form.Item>
        </Col>
      );
    case "list-m2a":
      return (
        <Col key={item._id} md={width}>
          <Form.Item
            label={fieldName}
            className="font-bold"
            name={item.meta.field}
            htmlFor={item._id}
            rules={[
              {
                required: !!item.meta?.required,
              },
            ]}
            help={help}
          >
            <CustomManyToAny
              item={item}
              formData={finalFormData}
              updateFormData={updateFormData}
              isValidationError={isValidationError}
              language={language}
            />
          </Form.Item>
        </Col>
      );
    case "list-o2m":
      return (
        <Col key={item._id} md={width}>
          <Form.Item
            label={fieldName}
            className="font-bold"
            name={item.meta.field}
            htmlFor={item._id}
            rules={[
              {
                required: !!item.meta?.required,
              },
            ]}
            help={help}
          >
            <CustomRelationalField
              item={item}
              formData={finalFormData}
              updateFormData={updateFormData}
              isValidationError={isValidationError}
              language={language}
            />
          </Form.Item>
        </Col>
      );
    case "list-m2o":
      return (
        <Col key={item._id} md={width}>
          <Form.Item
            label={fieldName}
            className="font-bold"
            name={item.meta.field}
            htmlFor={item._id}
            rules={[
              {
                required: !!item.meta?.required,
              },
            ]}
            help={help}
          >
            <CustomRelationalField
              item={item}
              formData={finalFormData}
              updateFormData={updateFormData}
              isValidationError={isValidationError}
              language={language}
            />
          </Form.Item>
        </Col>
      );
    case "translations":
      return (
        <Col key={item._id} md={width}>
          <Form.Item
            label={fieldName}
            className="font-bold"
            name={item.meta.field}
            htmlFor={item._id}
            rules={[
              {
                required: !!item.meta?.required,
              },
            ]}
            help={help}
          >
            <CustomTranslations
              item={item}
              formData={finalFormData}
              updateFormData={updateFormData}
              isValidationError={isValidationError}
              language={language}
              setFormData={setFormData}
              validationErrors={validationErrors}
            />
          </Form.Item>
        </Col>
      );
    case "object":
      return (
        <Col key={item._id} md={width}>
          <Form.Item
            // label={fieldName}
            className="font-bold border-b border-[var(--input-field-border)]"
            name={item.meta.field}
            htmlFor={item._id}
            rules={[
              {
                required: !!item.meta?.required,
              },
            ]}
            help={help}
          >
            <span className="!text-xl">{fieldName}</span>
          </Form.Item>
        </Col>
      );
    case "items":
      return (
        <Col key={item._id} md={width}>
          <Form.Item
            // label={fieldName}
            className="font-bold"
            name={item.meta.field}
            htmlFor={item._id}
            rules={[
              {
                required: !!item.meta?.required,
              },
            ]}
            help={help}
          >
            <CustomItems
              item={item}
              formData={finalFormData}
              updateFormData={updateFormData}
              validationErrors={validationErrors}
              fieldName={fieldName}
              language={language}
            />
          </Form.Item>
        </Col>
      );
    case "color":
      return (
        <Col key={item._id} md={width}>
          <Form.Item
            label={fieldName}
            className="font-bold"
            name={item.meta.field}
            htmlFor={item._id}
            rules={[
              {
                required: !!item.meta?.required,
              },
            ]}
            help={help}
          >
            <CustomColorInput
              item={item}
              formData={finalFormData}
              updateFormData={updateFormData}
              isValidationError={isValidationError}
              language={language}
            />
          </Form.Item>
        </Col>
      );
  }
};

export default FormGenerator;
