import React, { useCallback, useEffect, useState } from "react";
import LeftSideTitlePart from "../../../Components/HeaderParts/LeftSideTitlePart";
import IconsAll from "../../../Components/IconsAll";
import RightSIdeTitlePart from "../../../Components/HeaderParts/RightSIdeTitlePart";
import {
  addEdge,
  Background,
  getConnectedEdges,
  MiniMap,
  ReactFlow,
  useEdgesState,
  useNodesState,
} from "@xyflow/react";
import { Flex, notification, Spin, Tooltip, Typography } from "antd";
import { useTranslation } from "react-i18next";
import Slide from "./Components/NodeTypes/Slide";
import axiosInstance from "../../../Helpers/axios";
import Apis from "../../../Helpers/Apis";
import withRouter from "../../../Common/withRouter";
import ButtonEdge from "./Components/EdgeTypes/ButtonEdge";
import { DrawerStyle, TItleIconStyle } from "../../../Common/CommonUiStyles";
import { useDispatch, useSelector } from "react-redux";
import {
  clearDeleteOperationIds,
  handleAddDeleteOperation,
  handleFlowActions,
  setIsCanvasDisabled,
} from "../../../Redux/slices/flows.reducer";
import EditOperation from "./Components/EditOperation";
import FlowActionForm from "./Components/FlowActionForm";
import FlowActivity from "./Components/FlowActivity";
import { GoChecklist } from "react-icons/go";
const { Title } = Typography;

const EditFlow = ({ router }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    operationData,
    isActionDrawerOpen,
    operationNodeId,
    deleteOperationIds,
    isCanvasDisabled,
  } = useSelector((state) => state.flows);

  const nodeTypes = {
    slide: Slide,
  };

  const edgeTypes = {
    button: ButtonEdge,
  };

  const initialNodes = [];
  const initialEdges = [];

  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const { field } = router.params;
  const [loader, setLoader] = useState(false);
  const [showLogs, setShowLogs] = useState(false);
  const [flowsWithOperationTrigger, setFlowsWithOperationTrigger] = useState(
    [],
  );

  const getFlowOptions = async () => {
    try {
      const response = await axiosInstance.get(
        Apis.GET_FLOWS({ trigger: "operation" }),
      );
      setFlowsWithOperationTrigger(response.data?.payload?.data?.data || []);
    } catch (e) {
      setFlowsWithOperationTrigger([]);
    }
  };

  const getFlowData = async ({ withoutLoader = false }) => {
    try {
      !withoutLoader && setLoader(true);
      const response = await axiosInstance.get(
        Apis.GET_FLOW_DATA({ flow_id: field }),
      );

      await getFlowOptions();

      const triggerData = response.data?.payload?.data;

      const nodes = triggerData.operations.map((operation) => {
        const convertOptionsFieldsToJSON = (options, fields) => {
          fields.forEach((field) => {
            if (
              options[field] &&
              Array.isArray(options[field]) &&
              field === "cases"
            ) {
              options[field] = options[field].map((item) => {
                if (item.payload && typeof item.payload === "object") {
                  return {
                    ...item,
                    payload: JSON.stringify(item.payload),
                  };
                } else {
                  return item;
                }
              });
            } else if (options[field] && typeof options[field] === "object") {
              options[field] = JSON.stringify(options[field], null, 2);
            }
          });
          return options;
        };

        const fieldsToConvert = [
          "payload",
          "query",
          "headers",
          "body",
          "condition",
          "cases",
        ];
        operation.options = convertOptionsFieldsToJSON(
          operation.options,
          fieldsToConvert,
        );

        if (operation?.options?.collection) {
          operation.options = {
            ...operation.options,
            collection: {
              value: operation.options.collection.collection_id,
              label: operation.options.collection.collection_name,
            },
          };
        }

        return {
          id: operation._id,
          type: "slide",
          position: {
            x: operation?.position_x || 0,
            y: operation?.position_y || 0,
          },
          data: operation,
        };
      });

      const edges = triggerData.operations.flatMap((operation) => {
        const edgeList = [];
        if (operation.resolve) {
          edgeList.push({
            id: `${operation._id}-${operation.resolve}`,
            source: operation._id,
            target: operation.resolve,
            sourceHandle: "resolve",
            targetHandle: "target",
            type: "button",
          });
        }
        if (operation.reject) {
          edgeList.push({
            id: `${operation._id}-${operation.reject}`,
            source: operation._id,
            target: operation.reject,
            sourceHandle: "reject",
            targetHandle: "target",
            type: "button",
          });
        }
        return edgeList;
      });

      nodes.push({
        id: triggerData._id,
        type: "slide",
        position: {
          x: 25,
          y: 25,
        },
        data: triggerData,
        deletable: false,
        selected: true,
        draggable: false,
      });

      if (triggerData.operation) {
        edges.push({
          id: `${triggerData._id}-${triggerData.operation}`,
          source: triggerData._id,
          target: triggerData.operation,
          type: "button",
          sourceHandle: "trigger",
          targetHandle: "target",
        });
      }

      setNodes(nodes);
      setEdges(edges);
    } catch (error) {
      notification.error({ message: t(error?.data?.message) });
      router.navigate("/settings/flows");
    } finally {
      !withoutLoader && setLoader(false);
    }
  };

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge({ ...params, type: "button" }, eds)),
    [setEdges],
  );

  const onNodesDelete = useCallback(
    (deleted) => {
      deleted.forEach((node) => {
        dispatch(handleAddDeleteOperation({ nodeId: node.id }));
        const connectedEdgeIds = getConnectedEdges([node], edges).map(
          (item) => item.id,
        );
        setEdges((prevEdges) =>
          prevEdges.filter((edge) => !connectedEdgeIds.includes(edge.id)),
        );
      });
    },
    [edges],
  );

  const buildNestedStructure = (operations) => {
    const operationMap = new Map(
      operations.map((op) => {
        return [op.id ?? null, { ...op }];
      }),
    );

    const nestOperation = (operation) => {
      if (operation.resolve) {
        operation.resolve = nestOperation(operationMap.get(operation.resolve));
      }
      if (operation.reject) {
        operation.reject = nestOperation(operationMap.get(operation.reject));
      }
      return operation;
    };
    const operationChains = operations.filter(
      (op) =>
        !operations.some((o) => o.resolve === op.id || o.reject === op.id),
    );
    return operationChains.map(nestOperation);
  };

  const parseOptionsFields = (options, fields) => {
    fields.forEach((field) => {
      if (options[field] !== undefined && options[field] !== null) {
        if (typeof options[field] === "string") {
          try {
            options = {
              ...options,
              [field]: options[field] === "" ? {} : JSON.parse(options[field]),
            };
          } catch (e) {
            console.error(
              `Invalid JSON string for field ${field}:`,
              options[field],
            );
          }
        } else if (Array.isArray(options[field]) && field === "cases") {
          options = {
            ...options,
            [field]: options[field].map((item) => {
              if (typeof item["payload"] === "string") {
                try {
                  return {
                    ...item,
                    payload: { ...JSON.parse(item["payload"]) },
                  };
                } catch (e) {
                  console.error(
                    `Invalid JSON string for field ${field}:`,
                    item,
                  );
                  return item;
                }
              }
              return item;
            }),
          };
        }
      }
    });
    return options;
  };

  const handleSubmit = useCallback(async () => {
    try {
      dispatch(setIsCanvasDisabled(true));
      const nodesData = [...nodes];
      let payload = {};
      const triggerData = nodesData.find((node) => "operation" in node.data);
      triggerData.data.operation = null;

      const parsedNodes = nodesData
        .filter((node) => node.id !== triggerData.id)
        .map((node) => {
          const data = {
            ...node.data,
            resolve: null,
            reject: null,
            id: node.id,
            position_x: node.position.x,
            position_y: node.position.y,
          };

          const fieldsToParse = [
            "payload",
            "query",
            "headers",
            "body",
            "condition",
            "cases",
          ];
          if (data.options) {
            data.options = parseOptionsFields(data.options, fieldsToParse);
          }

          if (data.options.collection) {
            data.options = {
              ...data.options,
              collection: {
                collection_id: data.options.collection.value,
                collection_name: data.options.collection.label,
              },
            };
          }

          return data;
        });

      edges.forEach((edge) => {
        if (edge.sourceHandle === "trigger") {
          triggerData.data.operation = {
            ...(triggerData.data?.operation || {}),
            id: edge.target,
          };
          return;
        }
        const operation = parsedNodes.find(
          (operation) => edge.source === operation.id,
        );
        if (operation) {
          switch (edge.sourceHandle) {
            case "resolve":
              operation.resolve = edge.target;
              break;
            case "reject":
              operation.reject = edge.target;
              break;
            default:
              break;
          }
        }
      });
      const nestedOperation = buildNestedStructure(parsedNodes);
      const connectedNode = nestedOperation.findIndex(
        (i) => i.id === (triggerData.data?.operation?.id ?? null),
      );

      const operationData =
        connectedNode > -1 ? { ...nestedOperation[connectedNode] } : null;
      if (operationData) delete operationData.id;

      payload = {
        ...triggerData.data,
        position_x: triggerData.position.x,
        position_y: triggerData.position.y,
        operation: operationData,
      };
      if (connectedNode > -1) {
        nestedOperation.splice(connectedNode, 1);
      }
      const operations = {
        created: [],
        deleted: [],
        updated: [],
      };
      // eslint-disable-next-line no-unused-vars
      nestedOperation.forEach(({ id, ...restOperation }) => {
        if (restOperation._id) {
          operations.updated.push(restOperation);
        } else {
          operations.created.push(restOperation);
        }
      });

      if (deleteOperationIds.length > 0) {
        operations.deleted = deleteOperationIds;
      }

      payload.operations = operations;

      const response = await axiosInstance.put(
        Apis.UPDATE_FLOW({ flow_id: field }),
        payload,
      );
      dispatch(clearDeleteOperationIds());
      await getFlowData({ withoutLoader: true });
      notification.success({ message: t(response.data?.message) });
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setIsCanvasDisabled(false));
    }
  }, [nodes, edges, deleteOperationIds, setEdges]);

  useEffect(() => {
    getFlowData({});
  }, []);

  return (
    <Flex className="w-full">
      <div className="px-4 xl:px-16 w-full">
        <div className="primary-header pt-9 pb-5">
          <div
            onClick={() => {
              router.navigate(`/settings/flows`);
            }}
          >
            <LeftSideTitlePart
              pageTitle={`${t("updating_item_in")} ${t("flow")}`}
              pageSubTitle={t("flow")}
              backButton={true}
            />
          </div>
          <RightSIdeTitlePart
            showButton={true}
            showDisabled={isCanvasDisabled || loader}
            buttonLoading={isCanvasDisabled || loader}
            handleShowClick={handleSubmit}
            addDisabled={isCanvasDisabled || loader}
            handleAddClick={() => {
              dispatch(
                handleFlowActions({
                  flag: "operation",
                  data: {},
                  nodeId: "",
                  source: {},
                }),
              );
            }}
            logsButton={true}
            logsDisabled={isCanvasDisabled || loader}
            handleLogsButton={() => setShowLogs(true)}
          />
        </div>
        <section className="mt-4 w-full h-4/5 flex">
          {loader ? (
            <Spin
              indicator={<div className="custom-loader" />}
              className={"m-auto"}
            />
          ) : (
            <ReactFlow
              nodes={nodes}
              edges={edges}
              onNodesChange={onNodesChange}
              onNodesDelete={onNodesDelete}
              onEdgesChange={onEdgesChange}
              onConnect={onConnect}
              nodeTypes={nodeTypes}
              edgeTypes={edgeTypes}
              className="inactive-flow"
              snapToGrid={true}
              snapGrid={[25, 25]}
              edgesFocusable={!isCanvasDisabled}
              nodesDraggable={!isCanvasDisabled}
              nodesConnectable={!isCanvasDisabled}
              nodesFocusable={!isCanvasDisabled}
            >
              <MiniMap pannable={true} />
              <Background variant={"dots"} gap={25} size={1} />
            </ReactFlow>
          )}
          <DrawerStyle
            width={870}
            open={isActionDrawerOpen}
            onClose={() => {
              dispatch(
                handleFlowActions({
                  flag: null,
                  data: {},
                  nodeId: "",
                  source: {},
                }),
              );
            }}
            closeIcon={<IconsAll.CloseIconCustom />}
            destroyOnClose
          >
            {isActionDrawerOpen === "trigger" ? (
              <FlowActionForm
                operationData={operationData}
                setNodes={setNodes}
              />
            ) : (
              isActionDrawerOpen === "operation" && (
                <EditOperation
                  operationData={operationData}
                  setNodes={setNodes}
                  setEdges={setEdges}
                  operationNodeId={operationNodeId}
                  flowsWithOperationTrigger={flowsWithOperationTrigger}
                />
              )
            )}
          </DrawerStyle>
          <DrawerStyle
            width={450}
            open={showLogs}
            onClose={() => {
              setShowLogs(false);
            }}
            closeIcon={<IconsAll.CloseIconCustom />}
            destroyOnClose
          >
            <div className="py-4 flow-action-drawer">
              <Flex wrap="wrap" align="center" justify="space-between">
                <Title
                  className="project-header-left-side max-w-[90%]"
                  level={3}
                >
                  <TItleIconStyle>
                    <GoChecklist size={20} className="text-project-base" />
                  </TItleIconStyle>
                  <Tooltip
                    title={t("flow_activities")}
                    placement="bottomLeft"
                    className="truncate"
                  >
                    {t("flow_activities")}
                  </Tooltip>
                </Title>
              </Flex>
            </div>
            <FlowActivity flowId={field} />
          </DrawerStyle>
        </section>
      </div>
    </Flex>
  );
};

export default withRouter(EditFlow);
