import React, { useCallback, useEffect, useState } from "react";
import { Checkbox, Flex, Modal, notification, Spin } from "antd";
import IconsAll from "../../Components/IconsAll";
import LeftSideTitlePart from "../../Components/HeaderParts/LeftSideTitlePart";
import RightSIdeTitlePart from "../../Components/HeaderParts/RightSIdeTitlePart";
import { useTranslation } from "react-i18next";
import AppInfo from "../../Components/AppInfo";
import { useSelector } from "react-redux";
import axiosInstance from "../../Helpers/axios";
import Apis from "../../Helpers/Apis";
import withRouter from "../../Common/withRouter";
import { queryClient } from "../..";
import { TableStriped } from "../../Common/CommonUiStyles";
import { usePagination } from "../../Helpers/custom-hooks/usePagination";
import { debounce } from "lodash";
import NoData from "../../Components/NoData";
import i18n from "../../i18n";
import { hideLoader } from "../../Redux/slices/loader.reducer";
import { useDispatch } from "react-redux";

const Vertalingen = ({ router }) => {
  const { t } = useTranslation();
  const currentLng = useSelector((state) => state.user.userProfile.language);
  const path = location.pathname.split("/");
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [translationsList, setTranslationsList] = useState([]);
  const [columnWidths, setColumnWidths] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const { pagination, updatePagination } = usePagination(
    1,
    10,
    "createdAt",
    true,
  );
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    searchTerm && searchTerm !== "" && setSearchTerm("");
    init();
  }, [path[2]]);

  const handleResizeStart = (key) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    const startX = e.clientX;
    const startWidth = columnWidths[key] || 150;

    const handleMouseMove = (moveEvent) => {
      setColumnWidths((prev) => ({
        ...prev,
        [key]: Math.max(startWidth + (moveEvent.clientX - startX), 50),
      }));
    };

    const handleMouseUp = () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleDeleteClick = async () => {
    setOpen(false);
    setLoading(true);
    try {
      const resp = await axiosInstance.put(Apis.DELETE_TRANSLATIONS(), {
        deleteTranslations: selectedRows,
      });
      if (resp.status === 200) {
        selectedRows?.map((row) => {
          i18n.store.data[currentLng]["tenant"] &&
            delete i18n.store.data[currentLng]["tenant"][
              translationsList?.find((tr) => tr?._id === row)?.key
            ];
        });
        queryClient.invalidateQueries({
          queryKey: ["groupedTranslations", currentLng],
          exact: true,
        });
        setSelectedRows([]);
        notification.success({ message: t("translation_delete_success") });
        init();
      }
    } catch (error) {
      notification.error({
        message: error?.response?.data?.message || t("something_went_wrong"),
      });
    } finally {
      setLoading(false);
    }
  };

  const init = async (
    search = "",
    page = pagination.currentPage,
    limit = pagination.pageSize,
  ) => {
    try {
      const resp = await axiosInstance.get(
        Apis.GET_TRANSLATIONS(search, false, page, limit),
      );
      if (resp.status === 200) {
        setTranslationsList(resp.data.payload.data);
        updatePagination(
          "totalItems",
          resp.data?.payload?.meta?.total_found || 0,
        );
        updatePagination("currentPage", page);
        updatePagination("pageSize", limit);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
      dispatch(hideLoader());
    }
  };

  const debouncedInit = useCallback(
    debounce((search, page) => {
      setLoading(true);
      init(search, page);
    }, 1000),
    [path[2]],
  );

  const handleRowSelect = (checked, record) => {
    setSelectedRows((prevSelectedRows) => {
      if (checked) {
        return [...prevSelectedRows, record._id];
      } else {
        return prevSelectedRows.filter((id) => id !== record._id);
      }
    });
  };

  const columns = [
    {
      title: (
        <div
          className="resizable-header"
          style={{ width: columnWidths["select"] }}
        >
          {t("select")}
          <div
            className="resize-handle"
            onMouseDown={handleResizeStart("select")}
          />
        </div>
      ),
      dataIndex: "select",
      key: "select",
      width: columnWidths["select"],
      render: (text, record) => (
        <Checkbox
          onChange={(e) => handleRowSelect(e.target.checked, record)}
          checked={selectedRows?.includes(record?._id)}
        />
      ),
    },
    ...[
      { key: "key", label: t("key") },
      { key: "language", label: t("language") },
      { key: "value", label: t("value") },
      // { key: "_id", label: "_id" },
    ].map(({ key, label }) => ({
      title: (
        <div className="resizable-header">
          {label}
          {key !== "value" && (
            <div
              className="resize-handle"
              onMouseDown={handleResizeStart(key)}
            />
          )}
        </div>
      ),
      dataIndex: key,
      key,
      // width: columnWidths[key],
      render: (record, rowData) => (
        <div
          onClick={() =>
            router.navigate(`/settings/vertalingen/${rowData?._id}`)
          }
        >
          {record}
        </div>
      ),
    })),
  ];

  return (
    <Flex className="w-full main-content-wrap">
      <div className="px-4 xl:px-16 w-full left-part">
        <div className="primary-header pt-9 pb-5">
          <LeftSideTitlePart
            pageTitle={t("translations")}
            pageSubTitle={t("setting")}
            pageIcon={true}
            pageInnerIcon={<IconsAll.PaginasIcon />}
            backButton={false}
          />
          <RightSIdeTitlePart
            isFilterShow={true}
            searchTerm={searchTerm}
            searchDisabled={
              !translationsList?.length && (!searchTerm || searchTerm === "")
            }
            searchTermPlaceholder={t("search")}
            setSearchTerm={setSearchTerm}
            addButton={true}
            addDisabled={!path[2] || loading}
            deleteButton={selectedRows?.length > 0}
            deleteDisabled={!selectedRows?.length}
            handleDeleteClick={() => setOpen(true)}
            handleAddClick={() => {
              router.navigate(`/settings/vertalingen/add`);
            }}
            debouncedInit={debouncedInit}
          />
        </div>

        <section className="mt-4">
          <div className="relative">
            <TableStriped
              columns={columns}
              dataSource={translationsList}
              rowKey={(record) => record?._id}
              locale={{
                emptyText: <NoData title={t("no_data_found")} />,
              }}
              pagination={{
                pageSizeOptions: [10, 25, 50],
                current: pagination.currentPage,
                pageSize: pagination.pageSize,
                total: pagination.totalItems,
                hideOnSinglePage: true,
                showSizeChanger: true,
                locale: { items_per_page: "" },
                onChange: async (page, size) => {
                  page !== pagination.currentPage &&
                    (setLoading(true), await init(searchTerm, page, size));
                },
                onShowSizeChange: async (page, size) => {
                  setLoading(true);
                  await init(searchTerm, page, size);
                },
              }}
              loading={{
                spinning: loading,
                indicator: (
                  <Spin indicator={<div className="custom-loader" />} />
                ),
              }}
              className="change-width-of-first-column"
            />
          </div>
        </section>
      </div>
      <Modal
        open={open}
        title={t("delete_translations")}
        okText={t("delete_translations")}
        onOk={handleDeleteClick}
        cancelText={t("cancel")}
        okButtonProps={{ className: "modal-primary-btn" }}
        cancelButtonProps={{ className: "modal-default-btn" }}
        onCancel={() => setOpen(false)}
        className="modal-delete"
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <CancelBtn />
            <OkBtn />
          </>
        )}
      >
        <p className="my-6">{t("delete_translations_confirm")}</p>
      </Modal>
      <AppInfo
        visibleList={["information"]}
        config={{
          msg: t("information_translations"),
        }}
      />
    </Flex>
  );
};

export default withRouter(Vertalingen);
