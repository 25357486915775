import { Button, Flex, notification, Select, Spin, Typography } from "antd";
import React, { useEffect, useRef, useState } from "react";
import LeftSideTitlePart from "../../HeaderParts/LeftSideTitlePart";
import IconsAll from "../../IconsAll";
import RightSIdeTitlePart from "../../HeaderParts/RightSIdeTitlePart";
import styled from "styled-components";
import "cropperjs/dist/cropper.css";
import Cropper from "cropperjs";
import axiosInstance from "../../../Helpers/axios";
import Apis from "../../../Helpers/Apis";
import { getDisplayImage } from "../../../Helpers/utils";
import { BiRotateRight } from "react-icons/bi";
import { LuFlipHorizontal2, LuFlipVertical2 } from "react-icons/lu";
import { LiaUndoAltSolid } from "react-icons/lia";
import { useTranslation } from "react-i18next";

const TItleIconStyle = styled(Typography)`
  min-width: 56px !important;
  width: 56px !important;
  height: 56px;
  border-radius: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EditImage = ({
  setIsImageEditOpen,
  setFileDetails,
  setCropImageDetails,
  fileDetails,
  assignImageUrl,
  isDrawer,
  updateFilesList,
  cropImage,
}) => {
  const imageElement = useRef(null);
  const cropperInstance = useRef(null);
  const { Option } = Select;
  const { t } = useTranslation();
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (imageElement.current && !cropperInstance.current) {
      cropperInstance.current = new Cropper(imageElement.current, {
        viewMode: 1,
        movable: true,
        rotatable: true,
        scalable: true,
        zoomable: true,
        autoCropArea: 0.5,
        aspectRatio: 16 / 9,
      });
    }

    return () => {
      if (cropperInstance.current) {
        cropperInstance.current.destroy();
        cropperInstance.current = null;
      }
    };
  }, [fileDetails?.imageUrl]);

  useEffect(() => {
    if (!fileDetails?.imageUrl) {
      setImageUrl(fileDetails?._id);
    }
  }, [fileDetails?._id]);

  const setImageUrl = async (fileId) => {
    setLoader(true);
    const imageUrl = await getDisplayImage(fileId);
    setCropImageDetails((prev) => {
      return { ...prev, imageUrl };
    });
    setLoader(false);
  };

  const getCroppedImage = (sizeLimitKB = 2048) => {
    return new Promise((resolve, reject) => {
      if (cropperInstance.current) {
        const canvas = cropperInstance.current.getCroppedCanvas();

        const compressImage = (quality) => {
          canvas.toBlob(
            (blob) => {
              if (blob && blob?.size / 1024 > sizeLimitKB) {
                compressImage(quality - 0.1);
              } else if (blob) {
                resolve(blob);
              } else {
                reject(new Error("Blob conversion failed."));
              }
            },
            fileDetails?.type || "image/jpeg",
            quality,
          );
        };

        compressImage(1);
      } else {
        reject(new Error("No cropper instance available."));
      }
    });
  };

  const rotateImage = (degree) => {
    if (cropperInstance.current) {
      const currentRotation = cropperInstance.current.getData().rotate || 0;
      cropperInstance.current.rotateTo(currentRotation + degree);
    }
  };

  const flipImage = (isHorizontal = false) => {
    if (cropperInstance.current) {
      const currentImageData = cropperInstance.current.getImageData();
      if (isHorizontal) {
        cropperInstance.current.scaleX((currentImageData.scaleX || 1) * -1);
      } else {
        cropperInstance.current.scaleY((currentImageData.scaleY || 1) * -1);
      }
    }
  };

  const handleImage = async () => {
    try {
      setLoader(true);
      const formData = new FormData();
      const croppedBlob = await getCroppedImage();
      formData.append("file", croppedBlob, fileDetails?.filename_disk);

      const resp = await axiosInstance.put(
        Apis.UPDATE_FILE(fileDetails?._id),
        formData,
      );
      if (resp.status === 200) {
        notification.success({ message: t("image_update_success") });
        setIsImageEditOpen(false);
        if (cropImage) {
          updateFilesList([{ ...(resp.data?.payload?.data || {}) }]);
          setFileDetails({});
        } else {
          let imageUrl = await getDisplayImage(fileDetails?._id);
          setFileDetails({ ...(resp.data?.payload?.data || {}), imageUrl });
        }
        isDrawer && assignImageUrl();
      }
    } catch (error) {
      console.log("error", error);
      setFileDetails({});
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      <div className="primary-header p-[24px]">
        <LeftSideTitlePart
          pageIcon
          pageInnerIcon={
            <TItleIconStyle>
              <IconsAll.ContentIcon />
            </TItleIconStyle>
          }
          pageTitle={t("editing_image")}
          pageSubTitle={t("changes_are_permanent")}
        />
        <RightSIdeTitlePart
          showButton
          handleShowClick={handleImage}
          showDisabled={loader}
        />
      </div>

      {loader ? (
        <Spin
          className="flex w-full justify-center"
          indicator={<div className="custom-loader" />}
        />
      ) : (
        <>
          <img
            src={fileDetails?.imageUrl}
            ref={imageElement}
            alt="Edit Image"
            className="min-h-[500px] max-h-[500px] h-auto! w-full! object-contain mx-auto"
          />
          <Flex className="flex-wrap justify-between gap-3 mt-6 px-[24px]">
            <Select
              defaultValue="16/9"
              style={{ width: 180, marginBottom: 20 }}
              onChange={(e) => cropperInstance.current.setAspectRatio(e)}
            >
              <Option value={1}>Square</Option>
              <Option value={16 / 9}>16:9</Option>
              <Option value={4 / 3}>4:3</Option>
              <Option value={3 / 2}>3:2</Option>
              <Option value={NaN}>Free</Option>
            </Select>
            <Flex className="flex-wrap gap-3">
              <Button
                shape="circle"
                size="large"
                onClick={() => rotateImage(-90)}
              >
                <BiRotateRight size={24} />
              </Button>
              <Button
                shape="circle"
                size="large"
                onClick={() => flipImage(true)}
              >
                <LuFlipHorizontal2 size={24} />
              </Button>
              <Button shape="circle" size="large" onClick={() => flipImage()}>
                <LuFlipVertical2 size={24} />
              </Button>
              <Button
                shape="circle"
                size="large"
                onClick={() => cropperInstance.current.reset()}
              >
                <LiaUndoAltSolid size={24} />
              </Button>
            </Flex>
          </Flex>
        </>
      )}
    </>
  );
};

export default EditImage;
