import Apis from "./Apis";
import axiosInstance from "./axios";
import { setFields, setFieldsRelations } from "../Redux/slices/fields.reducer";
import {
  setSchemas,
  setSystemCollections,
} from "../Redux/slices/schema.reducer";

const getAllFields = async (params) => {
  try {
    const response = await axiosInstance.get(
      Apis.GET_ALL_FIELDS({ pagination: false, ...params }),
    );

    params.dispatch(setFields(response?.data?.payload?.data));
  } catch (error) {
    console.log(error);
  }
};

const getAllFieldsRelations = async (params) => {
  try {
    const response = await axiosInstance.get(Apis.GET_ALL_FIELDS_RELATIONS());

    params.dispatch(setFieldsRelations(response?.data?.payload?.data));
  } catch (error) {
    console.log(error);
  }
};

const getAllSchemas = async (params) => {
  try {
    const response = await axiosInstance.get(
      Apis.GET_ALL_SCHEMAS({ pagination: false, system: true, ...params }),
    );

    const allSchemas = response?.data?.payload?.data || [];

    params.dispatch((dispatch) => {
      dispatch(setSchemas(allSchemas.filter((col) => !col?.system_default)));
      dispatch(
        setSystemCollections(allSchemas.filter((col) => col?.system_default)),
      );
    });
  } catch (error) {
    console.log(error);
  }
};

export { getAllFields, getAllSchemas, getAllFieldsRelations };
