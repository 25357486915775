import React, { useEffect } from "react";
import { Flex } from "antd";
import SubMenu from "../../Components/SubMenu";
import { useTranslation } from "react-i18next";
import IconsAll from "../../Components/IconsAll";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setActiveTab } from "../../Redux/slices/activeTab.reducer";
import DataModal from "./DataModal";
import Toegangscontrole from "./Toegangscontrole";
import Webhooks from "./Webhooks";
import Flows from "./Flows";
import Extenties from "./Extenties";
import Uiterlijk from "./Uiterlijk";
import Bookmarks from "./Bookmarks";
import Vertalingen from "./Vertalingen";
import MeldeenBug from "./MeldeenBug";
import RequestFeature from "./RequestFeature";
import ChangeDataModalCollection from "./ChangeDataModalCollection";
// import Permissions from "./Permissions";
import AddVertalingen from "./AddVertalingen";
import AddEditRole from "./Toegangscontrole/AddEditRole";
import ConfigurationList from "./Configuration/List";
import AddConfiguration from "./Configuration/Add";
import EditConfiguration from "./Configuration/Edit";
import { GrConfigure } from "react-icons/gr";
import MarketPlace from "./MarketPlace";
import { AiOutlineShop } from "react-icons/ai";
import EditFlow from "./Flows/Edit";
import Settings from "./Settings/Settings";
import EditEmailTemplate from "./EmailTemplates/Edit";
import EmailTemplates from "./EmailTemplates";

const Gebruikers = () => {
  const { t } = useTranslation();
  const path = location.pathname.split("/");
  const activeTab = useSelector((state) => state.activeTab[path[1]]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const subMenus = [
    {
      name: t("data_model"),
      link: "/settings/data-model",
      icon: <IconsAll.DataModalIcon />,
    },
    {
      name: t("access_control"),
      link: "/settings/toegangscontrole",
      icon: <IconsAll.ToegangsControleIcon />,
      divider: true,
    },
    // {
    //   name: t("webhooks"),
    //   link: "/settings/webhooks",
    //   icon: <IconsAll.WebhooksIcon />,
    //   disabled: true
    // },
    {
      name: t("setting"),
      link: "/settings/settings",
      icon: <IconsAll.SettingIcon />,
    },
    {
      name: t("flows"),
      link: "/settings/flows",
      icon: <IconsAll.FlowsIcon />,
    },
    {
      name: t("email_templates"),
      link: "/settings/email-templates",
      icon: <IconsAll.EmailTemplatesIcon />,
    },
    // {
    //   name: t("extentions"),
    //   link: "/settings/extenties",
    //   icon: <IconsAll.ExtentiesIcon />,
    //   divider: true,
    // },
    {
      name: t("appearance"),
      link: "/settings/uiterlijk",
      icon: <IconsAll.UiterlijkIcon />,
    },
    // {
    //   name: t("bookmarks"),
    //   link: "/settings/bookmarks",
    //   icon: <IconsAll.BookmarksIcon />,
    // },
    {
      name: t("translations"),
      link: "/settings/vertalingen",
      icon: <IconsAll.VertalingenIcon />,
    },
    {
      name: t("configurations"),
      link: "/settings/configuration",
      icon: <GrConfigure size={18} color="var(--project-base)" />,
      divider: true,
    },
    {
      name: t("marketplace"),
      link: "/settings/marketplace",
      icon: <AiOutlineShop size={20} color="var(--project-base)" />,
      divider: false,
    },
    // {
    //   name: t("report_a_bug"),
    //   link: "/settings/meld-een-bug",
    //   icon: <IconsAll.MeldeenBugIcon />,
    // },
    // {
    //   name: t("request") + " " + t("feature"),
    //   link: "/settings/request-feature",
    //   icon: <IconsAll.RequestFeatureIcon />,
    // },
  ];

  useEffect(() => {
    if (activeTab && activeTab?.currentTab) {
      !path[3] && navigate(activeTab?.redirectionLink);
    } else {
      dispatch(
        setActiveTab({
          page: path[1],
          tab: "Data Model",
          redirectionLink: "/settings/data-model",
        }),
      );
    }
  }, [path[2], activeTab]);

  const renderChild = () => {
    switch (path[2]) {
      case "data-model":
        return path[3] ? <ChangeDataModalCollection /> : <DataModal />;
      case "toegangscontrole":
        return path[3] ? <AddEditRole /> : <Toegangscontrole />;
      // case "add-user":
      //   return <Permissions />;
      case "settings":
        return <Settings />;
      case "webhooks":
        return <Webhooks />;
      case "flows":
        return path[3] ? <EditFlow /> : <Flows />;
      case "email-templates":
        return path[3] ? <EditEmailTemplate /> : <EmailTemplates />;
      case "extenties":
        return <Extenties />;
      case "uiterlijk":
        return <Uiterlijk />;
      case "bookmarks":
        return <Bookmarks />;
      case "vertalingen":
        return path[3] ? <AddVertalingen /> : <Vertalingen />;
      case "meld-een-bug":
        return <MeldeenBug />;
      case "request-feature":
        return <RequestFeature />;
      case "configuration":
        return path[3] == "add" ? (
          <AddConfiguration />
        ) : path[3] ? (
          <EditConfiguration />
        ) : (
          <ConfigurationList />
        );
      case "marketplace":
        return <MarketPlace />;
      default:
        return <DataModal />;
    }
  };

  return (
    <Flex className="main-content-wrap">
      <SubMenu
        isSubMenuLogo
        isSubMenuVersion
        items={subMenus}
        currentPage={path[1]}
      />
      {renderChild()}
    </Flex>
  );
};

export default Gebruikers;
