import React, { useRef, useEffect } from "react";
import JSONEditor from "jsoneditor";
import "jsoneditor/dist/jsoneditor.css";
import { notification } from "antd";
import { useTranslation } from "react-i18next";

const JsonEditor = ({ setJson, initialValue, disable = false }) => {
  const containerRef = useRef(null);
  const editorRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (containerRef.current) {
      editorRef.current = new JSONEditor(containerRef.current, {
        mode: "code",
        mainMenuBar: true,
        onChange: () => {
          try {
            let jsonData = editorRef.current.get();
            setJson({ jsonData, isValidJson: true });
          } catch (error) {
            setJson({ jsonData: null, isValidJson: false });
          }
        },
      });

      if (initialValue) {
        try {
          editorRef.current.set(initialValue);
        } catch (error) {
          notification.error({ message: t("please_enter_valid_json") });
        }
      }
    }

    return () => {
      if (editorRef.current) {
        editorRef.current.destroy();
      }
    };
  }, []);

  return (
    <div
      ref={containerRef}
      style={{
        height: "400px",
        pointerEvents: disable ? "none" : "auto",
      }}
    />
  );
};

export default JsonEditor;
