import {
  Checkbox,
  Col,
  ColorPicker,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Tag,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import dayjs from "dayjs";
import React, { useId, useState } from "react";
import ReactQuill from "react-quill";
import { BsTags } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { InvertedCheckBox } from "../../Common/CommonUiStyles";
import JsonEditor from "../Create-new-fields/EditFields/JsonEditor";

const GenerateUpdateFieldsForm = ({
  category,
  activeItem,
  tags,
  setTags,
  form,
  setJson,
  Json,
}) => {
  const id = useId();
  const [inputValue, setInputValue] = useState("");
  const { t } = useTranslation();

  const handleInputConfirm = async () => {
    if (inputValue && !tags?.includes(inputValue)) {
      await setTags([...tags, inputValue]);
    }
    setInputValue("");
  };

  switch (category) {
    case "key":
      return (
        <Col className="gutter-row" xs={24} md={24}>
          <Form.Item
            name="field"
            label={t("key")}
            className="font-bold"
            rules={[{ required: true, message: t("please_input_key") }]}
            initialValue={activeItem?.meta?.field}
          >
            <Input className="project-custom-input" disabled />
          </Form.Item>
        </Col>
      );
    case "type":
      return (
        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            name="type"
            label={t("type")}
            className="font-bold"
            rules={[{ required: true, message: t("please_input_type") }]}
            htmlFor={`${id}-type`}
            initialValue={activeItem?.type}
          >
            <Select
              size="large"
              disabled
              placeholder="String"
              style={{ flex: 1, height: "56px" }}
              id={`${id}-type`}
              options={[
                { value: "String", label: "String" },
                { value: "ObjectId", label: "UniqueId" },
                { value: "Number", label: "Number" },
              ]}
            />
          </Form.Item>
        </Col>
      );

    case "length":
      return (
        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            name="length"
            label={t("length")}
            className="font-bold"
            initialValue={activeItem?.length}
          >
            <InputNumber
              className="project-custom-input"
              disabled
              placeholder={
                activeItem?.type === "String"
                  ? ""
                  : t("not_available_for_this_type")
              }
            />
          </Form.Item>
        </Col>
      );

    case "schema_definition.default":
      if (
        activeItem?.meta?.interface === "input" &&
        activeItem?.type === "Number"
      ) {
        return (
          <Col className="gutter-row" xs={24} md={24}>
            <Form.Item
              name="schema_definition.default"
              className="font-bold"
              label={t("default_value")}
              initialValue={activeItem?.schema_definition?.default}
            >
              <InputNumber className="project-custom-input" />
            </Form.Item>
          </Col>
        );
      }
      if (
        activeItem?.meta?.interface === "input" ||
        activeItem?.meta?.interface === "autocomplete"
        // activeItem?.meta?.interface === "list-m2o"
      ) {
        return (
          <Col className="gutter-row" xs={24} md={24}>
            <Form.Item
              name="schema_definition.default"
              className="font-bold"
              label={t("default_value")}
              initialValue={activeItem?.schema_definition?.default}
            >
              <Input className="project-custom-input" />
            </Form.Item>
          </Col>
        );
      }
      if (
        activeItem?.meta?.interface === "dropdown" ||
        activeItem?.meta?.interface === "radio"
      ) {
        return (
          <Col className="gutter-row" xs={24} md={24}>
            <Form.Item
              name="schema_definition.default"
              className="font-bold"
              label={t("default_value")}
              initialValue={activeItem?.schema_definition?.default}
            >
              <Radio.Group
                className="flex flex-col gap-y-2 w-full text-ellipsis overflow-clip"
                options={activeItem?.meta?.options?.choices}
              />
            </Form.Item>
          </Col>
        );
      }
      if (activeItem?.meta?.interface === "textarea") {
        return (
          <Col className="gutter-row" xs={24} md={24}>
            <Form.Item
              name="schema_definition.default"
              className="font-bold"
              label={t("default_value")}
              initialValue={activeItem?.schema_definition?.default}
            >
              <TextArea className="project-custom-input" rows={4} />
            </Form.Item>
          </Col>
        );
      }
      if (
        activeItem?.meta?.interface === "checkboxes" ||
        activeItem?.meta?.interface === "dropdown_multiple"
      ) {
        return (
          <Col className="gutter-row" xs={24} md={24}>
            <Form.Item
              name="schema_definition.default"
              className="font-bold"
              label={t("default_value")}
              initialValue={activeItem?.schema_definition?.default}
            >
              <Checkbox.Group options={activeItem?.meta?.options?.choices} />
            </Form.Item>
          </Col>
        );
      }
      if (activeItem?.meta?.interface === "wysiwyg") {
        return (
          <Col className="gutter-row" xs={24} md={24}>
            <Form.Item
              name="schema_definition.default"
              className="font-bold"
              label={t("default_value")}
              initialValue={activeItem?.schema_definition?.default}
            >
              <ReactQuill />
            </Form.Item>
          </Col>
        );
      }
      if (activeItem?.meta?.interface === "json") {
        return (
          <Col className="gutter-row" xs={24} md={24}>
            <Form.Item
              name="schema_definition.default"
              className="font-bold"
              label={t("default_value")}
            >
              <JsonEditor setJson={setJson} initialValue={Json.jsonData} />
            </Form.Item>
          </Col>
        );
      }
      if (activeItem?.meta?.interface === "toggle") {
        return (
          <Col className="gutter-row" xs={24} md={24}>
            <Form.Item
              name="schema_definition.default"
              className="font-bold"
              label={t("default_value")}
              valuePropName="checked"
              initialValue={activeItem?.schema_definition?.default}
            >
              <Checkbox checked={activeItem?.met} />
            </Form.Item>
          </Col>
        );
      }
      if (activeItem?.meta?.interface === "datetime") {
        return (
          <Col className="gutter-row" xs={24} md={24}>
            <Form.Item
              name="schema_definition.default"
              className="font-bold"
              label={t("default_value")}
              initialValue={
                activeItem?.schema_definition?.default
                  ? activeItem?.type === "time"
                    ? dayjs()
                        .set(
                          "hour",
                          parseInt(
                            activeItem?.schema_definition?.default?.split(
                              ":",
                            )?.[0],
                            10,
                          ),
                        )
                        .set(
                          "minute",
                          parseInt(
                            activeItem?.schema_definition?.default?.split(
                              ":",
                            )?.[1],
                            10,
                          ),
                        )
                        .set(
                          "second",
                          parseInt(
                            activeItem?.schema_definition?.default?.split(
                              ":",
                            )?.[2],
                            10,
                          ),
                        )
                    : dayjs(activeItem.schema_definition.default)
                  : null
              }
            >
              <DatePicker
                showTime={!(activeItem?.type === "date")}
                picker={activeItem?.type === "time" ? "time" : "date"}
                size={"large"}
                onChange={(date) => {
                  const formattedDate = date ? dayjs(date) : null;
                  form.setFieldValue(
                    "schema_definition.default",
                    formattedDate,
                  );
                }}
              />
            </Form.Item>
          </Col>
        );
      }

      if (activeItem?.meta?.interface === "color") {
        return (
          <Col className="gutter-row" xs={24} md={24}>
            <Form.Item
              name="schema_definition.default"
              className="font-bold"
              label={t("default_value")}
              initialValue={activeItem?.schema_definition?.default}
            >
              <ColorPicker
                id={`color`}
                showText
                className="px-[10px] py-[10px] w-full min-h-14 rounded-lg font-normal justify-start items-center border-gray-300"
                defaultFormat="hex"
                onChange={(_, hex) => {
                  form.setFieldValue("schema_definition.default", hex);
                }}
                allowClear
                onClear={() => {
                  form.setFieldValue("schema_definition.default", null);
                }}
              />
            </Form.Item>
          </Col>
        );
      }

      if (activeItem?.meta?.interface === "tags") {
        return (
          <Col className="gutter-row" xs={24} md={24}>
            <Form.Item
              name="schema_definition.default"
              className="font-bold"
              label={t("default_value")}
            >
              <Input
                type="text"
                placeholder={t("add_a_tag_and_press_enter")}
                id={`${id}-presets`}
                className="project-custom-input"
                suffix={<BsTags size={20} color="gray" />}
                value={inputValue}
                onChange={(e) => {
                  setInputValue(e.target.value);
                }}
                onPressEnter={handleInputConfirm}
              />
              {tags?.map((tag, index) => (
                <Tag
                  color="blue"
                  key={index}
                  onClick={async () => {
                    await setTags((prev) => prev?.filter((t) => t !== tag));
                  }}
                  className="custom-tag"
                >
                  {tag}
                </Tag>
              ))}
            </Form.Item>
          </Col>
        );
      }
      return <></>;
    case "meta.nullable":
      return (
        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            name="meta.nullable"
            label={t("nullable")}
            valuePropName="checked"
            className="font-bold"
            initialValue={activeItem?.meta?.nullable}
          >
            <InvertedCheckBox>{t("allow_null_value")}</InvertedCheckBox>
          </Form.Item>
        </Col>
      );

    case "schema_definition.unique":
      return (
        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            name="schema_definition.unique"
            label={t("unique")}
            valuePropName="checked"
            className="font-bold"
            initialValue={activeItem?.schema_definition?.unique}
          >
            <InvertedCheckBox checked>
              {t("value_has_to_be_unique")}
            </InvertedCheckBox>
          </Form.Item>
        </Col>
      );
    case "meta.on_create":
      return (
        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            name="meta.on_create"
            label={t("on_create")}
            className="font-bold"
            htmlFor={`${id}-on_create`}
            initialValue={activeItem?.meta.on_create}
          >
            <Select
              size="large"
              id={`${id}-on_create`}
              style={{ flex: 1, height: "56px" }}
              options={[
                { label: t("do_nothing"), value: "do_nothing" },
                {
                  label: t("save_current_user_id"),
                  value: "save_current_user_id",
                },
                {
                  label: t("save_current_user_role"),
                  value: "save_current_user_role",
                },
              ]}
            />
          </Form.Item>
        </Col>
      );
    case "meta.on_update":
      return (
        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            name="meta.on_update"
            label={t("on_update")}
            className="font-bold"
            htmlFor={`${id}-on_update`}
            initialValue={activeItem?.meta.on_update}
          >
            <Select
              size="large"
              style={{ flex: 1, height: "56px" }}
              id={`${id}-on_update`}
              options={[
                { label: t("do_nothing"), value: "do_nothing" },
                {
                  label: t("save_current_user_id"),
                  value: "save_current_user_id",
                },
                {
                  label: t("save_current_user_role"),
                  value: "save_current_user_role",
                },
              ]}
            />
          </Form.Item>
        </Col>
      );
  }
};

export default GenerateUpdateFieldsForm;
