import React, { useState, useCallback } from "react";
import { DndContext, pointerWithin } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useSortable } from "@dnd-kit/sortable";
import LeftSideTitlePart from "../../../Components/HeaderParts/LeftSideTitlePart";
import IconsAll from "../../../Components/IconsAll";
import RightSIdeTitlePart from "../../../Components/HeaderParts/RightSIdeTitlePart";
import { Dropdown, Flex, Select, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getFieldsBySchemaId } from "../../../Helpers/functions";
import { IoClose } from "react-icons/io5";
import {
  convertToTreeData,
  convertTreeDataToOptions,
} from "../../../Helpers/utils";

const operatorMap = {
  _eq: {
    description: "Equals",
    type: ["String", "Number", "Boolean"],
    inputType: "Single value",
  },
  _neq: {
    description: "Not equals",
    type: ["String", "Number", "Boolean"],
    inputType: "Single value",
  },
  _lt: {
    description: "Less than",
    type: ["Number", "Date"],
    inputType: "Single value",
  },
  _lte: {
    description: "Less than or equal to",
    type: ["Number", "Date"],
    inputType: "Single value",
  },
  _gt: {
    description: "Greater than",
    type: ["Number", "Date"],
    inputType: "Single value",
  },
  _gte: {
    description: "Greater than or equal to",
    type: ["Number", "Date"],
    inputType: "Single value",
  },
  _in: { description: "In", type: ["Array"], inputType: "List of values" },
  _nin: { description: "Not in", type: ["Array"], inputType: "List of values" },
  _contains: {
    description: "Contains",
    type: ["String"],
    inputType: "SubString",
  },
  _icontains: {
    description: "Contains (case insensitive)",
    type: ["String"],
    inputType: "SubString",
  },
  _starts_with: {
    description: "Starts with",
    type: ["String"],
    inputType: "Prefix",
  },
  _istarts_with: {
    description: "Starts with (case insensitive)",
    type: ["String"],
    inputType: "Prefix",
  },
  _ends_with: {
    description: "Ends with",
    type: ["String"],
    inputType: "Suffix",
  },
  _iends_with: {
    description: "Ends with (case insensitive)",
    type: ["String"],
    inputType: "Suffix",
  },
  _and: {
    description: "AND",
    type: ["Logical"],
    inputType: "Condition",
  },
  _or: {
    description: "OR",
    type: ["Logical"],
    inputType: "Condition",
  },
};

const SortableRule = ({ ruleId, rule, schemaData, setRules, fieldOptions }) => {
  const { attributes, listeners, setNodeRef, isDragging, isOver } = useSortable(
    {
      id: ruleId,
      data: {
        type: "rule",
        isAndOr: ruleId.startsWith("and-or-condition"),
      },
    },
  );

  const style = {
    cursor: "grab",
    background: isOver
      ? "rgba(from var(--color-project-base) r g b / .1)"
      : "white",
    border: "1px solid var(--input-field-border)",
    padding: "12px 6px",
    borderRadius: "8px",
    width: "100%",
    maxWidth: "600px",
    marginBottom: "8px",
    display: "flex",
    flexDirection: "column",
    opacity: isDragging ? 0.5 : 1,
    position: "relative",
    overflow: "auto",
  };

  const isAndOrCondition = ruleId.startsWith("and-or-condition");

  return (
    <div ref={setNodeRef} style={style} className="custom-scrollbar">
      <div className="flex mb-3 items-center">
        <div {...attributes} {...listeners}>
          <IconsAll.DragDropIcon />
        </div>
        <div className="flex items-center flex-1 pr-2">
          <Rule
            ruleId={ruleId}
            rule={rule}
            schemaData={schemaData}
            setRules={setRules}
            fieldOptions={fieldOptions}
          />
        </div>
      </div>
      {isAndOrCondition && (
        <div className="ml-4 pl-2 border-[--project-color] border-l border-dashed">
          {Object.entries(rule.children || {}).map(([childId, childRule]) => (
            <SortableRule
              key={childId}
              ruleId={childId}
              rule={childRule}
              schemaData={schemaData}
              setRules={setRules}
              fieldOptions={fieldOptions}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const Rule = ({ ruleId, rule, schemaData, setRules, fieldOptions }) => {
  const isAndOrCondition = ruleId.startsWith("and-or-condition");
  const fieldItems = isAndOrCondition
    ? [
        { label: "AND", key: "_and", operator: "_and" },
        { label: "OR", key: "_or", operator: "_or" },
      ]
    : fieldOptions;

  const selectedField = isAndOrCondition
    ? { field: operatorMap[rule.operator]?.description }
    : schemaData?.fields?.find((field) => field._id === rule.field);

  const operatorItems = Object.entries(operatorMap)
    .filter(([, value]) => !value.type.includes("Logical"))
    .map(([key, value]) => ({
      label: value.description,
      key,
    }));

  const updateRule = useCallback(
    (key, value) => {
      setRules((prev) => {
        const updateNestedRule = (rules, targetId) => {
          const newRules = { ...rules };
          for (const id in newRules) {
            if (id === targetId) {
              if (key === "operator") {
                if (
                  ["_in", "_nin"].includes(newRules[id].operator) &&
                  !["_in", "_nin"].includes(value)
                ) {
                  newRules[id].value = "";
                } else if (
                  !["._in", "_nin"].includes(newRules[id].operator) &&
                  ["_in", "_nin"].includes(value)
                ) {
                  newRules[id].value = [];
                }
              }
              newRules[id] = {
                ...newRules[id],
                [key]: value,
              };
              return newRules;
            }
            if (newRules[id].children) {
              newRules[id].children = updateNestedRule(
                newRules[id].children,
                targetId,
              );
            }
          }
          return newRules;
        };

        return {
          ...prev,
          children: updateNestedRule(prev.children, ruleId),
        };
      });
    },
    [ruleId, setRules],
  );

  const deleteRule = useCallback(() => {
    setRules((prev) => {
      const newRules = { ...prev };
      const deleteNestedRule = (rules, targetId) => {
        const newChildren = { ...rules };
        for (const id in newChildren) {
          if (id === targetId) {
            delete newChildren[id];
            return newChildren;
          }
          if (newChildren[id].children) {
            newChildren[id].children = deleteNestedRule(
              newChildren[id].children,
              targetId,
            );
          }
        }
        return newChildren;
      };
      newRules.children = deleteNestedRule(newRules.children, ruleId);
      return newRules;
    });
  }, [ruleId, setRules]);

  return (
    <Flex gap={16} align="center" className="group transition duration-300">
      <Dropdown
        menu={{
          items: fieldItems,
          onClick: (item) =>
            isAndOrCondition
              ? updateRule("operator", item.key)
              : updateRule("field", item.key),
        }}
        trigger={["click"]}
        className="shrink-0 border border-(--input-field-border) min-h-[30px] !px-3"
      >
        <Space className="cursor-pointer border rounded-full px-2 whitespace-nowrap">
          {selectedField?.field || "Select Field"}
          <DownOutlined />
        </Space>
      </Dropdown>
      {!isAndOrCondition && (
        <>
          <Dropdown
            menu={{
              items: operatorItems,
              onClick: (item) => updateRule("operator", item.key),
            }}
            trigger={["click"]}
            className="shrink-0 border border-(--input-field-border) min-h-[30px] !px-3"
          >
            <Space className="cursor-pointer border rounded-full px-2 whitespace-nowrap">
              {operatorMap[rule.operator]?.description || "Select Operator"}
              <DownOutlined />
            </Space>
          </Dropdown>
          {operatorMap[rule.operator]?.type?.includes("Array") ? (
            <Select
              value={rule.value}
              mode={"tags"}
              onChange={(value) => updateRule("value", value)}
              className="w-full min-w-full item-permissions-select"
            />
          ) : (
            <input
              type="text"
              value={rule.value}
              onChange={(e) => updateRule("value", e.target.value)}
              className="!rounded-full placeholder:font-normal !px-3 border border-(--input-field-border) shrink-0 max-w-[200px] min-h-[30px]"
            />
          )}
        </>
      )}
      <IoClose
        onClick={deleteRule}
        size={18}
        className="shrink-0 cursor-pointer transition duration-300 text-[rgba(var(--btn-delete),1)] opacity-0 group-hover:opacity-100"
      />
    </Flex>
  );
};

const DroppableArea = ({ children }) => {
  const style = {
    padding: "10px",
    gap: "10px",
    display: "flex",
    flexDirection: "column",
    minHeight: "100px",
    border: "1px dashed #ccc",
    borderRadius: "8px",
    transition: "background-color 0.2s",
    marginTop: "24px",
  };

  const { setNodeRef, isOver } = useSortable({
    id: "root",
    data: {
      type: "container",
    },
  });

  return (
    <div
      ref={setNodeRef}
      style={{
        ...style,
        backgroundColor: isOver
          ? "rgba(from var(--color-project-base) r g b / .1)"
          : "transparent",
      }}
      className="custom-scrollbar"
    >
      {children}
    </div>
  );
};

const ItemPermissions = ({
  DrawerTitle,
  selectedSchema,
  setPermissions,
  permissions,
  setIsDrawerOpen,
}) => {
  const { t } = useTranslation();
  const { rowData, action } = selectedSchema;
  const allFields = useSelector((state) => state?.fields?.fields);
  const schemaData = getFieldsBySchemaId({
    schemas: [selectedSchema.rowData],
    fields: allFields,
    schemaId: selectedSchema.rowData?._id,
  });

  const getFieldId = (fieldName) =>
    schemaData?.fields?.find((f) => f.path === fieldName)?._id;

  const processCondition = (condition) => {
    const field = Object.keys(condition)[0];
    const fieldId = getFieldId(field);
    const operator = Object.keys(condition[field])[0];
    const value = Object.values(condition[field])[0];
    if (!fieldId) return null;

    return {
      field: fieldId,
      operator: operator,
      value: value,
    };
  };

  const reverseTransformRules = (transformedRules) => {
    const result = {
      type: "root",
      children: {},
    };

    const processRules = (rules, parentId = "root") => {
      for (const [operator, conditions] of Object.entries(rules)) {
        if (operator === "_and" || operator === "_or") {
          const andOrConditionId = `and-or-condition-${Date.now()}`;
          result.children[andOrConditionId] = {
            operator: operator,
            children: {},
          };
          conditions.forEach((condition, index) => {
            const childRule = processCondition(condition);
            if (childRule) {
              const childRuleId = `${Date.now()}${index}`;
              result.children[andOrConditionId].children[childRuleId] =
                childRule;
            }
          });
        } else {
          const rule = processCondition({ [operator]: conditions });
          if (rule) {
            const ruleId = `${parentId}-${Date.now() + Math.random()}`;
            result.children[ruleId] = rule;
          }
        }
      }
    };

    processRules(transformedRules);
    return result;
  };

  const foundedPermission = permissions.find(
    (permission) =>
      permission.action === action && permission.collection_id === rowData?._id,
  );
  const initialRules = reverseTransformRules(
    JSON.parse(
      (typeof foundedPermission?.rules === "string" &&
        foundedPermission.rules) ||
        "{}",
    ),
  );

  const [rules, setRules] = useState(initialRules);

  const fieldOptions =
    convertTreeDataToOptions(
      convertToTreeData(schemaData?.fields || [], "tree-fields"),
      "field",
      "_id",
    ) || [];

  const ruleItems = [
    { label: "AND / OR condition", key: "and-or-condition" },
    { type: "divider" },
  ];
  ruleItems.push(...fieldOptions);

  const handleSubmit = useCallback(() => {
    const getFieldName = (fieldId) =>
      schemaData?.fields?.find((f) => f._id === fieldId)?.path;

    const processChildRule = (childRule) => {
      if (!childRule.field) return null;

      const fieldName = getFieldName(childRule.field);
      if (!fieldName) return null;

      return {
        [fieldName]: {
          [childRule.operator]: childRule.value || "",
        },
      };
    };

    const transformRules = (rulesObj) => {
      const result = {};

      for (const [ruleId, rule] of Object.entries(rulesObj.children)) {
        if (ruleId.startsWith("and-or-condition")) {
          const logicalOperator = rule.operator;
          const conditions = Object.values(rule.children)
            .map(processChildRule)
            .filter(Boolean);

          if (conditions.length > 0) {
            result[logicalOperator] = conditions;
          }
        } else {
          const condition = processChildRule(rule);
          if (condition) {
            Object.assign(result, condition);
          }
        }
      }

      return result;
    };

    const transformedRules = JSON.stringify(transformRules(rules) || {});
    setPermissions((prev) => {
      const existingPermission = prev.find(
        (permission) =>
          permission.collection_id === rowData?._id &&
          permission.action === action,
      );

      const newPermission = {
        action: action,
        collections: rowData?.collection_name,
        collection_id: rowData?._id,
        full_access: false,
        fields: [],
        fields_id: [],
        rules: transformedRules,
      };

      if (existingPermission) {
        Object.assign(existingPermission, {
          rules: transformedRules,
        });
        return [...prev];
      } else {
        return [...prev, newPermission];
      }
    });
    setIsDrawerOpen(false);
  }, [rules, schemaData?.fields]);

  const onDragEnd = useCallback(({ active, over }) => {
    if (
      !over ||
      active.id === over?.id ||
      !(over?.id?.includes("and-or-condition") || over?.id?.includes("root"))
    )
      return;

    setRules((prev) => {
      const findRuleAndParent = (rules, targetId) => {
        const dfs = (currentRules, parentId = null) => {
          if (targetId in currentRules) {
            return { rule: currentRules[targetId], parentId };
          }
          for (const [key, value] of Object.entries(currentRules)) {
            if (value.children) {
              const found = dfs(value.children, key);
              if (found) return found;
            }
          }
          return null;
        };
        return dfs(rules);
      };

      const removeRule = (rules, targetId) => {
        const newRules = { ...rules };

        if (targetId in newRules) {
          delete newRules[targetId];
          return newRules;
        }

        for (const [key, value] of Object.entries(newRules)) {
          if (value.children) {
            const updatedChildren = removeRule(value.children, targetId);
            newRules[key] = { ...value, children: updatedChildren };
          }
        }
        return newRules;
      };

      const found = findRuleAndParent(prev.children, active.id);
      if (!found) return prev;

      const { rule: draggedRule } = found;

      let newChildren = removeRule(prev.children, active.id);

      if (over.id === "root") {
        newChildren = {
          ...newChildren,
          [active.id]: draggedRule,
        };
      } else {
        const targetFound = findRuleAndParent(newChildren, over.id);
        if (
          targetFound?.rule?.operator === "_and" ||
          targetFound?.rule?.operator === "_or"
        ) {
          targetFound.rule.children = {
            ...targetFound.rule.children,
            [active.id]: draggedRule,
          };
        }
      }

      return {
        ...prev,
        children: newChildren,
      };
    });
  }, []);

  return (
    <div>
      <Flex wrap="wrap" align="center" justify="space-between">
        <LeftSideTitlePart
          pageTitle={<DrawerTitle />}
          pageIcon={true}
          pageInnerIcon={<IconsAll.DataModalIcon />}
          backButton={false}
        />
        <RightSIdeTitlePart showButton={true} handleShowClick={handleSubmit} />
      </Flex>
      <DndContext collisionDetection={pointerWithin} onDragEnd={onDragEnd}>
        <SortableContext
          items={["root", ...Object.keys(rules.children)]}
          strategy={verticalListSortingStrategy}
        >
          <DroppableArea>
            {Object.entries(rules.children).map(([ruleId, rule]) => (
              <SortableRule
                key={ruleId}
                ruleId={ruleId}
                rule={rule}
                setRules={setRules}
                schemaData={schemaData}
                fieldOptions={fieldOptions}
              />
            ))}
          </DroppableArea>
        </SortableContext>
      </DndContext>
      <Dropdown
        menu={{
          items: ruleItems,
          onClick: ({ key }) => {
            const isAndOrCondition = key === "and-or-condition";
            const newRuleId = (isAndOrCondition ? key : "") + Date.now();
            setRules((prev) => ({
              ...prev,
              children: {
                ...prev.children,
                [newRuleId]: {
                  field: isAndOrCondition ? undefined : key,
                  operator: isAndOrCondition ? "_and" : "_eq",
                  value: "",
                  children: {},
                },
              },
            }));
          },
        }}
        trigger={["click"]}
      >
        <Space className="cursor-pointer text-[var(--project-color)] mt-5">
          {t("add_rule")}
          <DownOutlined />
        </Space>
      </Dropdown>
    </div>
  );
};

export default ItemPermissions;
