import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Card,
  Checkbox,
  Flex,
  Modal,
  notification,
  Pagination,
  Select,
  Spin,
} from "antd";
import SubMenu from "../../Components/SubMenu";
import { useTranslation } from "react-i18next";
import AppInfo from "../../Components/AppInfo";
import LeftSideTitlePart from "../../Components/HeaderParts/LeftSideTitlePart";
import IconsAll from "../../Components/IconsAll";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { themes } from "../../config";
import Paragraph from "antd/es/typography/Paragraph";
import {
  MdDeleteOutline,
  MdOutlineCreateNewFolder,
  MdOutlineDriveFileMove,
  MdOutlineEdit,
} from "react-icons/md";
import { FaRegFolder } from "react-icons/fa";
import axiosInstance from "../../Helpers/axios";
import Apis from "../../Helpers/Apis";
import { Link, useNavigate, useParams } from "react-router-dom";
import CustomDragger from "../../Components/DynamicForm/Components/CustomDragger";
import RightSIdeTitlePart from "../../Components/HeaderParts/RightSIdeTitlePart";
import {
  setDestinationFolderData,
  setFolderActionData,
  setIsMoveFolderModalOpen,
  setSelectedFolderData,
} from "../../Redux/slices/media.reducer";
import { getDisplayImage } from "../../Helpers/utils";
import EditFiles from "../../Components/DynamicForm/Components/EditFiles";
import { GoSortAsc, GoSortDesc } from "react-icons/go";
import FileExtension from "../../Components/DynamicForm/Components/FileExtension";
import NoData from "../../Components/NoData";
import { usePagination } from "../../Helpers/custom-hooks/usePagination";
import { setActiveTab } from "../../Redux/slices/activeTab.reducer";
import FolderActionsModal from "./FolderActionsModal";
import { getAndSetMediaFolders } from "../../Helpers/functions";
import {
  addMediaFolder,
  deleteMediaFolder,
  moveMediaFolder,
  renameMediaFolder,
} from "../../Redux/slices/mediaFolders.reducers";
import { RiDeleteBin6Line } from "react-icons/ri";

const GebruikerSort = styled(Flex)`
  border-radius: 8px;
  border: 1px solid var(--input-field-border);
  background: var(--gebruikers-table-sortBg);
  box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.04);
  backdrop-filter: blur(2px);
  position: sticky;
  top: 10px;
  z-index: 1;
  padding: 13px 24px;
  align-items: center;
  justify-content: space-between;

  .ant-checkbox-wrapper-disabled {
    opacity: 0.5;
  }

  .ant-checkbox {
    .ant-checkbox-inner {
      border-radius: 100vh;
      border: 1px solid #88909a;
    }

    + span {
      color: #88909a;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
    }
  }

  .email-sort-button {
    &:hover,
    &:active,
    &:focus-visible {
      background-color: transparent !important;
    }
  }
`;

const DocumentCard = styled(Card)`
  border: none;
  max-width: 160px;
  position: relative;
  background-color: transparent;

  &:hover {
    .dock-checkbox {
      opacity: 1;
    }
  }

  .dock-checkbox {
    position: absolute;
    top: 5px;
    left: 5px;
    transition: 0.3s ease-in-out;
    z-index: 1;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
  }

  .ant-card-body {
    padding: 0;

    .doc-image {
      width: 160px;
      height: 160px;
      border-radius: 10px;
      margin-bottom: 5px;
      overflow: hidden;
      background-color: ${() =>
        themes[useSelector((state) => state.theme.name)].components.Menu
          .itemBg};
      display: flex;
      align-items: center;
      justify-content: center;

      > img {
        object-fit: cover;
        border-radius: 10px;
      }

      > svg {
        width: 50px;
        height: 50px;
        object-fit: contain;
      }
    }

    .folder-info {
      list-style: none;
      margin-bottom: 0;
      display: flex;
      font-size: 14px;
      gap: 5px;

      li {
        margin: 0;
        padding: 0;
      }
    }
  }

  &:has(.ant-checkbox-checked) {
    .doc-image {
      > img {
        transform: scale(0.9);
      }
    }
  }
`;

const FileCard = ({
  file,
  selectedFiles,
  setSelectedFiles,
  allowMultiple,
  iFace,
}) => {
  const [imageUrl, setImageUrl] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    assignImageUrl();
  }, [file?._id]);

  const assignImageUrl = async () => {
    try {
      setImageUrl(
        ["image/jpg", "image/jpeg", "image/png"].includes(file?.type)
          ? await getDisplayImage(file?._id, 160, 160)
          : null,
      );
    } catch (error) {
      console.log("Error while preview image");
    }
  };

  return (
    <DocumentCard key={file?._id}>
      <Checkbox
        onChange={(e) =>
          setSelectedFiles((prev) =>
            allowMultiple
              ? e.target.checked
                ? [...prev, file?._id]
                : prev?.filter((p) => p !== file?._id)
              : [file?._id],
          )
        }
        checked={selectedFiles?.includes(file?._id)}
        className={`dock-checkbox ${
          !selectedFiles?.length ? "opacity-0" : "opacity-100"
        }`}
      ></Checkbox>
      <div
        className="doc-image"
        onClick={() =>
          !iFace && navigate(`/media-library/file/${file?._id}/edit`)
        }
      >
        {imageUrl ? (
          <img src={imageUrl} alt="image" />
        ) : (
          <FileExtension
            size={90}
            extension={file?.filename_disk?.split(".")?.pop()}
          />
        )}
        {/* <IconsAll.AdministratorIcon /> */}
      </div>
      <Paragraph
        className="mb-1! font-bold whitespace-nowrap text-ellipsis overflow-hidden"
        title={file?.title}
      >
        {file?.title}
      </Paragraph>
      <Paragraph>
        <ul className="folder-info">
          <li>{file?.filename_disk?.split(".")?.pop()}</li>
          <li>.</li>
          <li>{file?.file_size} kB</li>
        </ul>
      </Paragraph>
    </DocumentCard>
  );
};
const Media = ({
  updateContentFilesList,
  setShowDrawer,
  iFace,
  folderScope,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    selectedFolderData,
    isMoveFolderModalOpen,
    folderActionData,
    destinationFolderData,
  } = useSelector((state) => state.media);
  const loader = useSelector((state) => state.loader.isLoading);
  const { folderId, fileId } = useParams();
  const preSelectedFolder = folderScope || folderId;
  const folders = useSelector((state) => state.mediaFolders.folders);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [subMenus, setSubMenus] = useState([]);
  const [newFolderName, setNewFolderName] = useState("");
  const [isFileModalOpen, setIsFileModalOpen] = useState(false);
  const [filesList, setFilesList] = useState([]);
  const [fileListLoading, setFileListLoading] = useState(false);
  const [folderModal, setFolderModal] = useState({
    type: t("create"),
    isOpen: false,
    loading: false,
  });
  const [openFolders, setOpenFolders] = useState([]);
  const [foldersLoading, setFoldersLoading] = useState(false);
  const sortOptions = [
    {
      value: "title",
      label: t("title"),
    },
    {
      value: "file_size",
      label: t("size"),
    },
    {
      value: "created_at",
      label: t("uploaded_on"),
    },
  ];

  const { pagination, updatePagination } = usePagination(
    1,
    25,
    "created_at",
    true,
  );

  useEffect(() => {
    if (iFace) {
      setFoldersLoading(true);
      setFileListLoading(true);
    }
    getFoldersList();
  }, []);

  useEffect(() => {
    if (Object.keys(selectedFolderData).length) {
      getFilesList();
    }
  }, [selectedFolderData?._id]);

  const getFilesList = async (
    page = pagination.currentPage,
    limit = pagination.pageSize,
    isSortDesc = pagination.isSortDesc,
    sortKey = pagination.sortKey,
  ) => {
    try {
      setFileListLoading(true);
      const resp = await axiosInstance.get(
        Apis.GET_FILES({
          folder: selectedFolderData?.isRoot
            ? ""
            : selectedFolderData?._id || "",
          page: page,
          limit: limit,
          descending: isSortDesc,
          sortBy: sortKey,
        }),
      );
      if (resp.status === 200) {
        updatePagination(
          "totalItems",
          resp.data?.payload?.meta?.total_found || 0,
        );
        updatePagination("currentPage", page);
        updatePagination("pageSize", limit);
        updatePagination("sortKey", sortKey);
        updatePagination("isSortDesc", isSortDesc);
        setFilesList(resp.data?.payload?.data);
      }
    } catch (error) {
      console.log("Error", error);
    } finally {
      setFileListLoading(false);
    }
  };

  const updateFilesList = () => {
    getFilesList();
  };

  const moveFolder = async () => {
    try {
      if (
        !(
          destinationFolderData?._id &&
          destinationFolderData?._id !== folderActionData?._id
        )
      )
        return;
      await axiosInstance.put(Apis.MOVE_FOLDER(), {
        sourceFolder: folderActionData?._id,
        destinationFolder: destinationFolderData?._id,
      });
      notification.success({
        message: t("folder_move_success", {
          folder: folderActionData?.name,
          destinationFolder: destinationFolderData?.name,
        }),
      });
      setFoldersLoading(true);
      dispatch(
        moveMediaFolder({
          sourceFolderId: folderActionData?._id,
          destinationFolderId: destinationFolderData?._id,
        }),
      );

      let updatedFolders = folders.map((folder) =>
        folder._id === folderActionData?._id
          ? { ...folder, parent: destinationFolderData?._id }
          : folder,
      );
      setSubMenus(transformData(updatedFolders));
    } catch (error) {
      notification.error({
        message: t("folder_move_error", {
          folder: folderActionData?.name,
          destinationFolder: destinationFolderData?.name,
        }),
      });
      console.log("Error", error);
    } finally {
      setFoldersLoading(false);
      dispatch(setIsMoveFolderModalOpen(false));
      dispatch(setFolderActionData({}));
      dispatch(setDestinationFolderData({}));
    }
  };

  const CustomDropdown = ({ title, indentation = 0, link, folderData }) => {
    const dispatch = useDispatch();
    const {
      selectedFolderData,
      isMoveFolderModalOpen,
      folderActionData,
      destinationFolderData,
    } = useSelector((state) => state.media);

    const [dropDownVisible, setIsDropdownVisible] = useState(false);
    const dropDownPadding = 20;
    const dropdownRef = useRef(null);
    const [dropdownListClient, setDropdownListClient] = useState({
      X: 0,
      Y: 0,
    });

    const handleRightClick = (event) => {
      if (
        !window.location.pathname.includes("media-library") ||
        dropDownVisible ||
        isMoveFolderModalOpen ||
        folderData?.isRoot
      )
        return;
      event.preventDefault();
      setIsDropdownVisible(true);
      setDropdownListClient({ X: event.clientX + 10, Y: event.clientY - 10 });
    };

    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownVisible(false);
      }
    };

    const selectCurrentFolder = (folderData) => {
      if (isMoveFolderModalOpen) {
        if (folderData._id !== folderActionData?._id) {
          dispatch(setDestinationFolderData(folderData));
        }
        return;
      }
      dispatch(setSelectedFolderData(folderData));
      dispatch(
        setActiveTab({
          page: "media-library",
          tab: folderData?.name,
          redirectionLink: `/media-library${folderData?.isRoot ? "" : "/" + folderData?._id}`,
        }),
      );
    };

    useEffect(() => {
      if (dropDownVisible) {
        document.addEventListener("mousedown", handleClickOutside);
      } else {
        document.removeEventListener("mousedown", handleClickOutside);
      }

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [dropDownVisible]);

    return (
      <div
        className={`flex items-center justify-between ${
          folderData._id === folderActionData?._id
            ? "disabled-menu"
            : folderData._id === destinationFolderData?._id
              ? "custom-destination-folder"
              : folderData._id === selectedFolderData?._id
                ? "custom-selected-submenu"
                : ""
        }`}
        onContextMenu={handleRightClick}
      >
        <div
          className="edit-delete-wrapper overflow-hidden"
          ref={dropdownRef}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Link
            to={!iFace && !isMoveFolderModalOpen ? link : ""}
            className="block"
            onClick={() => {
              selectCurrentFolder(folderData);
            }}
            style={{ paddingLeft: dropDownPadding * indentation + "px" }}
          >
            <div className="flex items-center gap-3 fs-14 w-[90%]">
              {folderData?.isRoot ? (
                <MdOutlineCreateNewFolder size={24} />
              ) : (
                <FaRegFolder size={24} className="shrink-0" />
              )}
              <span
                className="text-ellipsis overflow-hidden text-heading-text text-base font-light"
                style={{ lineHeight: "unset" }}
              >
                {title}
              </span>
            </div>
          </Link>
          {dropDownVisible && (
            <ul
              style={{ left: dropdownListClient.X, top: dropdownListClient.Y }}
              className="edit-delete-dropdown"
            >
              <li
                onClick={() => {
                  setFolderModal({
                    type: t("rename"),
                    isOpen: true,
                    loading: false,
                  });
                  setNewFolderName(folderData?.name);
                  dispatch(setFolderActionData(folderData));
                }}
                className="flex items-center gap-2"
              >
                <MdOutlineEdit size={20} className="shrink-0" />
                {t("rename")}
              </li>
              <li
                onClick={() => {
                  dispatch(setFolderActionData(folderData));
                  dispatch(setIsMoveFolderModalOpen(true));
                }}
                className="flex items-center gap-2"
              >
                <MdOutlineDriveFileMove size={20} className="shrink-0" />
                {t("move")}
              </li>
              <li
                className="flex items-center gap-2 text-delete-base!"
                onClick={() => {
                  dispatch(setFolderActionData(folderData));
                  setFolderModal({
                    type: t("delete"),
                    isOpen: true,
                    loading: false,
                  });
                }}
              >
                <MdDeleteOutline
                  size={20}
                  className="shrink-0 text-delete-base!"
                />
                {t("delete")}
              </li>
            </ul>
          )}
        </div>
        <div className="w-[45px]"></div>
      </div>
    );
  };

  const getFoldersList = async () => {
    setSubMenus(
      transformData(await getAndSetMediaFolders({ folders, dispatch })),
    );
    setFoldersLoading(false);
  };

  const openkeysCalculation = (folders = [], initialKey) => {
    let parentFolderId = initialKey;
    const parentIds = [];
    while (parentFolderId) {
      const currentFolderData = folders.find((item) => {
        return item._id === parentFolderId;
      });
      if (currentFolderData?._id) {
        parentIds.push(currentFolderData._id);
      }
      parentFolderId = currentFolderData?.parent;
    }
    setOpenFolders([...parentIds]);
  };

  const transformData = (folders) => {
    let matchedFolderData = {};

    const buildAndTransformHierarchy = (parentId, indentation = 1) => {
      return folders
        ?.filter((folder) => folder.parent === parentId)
        .map((f) => {
          let folder = { ...f };
          folder.isRoot = folder.parent === null;
          if (folder._id === preSelectedFolder) {
            matchedFolderData = folder;
          } else if (folder.isRoot) {
            matchedFolderData = folder;
          }

          const children = buildAndTransformHierarchy(
            folder._id,
            indentation + 1,
          );

          const transformedItem = {
            key: folder._id,
            indentation,
            ...(folder.isRoot
              ? {
                  name: (
                    <CustomDropdown
                      title={folder.name}
                      indentation={indentation}
                      link={"/media-library"}
                      folderData={folder}
                    />
                  ),
                }
              : {
                  label: (
                    <CustomDropdown
                      title={folder.name}
                      indentation={indentation}
                      link={"/media-library/" + folder._id}
                      folderData={folder}
                    />
                  ),
                }),
            children: children.length > 0 ? children : undefined,
          };

          if (
            folder.isRoot &&
            !preSelectedFolder &&
            selectedFolderData?._id !== folder._id &&
            !matchedFolderData?._id
          ) {
            matchedFolderData = folder;
          }

          return transformedItem;
        });
    };

    const hierarchicalData = buildAndTransformHierarchy(null);

    if (matchedFolderData?._id) {
      if (matchedFolderData?.isRoot && preSelectedFolder) {
        dispatch(setSelectedFolderData({}));
        navigate("/media-library");
      }
      if (selectedFolderData?._id !== matchedFolderData?._id) {
        dispatch(setSelectedFolderData(matchedFolderData));
      }
      openkeysCalculation(folders, matchedFolderData._id);
    }

    return hierarchicalData;
  };

  function updateItemByKey(data, targetKey, newData, action = "update") {
    function recursiveUpdate(item) {
      if (item.key === targetKey) {
        if (action === "delete") {
          dispatch(deleteMediaFolder(item?.key));
          return null;
        }
        if (React.isValidElement(item.label)) {
          const updatedLabelProps = {
            ...JSON.parse(JSON.stringify(item?.label?.props || {})),
          };

          for (const key in newData) {
            if (key.startsWith("label.props.")) {
              const propPath = key.replace("label.props.", "").split(".");
              let current = updatedLabelProps;

              for (let i = 0; i < propPath.length - 1; i++) {
                const part = propPath[i];
                if (!current[part]) {
                  current[part] = {};
                }
                current = current[part];
              }

              current[propPath[propPath.length - 1]] = newData[key];
            }
          }

          dispatch(setSelectedFolderData(updatedLabelProps.folderData));
          dispatch(
            renameMediaFolder({
              folderId: item?.key,
              title: updatedLabelProps?.title,
            }),
          );
          return {
            ...item,
            label: React.cloneElement(item.label, updatedLabelProps),
          };
        }

        return { ...item, ...newData };
      }

      if (action === "create" && item.key === newData.parent) {
        // Add newly created folder to the parent folder
        let children = [
          ...(item?.children || []),
          {
            key: newData._id,
            indentation: item.indentation + 1,
            label: (
              <CustomDropdown
                title={newData.name}
                indentation={item.indentation + 1}
                link={"/media-library/" + newData._id}
                folderData={newData}
              />
            ),
          },
        ];

        if (!openFolders.includes(item.key)) {
          setOpenFolders([...openFolders, item.key]);
        }
        dispatch(addMediaFolder(newData));
        return {
          ...item,
          children,
        };
      }

      // If the item has children, apply the recursive update on them
      if (item.children && item.children.length > 0) {
        let children = [];
        // Apply the recursive update on the children
        item.children.map((child) => {
          const updatedChild = recursiveUpdate(child);
          if (action === "delete" && updatedChild === null) {
            dispatch(
              setSelectedFolderData({
                ...(item?.label?.props?.folderData || {}),
              }),
            );
          }
          updatedChild && children.push(updatedChild);
        });

        return {
          ...item,
          children,
        };
      }

      return item;
    }

    // Apply the recursive update starting from the root data
    return recursiveUpdate(data);
  }

  const handleFilesDelete = async () => {
    Modal.confirm({
      title: t("delete_files"),
      content: t("delete_files_confirm"),
      icon: (
        <div className="modal-icon">
          <RiDeleteBin6Line size={24} />
        </div>
      ),
      okText: t("delete"),
      okType: "primary",
      cancelText: t("cancel"),
      maskClosable: true,
      className: "modal-delete",
      onOk: async () => {
        try {
          const resp = await axiosInstance.put(Apis.DELETE_FILE(), {
            ids: selectedFiles,
          });
          if (resp.status === 200) {
            notification.success({
              message:
                selectedFiles?.length > 1
                  ? t("files_delete_success")
                  : t("file_delete_success"),
            });
            getFilesList();
            setSelectedFiles([]);
          }
        } catch (error) {
          notification.error({ message: t("files_delete_error") });
        }
      },
    });
  };

  const handleSelect = () => {
    updateContentFilesList(
      filesList?.filter((file) => selectedFiles?.includes(file?._id)),
    );
    setShowDrawer(false);
  };

  const onOpenChange = (keys) => {
    setOpenFolders(keys);
  };

  return (
    <Flex className="w-full main-content-wrap">
      <SubMenu
        isSubMenuLogo={true}
        isSubMenuVersion={true}
        items={subMenus}
        openKeys={openFolders}
        currentPage={"media"}
        onOpenChange={onOpenChange}
        isItemsLoading={foldersLoading}
      />
      {fileId ? (
        <EditFiles fileId={fileId} getFilesList={getFilesList} />
      ) : (
        !loader &&
        !foldersLoading && (
          <div id="left-part" className="px-4 xl:px-16 w-full left-part">
            <div className="primary-header pt-9 pb-5">
              <LeftSideTitlePart
                pageIcon={true}
                pageInnerIcon={
                  <IconsAll.MediaLibraryIcon
                    strokeColor={"var(--project-base)"}
                  />
                }
                pageTitle={selectedFolderData?.name || t("file_library")}
                pageSubTitle={t("file_library")}
              />
              <RightSIdeTitlePart
                addButton={!iFace}
                handleAddClick={() => setIsFileModalOpen(true)}
                addDisabled={fileListLoading}
                deleteButton={selectedFiles?.length > 0 && !iFace}
                deleteDisabled={!selectedFiles?.length}
                handleDeleteClick={handleFilesDelete}
                folderButton={!iFace}
                folderDisabled={fileListLoading}
                showButton={iFace}
                handleShowClick={handleSelect}
                handleFolderClick={() => {
                  setFolderModal({
                    type: t("create"),
                    isOpen: true,
                    loading: false,
                  });
                  setNewFolderName("");
                }}
              />
              {/* <===================== File Modal =====================> */}
              <Modal
                title={t("add_file")}
                open={isFileModalOpen}
                okText={t("ok")}
                cancelText={t("cancel")}
                okButtonProps={{ className: "modal-primary-btn" }}
                cancelButtonProps={{ className: "modal-default-btn" }}
                onOk={() => setIsFileModalOpen(false)}
                onCancel={() => setIsFileModalOpen(false)}
                maskClosable={false}
                destroyOnClose
              >
                <CustomDragger
                  iFace={`files-page`}
                  setIsModalOpen={setIsFileModalOpen}
                  updateFilesList={updateFilesList}
                  folder={selectedFolderData?._id}
                />
              </Modal>
              {/* <===================== Move Folder Modal =====================> */}
              <Modal
                title={t("move_folder")}
                open={isMoveFolderModalOpen}
                okText={t("ok")}
                cancelText={t("cancel")}
                onOk={() => {
                  moveFolder();
                }}
                onCancel={() => {
                  dispatch(setIsMoveFolderModalOpen(false));
                  dispatch(setDestinationFolderData({}));
                }}
                className="move-folder-modal"
                destroyOnClose={true}
                cancelButtonProps={{ className: "modal-default-btn" }}
                okButtonProps={{
                  className: "modal-primary-btn",
                  disabled: !(
                    destinationFolderData?._id &&
                    destinationFolderData?._id !== folderActionData?._id
                  ),
                }}
              >
                <SubMenu
                  isSubMenuLogo={false}
                  isSubMenuVersion={false}
                  items={subMenus}
                  from={"media"}
                  defaultOpenKeys={openFolders}
                />
              </Modal>
              {/* <===================== Commmon Folder Modal =====================> */}
              <FolderActionsModal
                folderModal={folderModal}
                setFolderModal={setFolderModal}
                selectedFolderData={selectedFolderData}
                isCustomFolderNameInput={true}
                newFolderName={newFolderName}
                setNewFolderName={setNewFolderName}
                afterRenameAPI={(folderName) => {
                  setSubMenus((prevData) => [
                    updateItemByKey(prevData?.[0], folderActionData._id, {
                      ["label.props.title"]: folderName,
                      ["label.props.folderData.name"]: folderName,
                    }),
                  ]);
                }}
                afterDeleteAPI={() => {
                  setSubMenus((prevData) => [
                    updateItemByKey(
                      prevData?.[0],
                      folderActionData._id,
                      {},
                      "delete",
                    ),
                  ]);
                }}
                afterCreateAPI={(resp) => {
                  setSubMenus((prevData) => [
                    updateItemByKey(
                      prevData?.[0],
                      resp.data.payload.data._id,
                      resp.data.payload.data,
                      "create",
                    ),
                  ]);
                }}
              />
            </div>
            {fileListLoading || foldersLoading ? (
              <Flex
                className="items-center justify-center"
                style={{ minHeight: "calc(100% - 350px)" }}
              >
                <Spin
                  indicator={<div className="custom-loader" />}
                  size="default"
                />
              </Flex>
            ) : filesList?.length ? (
              <>
                <GebruikerSort className="mb-8">
                  <Checkbox
                    onChange={(e) =>
                      setSelectedFiles(
                        e.target.checked ? filesList?.map((f) => f?._id) : [],
                      )
                    }
                    disabled={["file", "file-image"].includes(iFace)}
                    checked={
                      selectedFiles?.length > 0 &&
                      selectedFiles?.length === filesList?.length
                    }
                  >
                    {t("select_all")}
                  </Checkbox>
                  <div className="flex items-center justify-between">
                    <div className="w-full min-w-[120px]">
                      <span className="block text-sm text-slate-400">
                        {t("sort_on")}:
                      </span>
                      <Select
                        options={sortOptions}
                        variant="borderless"
                        onChange={(value) => {
                          getFilesList(
                            pagination.page,
                            pagination.size,
                            pagination.isSortDesc,
                            value,
                          );
                        }}
                        value={pagination.sortKey}
                        size={"small"}
                        suffixIcon={false}
                        className="remove-select-inline-padding"
                        disabled={fileListLoading}
                      />
                    </div>
                    <Button
                      icon={
                        pagination.isSortDesc ? (
                          <GoSortDesc size={20} />
                        ) : (
                          <GoSortAsc size={20} />
                        )
                      }
                      onClick={() => {
                        getFilesList(
                          pagination.page,
                          pagination.size,
                          !pagination.isSortDesc,
                        );
                      }}
                      disabled={fileListLoading}
                      className="bg-[transparent] border-[none] p-0 text-project-base hover:bg-[transparent]! hover:text-(--project-color)!"
                    ></Button>
                  </div>
                </GebruikerSort>
                <Flex gap="middle" align="start" wrap="wrap">
                  {filesList?.map((file) => {
                    return (
                      <FileCard
                        key={file?._id}
                        file={file}
                        selectedFiles={selectedFiles}
                        setSelectedFiles={setSelectedFiles}
                        allowMultiple={!["file", "file-image"].includes(iFace)}
                        iFace={iFace}
                      />
                    );
                  })}
                </Flex>
              </>
            ) : (
              <NoData
                icon={<FaRegFolder size={30} color="#00000040" />}
                title={t("no_files")}
                description={t("there_are_no_files_here")}
              />
            )}
            {filesList?.length > 0 && (
              <Pagination
                pageSize={pagination.pageSize}
                current={pagination.currentPage}
                total={pagination.totalItems}
                onChange={(page, size) => {
                  getFilesList(page, size);
                }}
                onShowSizeChange={(current, size) =>
                  updatePagination("pageSize", size)
                }
                disabled={fileListLoading}
                className="py-8"
                locale={{ items_per_page: "" }}
              />
            )}
          </div>
        )
      )}
      {/* <Col className="right-part"> */}
      {!loader && (
        <AppInfo
          visibleList={["information"]}
          config={{
            msg: t("information_file_library"),
          }}
        />
      )}
      {/* </Col> */}
    </Flex>
  );
};

export default Media;
