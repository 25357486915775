const themes = {
  /* <============ NOX Standard Color Properties ============> */
  noxStandard: {
    name: "noxStandard",
    key: "light_nox_standard_theme",
    type: "light",
    img: "NoxStandardIcon",
    companyLogoHeight: "16",
    direction: "ltr",
    token: {
      //colors Palette
      grayClr: "var(--project-base-text)",
      // Spacing and sizes
      controlHeight: 35,
      // Font Family
      fontFamily: "Lexend",
    },
    components: {
      Menu: {
        verticalSidebarWidth: 260,
      },
      Table: {
        tableBg: "var(--body-bg)",
        borderColor: "rgba(225, 227, 230, 1)",
        sortedBg: "#F5F6F7",
        fontSize: "16px",
        fontWeightStrong: "500",
        imgLocationClr: "#394656",
        rowHoverBg: "rgba(0, 0, 0, .05)",
      },

      Select: {
        borderRadius: 12,
        selectorBg: "#ffffff",
        colorIcon: "#E0364E",
        controlHeight: 56,
        colorBgContainer: "#ffffff",
      },
      Input: {
        colorBgContainer: "#FFF",
        colorBorder: "#E1E3E6",
        colorTextPlaceholder: "#88909A",
      },
      HeaderButtonsStyle: {
        // folder Button Style
        folderButtonBg: "var(--project-base)",
        folderButtonClr: "var(--project-base-text)",
        // Hover Style
        folderButtonHoverBg: "rgba(from var(--project-base) r g b / 90%)",
        folderButtonHoverClr: "var(--project-base-text)",

        // Add Button Style
        addButtonBg: "var(--project-base)",
        addButtonClr: "var(--project-base-text)",
        // Hover Style
        addButtonHoverBg: "rgba(from var(--project-base) r g b / 90%)",
        addButtonHoverClr: "var(--project-base-text)",

        // delete Button Style
        deleteButtonBg: "rgba(224, 54, 78, 0.08)",
        deleteButtonClr: "#E0364E",
        // Hover Style
        deleteButtonHoverBg: "#E0364E",
        deleteButtonHoverClr: "#fff",

        // Submit Button Style
        submitButtonBg: "var(--project-base)",
        submitButtonClr: "var(--project-base-text)",
        // Hover Style
        submitButtonHoverBg: "rgba(from var(--project-base) r g b / 90%)",
        submitButtonHoverClr: "var(--project-base-text)",

        // disabled Submit Button Style
        disabledSubmitButtonBg: "var(--project-base)",
        disabledSubmitButtonClr: "var(--project-base-text)",
        // Hover Style
        disabledSubmitButtonHoverBg:
          "rgba(from var(--project-base) r g b / 90%)",
        disabledSubmitButtonHoverClr: "var(--project-base-text)",

        // Archive Button Style
        archiveButtonBg: "var(--project-base)",
        archiveButtonClr: "var(--project-base-text)",
        // Hover Style
        archiveButtonHoverBg: "rgba(from var(--project-base) r g b / 90%)",
        archiveButtonHoverClr: "var(--project-base-text)",

        // back button Style
        backButtonBg: " var(--project-base)",
        backButtonBorder: "rgba(12, 35, 64, 0.24)",
        backButtonClr: "var(--project-base-text)",
      },
      TreeSelect: {
        titleHeight: 36,
      },
    },
  },

  /* <============ Minimal Color Properties ============> */
  minimal: {
    name: "minimal",
    key: "light_nox_minimal_theme",
    type: "light",
    img: "NoxMiniMalIcon",
    companyLogoHeight: "16",
    direction: "ltr",
    token: {
      //colors Palette
      grayClr: "var(--project-base-text)",
      // Spacing and sizes
      controlHeight: 35,
      // Font Family
      fontFamily: "Lexend",
    },
    components: {
      Menu: {
        verticalSidebarWidth: 260,
      },
      Table: {
        tableBg: "var(--body-bg)",
        borderColor: "rgba(225, 227, 230, 1)",
        sortedBg: "#F5F6F7",
        fontSize: "16px",
        fontWeightStrong: "500",
        imgLocationClr: "#394656",
        rowHoverBg: "rgba(0, 0, 0, .05)",
      },

      Select: {
        borderRadius: 12,
        selectorBg: "#ffffff",
        colorIcon: "#E0364E",
        controlHeight: 56,
        colorBgContainer: "#FFFFFF",
        colorBorder: "#E1E3E6",
        colorPrimary: "#88909A",
        colorPrimaryHover: "#88909A",
      },
      Input: {
        colorBgContainer: "#FFFFFF",
        colorBorder: "#E1E3E6",
        colorTextPlaceholder: "#88909A",
        activeBorderColor: "#88909A",
        hoverBorderColor: "#88909A",
      },
      HeaderButtonsStyle: {
        // folder Button Style
        folderButtonBg: "var(--project-base)",
        folderButtonClr: "var(--project-base-text)",
        // Hover Style
        folderButtonHoverBg: "rgba(from var(--project-base) r g b / 90%)",
        folderButtonHoverClr: "var(--project-base-text)",

        // Add Button Style
        addButtonBg: "var(--project-base)",
        addButtonClr: "var(--project-base-text)",
        // Hover Style
        addButtonHoverBg: "rgba(from var(--project-base) r g b / 90%)",
        addButtonHoverClr: "var(--project-base-text)",

        // delete Button Style
        deleteButtonBg: "rgba(224, 54, 78, 0.08)",
        deleteButtonClr: "#E0364E",
        // Hover Style
        deleteButtonHoverBg: "#E0364E",
        deleteButtonHoverClr: "#fff",

        // Submit Button Style
        submitButtonBg: "var(--project-base)",
        submitButtonClr: "var(--project-base-text)",
        // Hover Style
        submitButtonHoverBg: "rgba(from var(--project-base) r g b / 90%)",
        submitButtonHoverClr: "var(--project-base-text)",

        // disabled Submit Button Style
        disabledSubmitButtonBg: "var(--project-base)",
        disabledSubmitButtonClr: "var(--project-base-text)",
        // Hover Style
        disabledSubmitButtonHoverBg:
          "rgba(from var(--project-base) r g b / 90%)",
        disabledSubmitButtonHoverClr: "var(--project-base-text)",

        // Archive Button Style
        archiveButtonBg: "var(--project-base)",
        archiveButtonClr: "var(--project-base-text)",
        // Hover Style
        archiveButtonHoverBg: "rgba(from var(--project-base) r g b / 90%)",
        archiveButtonHoverClr: "var(--project-base-text)",

        // back button Style
        backButtonBg: " var(--project-base)",
        backButtonBorder: "rgba(12, 35, 64, 0.24)",
        backButtonClr: "var(--project-base-text)",
      },
      TreeSelect: {
        titleHeight: 36,
      },
    },
  },

  /* <============ Color Match Color Properties ============> */
  colorMatch: {
    name: "colorMatch",
    key: "light_nox_color_match_theme",
    type: "light",
    img: "NoxColorMatchIcon",
    companyLogoHeight: "16",
    direction: "ltr",
    token: {
      //colors Palette
      grayClr: "var(--project-base-text)",
      // Spacing and sizes
      controlHeight: 35,
      // Font Family
      fontFamily: "Lexend",
    },
    components: {
      Menu: {
        verticalSidebarWidth: 260,
      },
      Table: {
        tableBg: "color-mix(in oklab, var(--table-row-strips) 25%, white)",
        borderColor: "rgba(0, 0, 0, .05)",
        sortedBg: "#c4e5ea",
        fontSize: "16px",
        fontWeightStrong: "500",
        imgLocationClr: "#394656",
        rowHoverBg: "var(--table-row-strips)",
      },

      Select: {
        borderRadius: 12,
        selectorBg: "#ffffff",
        colorIcon: "#E0364E",
        controlHeight: 56,
        colorBgContainer: "#FFFFFF",
        colorBorder: "#E1E3E6",
        colorPrimary: "#88909A",
        colorPrimaryHover: "#88909A",
      },
      Input: {
        colorBgContainer: "#9DCED4",
        colorBorder: "#E1E3E6",
        colorTextPlaceholder: "#08182C",
        activeBorderColor: "#FFFFFF",
        hoverBorderColor: "#FFFFFF",
      },
      HeaderButtonsStyle: {
        // folder Button Style
        folderButtonBg: "var(--project-base)",
        folderButtonClr: "var(--project-base-text)",
        // Hover Style
        folderButtonHoverBg: "rgba(from var(--project-base) r g b / 90%)",
        folderButtonHoverClr: "var(--project-base-text)",

        // Add Button Style
        addButtonBg: "var(--project-base)",
        addButtonClr: "var(--project-base-text)",
        // Hover Style
        addButtonHoverBg: "rgba(from var(--project-base) r g b / 90%)",
        addButtonHoverClr: "var(--project-base-text)",

        // delete Button Style
        deleteButtonBg: "rgba(224, 54, 78, 0.08)",
        deleteButtonClr: "#E0364E",
        // Hover Style
        deleteButtonHoverBg: "#E0364E",
        deleteButtonHoverClr: "#fff",

        // Submit Button Style
        submitButtonBg: "var(--project-base)",
        submitButtonClr: "var(--project-base-text)",
        // Hover Style
        submitButtonHoverBg: "rgba(from var(--project-base) r g b / 90%)",
        submitButtonHoverClr: "var(--project-base-text)",

        // disabled Submit Button Style
        disabledSubmitButtonBg: "var(--project-base)",
        disabledSubmitButtonClr: "var(--project-base-text)",
        // Hover Style
        disabledSubmitButtonHoverBg:
          "rgba(from var(--project-base) r g b / 90%)",
        disabledSubmitButtonHoverClr: "var(--project-base-text)",

        // Archive Button Style
        archiveButtonBg: "var(--project-base)",
        archiveButtonClr: "var(--project-base-text)",
        // Hover Style
        archiveButtonHoverBg: "rgba(from var(--project-base) r g b / 90%)",
        archiveButtonHoverClr: "var(--project-base-text)",

        // back button Style
        backButtonBg: " var(--project-base)",
        backButtonBorder: "rgba(12, 35, 64, 0.24)",
        backButtonClr: "var(--project-base-text)",
      },
      TreeSelect: {
        titleHeight: 36,
      },
    },
  },

  // Theme colors specific to dark theme
  darkNoxStandard: {
    name: "darkNoxStandard",
    key: "dark_light_nox_standard_theme",
    type: "dark",
    img: "NoxDarkStandardIcon",
    companyLogoHeight: "16",
    direction: "ltr",
    token: {
      grayClr: "var(--project-base-text)",
      // Spacing and sizes
      controlHeight: 35,
      // Font Family
      fontFamily: "Lexend",
    },
    components: {
      Menu: {
        verticalSidebarWidth: 260,
      },
      Table: {
        tableBg: "transparent",
        borderColor: "rgba(225, 227, 230, 0.25)",
        sortedBg: "#1a3052",
        fontSize: "16px",
        fontWeightStrong: "500",
        imgLocationClr: "#fff",
        rowHoverBg: "#436d97",
      },

      Select: {
        borderRadius: 12,
        selectorBg: "#0E2240",
        colorIcon: "#ffffff",
        colorPrimaryHover: "#ffffff",
        controlHeight: 56,
        colorBgElevated: "#0E2240",
        optionSelectedBg: "#1d4177",
        colorBorder: "rgba(225, 227, 230, 0.25)",
      },
      Input: {
        colorBgContainer: "#0E2240",
        colorBorder: "rgba(225, 227, 230, 0.25)",
        colorTextPlaceholder: "rgba(225, 227, 230, 1)",
        colorBgContainerDisabled: "#12233f",
        colorTextDisabled: "rgba(225,225,225,0.25)",
      },
      HeaderButtonsStyle: {
        // folder Button Style
        folderButtonBg: "var(--project-base)",
        folderButtonClr: "var(--project-base-text)",
        // Hover Style
        folderButtonHoverBg: "rgba(from var(--project-base) r g b / 90%)",
        folderButtonHoverClr: "var(--project-base-text)",

        // Add Button Style
        addButtonBg: "var(--project-base)",
        addButtonClr: "var(--project-base-text)",
        // Hover Style
        addButtonHoverBg: "rgba(from var(--project-base) r g b / 90%)",
        addButtonHoverClr: "var(--project-base-text)",

        // delete Button Style
        deleteButtonBg: "rgba(224, 54, 78, 0.08)",
        deleteButtonClr: "#E0364E",
        // Hover Style
        deleteButtonHoverBg: "#E0364E",
        deleteButtonHoverClr: "#fff",

        // Submit Button Style
        submitButtonBg: "var(--project-base)",
        submitButtonClr: "var(--project-base-text)",
        // Hover Style
        submitButtonHoverBg: "rgba(from var(--project-base) r g b / 90%)",
        submitButtonHoverClr: "var(--project-base-text)",

        // disabled Submit Button Style
        disabledSubmitButtonBg: "var(--project-base)",
        disabledSubmitButtonClr: "var(--project-base-text)",
        // Hover Style
        disabledSubmitButtonHoverBg:
          "rgba(from var(--project-base) r g b / 90%)",
        disabledSubmitButtonHoverClr: "var(--project-base-text)",

        // Archive Button Style
        archiveButtonBg: "var(--project-base)",
        archiveButtonClr: "var(--project-base-text)",
        // Hover Style
        archiveButtonHoverBg: "rgba(from var(--project-base) r g b / 90%)",
        archiveButtonHoverClr: "var(--project-base-text)",

        // back button Style
        backButtonBg: " var(--project-base)",
        backButtonBorder: "rgba(12, 35, 64, 0.24)",
        backButtonClr: "var(--project-base-text)",
      },
      TreeSelect: {
        titleHeight: 36,
      },
    },
  },
};

export { themes };
