import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { hideLoader } from "../../Redux/slices/loader.reducer";
import { useTranslation } from "react-i18next";

const NotFound = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    setTimeout(() => {
      dispatch(hideLoader());
    }, 100);
  }, []);
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-base text-center">
      <h1 className="text-6xl font-bold text-gray-base">404</h1>
      <p className="mt-4 text-lg text-gray-base">{t("page_not_found")}</p>
    </div>
  );
};

export default NotFound;
